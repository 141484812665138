import { useState, createRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Input,
  Typography,
} from "@material-tailwind/react";
import { GrPowerReset } from "react-icons/gr";
import {
  useDetailedTranferScheduleQuery,
  useTransferOutOfStockMutation,
  useNewTransferRawMatsMutation,
  useSaveTransItemPlasInputMutation,
} from "../../../../store";
import { HashLoader } from "react-spinners";
import SignatureCanvas from "react-signature-canvas";
import { toast, ToastContainer } from "react-toastify";
import moment from "moment";
import { useSaveTranferProdSigMutation } from "../../../../store/apis/TransferApi";

function ProductionItemTranfer({ transferId, refetch_trans, trans_number }) {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const [clickDisable, setClickDisable] = useState(false)
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [open, setOpen] = useState(false);
  const [actualRequireQty, setActualRequireQty] = useState([]);
  let id = transferId;
  let client_id = account_detailed.client.id;
  const { data, isLoading, error, refetch } = useDetailedTranferScheduleQuery({
    id,
    client_id,
  });
  const [postTransfer, {isLoading: postIsloading, }] = useNewTransferRawMatsMutation();
  const [transOutOfStock] = useTransferOutOfStockMutation();
  const [saveTransferSignature] = useSaveTranferProdSigMutation();
  const [save_tran_item_input] = useSaveTransItemPlasInputMutation();

  // implement refetching of data from api
  useEffect(() => {
    refetch();
  }, [refetch]);
  // to plot the remarks from ap

  // const scheduleProd = data?.data.ingredients.find(
  //   (val) => val.remarks === "Insufficient"
  // );
  const raw_to_prod = data?.data.ingredients.find(
    (val) => val.is_raw_to_prod === true
  );

  const api_domain = process.env.REACT_APP_API_DOMAIN;
  const product_line = data?.data.product_line;
  const for_plastic = data?.data.for_plastic;

  // for signature img
  const signatureName = api_domain + data?.data.schedule.approver;
  const TableHeader = ["Description", "Unit Measure", "Requested Quantity"];

  if (
    product_line === 6 ||
    product_line === 7 ||
    product_line === 8 ||
    for_plastic === true ||
    Boolean(raw_to_prod) === true
  ) {
    TableHeader.push("Actual Requested Quantity");
  }
  TableHeader.push("Stock on Hand");
  TableHeader.push("Remarks");

  const burgerMachineLogo = "../images/BurgerMachineLogo.jpg";
  const signatureCanvas = [
    {
      id: "1",
      label: "issuer",
      ref: createRef(),
    },
    {
      id: "2",
      label: "receiver",
      ref: createRef(),
    },
  ];
  let content;
  const [sigData, setSigData] = useState([]);
  const boxResetClasses =
    "flex justify-center items-center h-[105px]  w-[60px] bg-[#0B6E4F] shadow-md rounded-r-xl";

  const schedule_prod = data?.data.ingredients.map((pr) => {
    return {
      id: pr.id,
      name: pr.name,
      requestQty: pr.total,
    };
  });

  /// handle toggle of modal
  const handleOpen = () => setOpen(!open);
  /// handle reset of signature canvas
  const clearSignature = (canvasRef) => {
    canvasRef.current.isEmpty() ? console.log("") : canvasRef.current.clear();
  };

  // handle log signature
  const handleSignature = () => {
    const result = [];
    signatureCanvas.map((s) => {
      if (s.ref.current !== null) {
        const signatureData = s.ref.current.toDataURL();
        const base64Signature = signatureData.split(",");
        result.push({
          img: base64Signature[1],
          label: s.label,
        });
      }
      return result;
    });
    setSigData(result);
  };

  const handleSave = async () => {
    setClickDisable(true)
    const detailProduct = {
      user_id: user_info.id,
      id: transferId,
    };

    if (product_line === 6 || for_plastic === true) {
      detailProduct["product"] = actualRequireQty;
    } else {
      detailProduct["product"] = schedule_prod;
    }
    const sea = await postTransfer(detailProduct).unwrap();
    // after approved the request
    if (sea.success === true) {
      setClickDisable(false)
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    setOpen(!open);
    refetch_trans();
  };

  /// conditioning for fetching of data from api
  if (isLoading) {
    return <HashLoader />;
  } else if (error) {
    return <div>Refresh the page...</div>;
  } else {
    content = (
      <>
        {data.data.ingredients?.map((sp, i) => {
          const isLast = sp.length - 1;
          const classes = isLast
            ? "p-4 text-[#252525] text-sm"
            : "p-4 border-b border-blue-gray-50 text-[#252525] text-sm";
          return (
            <tr key={i}>
              <td className={classes}>{sp.name}</td>
              {for_plastic === true ? (
                <td className={classes}>{sp.unit_measurement}</td>
              ) : (
                <td className={classes}>
                  {product_line === 6 ||  for_plastic === true || sp.is_raw_to_prod === true || product_line === 8
                    ? sp.unit_measurement
                    : "PKGS"}
                </td>
              )}
              <td className={classes}>{sp.total}</td>
              {product_line === 6 ||
              product_line === 7 ||
              product_line === 8 ||
              for_plastic === true ||
              sp.is_raw_to_prod === true ? (
                <td className={classes}>
                  <Input
                    required
                    defaultValue={sp.ai ? sp.ai : 0}
                    // disabled={sp.remarks === "Insufficient"}
                    onBlur={(evt) =>
                      updateValueByKey(
                        evt.target.value,
                        sp.name,
                        sp.stock_on_hand,
                        i,
                        sp.id
                      )
                    }
                  />
                </td>
              ) : (
                Boolean(raw_to_prod) === true ? (<td className={classes}>N|A</td>) : ("")
              )}
              <td className={classes}>{sp.stock_on_hand}</td>
              <td className={classes}>
                <Typography
                  className={
                    sp.remarks === "Sufficient"
                      ? "text-green-800"
                      : "text-red-800"
                  }
                >
                  {sp.remarks}
                </Typography>
              </td>
            </tr>
          );
        })}
      </>
    );
  }

  const outOfStockSave = async () => {
    const formOut = {
      id: transferId,
      client_id: account_detailed.client.id,
      user_id: user_info.id,
    };
    const toos = await transOutOfStock(formOut).unwrap();
    if (toos.success === true) {
      toast.success(toos.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(toos.message, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetch();
    refetch_trans();
    handleOpen();
  };

  const saveSignature = async () => {
    console.log(sigData);
    let formData = {
      id: transferId,
      signature: sigData,
    };

    const sts = await saveTransferSignature(formData).unwrap();
    if (sts.success === true) {
      toast.success(sts.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sts.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetch();
    handleOpen();
  };

  const disableSubmit = () => {
    let flag = true;

    if (product_line == 6) {

      if (
        data?.data.issuer_signature != null &&
        data?.data.receiver_signature != null
      ) {
        flag = false;
      }
    } else {
      if (
        // !Boolean(scheduleProd) === true &&
        data?.data.issuer_signature != null &&
        data?.data.receiver_signature != null
      ) {
        flag = false;
      }
    }
    return flag;
  };

  const updateValueByKey = async (value, name, stock_on_hand, i, id) => {

    let formData = {
      tpl_id: transferId,
      item_id: id,
      actual_input: value,
    };
    const stii = await save_tran_item_input(formData).unwrap();
    if (stii.success === true) {
      toast.success(stii.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(stii.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetch();
  };

  return (
    <>
      <Button onClick={handleOpen} variant="gradient" color="teal" size="sm">
        Transfer Slip
      </Button>
      <Dialog open={open} handler={handleOpen} size="xl">
        <ToastContainer />
        <DialogHeader className="w-full space-y-3 md:space-y-0 md:space-x-10 rounded-sm flex flex-col md:flex-row justify-center">
          <img
            src={burgerMachineLogo}
            className="aspect-auto w-72"
            alt="logo"
          />
          <Typography
            className="text-green-900 text-2xl lg:text-3xl font-bold"
            component={"span"}
          >
            Production Transfer Slip
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-auto max-h-[50vh] md:max-h-[60vh] container">
          <div className="md:flex md:space-x-3">
            <div className=" md:w-[80%] space-y-3">
              <Input
                type="text"
                label="Issuance From"
                value={Boolean(raw_to_prod) === true ? 'Warehouse' : data.data?.issuance_from}
                readOnly
              />
              <Input
                type="text"
                label="Transfer To"
                value={data.data?.transfer_to}
                readOnly
              />
              <Input
                type="text"
                label="Transfer Request Date"
                value={data.data?.transfer_request_date}
                readOnly
              />
              <Input
                label="Actual Issued Date"
                value={moment(new Date()).format("YYYY-MM-DD")}
                readOnly
              />
            </div>
            <div className="md:w-[80%] space-y-3 mt-3 md:mt-0 ">
              <Input
                type="text"
                label="Name of Issuer"
                value="Leoni Fe Plarisan"
              />
              <Input
                type="text"
                label="Name of Receiver"
                readOnly
                value={data.data?.requested_by}
              />
              <Input
                type="text"
                label="Transfer Tracking Number"
                value={trans_number}
                readOnly
              />
            </div>
          </div>
          <div className="mt-10 mx-5">
            <table className=" w-full table-auto text-left">
              <thead>
                <tr>
                  {TableHeader.map((head) => (
                    <th
                      key={head}
                      className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>{content}</tbody>
            </table>
          </div>
          <div className="md:flex justify-center items-center space-x-4">
            {signatureName === null ? (
              ""
            ) : (
              <div className="flex flex-col justify-center items-center mt-10 space-y-3 w-[100] ">
                <div className="border border-blue-gray-300 rounded-lg py-1 flex justify-center">
                  <img src={signatureName} alt="" />
                </div>
                <div>
                  <Typography>{data?.data.name}</Typography>
                </div>
              </div>
            )}
            {data.data.issuer_signature === null ? (
              ""
            ) : (
              <div className="flex flex-col justify-center items-center mt-10 space-y-3 w-[100] ">
                <div className="border border-blue-gray-300 rounded-lg py-1 flex justify-center">
                  <img src={api_domain + data.data.issuer_signature} alt="" />
                </div>
                <div>
                  <Typography>{data?.data.warehouse_supervisor}</Typography>
                </div>
              </div>
            )}
            {data.data.receiver_signature === null ? (
              ""
            ) : (
              <div className="flex flex-col justify-center items-center mt-10 space-y-3 w-[100] ">
                <div className="border border-blue-gray-300 rounded-lg py-1 flex justify-center">
                  <img src={api_domain + data.data.receiver_signature} alt="" />
                </div>
                <div>
                  <Typography>{data?.data.requested_by}</Typography>
                </div>
              </div>
            )}
            <div className="block mt-3">
              <div className="md:flex justify-center mx-5 md:mx-0 md:space-x-3 space-y-7 md:space-y-0">
                {signatureCanvas.map((sig, index) => {
                  if (data?.data.receiver_signature === null) {
                    if (sig.label === "receiver") {
                      return (
                        <>
                          <div key={sig.id} className="flex flex-col">
                            <div className="flex flex-row mt-6 md:mt-8">
                              <SignatureCanvas
                                penColor="black"
                                canvasProps={{
                                  width: 200,
                                  height: 100,
                                  className:
                                    "border-black border-2 rounded-l-xl",
                                }}
                                ref={sig.ref}
                                onEnd={handleSignature}
                              />
                              <div className={boxResetClasses}>
                                <GrPowerReset
                                  size={"30px"}
                                  onClick={() => clearSignature(sig.ref)}
                                  className="cursor-pointer"
                                  color="white"
                                />
                              </div>
                            </div>
                            <Typography className="text-center mt-[-150px] md:mt-3">
                              {sig.label === "receiver"
                                ? data?.data.requested_by
                                : ""}
                            </Typography>
                          </div>
                        </>
                      );
                    }
                  }
                  if (data?.data.issuer_signature === null) {
                    if (sig.label === "issuer") {
                      return (
                        <>
                          <div key={sig.id} className="flex flex-col">
                            <div className="flex flex-row mt-6 md:mt-8">
                              <SignatureCanvas
                                penColor="black"
                                canvasProps={{
                                  width: 200,
                                  height: 100,
                                  className:
                                    "border-black border-2 rounded-l-xl",
                                }}
                                ref={sig.ref}
                                onEnd={handleSignature}
                              />
                              <div className={boxResetClasses}>
                                <GrPowerReset
                                  size={"30px"}
                                  onClick={() => clearSignature(sig.ref)}
                                  className="cursor-pointer"
                                  color="white"
                                />
                              </div>
                            </div>
                            <Typography className="text-center mt-[-150px] md:mt-3">
                              {sig.label === "issuer"
                                ? data?.data.warehouse_supervisor
                                : ""}
                            </Typography>
                          </div>
                        </>
                      );
                    }
                  }
                })}
              </div>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button className="bg-blue-900 ml-3 mr-1" onClick={saveSignature}>
            <span>Save</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={handleSave}
            className="mr-1"
            disabled={disableSubmit() || clickDisable === true}
          >
            <span>Transfer</span>
          </Button>
          <Button
            variant="gradient"
            color="red"
            onClick={outOfStockSave}
            // disabled={!Boolean(scheduleProd)}
          >
            <span>Out of Stock</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
export default ProductionItemTranfer;
