import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TabComponent from "../page_components/TabComponent";
import PurchaseRequestFormItemList from "../modal_form_components/Warehouse/WarehousePurchaseOrder/PurchaseRequestFormItemList";
import CompleteTableVendorDbView from "../page_components/Complete_Table_VendorDb_View";
import ExtractPO from "../modal_form_components/Warehouse/WarehousePurchaseOrder//ExtractPO";
import { GrPowerReset } from "react-icons/gr";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactSignatureCanvas from "react-signature-canvas";
import PageTitle from "../page_components/PageTitle";
import moment from "moment";
import { toast } from "react-toastify";
import { LuPackagePlus } from "react-icons/lu";
import {
  Typography,
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Card,
  CardBody,
  Input,
} from "@material-tailwind/react";
import { useAddRequestItemMutation } from "../../store";
import CompleteTableView from "../page_components/Complete_Table_View";

const Warehouse_PurchaseOrder = () => {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const token = localStorage.getItem("mytoken");
  let navigate = useNavigate();
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

  useEffect(() => {
    if (
      account_detailed.job_position == 1 ||
      account_detailed.job_position == 5 ||
      account_detailed.job_position == 6 ||
      account_detailed.job_position == 7 ||
      account_detailed.job_position == 8 ||
      account_detailed.user_type == 1 ||
      account_detailed.job_position === 13 ||
      account_detailed.job_position === 14 ||
      account_detailed.job_position === 17
    ) {
      // pass
    } else {
      return;
    }
  }, [token]);

  const trclasses = "text-center p-4 border-b border-blue-gray-50";
  // const signatureBoxclasses = "flex mb-1 mt-5 justify-center";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  // const viewDescription = "See all Purchase Order information";

  const insideAddFormTblHead = [
    "Items",
    "Unit Measure",
    "Stock on Hand",
  ];

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  let pastThreeMonthsDates = [];

  for (let i = 1; i < 4; i++) {
    const month = (currentMonth - i + 12) % 12;
    const year = currentYear - Math.floor((currentMonth - i + 12) / 12);
    pastThreeMonthsDates.unshift(
      moment(new Date(year, month, 1)).format("MMM")
    );
  }
  for (let ptmd in pastThreeMonthsDates) {
    let each_ptmd = pastThreeMonthsDates[ptmd];
    insideAddFormTblHead.push(each_ptmd);
  }

  const TABLE_HEAD = ["PO Number", "Request Date", "Details", "Status"];

  insideAddFormTblHead.push(
    "Stock Level",
    "Required Quantity",
    // "Required Quantity into Kilos",
    "Actual Quantity",
    "Delivery Date"
  );

  const VendorTableHead = ["Item", "Vendor", "Status"];
  const ItemTableHead = ["Id", "Name", "Unit Measurement"];
  const buttonForModal = "Add Purchase Request";
  const extractPOButton = <ExtractPO />;

  // Model usestate
  const [openAdd, setOpenAdd] = useState(false);

  const openAddRequestModal = () => setOpenAdd(!openAdd);
  const [showDate, setShowDate] = useState(false);
  // Variable for add Purchase
  const [addselectedDepartment, setAddSelectedDepartment] = useState("");
  const [addSelectedCategory, setAddSelectedCategory] = useState("");
  const [signature, setSignature] = useState();
  const [addRequestDate, setAddRequestDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [unitMeasure, setUnitMeasure] = useState("");
  const [stockLevel, setStockLevel] = useState("");
  const [stockLevelPast3, setStockLevelPast3] = useState([]);
  const [stockLevelPast2, setStockLevelPast2] = useState([]);
  const [stockLevelPast1, setStockLevelPast1] = useState([]);
  const [requireQty, setRequireQty] = useState([]);
  const [deliveryDate, setDeliveryDate] = useState([]);
  const [selectItemId, setSelectItemId] = useState([]);
  const [selectItemName, setSelectItemName] = useState([]);
  const [itemList, setItemList] = useState([]);
  const [submitDisable, setSubmitDisable] = useState(false);
  const [submitHide, setSubmitHide] = useState(true);

  // handle select of items from child component
  const handleSelect = (values1, values2, values3, value4) => {
    setSelectItemId(values1);
    setSelectItemName(values2);
    setItemList(value4);
  };

  const TABS = [];
  let TABLE_ROWS = [];

  const data = [
    {
      label: "PO List",
      value: "polist",
      desc: (
        <CompleteTableView
          TABS={TABS}
          TABLE_HEAD={TABLE_HEAD}
          buttonForModal={buttonForModal}
          extractPOButton={extractPOButton}
          TABLE_ROWS={TABLE_ROWS}
          onTopButtonClick={openAddRequestModal}
        />
      ),
    },
    {
      label: "Vendor Database",
      value: "vendordatabse",
      desc: (
        <CompleteTableVendorDbView
          VendorTableHead={VendorTableHead}
          buttonForModal={buttonForModal}
          TABLE_ROWS={TABLE_ROWS}
          onTopButtonClick={openAddRequestModal}
        />
      ),
    },

  ];

  // clear the signature pad
  function handleReset() {
    signature.clear();
    setSignature("");
  }

  const selectedItemsArray = Array.isArray(selectItemName)
    ? selectItemName
    : [];

  const [saveRequestItem] = useAddRequestItemMutation();
  const handleSubmit = async (event) => {
    setSubmitHide(false);
    event.preventDefault();
    let item = [];
    for (let index = 0; index < selectedItemsArray.length; index++) {
      let obj = {};
      if (selectItemId[index]) {
        obj.id = selectItemId[index];
      }
      if (unitMeasure[index]) {
        obj.unit_measurement = unitMeasure[index];
      }
      if (stockLevel[index]) {
        obj.stock_level = stockLevel[index];
      }
      if (stockLevelPast3[index]) {
        obj.past_3_stock_level = stockLevelPast3[index];
      }
      if (stockLevelPast2[index]) {
        obj.past_2_stock_level = stockLevelPast2[index];
      }
      if (stockLevelPast1[index]) {
        obj.past_1_stock_level = stockLevelPast1[index];
      }
      if (requireQty[index]) {
        obj.required_qty = requireQty[index];
      }
      if (deliveryDate[index]) {
        obj.delivery_date = moment(new Date(deliveryDate[index])).format(
          "YYYY-MM-DD"
        );
      }
      item[index] = obj;
    }

    let sig_split = signature.toDataURL().split(",");
    let form = {
      department_id: account_detailed.department,
      category: addSelectedCategory,
      date: addRequestDate,
      user_id: parseInt(user_info.id),
      signatures: sig_split[1],
      client_id: account_detailed.client.id,
      items: item,
    };
    const sea = await saveRequestItem(form).unwrap();
    if (sea.success === true) {
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      setSubmitHide(true);
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }

    setTimeout(() => {
      setSubmitDisable(false);
    }, 1000);

    setSelectItemId([]);
    setSelectItemName([]);
    setAddSelectedDepartment("");
    setAddSelectedCategory("");
    setItemList([]);
    setOpenAdd(!openAdd);
  };

  const inputActualQuantity = (value, index) => {
    const rqry_con = [...requireQty];
    rqry_con[index] = value;
    setRequireQty(rqry_con);
  }

  function handleDelChange(
    date,
    index,
    spast3,
    spast2,
    spast1,
    total_sl,
    required_qty
  ) {
    const newDate = [...deliveryDate];
    newDate[index] = date;
    setDeliveryDate(newDate);

    const past3 = [...stockLevelPast3];
    past3[index] = spast3;
    setStockLevelPast3(past3);

    const past2 = [...stockLevelPast2];
    past2[index] = spast2;
    setStockLevelPast2(past2);

    const past1 = [...stockLevelPast1];
    past1[index] = spast1;
    setStockLevelPast1(past1);

    const stocklvl = [...stockLevel];
    stocklvl[index] = total_sl;
    setStockLevel(stocklvl);
  }

  const select_category = (evt) => {
    setAddSelectedCategory(evt);
    setItemList([]);
    setSelectItemId([]);
    setSelectItemName([]);
    handleReset(0);
    setSubmitDisable(false);
  };

  const onSignature = (data) => {
    setSignature(data);
    setSubmitDisable(true);
  };

  const submitingDisable = () => {
    return itemList.length === deliveryDate.length && submitDisable === true && submitHide == true;
  };

  return (
    <>
      <div className="container mx-auto px-4 mt-5 lg:mt-0">
        <div className="md:hidden flex justify-end mt-[-40px] mb-5">
          <PageTitle />
        </div>
        <div className="flex flex-row justify-center md:justify-between items-center ml-3 lg:ml-10 mr-5 lg:mr-28 mb-10 md:mb-16 lg:mb-5">
          <div className="flex items-center space-x-2">
            <LuPackagePlus className="h-10 w-10  text-[#137944]" />
            <Typography className="text-[#137944] font-bold text-3xl tracking-normal">
              Purchase Order
            </Typography>
          </div>
          <div className="hidden md:block">
            <PageTitle />
          </div>
        </div>
        <div className=" w-[105%] ml-[-2%] md:w-[110%] md:ml-[-5%] lg:w-[100%] lg:ml-[0%]">
          <TabComponent data={data} />
        </div>
      </div>
      {/* Dialog for add purchase */}
      <Dialog
        open={openAdd}
        size="xxl"
        handler={openAddRequestModal}
        className="relative max-h-min"
      >
        <DialogHeader className="bg-[#0B6E4F] rounded-sm flex justify-center">
          <Typography className="text-white" variant="h5">
            Purchase Request Form
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto mt-10">
          <Card>
            <CardBody className="px-0 md:px-8 bg-gray-50">
              <div className="relative h-10 w-full md:mx-5 mb-2">
                <div className="flex justify-end items-center mr-10 space-x-2">
                  <Typography color="black">Date:</Typography>
                  <Typography>
                    {moment(new Date()).format("YYYY-MM-DD")}
                  </Typography>
                </div>
              </div>
              <div className="grid grid-flow-row col-span-1 grid-cols-1 md:col-span-2 md:grid-cols-2 items-center gap-2 mx-5 mb-10 space-y-5 md:space-y-0">
                <div className="relative h-10 w-full">
                  {/* fist select */}
                  <select
                    label="Department"
                    // onChange={(evt) => select_department(evt.target.value)}
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    disabled
                  >
                    <option
                      value={account_detailed.department}
                      selected
                      className="text-center"
                    >
                      {account_detailed.department_name}
                    </option>
                  </select>
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Department
                  </label>
                </div>
                <div className="relative h-10 w-full">
                  <select
                    label="Category"
                    onChange={(evt) => select_category(evt.target.value)}
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  >
                    <option disabled selected className="text-center">
                      ----- Select Category -----
                    </option>
                    <option value="1">Raw Materials</option>
                    <option value="2">Foods duty</option>
                    <option value="3">Beverages</option>
                    <option value="4">Consumables</option>
                    <option value="5">Hardware</option>
                    <option value="7">Plastic</option>
                    <option value="8">Office Supplies</option>
                  </select>
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Category
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-16 m-4">
                <div className="overflow-x-auto">
                  <table className="table-auto w-full min-w-max text-left">
                    <thead>
                      <tr>
                        <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"></th>
                        {insideAddFormTblHead.map((head) => (
                          <th
                            key={("requiredqty", head)}
                            className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {itemList.map((item, index) => {
                        let splitV4 = item.split(",");
                        let soh = splitV4[7]
                        let required_qty = 0;
                        let converted_to_kilo = 0
                        let soh_or_kilo = 0
                        let total_sl =
                          parseInt(splitV4[4]) +
                          parseInt(splitV4[5]) +
                          parseInt(splitV4[6]);
                        total_sl = (total_sl / 3) * 2;
                        total_sl = total_sl.toFixed(2);

                        // let converted_to_kilo = parseFloat(total_sl);

                        if (splitV4[10] === 'true'){
                            soh = splitV4[3]
                            total_sl = parseFloat(
                              total_sl / parseInt(splitV4[8])
                            ).toFixed(2)
                            // converted_to_kilo = Math.round(
                            //   required_qty / parseInt(splitV4[8])
                            // );
                            soh_or_kilo = parseInt(splitV4[3])
                        }else{
                          soh_or_kilo = parseInt(splitV4[7])
                        }
                        if (!total_sl === 0 || total_sl > soh_or_kilo) {
                          required_qty = total_sl - soh_or_kilo;
                        }

                        return (
                          <>
                            <tr key={index}>
                              <td></td>
                              <td className="text-center">{splitV4[1]}</td>
                              <td className="text-center">{splitV4[2]}</td>
                              <td className={trclasses}>{soh}</td>
                              <td className={trclasses}>{splitV4[4]}</td>
                              <td className={trclasses}>{splitV4[5]}</td>
                              <td className={trclasses}>{splitV4[6]}</td>
                              <td className={trclasses}>{total_sl}</td>
                              <td className={trclasses}>
                                {required_qty != null ? Math.round(required_qty) : '0'}
                              </td>
                              {/* <td className={trclasses}>{required_qty.toFixed(2)}</td> */}
                              <td>
                                <Input
                                  label="Actual Quantity"
                                  required
                                  onChange={(evt) => inputActualQuantity(evt.target.value, index)}
                                />
                              </td>
                              <td className={trclasses}>
                                <DatePicker
                                  className="bordered border p-1 border-blue-gray-300 rounded-lg"
                                  selected={deliveryDate[index]}
                                  required
                                  onChange={(date) =>
                                    handleDelChange(
                                      date,
                                      index,
                                      splitV4[4],
                                      splitV4[5],
                                      splitV4[6],
                                      total_sl,
                                      converted_to_kilo
                                    )
                                  }
                                  dateFormat="yyyy/MM/dd"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  placeholderText="Select"
                                />
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                  <div className="mt-10 md:mt-5 ml-3">
                    <PurchaseRequestFormItemList
                      onSelectItem={handleSelect}
                      addselectedDepartment={addselectedDepartment}
                      addselectedCategory={addSelectedCategory}
                    />
                  </div>
                  <div className="flex flex-col items-center space-y-2 md:my-2 mt-10">
                    <div className="flex flex-row">
                      <ReactSignatureCanvas
                        required
                        penColor="black"
                        canvasProps={{
                          width: 200,
                          height: 100,
                          className: "border-black border-2 rounded-s-xl",
                        }}
                        onEnd={(data) => onSignature(data)}
                        ref={(data) => setSignature(data)}
                      />
                      <div className={boxResetClasses}>
                        <GrPowerReset
                          size={"30px"}
                          onClick={() => handleReset(0)}
                          className="cursor-pointer"
                          color="white"
                        />
                      </div>
                    </div>
                    <div className="flex flex-col space-y-2">
                      <Typography className="text-center">
                        {user_info.first_name + " " + user_info.last_name}
                      </Typography>
                      <Typography className="text-center">
                        {moment(new Date()).format("YYYY-MM-DD")}
                      </Typography>
                    </div>
                  </div>
                </div>
              </div>
            </CardBody>
          </Card>
        </DialogBody>
        <DialogFooter className="mt-14">
          <Button
            variant="text"
            color="red"
            className="mr-1"
            onClick={openAddRequestModal}
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={handleSubmit}
            disabled={!submitingDisable()}
          >
            Submit
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};
export default Warehouse_PurchaseOrder;
