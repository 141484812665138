import { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Checkbox,
} from "@material-tailwind/react";
import { GrPowerReset } from "react-icons/gr";
import ReactSignatureCanvas from "react-signature-canvas";
import DatePicker from "react-datepicker";
import BakeryProductList from "../../table_components/Production/BakeryProductList";
import PattyProductList from "../../table_components/Production/PattyProductList";
import SandwichLineProd from "../../table_components/Production/SandwichLineProd";
import KetsupProductList from "../../table_components/Production/KetsupProductList";
import CakePastriesProdList from "../../table_components/Production/CakePastriesProdList";
import PremixesList from "../../table_components/Production/PremixesList";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { useAddProductionScheduleMutation } from "../../../store/apis/ProductionApi";
import Breadcrumbs from "../../table_components/Production/Breadcrumbs";
import BreadcrumbsBuns from "../../table_components/Production/BreadCrumbsBun";


function CreateProdSched({ refetch }) {
  const user_info = JSON.parse(
    localStorage.getItem("user_info")
  );
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [open, setOpen] = useState(false);
  const [checkProd, setCheckProd] = useState([]);
  const [prodCat, setProdCat] = useState(false);
  const [showDate, setShowDate] = useState(false);
  const [signature, setSignature] = useState();
  const [splitSignature, setSplitSignature] = useState();
  const [signatureVal, setsignatureVal] = useState(false);
  const [bakeryInput, setBakeryInput] = useState({});
  const [inputPatty, setInputPatty] = useState({});
  const [inputSandwichLine, setInputSandwichLine] = useState({});
  const [inputCatsup, setInputCatsup] = useState({});
  const [inputCakePastries, setInputCakePastries] = useState({});
  const [inputPremixes, setInputPremixes] = useState({});
  const [inputBreadCrumbsBuns, setInputBreadCrumbsBuns] = useState({});
  const [inputBreadCrumbs, setInputBreadCrumbs] = useState({});
  const [overAllBatch, setOverAllBatch] = useState(0);
  const [overAllPre, setOverAllPre] = useState(0);
  const [disableGenareteBTN, setDisableGenarateBTM] = useState(false);

  const [
    bakerySavedFlag,
    setBakerySavedFlag
  ] = useState(false);

  const [
    pattySavedFlag,
    setPattySavedFlag
  ] = useState(false);

  const [
    sandwich_lineSavedFlag,
    setSandwichLineSavedFlag
  ] = useState(false);

  const [
    catsupSavedFlag,
    setCatsupSavedFlag
  ] = useState(false);
  const [
    cake_pastriesSavedFlag,
    setCakePastriesSavedFlag
  ] = useState(false);
  const [
    premixesSavedFlag,
    setPremixesSavedFlag
  ] = useState(false);
  const [
    breadCrumbsBunsFlag,
    setBreadCrumbsBunsFlag
  ] = useState(false);
  const [
    breadCrumbsFlag,
    setBreadCrumbsFlag
  ] = useState(false);

  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [addProductionSched, {isLoading}] = useAddProductionScheduleMutation();
  const prodLine = []

  if (account_detailed && account_detailed.job_position === 11 || account_detailed.job_position === 1) {
    prodLine.push(
      {
        id: 2,
        name: "Patty",
      },
      {
        id: 3,
        name: "Sandwich Line",
      }
    )
  }
  if (account_detailed && account_detailed.job_position === 10 || account_detailed.job_position === 1) {
    prodLine.push(
      {
        id: 1,
        name: "Bakery",
      },
      {
        id: 8,
        name: "Bread Crumbs"
      },
      {
        id: 7,
        name: "Bread Crumbs Buns"
      }
    )
  }
  if (account_detailed && account_detailed.job_position === 12 || account_detailed.job_position === 1) {
    prodLine.push(
      {
        id: 4,
        name: "Catsup",
      },
      {
        id: 5,
        name: "Cake and Pastries",
      }
    )
  }
  if (account_detailed && account_detailed.job_position === 1 || account_detailed.job_position === 19 || account_detailed.job_position === 10) {
    prodLine.push({
      id: 6,
      name: "Premixes",
    })
  }

  function calculateSum(arr) {
    var sum = 0;
    for (var i = 0; i < arr.length; i++) {
      sum += arr[i];
    }
    return sum;
  }
  const burgerMachineLogo = "../images/BurgerMachineLogo.jpg";

  // handle closing of parent modal
  const handleOpen = () => setOpen(!open);
  // handle closing of child modal
  const openProdCat = () => setProdCat(!prodCat);
  // toggle the date picker
  const openDatePicker = () => setShowDate(!showDate);
  // handle change of checkbox for production line
  const handleChangeProd = (e) => {
    const checkVal = e.target.checked;
    const value = e.target.id;

    if (checkVal) {
      setCheckProd([...checkProd, value]);
    } else {
      setCheckProd((prev) => {
        return prev.filter((id) => {
          return id !== value;
        });
      });
    }
  };
  const handleReset = () => {
    signature.clear();
    setSignature("");
    setsignatureVal(false);
  };

  const set = () => {
    signature.isEmpty() ? setsignatureVal(false) : setsignatureVal(true);
    let sig_split = signature.toDataURL().split(",");
    setSplitSignature(sig_split[1]);
  };

  let handleBakeryInput = (value) => {
    setBakeryInput(value)
    setOverAllBatch(calculateSum(
      [
        parseInt(
          Object.keys(bakeryInput).length > 0 ? bakeryInput.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputPatty).length > 0 ? inputPatty.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputSandwichLine).length > 0 ? inputSandwichLine.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputCatsup).length > 0 ? inputCatsup.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputCakePastries).length > 0 ? inputCakePastries.inputs.length : 0
        )
      ]
    ))
  }

  let handlePattyInput = (value) => {
    setInputPatty(value)
    setOverAllBatch(calculateSum(
      [
        parseInt(
          Object.keys(bakeryInput).length > 0 ? bakeryInput.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputPatty).length > 0 ? inputPatty.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputSandwichLine).length > 0 ? inputSandwichLine.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputCatsup).length > 0 ? inputCatsup.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputCakePastries).length > 0 ? inputCakePastries.inputs.length : 0
        )
      ]
    ))
  }

  let handleSandwichLineInput = (value) => {
    setInputSandwichLine(value)
    setOverAllBatch(calculateSum(
      [
        parseInt(
          Object.keys(bakeryInput).length > 0 ? bakeryInput.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputPatty).length > 0 ? inputPatty.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputSandwichLine).length > 0 ? inputSandwichLine.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputCatsup).length > 0 ? inputCatsup.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputCakePastries).length > 0 ? inputCakePastries.inputs.length : 0
        )
      ]
    ))
  }

  let handleCatsupInput = (value) => {
    setInputCatsup(value)
    setOverAllBatch(calculateSum(
      [
        parseInt(
          Object.keys(bakeryInput).length > 0 ? bakeryInput.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputPatty).length > 0 ? inputPatty.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputSandwichLine).length > 0 ? inputSandwichLine.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputCatsup).length > 0 ? inputCatsup.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputCakePastries).length > 0 ? inputCakePastries.inputs.length : 0
        )
      ]
    ))
  }

  let handleCakePastriesInput = (value) => {
    setInputCakePastries(value)
    setOverAllBatch(calculateSum(
      [
        parseInt(
          Object.keys(bakeryInput).length > 0 ? bakeryInput.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputPatty).length > 0 ? inputPatty.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputSandwichLine).length > 0 ? inputSandwichLine.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputCatsup).length > 0 ? inputCatsup.inputs.length : 0
        ),
        parseInt(
          Object.keys(inputCakePastries).length > 0 ? inputCakePastries.inputs.length : 0
        )
      ]
    ))
  }

  const handlePremixesInput = (value) => {
    setInputPremixes(value)
    setOverAllPre(calculateSum(
      [
        parseInt(
          Object.keys(inputPremixes).length > 0 ? inputPremixes.inputs.length : 0
        )
      ]
    ))
  }

  const handleBreadCrumbsBunsInput = (value) => {
    setInputBreadCrumbsBuns(value)
    setOverAllPre(calculateSum(
      [
        parseInt(
          Object.keys(inputBreadCrumbsBuns).length > 0 ? inputBreadCrumbsBuns.inputs.length : 0
        )
      ]
    ))
  }

  const handleBreadCrumbsInput = (value) => {
    setInputBreadCrumbs(value)
    setOverAllPre(calculateSum(
      [
        parseInt(
          Object.keys(inputBreadCrumbs).length > 0 ? inputBreadCrumbs.inputs.length : 0
        )
      ]
    ))
  }

  const handleSaveSchedule = async() => {
    setDisableGenarateBTM(true)
    let schedDate = moment(selectedDate).format("YYYY-MM-DD");
    let prod_data = [
      {
        bakery:bakeryInput
      },
      {
        patty:inputPatty
      },
      {
        sandwich_line:inputSandwichLine
      },
      {
        catsup:inputCatsup
      },
      {
        cake_pastries:inputCakePastries
      },
      {
        premixes:inputPremixes
      },
      {
        bread_crumbs_buns:inputBreadCrumbsBuns
      },
      {
        bread_crumbs:inputBreadCrumbs
      }
    ]

    const formData = {
      user_id: user_info.id,
      schedule_date: schedDate,
      products: prod_data,
      signature: splitSignature,
      total_batch: overAllBatch,
      total_pre_mix: overAllPre,
    };
    const sea = await addProductionSched(formData).unwrap();
     if (sea.success === true) {
      setDisableGenarateBTM(false)
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      setCheckProd([]);
      setBakeryInput({});
      setInputPatty({});
      setInputSandwichLine({});
      setInputCatsup({});
      setInputCakePastries({});
      setInputPremixes({});
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    handleOpen()
  }

  const generate_flag = () => {
    let result = true
    let bool_list = []
    if (checkProd.includes('1')){
      bool_list.push(bakerySavedFlag)
    }

    if (checkProd.includes('2')){
      bool_list.push(pattySavedFlag)
    }

    if (checkProd.includes('3')){
      bool_list.push(sandwich_lineSavedFlag)
    }

    if (checkProd.includes('4')){
      bool_list.push(catsupSavedFlag)
    }

    if (checkProd.includes('5')){
      bool_list.push(cake_pastriesSavedFlag)
    }

    if (checkProd.includes('6')){
      bool_list.push(premixesSavedFlag)
    }

    if (checkProd.includes('7')){
      bool_list.push(breadCrumbsBunsFlag)
    }
    if (checkProd.includes('8')){
      bool_list.push(breadCrumbsFlag)
    }
    const allTrue = bool_list.every(value => value === true);

    if (
      allTrue === true &&
      signatureVal == true){
      result = false
    }
    return result
  }

  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]">
        Create Schedule
      </Button>
      <Dialog
        open={open}
        handler={handleOpen}
        className="relative max-h-min"
        size="xxl"
      >
        <DialogHeader className="w-full space-y-3 md:space-y-0 md:space-x-10 rounded-sm flex flex-col md:flex-row justify-center">
          <img
            src={burgerMachineLogo}
            className="aspect-auto w-72"
            alt="bmlogo"
          />
          <Typography className="text-green-900 text-2xl font-bold">
            Create Production Schedule
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <form>
            <div className="flex flex-col md:flex-row md:justify-between md:items-center md:mx-10">
              <div className="flex flex-col md:flex-row md:items-center md:space-x-2 md:ml-5 mt-5 mb-2">
                <Typography>Production Date:</Typography>
                <DatePicker
                  className="bordered border p-1 border-blue-gray-300 rounded-lg"
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="yyyy/MM/dd"
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  onInputClick={openDatePicker}
                  onClickOutside={openDatePicker}
                  open={showDate}
                  placeholderText="Select"
                />
              </div>
              <div className="mt-2">
                <Button onClick={openProdCat} variant="gradient" size="sm">
                  Select Production Line
                </Button>
              </div>
            </div>
            <div className="mt-14 md:m-5 space-y-5">
              {checkProd.map((val) =>
                val.includes("1") ? (
                  <BakeryProductList
                    bakery_inputs={handleBakeryInput}
                    set_bakery_saved_flag={setBakerySavedFlag}
                  />
                ) : (
                  ""
                )
              )}
              {checkProd.map((val) =>
                val.includes("2") ? (
                  <PattyProductList
                    input_patty={handlePattyInput}
                    set_patty_saved_flag={setPattySavedFlag}
                  />
                ) : (
                  ""
                )
              )}
              {checkProd.map((val) =>
                val.includes("3") ? (
                  <SandwichLineProd
                    input_sandwich_line={handleSandwichLineInput}
                    set_sandwich_line_saved_flag={setSandwichLineSavedFlag}
                  />
                ) : (
                  ""
                )
              )}
              {checkProd.map((val) =>
                val.includes("4") ? (
                  <KetsupProductList
                    input_catsup={handleCatsupInput}
                    set_catsup_saved_flag={setCatsupSavedFlag}
                  />
                ) : (
                  ""
                )
              )}
              {checkProd.map((val) =>
                val.includes("5") ? (
                  <CakePastriesProdList
                    input_cake_pastries={handleCakePastriesInput}
                    set_cake_pastries_saved_flag={setCakePastriesSavedFlag}
                  />
                ) : (
                  ""
                )
              )}
              {checkProd.map((val) =>
                val.includes("6") ? (
                  <PremixesList
                    input_premixe={handlePremixesInput}
                    set_premixes_save_flag={setPremixesSavedFlag}
                  />
                ) : (
                  ""
                )
              )}
              {checkProd.map((val) =>
                val.includes("7") ? (
                  <BreadcrumbsBuns
                    input_breadcrums_buns={handleBreadCrumbsBunsInput}
                    set_breadcrums_buns_flag={setBreadCrumbsBunsFlag}
                  />
                ) : (
                  ""
                )
              )}
              {checkProd.map((val) =>
                val.includes("8") ? (
                  <Breadcrumbs
                    input_breadcrums={handleBreadCrumbsInput}
                    set_breadcrums_flag={setBreadCrumbsFlag}
                  />
                ) : (
                  ""
                )
              )}
            </div>
            <div>
              <Typography className="text-start mt-5">
                Over all batch:{" "}
                <span className=" text-[#252525] font-bold text-lg">
                  {overAllBatch}
                </span>
              </Typography>
            </div>
            <div className="col-span-1 flex items-center justify-center space-y-5">
              <Button
                className=" bg-[#1AA55D] mr-10"
                size="sm"
                onClick={handleSaveSchedule}
                disabled={generate_flag() || disableGenareteBTN === true}
              >
                Generate Transfer Slip
              </Button>
            </div>
            <div className="col-span-1 flex items-center justify-center space-y-5">
              <div className={signatureBoxclasses}>
                <ReactSignatureCanvas
                  penColor="black"
                  canvasProps={{
                    width: 200,
                    height: 100,
                    className: "border-black border-2 rounded-s-xl",
                  }}
                  ref={(data) => setSignature(data)}
                  onEnd={set}
                />
                <div className={boxResetClasses}>
                  <GrPowerReset
                    size={"30px"}
                    onClick={handleReset}
                    id="1"
                    className="cursor-pointer"
                    color="white"
                  />
                </div>
              </div>
            </div>
            <Typography className="text-center text-black" variant="h6">
              Herman F. Gatela
            </Typography>
          </form>
        <ToastContainer />
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            className="mr-3"
            onClick={handleOpen}
          >
            <span>Close</span>
          </Button>
          <Dialog open={prodCat} handler={openProdCat} size="xs">
            <DialogHeader className=" text-green-900">
              Production List
            </DialogHeader>
            <DialogBody>
              <div>
                {prodLine.map((pl) => (
                  <div className="flex justify-start">
                    <ul
                      key={pl.id}
                      className="flex justify-around items-center space-x-7 ml-15"
                    >
                      <li>
                        <Checkbox
                          value={pl.id}
                          id={pl.id}
                          checked={checkProd.includes(pl.id.toString())}
                          color="teal"
                          onChange={handleChangeProd}
                        />
                      </li>
                      <li>
                        <Typography className="text-lg">{pl.name}</Typography>
                      </li>
                    </ul>
                  </div>
                ))}
              </div>
            </DialogBody>
            <DialogFooter>
              <Button variant="text" color="red" onClick={openProdCat}>
                <span>Close</span>
              </Button>
            </DialogFooter>
          </Dialog>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default CreateProdSched;
