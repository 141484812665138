import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Checkbox,
  Input,
} from "@material-tailwind/react";
import { FaUserCheck } from "react-icons/fa";
import { IconButton } from "@material-tailwind/react";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import HashLoader from "react-spinners/HashLoader";
import { useState } from "react";
import { useFetchVendorListQuery } from "../../../../store";

function VendorListNewItem({ handleSelectVendor }) {
  const [open, setOpen] = useState(false);
  const tableHead = ["", "Id", "Name", "Contact Number", "Address"];
  // const [checkVendorId, setCheckVendorId] = useState(vendor_list_id);
  const [checkVendorId, setCheckVendorId] = useState([]);
  const [checkVendorItem, setCheckVendorItem] = useState([]);
  const [selectActive, setSelectActive] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [str, setStr] = useState("");

  const fetVendorListQuery = useFetchVendorListQuery({
    page: selectActive,
    pageSize: pageSize,
    search: str,
  });
  const totalPage = Math.ceil(fetVendorListQuery.data?.count / pageSize);
  // variable for data of api
  let result = [];

  const handleSearch = (e) => setStr(e.target.value);
  // Update the state based on whether the checkbox is checked or unchecked
  const handleChange = (e) => {
    const { checked, value } = e.target;
    const newCheckboxvalue = value.split(",");
    console.log(parseInt(newCheckboxvalue[0]));
    console.log(newCheckboxvalue[1]);
    if (checked) {
      setCheckVendorId([...checkVendorId, parseInt(newCheckboxvalue[0])]);
      setCheckVendorItem([...checkVendorItem, newCheckboxvalue[1]]);
    } else {
      const filteredVendor = checkVendorId.filter(
        (vendor) => vendor !== parseInt(newCheckboxvalue[0])
      );
      const filteredVendorItem = checkVendorItem.filter(
        (vendor_item) => vendor_item !== newCheckboxvalue[1]
      );
      setCheckVendorId(filteredVendor);
      setCheckVendorItem(filteredVendorItem);
    }
  };
  // preview of pagination
  const prev = () => {
    if (selectActive === 1) return;
    setSelectActive(selectActive - 1);
  };
  // next of pagination
  const next = () => {
    if (selectActive === totalPage) return;
    setSelectActive(selectActive + 1);
  };
  // handle selection of checkbox
  const handleSelect = () => {
    // console.log(checkVendorItem, checkVendorId);
    handleSelectVendor(checkVendorId, checkVendorItem);
    setOpen(!open);
  };
  // handle the page size of table
  const changePagesize = (e) => {
    setPageSize(e.target.value);
    setSelectActive(1);
  };
  // open/closing of dialog
  const handleOpen = () => setOpen(!open);
  // for data fetching
  if (fetVendorListQuery.isLoading) {
    return <HashLoader color="#36d7b7" />;
  } else if (fetVendorListQuery.data) {
    result = fetVendorListQuery.data.results;
  } else {
    return <div>Error Loading..</div>;
  }
  return (
    <div>
      <Button
        variant="outlined"
        color="teal"
        size="sm"
        onClick={handleOpen}
        className="mr-2"
      >
        <div className="flex items-center">
          <FaUserCheck className=" mr-1" />
          Select Vendor
        </div>
      </Button>
      <Dialog
        open={open}
        size="xl"
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="text-green-900">List of Vendor</DialogHeader>
        <div className=" ml-10 w-full md:w-72">
          <Input type="search" label="search" onKeyUp={handleSearch} />
        </div>
        <div className=" mr-10 flex justify-end space-x-4">
          <Typography>Page size:</Typography>
          <select
            defaultValue={pageSize}
            onChange={changePagesize}
            className="border-2 border-blue-gray-500"
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </div>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {tableHead.map((head, index) => (
                  <th
                    key={index}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {result.map((vendor, index) => {
                const isLast =
                  index === fetVendorListQuery.data.results.length - 1;
                let checkboxValue = vendor.id + "," + vendor.name;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                return (
                  <tr key={vendor.id}>
                    <td className={classes}>
                      <Checkbox
                        type="checkbox"
                        color="teal"
                        value={checkboxValue}
                        id="select_vendor"
                        //   defaultChecked={vendor_list_id.includes(vendor.id)}
                        onChange={handleChange}
                      />
                    </td>
                    <td className={classes}>{vendor.id}</td>
                    <td className={classes}>{vendor.name}</td>
                    <td className={classes}>{vendor.contact}</td>
                    <td className={classes}>{vendor.address}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </DialogBody>
        <DialogFooter className="flex justify-between">
          <div className="flex justify-center items-center gap-8 ml-[41%]">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={prev}
              disabled={selectActive === 1}
            >
              <IoArrowBackOutline strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <Typography color="gray" className="font-normal">
              <strong className="text-gray-900">{selectActive}</strong> of
              <strong className="text-gray-900 ml-1">{totalPage}</strong>
            </Typography>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={next}
              disabled={selectActive === totalPage}
            >
              <IoArrowForwardOutline strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
          <div>
            <Button
              color="red"
              variant="text"
              onClick={handleOpen}
              className="mr-2"
            >
              <span>Close</span>
            </Button>
            <Button onClick={handleSelect} className="bg-green-900">
              <span>Select</span>
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </div>
  );
}

export default VendorListNewItem;
