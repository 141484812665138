import { FiSearch } from "react-icons/fi";
import { FiDownload } from "react-icons/fi";
import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";
import { useFetchVendorListQuery } from "../../store";
import VendorListAddNewItem from "../modal_form_components/Warehouse/WarehousePurchaseOrder/VendorListAddNewItem";
import AddNewVendor from "../modal_form_components/Warehouse/WarehousePurchaseOrder/AddNewVendor";
import VendorItemList from "../modal_form_components/Warehouse/WarehousePurchaseOrder/VendorItemList";


function CompleteTableVendorSummView({
  VendorTableHead,
  addNewVendorButton,
  TABLE_ROWS,
}) {
  let result = [];
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const VendorSummaryTableHead = ["Vendor", "Contact Person", "Contact Number", 'Action'];
  // const VendorLisTableHead = [
  //   "Vendor",
  //   "Item",
  //   "POC",
  //   "Contact Number",
  //   "Address",
  //   "Status",
  //   "Attachment",
  // ];
  const handleSearch = (e) => setSearchTerm(e.target.value);
  const { data, error, isLoading, refetch } = useFetchVendorListQuery({
    page: currentPage,
    pageSize: pageSize,
    search: searchTerm,
  });
  const totalPage = Math.ceil(data?.count / pageSize);

  // handle preview of page for pagination
  const handlePreviousPage = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };

  // handle next of page for pagination
  const handleNextPage = () => {
    if (currentPage === totalPage) return;
    setCurrentPage(currentPage + 1);
  };

  // handle changing size of the table page
  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentPage(1);
  };

  if (isLoading) {
    return <div>Loading..</div>;
  } else if (data) {
    result = data.results;
  } else {
    return <div>Error Loading!</div>;
  }
  return (
    <>
      <Card className="h-full w-full z-0 md:mb-28 lg:mb-0">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div className=" space-y-3 md:space-y-0 md:flex items-center md:justify-end md:space-x-4 mr-5 mb-5 lg:mb-5">
            <Button
              className="flex items-center gap-3"
              variant="outlined"
              size="sm"
            >
              <FiDownload strokeWidth={2} className="h-4 w-4" /> Download
            </Button>
            <div className="flex items-center gap-3">
              <VendorListAddNewItem autoFetch={refetch} />
            </div>
            <div className="flex items-center gap-3">
              <AddNewVendor vendorFetch={refetch} />
            </div>
          </div>
          <div className="flex items-start">
            <div className="w-full md:w-72">
              <Input
                label="Search"
                icon={<FiSearch className="h-5 w-5" />}
                onKeyUp={handleSearch}
              />
            </div>
          </div>
        </CardHeader>
        <div className="flex justify-end items-center mr-10 space-x-4 mt-5 md:mt-0">
          <Typography>Page size:</Typography>
          <select
            defaultValue={pageSize}
            onChange={handlePageSizeChange}
            className="border-2 border-blue-gray-500 cursor-pointer"
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </div>
        <CardBody className="px-0 overflow-y-auto max-h-[60vh]">
          <table className=" w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {VendorSummaryTableHead.map((head) => (
                  <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="">
              {result.map((v, index) => {
                const isLast = index === result.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                return (
                  <tr key={v.id}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {v.name}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {v.poc}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {v.contact}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td>
                      <VendorItemList
                        vendor_name={v.name}
                        vendorId={v.id}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {currentPage} of {totalPage}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              size="sm"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleNextPage}
              disabled={currentPage === totalPage}
            >
              Next
            </Button>
          </div>
        </CardFooter>
      </Card>
    </>
  );
}

export default CompleteTableVendorSummView;
