import React, { useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Button,
} from "@material-tailwind/react";
import { useRawMatDetailedPageQuery } from "../../../store";


const GeneratePODraft = ({ vrr_id }) => {
    const [isPDFLoading, setIsPDFLoading] = useState(false);
    let result = [];
    const fetch_raw_mats_item = useRawMatDetailedPageQuery(vrr_id);
	const pesoSign = '\u20B1';

    if (fetch_raw_mats_item.isLoading) {
        return <div>Loading...</div>;
    } else if (fetch_raw_mats_item.error) {
        return <div>Error Loading...</div>;
    } else {
        result = fetch_raw_mats_item.data.data;
    }

	const generatePDF = (result) => {
    setIsPDFLoading(true);
		const currentDate = new Date();
		const date_now = currentDate.toLocaleDateString("en-US", {
			timeZone: "Asia/Tokyo",
			hour12: false,
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
		});

		const pdfDoc = new jsPDF("p", "mm", "a4");

		// Add logo and title
		pdfDoc.setFontSize(20);
		pdfDoc.setTextColor(0, 128, 0);
		pdfDoc.setFont("helvetica", "bold");
		pdfDoc.text("Purchase Order", 75, 25);
		pdfDoc.setFontSize(12);
		pdfDoc.setTextColor(0);
		pdfDoc.setFont("helvetica", "normal");
		// Add delivery information
		pdfDoc.text(`PO Number: ${result.po_number}`, 20, 45);
		pdfDoc.text(`Vendor: ${result.vendor.name}`, 20, 55);
		// var address_split = pdfDoc.splitTextToSize(result.vendor.address, 180);
		let vendor_address = result.vendor.address
		console.log(vendor_address.match(/.{1,50}/g));
		pdfDoc.text(`Address: ${result.vendor.address}`, 20, 65);
		pdfDoc.text(`Contact: ${result.vendor.contact}`, 120, 45);
		pdfDoc.text(`Contact Person: ${result.vendor.poc}`, 120, 55);

		const tableStartY = 95;

		pdfDoc.autoTable({
			head: [
			[
				{ content: "Items", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Unit Measure", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Ordered Quantity", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Quoted Price", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Total", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Payment in terms (in days)", styles: { halign: 'center', valign: 'middle' } },
			],
			],
			body: result.vendor_items.map((vl) => [
				{ content: vl.request_item.item.name, styles: { halign: 'center', valign: 'middle' } },
				{ content: vl.request_item.item.unit_measurement, styles: { halign: 'center', valign: 'middle' } },
				{ content: vl.request_item.required_qty, styles: { halign: 'center', valign: 'middle' } },
				{ content: `${vl.quoted_price}`, styles: { halign: 'center', valign: 'middle' } },
				{ content: `${(parseInt(vl.request_item.required_qty) * parseFloat(vl.quoted_price))}`, styles: { halign: 'center', valign: 'middle' } },
				{ content: vl.vendor.terms, styles: { halign: 'center', valign: 'middle' } },
			]),
			startY: tableStartY,
			theme: "grid",
			styles: {
			fillColor: [255, 255, 255],
			textColor: [0, 0, 0],
			},
		});

		// const tableHeight = pdfDoc.previousAutoTable.finalY - tableStartY;

		pdfDoc.save(`BM_${result.po_number}_Receipt_${result.vendor.name}_${date_now}.pdf`);
		setIsPDFLoading(false)
	};

	return (
		<div>
			<Button
				className="bg-green-700 mr-5"
				onClick={() => generatePDF(result)}
				>
				Extract PDF
			</Button>
		</div>
	);
}

export default GeneratePODraft;