import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");
const requestApi = createApi({
  reducerPath: "Request",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  endpoints(builder) {
    return {
      fetchRequest: builder.query({
        query: ({
          page = 1,
          page_size = 10,
          order_by = "id",
          order_dir = "desc",
          status,
          search,
        }) => {
          return {
            url: `/api/warehouse/request/request_list_page/?page=${page}&page_size=${page_size}&status=${status}&search=${search}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDepartmentList: builder.query({
        query: () => {
          return {
            url: "/api/warehouse/request/department_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      addRequestItem: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/request/add_purchase_order/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      fetchRequestItem: builder.query({
        query: (po_id) => {
          return {
            url: `/api/warehouse/request/${po_id}/request_item_detailed/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchRequestVendorItem: builder.query({
        query: (po_id) => {
          return {
            url: `/api/warehouse/request/${po_id}/request_vendor_item/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateRequestItem: builder.mutation({
        query: (form) => {
          return {
            url: "/api/warehouse/request/update_request_item/",
            headers: {
              Authorization: "token " + token,
              "Content-type": "application/json",
            },
            method: "PUT",
            body: form,
          };
        },
      }),
      updateItemRemarks: builder.mutation({
        query: (form) => {
          return {
            url: "/api/warehouse/request/request_item_remarks/",
            headers: {
              Authorization: "token " + token,
              "Content-type": "application/json",
            },
            method: "PUT",
            body: form,
          };
        },
      }),
      addSuggestedVendor: builder.mutation({
        query: (form) => {
          return {
            url: "/api/warehouse/request/add_suggested_vendor/",
            headers: {
              Authorization: "token " + token,
            },
            method: "POST",
            body: form,
          };
        },
      }),
      addSuggestedVendorDetail: builder.mutation({
        query: (form) => {
          return {
            url: "/api/warehouse/request/add_suggested_vendor_rfq/",
            headers: {
              Authorization: "token " + token,
            },
            method: "POST",
            body: form,
          };
        },
      }),
      updateItemRequest: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/request/${form.id}/update_item_request/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      updateSuggestedVendor: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/request/update_suggested_vendor/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      fetchDashboard: builder.query({
        query: () => {
          return {
            url: "/api/client/1/dashboard/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchRawMatVendorItem: builder.query({
        query: (po_id) => {
          return {
            url: `/api/warehouse/request/${po_id}/raw_mat_request_vendor_item/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      requestItemVendorApprove: builder.mutation({
        query: (form) => {
          console.log(form);
          return {
            url: `/api/warehouse/request/request_item_vendor_approve/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      deleteRequestItem: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/request/delete_request_item/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      approvalAdjustedRequiredQty: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/request/approval_adjusted_required_qty/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      rawMatApprove: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/request/raw_mat_approve/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      approvalRequestItem : builder.query({
        query: ({
          page,
          page_size,
          order_by = "id",
          order_dir = "desc",
        }) => {
          return {
            url: `/api/warehouse/request/request_item_approval_list_page/?page=${page}&page_size=${page_size}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      pmApprovalAdjustedRequiredQty: builder.mutation({
        query: (form) => {
          return {
            url: `/api/warehouse/request/pm_approval_adjusted_required_qty/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      suggestedVendorList : builder.query({
        query: (id) => {
          return {
            url: `/api/warehouse/request/${id}/suggested_vendor_list/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      rawMatPrint : builder.query({
        query: (id) => {
          return {
            url: `/api/warehouse/request/${id}/raw_mat_print/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      createNewPo: builder.mutation({
        query: (form) => {
          console.log(form);
          return {
            url: `/api/warehouse/request/create_new_po/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
    };
  },
});

export const {
  useFetchRequestQuery,
  useFetchDepartmentListQuery,
  useFetchRequestVendorItemQuery,
  useAddRequestItemMutation,
  useFetchRequestItemQuery,
  useUpdateRequestItemMutation,
  useUpdateItemRemarksMutation,
  useAddSuggestedVendorMutation,
  useAddSuggestedVendorDetailMutation,
  useUpdateItemRequestMutation,
  useFetchDashboardQuery,
  useFetchRawMatVendorItemQuery,
  useRequestItemVendorApproveMutation,
  useDeleteRequestItemMutation,
  useApprovalAdjustedRequiredQtyMutation,
  useRawMatApproveMutation,
  useApprovalRequestItemQuery,
  usePmApprovalAdjustedRequiredQtyMutation,
  useSuggestedVendorListQuery,
  useRawMatPrintQuery,
  useCreateNewPoMutation,
  useUpdateSuggestedVendorMutation,
} = requestApi;
export { requestApi };
