import PageTitle from "../page_components/PageTitle";
import { CompleteTableView } from "../page_components/Complete_Table_DeliveryDb_View";
import { Typography } from "@material-tailwind/react";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

function Delivery() {
  const token = localStorage.getItem("mytoken");
  let navigate = useNavigate();
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

  useEffect(() => {
    if (
      account_detailed.job_position == 1 ||
      account_detailed.user_type == 1 ||
      account_detailed.job_position === 13 ||
      account_detailed.job_position === 15 ||
      account_detailed.job_position === 16 ||
      account_detailed.job_position === 2 ||
      account_detailed.job_position === 4
    ) {
      // pass
    } else {
      navigate("/base/dashboard");
      return;
    }
  }, [token]);

  const ViewTitle = "Transfer";
  const viewDescription = "";
  const TABLE_HEAD = [
    "Order #",
    "Delivery Tracking #",
    "Outlet",
    "Route",
    "Order Date",
    "Status",
    "Actions",
  ];

  const buttonForModal = "";
  const extractPOButton = "";

  return (
    <>
      <div className="container mx-auto px-4 md:mb-28 lg:mb-0">
        <div className="md:hidden flex justify-end mt-[-20px] mb-5 mr-3">
          <PageTitle />
        </div>
        <div className="flex flex-row justify-center md:justify-between items-center ml-3 lg:ml-10 mr-5 lg:mr-28 mb-10 md:mb-16 lg:mb-5">
          <div className="flex items-center space-x-2">
            <FaArrowRightArrowLeft className="h-10 w-10  text-[#137944]" />
            <Typography className="text-[#137944] font-bold text-3xl tracking-normal">
              Delivery
            </Typography>
          </div>
          <div className="hidden md:block">
            <PageTitle />
          </div>
        </div>
        <div>
          <CompleteTableView
            ViewTitle={ViewTitle}
            viewDescription={viewDescription}
            TABLE_HEAD={TABLE_HEAD}
            buttonForModal={buttonForModal}
            extractPOButton={extractPOButton}
          />
        </div>
      </div>
    </>
  );
}

export default Delivery;
