import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");

const deliveryApi = createApi({
  reducerPath: "delivery",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  endpoints(builder) {
    return {
      fetchDeliveryListPage: builder.query({
        query: ({
          page = 1,
          page_size = 10,
          order_by = "id",
          order_dir = "desc",
          search = "",
          status = 1,
          outlet = "",
        }) => {
          return {
            url: `/api/delivery/delivery/delivery_order_list_page/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&search=${search}&status=${status}&outlet=${outlet}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDeliveryOutlet: builder.query({
        query: () => {
          return {
            url: "/api/delivery/delivery/outlet_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDeliveryProducts: builder.query({
        query: () => {
          return {
            url: "/api/delivery/delivery/order_products_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDeliveryBuns: builder.query({
        query: () => {
          return {
            url: "/api/delivery/delivery/order_buns_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDeliveryBeverages: builder.query({
        query: () => {
          return {
            url: "/api/delivery/delivery/order_beverages_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDeliveryWrappers: builder.query({
        query: () => {
          return {
            url: "/api/delivery/delivery/order_wrappers_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDeliverySundries: builder.query({
        query: () => {
          return {
            url: "/api/delivery/delivery/order_sundries_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDeliveryOS: builder.query({
        query: () => {
          return {
            url: "/api/delivery/delivery/order_os_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDeliveryCigarettes: builder.query({
        query: () => {
          return {
            url: "/api/delivery/delivery/order_cigarettes_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDeliveryTank: builder.query({
        query: () => {
          return {
            url: "/api/delivery/delivery/order_tank_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      addDeliveryOrderItem: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/add_order/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      fetchOrderNumberListPage: builder.query({
        query: ({
          page = 1,
          page_size = 10,
          order_by = "id",
          order_dir = "desc",
          search = "",
          fc = [],
          route = [],
          outlet = [],
          schedule,
        }) => {
          return {
            url: `/api/delivery/delivery/get_order_number_list_page/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&search=${search}&fc=${fc}&route=${route}&outlet=${outlet}&schedule=${schedule}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchForDelOrderNumberListPage: builder.query({
        query: ({
          page = 1,
          page_size = 10,
          order_by = "id",
          order_dir = "desc",
          search = "",
          order_number = [],
        }) => {
          return {
            url: `/api/delivery/delivery/for_delivery_order_list_page/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&search=${search}&order_number=${order_number}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchOutletListPage: builder.query({
        query: ({
          page = 1,
          page_size = 10,
          order_by = "id",
          order_dir = "desc",
          search = "",
          status = 1,
          route,
          fc,
        }) => {
          return {
            url: `/api/delivery/delivery/outlet_list_page/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&search=${search}&status=${status}&fc=${fc}&route=${route}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateOrderToTransfer: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/order_change_for_transfer/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      fetchDeliveryItemsDetails: builder.query({
        query: ({ page = 1, page_size = 10, del_tracking_num, is_fresh }) => {
          return {
            url: `/api/delivery/delivery/get_for_delivery_item_details/?page=${page}&page_size=${page_size}&del_tracking_num=${del_tracking_num}&is_fresh=${is_fresh}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDeliveryOrders: builder.query({
        query: ({ page = 1, page_size = 10, del_tracking_num }) => {
          return {
            url: `/api/delivery/delivery/delivery_order_list/?page=${page}&page_size=${page_size}&del_tracking_num=${del_tracking_num}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDeliveryItemsAdjust: builder.query({
        query: ({ page = 1, page_size = 10, order_number }) => {
          return {
            url: `/api/delivery/delivery/delivery_order_item_details/?page=${page}&page_size=${page_size}&order_number=${order_number}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateSaveDeliveryDetails: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/save_delivery/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      fetchDelIssuedItems: builder.query({
        query: ({ page = 1, page_size = 10, delivery_num }) => {
          return {
            url: `/api/delivery/delivery/delivery_issued_items_list/?page=${page}&page_size=${page_size}&delivery_num=${delivery_num}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateOrderToDeliver: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/update_order_status_delivered/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      updateOrderToReturn: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/update_order_status_return/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      fetchDeliveryDetails: builder.query({
        query: (id) => {
          return {
            url: `/api/delivery/delivery/${id}/delivery_details/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDeliveryOrdersSummary: builder.query({
        query: ({ page = 1, page_size = 10, del_tracking_num }) => {
          return {
            url: `/api/delivery/delivery/delivery_order_summary_list/?page=${page}&page_size=${page_size}&del_tracking_num=${del_tracking_num}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchOrderSummaryPDFDetails: builder.query({
        query: (id) => {
          return {
            url: `/api/delivery/delivery/${id}/complete_order_summary_pdf_view/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDelSchedule: builder.query({
        query: () => {
          return {
            url: "/api/delivery/delivery/get_schedule_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchRouteList: builder.query({
        query: ({ page = 1, page_size = 10, schedule }) => {
          return {
            url: `/api/delivery/delivery/route_view_list/?page=${page}&page_size=${page_size}&schedule=${schedule}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateRouteSchedule: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/update_delivery_schedule/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      fetchFCList: builder.query({
        query: () => {
          return {
            url: "/api/delivery/delivery/fc_view_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      addDailySalesReport: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/add_sales_record/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      updateStockLevel: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/updated_stock_level/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      fetchCurrentStockLvl: builder.query({
        query: (id) => {
          return {
            url: `/api/delivery/delivery/${id}/outlet_stock_level_details/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchGasList: builder.query({
        query: ({ page = 1, page_size = 10, order_number }) => {
          return {
            url: `/api/delivery/delivery/special_product_list/?page=${page}&page_size=${page_size}&order_number=${order_number}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateSerialNumber: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/update_serial_number/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      addWithdrawalRequest: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/create_withdrawal_request/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),

      fetchWithdrawalOrderRequest: builder.query({
        query: ({ page = 1, page_size = 10, outlet_id = 1 }) => {
          return {
            url: `/api/delivery/delivery/withdrawal_order_list_page/?page=${page}&page_size=${page_size}&outlet_id=${outlet_id}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchUserListPage: builder.query({
        query: ({ page = 1, page_size = 10, user_type = 1 }) => {
          return {
            url: `/api/delivery/delivery/user_list_page/?page=${page}&page_size=${page_size}&user_type=${user_type}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      addNewOutlet: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/add_outlet/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      updateOutletDetails: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/update_outlet_details/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      fetchDSRListPage: builder.query({
        query: ({
          page = 1,
          page_size = 10,
          types = 1,
          startDate,
          fc,
          route,
        }) => {
          return {
            url: `/api/delivery/delivery/outlet_sales_report_view/?page=${page}&page_size=${page_size}&types=${types}&startDate=${startDate}&fc=${fc}&route=${route}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchSRItemsDetails: builder.query({
        query: (id) => {
          return {
            url: `/api/delivery/delivery/${id}/sales_report_items_detail/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateOutletPassword: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/reset_password/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
      fetchManualRequestOrderProductList: builder.query({
        query: () => {
          return {
            url: `/api/delivery/delivery/product_manual_request_list/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      addDeliveryManualRequest: builder.mutation({
        query: (form) => {
          return {
            url: `/api/delivery/delivery/add_new_manual_request_delivery/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
    };
  },
});

export const {
  useAddDeliveryOrderItemMutation,
  useFetchDeliveryListPageQuery,
  useFetchDeliveryOutletQuery,
  useFetchDeliveryProductsQuery,
  useFetchDeliveryBeveragesQuery,
  useFetchDeliveryBunsQuery,
  useFetchDeliveryCigarettesQuery,
  useFetchDeliveryOSQuery,
  useFetchDeliverySundriesQuery,
  useFetchDeliveryWrappersQuery,
  useFetchDeliveryTankQuery,
  useFetchOrderNumberListPageQuery,
  useFetchForDelOrderNumberListPageQuery,
  useFetchOutletListPageQuery,
  useUpdateOrderToTransferMutation,
  useFetchDeliveryItemsDetailsQuery,
  useFetchDeliveryOrdersQuery,
  useFetchDeliveryItemsAdjustQuery,
  useUpdateSaveDeliveryDetailsMutation,
  useFetchDelIssuedItemsQuery,
  useUpdateOrderToDeliverMutation,
  useUpdateOrderToReturnMutation,
  useFetchDeliveryDetailsQuery,
  useFetchDeliveryOrdersSummaryQuery,
  useFetchOrderSummaryPDFDetailsQuery,
  useFetchDelScheduleQuery,
  useFetchRouteListQuery,
  useUpdateRouteScheduleMutation,
  useFetchFCListQuery,
  useAddDailySalesReportMutation,
  useUpdateStockLevelMutation,
  useFetchCurrentStockLvlQuery,
  useFetchGasListQuery,
  useUpdateSerialNumberMutation,
  useAddWithdrawalRequestMutation,
  useFetchWithdrawalOrderRequestQuery,
  useFetchUserListPageQuery,
  useAddNewOutletMutation,
  useUpdateOutletDetailsMutation,
  useFetchDSRListPageQuery,
  useFetchSRItemsDetailsQuery,
  useUpdateOutletPasswordMutation,
  useFetchManualRequestOrderProductListQuery,
  useAddDeliveryManualRequestMutation,
} = deliveryApi;
export { deliveryApi };
