import { useState, useEffect } from "react";
import { Typography, Badge, Avatar, Card } from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { GoBellFill } from "react-icons/go";
import { TbLogout } from "react-icons/tb";
import { IoSettingsSharp } from "react-icons/io5";
import { useFetchNotificationCountQuery } from "../../store";
function PageTitle() {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const [toggle, setToggle] = useState("hidden absolute top-10 z-[42]");
  const { data, refetch } = useFetchNotificationCountQuery(user_info.id);
  // toggling the notification card
  const handleToggle = () =>
    toggle === "hidden absolute top-10 z-[42]"
      ? setToggle("absolute top-10 z-[42]")
      : setToggle("hidden absolute top-10 z-[42]");
  // function for logout
  const logout = () => {
    localStorage.clear();
  };

  const intervalDuration = 6000; // 6 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, intervalDuration);

    return () => clearInterval(intervalId);
  }, [refetch, intervalDuration]);

  const userName = JSON.parse(localStorage.getItem("user_info"));
  const fullName = userName.first_name + " " + userName.last_name;

  return (
    <>
      <div className="flex space-x-5 items-center mb-2">
        <div className="relative flex flex-col justify-center items-center cursor-pointer mt-1">
          <div onClick={handleToggle} className="">
            <Badge
              content={data?.data}
              overlap="circular"
              placement="top-end"
              className={
                data?.data === 0 ? "hidden" : "flex justify-center text-xs"
              }
            >
              <Badge overlap="circular" placement="bottom-end" color="green">
                <Avatar
                  src="https://images.unsplash.com/photo-1633332755192-727a05c4013d?ixlib=rb-1.2.1&amp;ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&amp;auto=format&amp;fit=crop&amp;w=1480&amp;q=80"
                  alt="profile picture"
                  className=" w-7 h-7 md:w-9 md:h-9"
                />
              </Badge>
            </Badge>
          </div>
          <Card className={toggle}>
            <ul className="py-4 px-5">
              <Link to="/base/notification">
                <li className="flex items-center space-x-2">
                  <GoBellFill />
                  <Typography className="text-lg hover:font-semibold hover:text-teal-600">
                    Notification
                  </Typography>
                </li>
              </Link>
              <Link to="/base/requestform">
                <li className="flex items-center space-x-2" onClick={logout}>
                  <TbLogout />
                  <Typography className="text-lg hover:font-semibold hover:text-teal-600">
                    Log out
                  </Typography>
                </li>
              </Link>
            </ul>
          </Card>
        </div>{" "}
        <div>
          <Typography className="text-xs md:text-base">
            Hello{" "}
            <span className="text-xs md:text-base font-bold">
              {userName.first_name}!
            </span>
          </Typography>
          <Typography className=" text-xs text-blue-gray-600">
            {fullName}
          </Typography>
        </div>
      </div>
    </>
  );
}
export default PageTitle;
