import { useState } from "react";
import {
  Card,
  Typography,
  Input,
  CardBody,
  CardFooter,
  Button,
} from "@material-tailwind/react";
import { useFetchProductBakeryListQuery } from "../../../store";
import { ToastContainer } from "react-toastify";


function BakeryProductList({
  bakery_inputs,
  set_bakery_saved_flag
}) {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [search, setSearch] = useState("");
  const [qty, setQty] = useState([]);
  const [inputPrevious, setInputPrevious] = useState([]);
  const [saveInputFlag, setSaveInputFlag] = useState(false);
  // const [sum, setSum] = useState(0);
  const { data, isLoading, error } = useFetchProductBakeryListQuery({
    page: page,
    page_size: pageSize,
    search_str: search,
  });

  const TABLE_HEAD = [
    "Product Code",
    "Batch Quantity",
    "Name",
    "Unit Measurement",
  ];
  let content = [];
  // computation for total page
  const totalPage = Math.ceil(data?.count / pageSize);
  // varialbe for api

  // handle change the size of table
  const changePagesize = (e) => {
    setPageSize(e.target.value);
    setPage(1);
  };
  //handle preview of table page
  const handlePrev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };
  // handle next of table page
  const handleNext = () => {
    if (page === totalPage) return;
    setPage(page + 1);
  };
  // handle searching of data on search input
  const handleSearch = (e) => {
    setSearch(e.target.value);
  };
  // handle on change of quantity and check if the specific index not empty

  const handleInput = async (value, index, id) => {
    const existingIndex = qty.findIndex((item) => parseInt(
      item.prod
    ) === parseInt(id));

    if (existingIndex !== -1) {
      const updatedQty = [...qty];
      updatedQty[existingIndex] = { ...updatedQty[existingIndex], qtys: value };
      setQty(updatedQty);
    } else {
      const newQty = [...qty, { prod: id, qtys: value }];
      setQty(newQty);
    }
    if (inputPrevious.length > 0){
      inputPrevious.forEach(item1 => {
        const foundIndex = nonEmptyQty.findIndex(
          item2 => item2.qtys !== item1.qtys
        );
        if (foundIndex >= 0) {
          setSaveInputFlag(false)
        }
      });
    }
  };

  // variable for adding the batch qty
  let nonEmptyQty = qty.filter((item) => item && item.qtys !== "");
  let sum = 0;
  let qtySum = nonEmptyQty.reduce(function (accumulator, val) {
    return accumulator + parseInt(val.qtys);
  }, sum);

  // handle submit of data to api
  const handleSave = async () => {
    // let schedDate = moment(selectedDate).format("YYYY-MM-DD");
    let nonEmptyQty = qty.filter(
      (item) => item && item.qtys !== "" && item.qtys !== "0"
    );
    console.log(nonEmptyQty)
    console.log(qtySum)
    bakery_inputs({
      product_line: 1,
      inputs: nonEmptyQty,
      prod_line_qty: qtySum
    })
    if (inputPrevious.length <= 0) {
      setInputPrevious(nonEmptyQty)
    }
    setSaveInputFlag(true)
    set_bakery_saved_flag(true)
  };
  //condition for fetching state of data
  if (isLoading) {
    return <div>Loading....</div>;
  } else if (error) {
    return <div>Refresh the page....</div>;
  } else {
    content = data?.results;
  }

  return (
    <>
      <Typography className=" text-blue-gray-800 text-lg">
        Production Line:{" "}
        <span className=" font-semibold text-green-900">Bakery</span>
      </Typography>
      <Card className=" h-full w-full overflow-scroll">
        <CardBody>
          <div className="flex flex-col md:flex-row justify-between mx-3 my-2">
            <div className="w-full md:w-72">
              <Input label="Search" onKeyUp={handleSearch} />
            </div>
            <div className="flex justify-end mt-5 md:mt-0 items-center space-x-2">
              <Typography>Page size:</Typography>
              <select
                defaultValue={pageSize}
                onChange={changePagesize}
                className="border-2 border-blue-gray-500"
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {content.map((pl, i) => {
                const isLast = pl === data.results.length - 1;
                const savedQty = qty.find(
                  (item) => parseInt(item.prod) === pl.id
                );
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                return (
                  <tr key={pl.id}>
                    <td className={classes}>{pl.product_code}</td>
                    <td className={classes}>
                      <input
                        type="number"
                        min="0"
                        defaultValue={savedQty ? savedQty.qtys : ""}
                        id={pl.id}
                        onChange={(e) =>
                          handleInput(e.target.value, i, e.target.id)
                        }
                        // onBlur={(e) => {
                        //   enterInput(e.target.value, i, e.target.id);
                        // }}
                        className="w-20 border border-1 border-blue-gray-300 p-1"
                      />
                    </td>
                    <td className={classes}>{pl.name}</td>
                    <td className={classes}>{pl.unit_measurement}</td>
                  </tr>
                );
              })}
            </tbody>
            <tfoot>
              <tr>
                <td></td>
                <td>
                  <Typography className="text-start mt-5">
                    Total Batch:{" "}
                    <span className=" text-[#252525] font-bold text-lg">
                      {qtySum}
                    </span>
                  </Typography>
                </td>
              </tr>
            </tfoot>
          </table>
          <ToastContainer />
        </CardBody>
        <CardFooter className="flex flex-col md:flex-row md:items-center md:justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {page} of {totalPage}
          </Typography>
          <div className="flex gap-2 mt-5 md:mt-0">
            <Button
              className=" bg-[#1AA55D] mr-10"
              size="sm"
              onClick={handleSave}
              disabled={saveInputFlag}
            >
              {saveInputFlag === true ? 'Saved': 'Save'}
            </Button>
            <Button
              variant="outlined"
              size="sm"
              disabled={page === 1}
              onClick={handlePrev}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              disabled={page === totalPage}
              onClick={handleNext}
            >
              Next
            </Button>
          </div>
        </CardFooter>
      </Card>
    </>
  );
}
export default BakeryProductList;
