import ItemRejectTable from "../table_components/Warehouse/Inventory/ItemRejectTable";
import ProductRejectTable from "../table_components/Warehouse/Inventory/ProductRejectTable";
import ApprovalTabComponents from "./ApprovalTabComponents";


export function CompleteRejectTable() {
    let data = [
      {
        label: "Items",
        value: "item",
        desc: <ItemRejectTable/>,
      },
      {
        label: "Products",
        value: "raw materials",
        desc: <ProductRejectTable/>,
      }
    ]

    return <>
     <ApprovalTabComponents data={data} />
    </>;
  }
