import { useState } from "react";
import UMAccordionItem from "./UMAccordionItem";
import UMShowDetails from "./UMShowDetails";

function UMAccordion({
  data,
  currOpen,
  setCurrOpen,
  openInfo,
  setOpenInfo,
  onClose,
}) {
  const [detail, setDetail] = useState({
    topic: "",
    detail: "",
    num: 0,
    itemNum: "",
  });

  return (
    <div>
      {!openInfo ? (
        <>
          {data.map((faqs, index) => (
            <UMAccordionItem
              key={`faqs-${index}`}
              faqs={faqs}
              num={index}
              currOpen={currOpen}
              onOpen={setCurrOpen}
              setOpenInfo={setOpenInfo}
              setDetail={setDetail}
            />
          ))}
        </>
      ) : (
        <UMShowDetails detail={detail} onClose={onClose} />
      )}
    </div>
  );
}

export default UMAccordion;
