import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Select,
  Option,
} from "@material-tailwind/react";
import { useFetchDeliveryOutletQuery } from "../../../store";
import {
  ProductTbl,
  BeveragesTbl,
  BunsTbl,
  WrappersTbl,
  CigaretteTbl,
  OSTbl,
  SundriesTbl,
  TankTbl,
} from "../../table_components/Delivery/AdvOrderTbl";
import { useAddDailySalesReportMutation } from "../../../store";
import { Confirmation } from "../Error_trap_components/ErrorTrapComponentRefreshDisable";
import DelSchedulePicker from "../../form_components/calendar/DeliverySelectDelDate";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import { GrPowerReset } from "react-icons/gr";
import ReactSignatureCanvas from "react-signature-canvas";

const AuditSOHModal = ({ refetchDeliveryList }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const boxResetClasses =
  "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const inputClasses = "flex flex-row items-center";
  const trapMessage =
    "Are you sure you want to submit the audited sales report? ";
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const {
    data: outletData,
    error,
    isLoading,
    isSuccess,
  } = useFetchDeliveryOutletQuery();
  const [assignedRoute, setAssignedRoute] = useState("Route 1");

  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const handleOutletChange = (value) => {
    const outletId = parseInt(value, 10);
    const outlet = outlet_list_result.find((o) => o.id === outletId);
    setSelectedOutlet(outlet);
  };

  useEffect(() => {
    if (selectedOutlet) {
      setAssignedRoute(selectedOutlet.route.name);
    }
  }, [selectedOutlet]);

  function handleCrewChange(event) {
    const newValue = event.target.value;
    setCrewName(newValue);
  }

  const generateFC = () => {
    return selectedOutlet ? selectedOutlet.fc.name : "";
  };
  const generateRoute = () => {
    return assignedRoute;
  };
  const generateAddress = () => {
    return selectedOutlet ? selectedOutlet.address : "";
  };
  const [selectedStock, setSelectedStock] = useState("");
  const [crewName, setCrewName] = useState("");
  let [productData, setProductData] = useState([]);
  let [beveragesData, setBeveragesData] = useState([]);
  let [bunsData, setBunsData] = useState([]);
  let [wrappersData, setWrappersData] = useState([]);
  let [sundriesData, setSundriesData] = useState([]);
  let [cigarettesData, setCigarettesData] = useState([]);
  let [osData, setOsData] = useState([]);
  let [tankData, setTankData] = useState([]);
  let [isReadyToSave, setIsReadyToSave] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  let [deliveryDate, setdeliveryDate] = useState(null);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState([
    true,
    true,
  ]);
  const [signatures, setSignatures] = useState(["", ""]);
  const signatureRefs = [
    useRef(null),
    useRef(null),
  ];
  const handleReset = (index) => {
    if (signatureRefs[index].current) {
      signatureRefs[index].current.clear();
      setSignatures((prevSignatures) => {
        const updatedSignatures = [...prevSignatures];
        updatedSignatures[index] = "";
        return updatedSignatures;
      });

      setIsSignatureEmpty((prevIsSignatureEmpty) => {
        const updatedIsSignatureEmpty = [...prevIsSignatureEmpty];
        updatedIsSignatureEmpty[index] = true;
        return updatedIsSignatureEmpty;
      });
    }
  };
  const handleSignatureChange = (index) => {
    const isEmpty = signatureRefs[index].current.isEmpty();
    setIsSignatureEmpty((prev) => {
      const updated = [...prev];
      updated[index] = isEmpty;
      return updated;
    });
    const base64Signature = signatureRefs[index].current.toDataURL();
    setSignatures((prevSignatures) => {
      const updatedSignatures = [...prevSignatures];
      updatedSignatures[index] = base64Signature;
      return updatedSignatures;
    });
  };

  useEffect(() => {
    // Check if any of the arrays contain data and selectedOutlet is not null
    const anyArrayHasData =
      (productData.length > 0 ||
        beveragesData.length > 0 ||
        bunsData.length > 0 ||
        wrappersData.length > 0 ||
        sundriesData.length > 0 ||
        cigarettesData.length > 0 ||
        osData.length > 0 ||
        tankData.length > 0) &&
        selectedOutlet !== null &&
        selectedStock !== "" &&
        isSignatureEmpty.every((isSignatureEmpty) => isSignatureEmpty !== true) &&
        crewName !== "";


    setIsReadyToSave(anyArrayHasData);
  }, [
    productData,
    beveragesData,
    bunsData,
    wrappersData,
    sundriesData,
    cigarettesData,
    osData,
    tankData,
    selectedOutlet,
    selectedStock,
    crewName,
    isSignatureEmpty,
  ]);

  //fetching logo/img from localstorage
  // const logo = JSON.parse(localStorage.getItem("account_detail"));
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const token = localStorage.getItem("mytoken");
  let navigate = useNavigate();
  // const burgerMachineLogo =
  //   process.env.REACT_APP_API_DOMAIN + "" + logo.client.logo;

  // useEffect(() => {
  //   if (
  //     account_detailed.job_position == 1 ||
  //     account_detailed.user_type == 1 ||
  //     account_detailed.job_position === 13 ||
  //     account_detailed.job_position == 15 ||
  //     account_detailed.job_position == 2 ||
  //     account_detailed.job_position == 4
  //   ) {
  //     if (account_detailed.job_position === 15 && isSuccess) {
  //       const outlet = outletData.data.find(
  //         (outlet) => outlet.id === account_detailed.outlet
  //       );
  //       if (outlet) {
  //         setSelectedOutlet(outlet);
  //       }
  //     } else {
  //       return;
  //     }
  //   } else {
  //     navigate("/base/dashboard");
  //     return;
  //   }
  // }, [token, outletData, isSuccess]);

  const delDate = (day) => {
    // Update the selected date or perform any other actions
    setdeliveryDate(day);
  };

  let handleProductOrderChange = (data) => {
    setProductData(data);
  };
  let handleBeveragestOrderChange = (bev_data) => {
    setBeveragesData(bev_data);
  };
  let handleBunsOrderChange = (buns_data) => {
    setBunsData(buns_data);
  };

  let handleWrappersOrderChange = (wrappers_data) => {
    setWrappersData(wrappers_data);
  };

  let handleSundriesOrderChange = (sundries_data) => {
    setSundriesData(sundries_data);
  };

  let handleCigarettesOrderChange = (cigarettes_data) => {
    setCigarettesData(cigarettes_data);
  };

  let handleOsOrderChange = (os_data) => {
    setOsData(os_data);
  };

  let handleTankOrderChange = (tank_data) => {
    setTankData(tank_data);
  };

  const handleStockChange = (value) => {
    setSelectedStock(value);
  };

  const handleClickRefresh = () => {
    setIsRefreshing(true);

    // Trigger the refetch function
    refetchDeliveryList();

    // Disable the button after 2 seconds
    setTimeout(() => {
      setIsRefreshing(false);
    }, 5000);
  };
  const [saveOrderedProducts] = useAddDailySalesReportMutation();
  const handleSave = async (event) => {
    let base64DataAuditor = signatures[0].slice(
      "data:image/png;base64,".length
    );
    let base64DataOutlet = signatures[1].slice("data:image/png;base64,".length);
    event.preventDefault();
    // Gather data from each table component

    // const formattedDate = deliveryDate.format("YYYY-MM-DD");
    // Repeat for other table components

    // Combine all data as needed
    let allData = {
      outlet: selectedOutlet,
      stockLevelMultiplier: selectedStock,
      auditorSign: base64DataAuditor,
      outletSign: base64DataOutlet,
      productData,
      beveragesData,
      bunsData,
      wrappersData,
      sundriesData,
      cigarettesData,
      osData,
      tankData,
      user: account_detailed.user.id,
      crewDutyName: crewName,
      // deliveryDate: formattedDate,

      // Add other data as needed
    };
    console.log(allData);
    try {
      const sea = await saveOrderedProducts(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });

        setProductData([]);
        setBeveragesData([]);
        setBunsData([]);
        setWrappersData([]);
        setSundriesData([]);
        setCigarettesData([]);
        setOsData([]);
        setTankData([]);
        setSelectedOutlet();
        setCrewName("");
        handleClose();
        refetchDeliveryList();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // formRef.current?.reset();
      }
    } catch (error) {
      // Handle the error here
      // You can display a generic error message to the user if needed
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };
  let outlet_list_result = [];
  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (outletData.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    outlet_list_result = outletData.data;
    return (
      <>
        <Button onClick={handleOpen} className="bg-[#1AA55D] ml-5" size="md">
          Outlet Audit Report
        </Button>
        <Dialog
          open={open}
          size="xl"
          handler={handleOpen}
          className="relative max-h-min"
        >
          <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
            {/* <img
              src={burgerMachineLogo}
              alt="bmlogo"
              className="aspect-auto w-72"
            /> */}
            <Typography className="text-green-900" variant="h3">
              Audit Daily Sales Report
            </Typography>
          </DialogHeader>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
            <form>
              <div className="grid md:grid-cols-4 gap-5 grid-auto-rows-auto">
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Date"
                      name="date"
                      value={formattedDate}
                      readOnly
                    />
                  </div>
                  <Select
                    label="Outlet"
                    onChange={handleOutletChange}
                    value={selectedOutlet ? `${selectedOutlet.id}` : null}
                    disabled={account_detailed.job_position === 15}
                  >
                    {outlet_list_result.map((outlet) => (
                      <Option
                        key={outlet.id}
                        value={`${outlet.id}`}
                        fc={outlet.fc}
                        address={outlet.address}
                      >
                        {outlet.outlet}
                      </Option>
                    ))}
                  </Select>
                  <div className={inputClasses}>
                    <Input label="FC" name="fc" value={generateFC()} readOnly />
                  </div>
                  <div className={inputClasses}>
                    <Select
                      label="Stock Level"
                      name="sl"
                      value={selectedStock}
                      onChange={handleStockChange}
                    >
                      <Option value="">Select SL</Option>
                      <Option value="3">Low Stock Level</Option>
                      <Option value="4">High Stock Level</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Route"
                      name="route"
                      value={generateRoute()}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Address"
                      name="address"
                      value={generateAddress()}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Auditor"
                      name="auditor"
                      value={account_detailed.user?.first_name + ' ' + account_detailed.user?.last_name ?? "Auditor"}
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Crew On Duty"
                      name="crew"
                      onChange={handleCrewChange}
                    />
                  </div>
                  {crewName === "" && (
                    <span className="text-red-500 text-xs">
                      Must input a crew.
                    </span>
                  )}
                </div>
              </div>

              <div className="m-5">
                <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                  Products
                  <ProductTbl onOrderChange={handleProductOrderChange} />
                </Typography>
                <Typography className="text-green-900 mt-2" variant="h3">
                  Beverages
                  <BeveragesTbl onOrderChange={handleBeveragestOrderChange} />
                </Typography>
                <Typography className="text-green-900 mt-2" variant="h3">
                  Buns/Bread
                  <BunsTbl onOrderChange={handleBunsOrderChange} />
                </Typography>
                <Typography className="text-green-900 mt-2" variant="h3">
                  Wrappers
                  <WrappersTbl onOrderChange={handleWrappersOrderChange} />
                </Typography>
                <Typography className="text-green-900 mt-2" variant="h3">
                  Sundries
                  <SundriesTbl onOrderChange={handleSundriesOrderChange} />
                </Typography>
                <Typography className="text-green-900 mt-2" variant="h3">
                  Cigarettes
                  <CigaretteTbl onOrderChange={handleCigarettesOrderChange} />
                </Typography>
                <Typography className="text-green-900 mt-2" variant="h3">
                  Operating Supplies
                  <OSTbl onOrderChange={handleOsOrderChange} />
                </Typography>
                <Typography className="text-green-900 mt-2" variant="h3">
                  Tank
                  <TankTbl onOrderChange={handleTankOrderChange} />
                </Typography>
              </div>
            </form>

            <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto ml-20">
                   <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[0]}
                          onEnd={() => handleSignatureChange(0)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(0)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                       </div>
                       <Typography className="text-center text-black mr-20" variant="h6">
                       {account_detailed?.user?.first_name ?? "Auditor"} {account_detailed?.user?.last_name ?? ""}
                  </Typography>
                    </div>
                    <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[1]}
                          onEnd={() => handleSignatureChange(1)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(1)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                      </div>
                      <Typography className="text-center text-black mr-20" variant="h6">
                    {"Outlet Crew"}
                  </Typography>

                    </div>
                    </div>
            <ToastContainer />
          </DialogBody>


          <DialogFooter>
            {selectedStock == "" && (
              <Typography variant="lead" color="red">
                Please provide a stock level.
              </Typography>
            )}
            <Confirmation
              onConfirm={handleSave}
              onSend={handleClickRefresh}
              trapMessage={trapMessage}
              isReadyToSave={isReadyToSave}
              isRefreshing={isRefreshing}
            />
            <Button
              variant="text"
              color="red"
              className="mr-3"
              onClick={handleClose}
            >
              <span>Close</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
};

export default AuditSOHModal;
