import {
  Card,
  Typography,
  Button,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Dialog,
} from "@material-tailwind/react";
import { ToastContainer } from "react-toastify";
import DatePicker from "react-datepicker";
import { useState } from "react";
import { useSuggestedVendorListQuery } from "../../../store";


const PurchaseOrderTableSubTable = ({ request_item_id }) => {
  const trclasses = "p-4 border-b border-blue-gray-50";
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  const vendorTableHeader = [
    "Selected Vendor",
    "Required Quantity",
    "Previous Price",
    "Quoted Price",
    "Payment Terms (in days)",
    "Commited Date",
    "File",
  ];

  const suggested_vendor = useSuggestedVendorListQuery(request_item_id);
  const api_domain = process.env.REACT_APP_API_DOMAIN;
  let result = [];
  if (suggested_vendor.isLoading) {
    //
  } else if (suggested_vendor.error) {
    //
  } else {
    result = suggested_vendor.data?.data;
  }

  return (
    <>
      <Button
        onClick={handleOpen}
        className="flex items-center bg-green-900 space-x-1"
        size="sm"
      >
        <span>View</span>
      </Button>
      <Dialog
        open={open}
        handler={handleOpen}
        className="relative max-h-min"
        size="xl"
      >
        <DialogHeader className="w-full bg-green-900 rounded-sm flex justify-center">
          <Typography className="text-center text-white" variant="h5">
            Vendors
          </Typography>
        </DialogHeader>
        <DialogBody className="container overflow-y-auto max-h-[60vh]">
          <Card className="h-full w-full overflow-scroll p-2">
            <table className="w-full min-w-max table-auto text-left ">
              <thead>
                <tr>
                  {vendorTableHeader.map((head, k) => (
                    <th
                      key={k}
                      className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {result.map((sv, index) => {
                  return (
                    <tr key={index} className={trclasses}>
                      <td className={trclasses}>{sv.vendor.name}</td>
                      <td className={trclasses}>{sv.required_qty}</td>
                      <td className={trclasses}>{sv.previous_price}</td>
                      <td className={trclasses}>
                        <span
                          className={
                            sv.quoted_price > sv.previous_price
                              ? "text-red-700"
                              : "text-green-700"
                          }
                        >
                          {sv.quoted_price}
                        </span>
                      </td>
                      <td className={trclasses}>
                        {sv.vendor.terms}
                        <ToastContainer />
                      </td>
                      <td>
                        <DatePicker
                          id="suggested_commited_date"
                          className="bordered border p-1 border-blue-gray-300 rounded-lg"
                          selected={
                            sv.committed_date === null
                              ? new Date()
                              : new Date(sv.committed_date)
                          }
                          value={
                            sv.committed_date === null
                              ? new Date()
                              : new Date(sv.committed_date)
                          }
                          dateFormat="yyyy/MM/dd"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="Select"
                          disabled
                        />
                      </td>
                      <td>
                        {sv.file && <a
                          className="inline-flex items-center px-4 py-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-md shadow-md transition duration-300 ease-in-out"
                          href={api_domain  + sv.file}
                          target="_blank"
                          >Link File
                        </a>}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </Card>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default PurchaseOrderTableSubTable;
