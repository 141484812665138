import { CSSProperties, useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Checkbox,
  IconButton,
} from "@material-tailwind/react";
import { useFetchVendorListSearchPageQuery } from "../../../../store/apis/VendorItemApi";
import { FiMousePointer } from "react-icons/fi";
import HashLoader from "react-spinners/HashLoader";
import { IoArrowForwardOutline, IoArrowBackOutline } from "react-icons/io5";

function VendorItemListOption({ onSelect }) {
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [open, setOpen] = useState(false);
  const tableHead = ["Id", "Name", "Unit Measurement", ""];
  const [selectId, setSelectId] = useState("");
  const [selectName, setSelectName] = useState("");
  const [selectActive, setSelectActive] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");

  const { data, error, isSuccess, isLoading } =
    useFetchVendorListSearchPageQuery({
      page: selectActive,
      page_size: pageSize,
      search_str: searchTerm,
      client_id: account_detailed.client.id,
    });

  const totalPage = Math.ceil(data?.count / pageSize);
  // variable for data of api
  let result = [];

  // preview of pagination
  const prev = () => {
    if (selectActive === 1) return;
    setSelectActive(selectActive - 1);
  };
  // next of pagination
  const next = () => {
    if (selectActive === totalPage) return;
    setSelectActive(selectActive + 1);
  };
  //handle searching of data
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setSelectActive(1)
  }

  // handle click item pass on parent component
  const handleSelectItem = (e) => {
    const { value } = e.target;
    const newCheckboxValue = value.split("=");
    setSelectId(newCheckboxValue[0]);
    setSelectName(newCheckboxValue[1]);
    setOpen(!open);
  };
  // Passing data back to parent component
  onSelect(selectId, selectName);
  // handle the page size of table
  const changePagesize = (e) => {
    setPageSize(e.target.value);
    setSelectActive(1);
  };
  // open/closing of dialog
  const handleOpen = () => setOpen(!open);
  // for data fetching
  if (data) {
    result = data.results;
  } else if (isLoading) {
    return <HashLoader color="#36d7b7" />;
  } else {
    return <div>Error Loading..</div>;
  }

  return (
    <>
      <Button onClick={handleOpen} variant="outlined" color="green" size="sm">
        <FiMousePointer className="inline" />
        <span className="ml-1">Select Item</span>
      </Button>
      <Dialog
        open={open}
        size="xl"
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="text-green-900">
          Complete List of Items
        </DialogHeader>
        <div className=" mr-10 flex justify-between">
          <div className=" ml-10 w-full md:w-72">
            <Input type="search" label="search" onKeyUp={handleSearch} />
          </div>
          <div className="flex items-center space-x-2">
            <Typography>Page size:</Typography>
            <select
              defaultValue={pageSize}
              onChange={changePagesize}
              className="border-2 border-blue-gray-500"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {tableHead.map((head, index) => (
                  <th
                    key={index}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {result.map((item, index) => {
                const isLast = index === data.results.length - 1;
                const check_value = item.id + "=" + item.name;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                return (
                  <tr key={item.id}>
                    <td className={classes}>{item.id}</td>
                    <td className={classes}>{item.name}</td>
                    <td className={classes}>{item.unit_measurement}</td>
                    <td className={classes}>
                      <Button
                        color="teal"
                        value={check_value}
                        onClick={handleSelectItem}
                      >
                        Select
                      </Button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </DialogBody>
        <DialogFooter className="flex justify-between">
          <div className="flex justify-center items-center gap-8 ml-[41%]">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={prev}
              disabled={selectActive === 1}
            >
              <IoArrowBackOutline strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <Typography color="gray" className="font-normal">
              <strong className="text-gray-900">{selectActive}</strong> of
              <strong className="text-gray-900 ml-1">{totalPage}</strong>
            </Typography>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={next}
              disabled={selectActive === totalPage}
            >
              <IoArrowForwardOutline strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
          <div>
            <Button
              color="red"
              variant="text"
              onClick={handleOpen}
              className="mr-2"
            >
              <span>Close</span>
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default VendorItemListOption;
