import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Card,
} from "@material-tailwind/react";
import { GrPowerReset } from "react-icons/gr";
import {
  useAccountListSelectQuery,
  useFetchProdItemLineCompletedQuery,
  useSaveSchedItemMutation,
  useSelectedEmployeeSchedQuery,
  useSaveEmployeeSchedMutation,
  useSaveSchedInputMutation,
  useSaveSchedSignatureMutation,
  useSaveSchedItemDetailMutation,
  useSaveEmployeeInvolMutation,
  useEmpInvolSchedOutputQuery,
} from "../../../store";
import SignatureCanvas from "react-signature-canvas";
import { toast, ToastContainer } from "react-toastify";
import SelectEmployeeLIst from "./SelectEmployeeLIst";


function ProdItemTransSlipModal({ prod_id, prod_refetch }) {
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const burgerMachineLogo = "../images/BurgerMachineLogo.jpg";
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);
  const [signature, setSignature] = useState([]);
  const [currentPageEmp, setCurrentPageEmp] = useState(1);
  const [pageSizeEmp, setPageSizeEmp] = useState(10);

  const fetch_item_complete = useFetchProdItemLineCompletedQuery(prod_id);
  const [saveSchedItem] = useSaveSchedItemMutation();
  const selectedEmpSched = useSelectedEmployeeSchedQuery(prod_id);
  const [saveEmpSched] = useSaveEmployeeSchedMutation();
  const [saveSchedInput] = useSaveSchedInputMutation();
  const accouint_list_select = useAccountListSelectQuery(1);
  const [saveSchedSignature] = useSaveSchedSignatureMutation();
  const [saveSchedProd, {isLoading}] = useSaveSchedItemDetailMutation();
  const emp_sched_out = useEmpInvolSchedOutputQuery({
    spl_id: prod_id,
    page: currentPageEmp,
    pageSize: pageSizeEmp,
  });

  const [saveEmployeInvoMutation] = useSaveEmployeeInvolMutation();

  const inputClasses = "flex flex-row items-center";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const TableHead = [
    "Production Items",
    "Scheduled Batch",
    "Actual Batch",
    "Theoretical Output",
    "Actual Weight",
    "Theoretical Weight",
    "Weight in Packs",
    "Actual Yield",
    "Good",
    "Reject",
  ];
  const TABLE_HEAD_EMP = ["Name", "Assign", "Output", "Remark"];
  const prodLine = [
    {
      id: 1,
      name: "Bakery",
    },
    {
      id: 2,
      name: "Patty",
    },
    {
      id: 3,
      name: "Sandwich line",
    },
    {
      id: 4,
      name: "Catsup",
    },
    {
      id: 5,
      name: "Cake and Pastries",
    },
    {
      id: 6,
      name: "Premixes",
    },
  ];
  const WarehouseLocation = [
    {
      id: 1,
      loc: "Raw Materials Area",
    },
    {
      id: 2,
      loc: "Foods Duty Area",
    },
    {
      id: 3,
      loc: "Beverages Area",
    },
    {
      id: 4,
      loc: "Consumables Area",
    },
    {
      id: 5,
      loc: "Material Warehouse - Patty",
    },
    {
      id: 6,
      loc: "Material Warehouse - Bread",
    },
    {
      id: 7,
      loc: "Material Warehouse - Sline",
    },
    {
      id: 8,
      loc: "Material Warehouse - Cake",
    },
  ];
  const assignatories = [
    {
      id: "1",
      label: "bakery_team_leader",
      title: "Bakery Team Leader",
      ref: React.createRef(),
    },
    {
      id: "2",
      label: "production_supervisor",
      title: "Production Supervisor",
      ref: React.createRef(),
    },
  ];
  const location = prodLine.find(
    (location) => location.id === parseInt(fetch_item_complete.data?.data.issuance_from)
  );

  const clearSignature = (canvasRef) => {
    canvasRef.current.isEmpty() ? console.log("") : canvasRef.current.clear();
  };

  let selected_emp_arr = [];
  if (selectedEmpSched.isLoading) {
    return <div>Loading....</div>;
  } else if (selectedEmpSched.error) {
    return <div>Refresh the page...</div>;
  } else {
    selected_emp_arr = selectedEmpSched.data?.data;
  }

  let content;
  let count_null = 0;
  if (fetch_item_complete.isLoading) {
    return <div>Loading....</div>;
  } else if (fetch_item_complete.error) {
    return <div>Refresh the page...</div>;
  } else {
    let prod_sched = fetch_item_complete.data?.data.sched_item;
    let prod_sche_list = [];

    for (let i = 0; i < prod_sched.length; i++) {
      let eachProdSched = prod_sched[i];
      if (eachProdSched.actual_batch === null || eachProdSched.actual_batch === '') {
        count_null += 1
      }

      if (eachProdSched.actual_weight === null || eachProdSched.actual_weight === '') {
        count_null += 1
      }

      if (eachProdSched.good === null || eachProdSched.good === '') {
        count_null += 1
      }

      if (eachProdSched.reject === null || eachProdSched.reject === '') {
        count_null += 1
      }

      prod_sche_list.push({
        id: eachProdSched.id,
        actual_batch: eachProdSched.actual_batch,
        actual_weight: eachProdSched.actual_weight,
        actual_yield: eachProdSched.actual_yield,
        weight_in_pack: eachProdSched.weight_in_pack,
        good: eachProdSched.good,
        name: eachProdSched.name,
        reject: eachProdSched.reject,
        schedule_batch: eachProdSched.schedule_batch,
        theo_weight: eachProdSched.theo_weight,
        theo_yield: eachProdSched.theo_yield,
      });
    }

    content = (
      <>
        {prod_sche_list
          .sort((a, b) => b.id - a.id)
          .map((pr, i) => {
            const isLast = pr.length - 1;
            const classes = isLast
              ? "p-4 text-[#252525] text-sm"
              : "p-4 border-b border-blue-gray-50 text-[#252525] text-sm";
            return (
              <tr key={i}>
                <td className={classes}>{pr.name}</td>
                <td className={classes}>{pr.schedule_batch}</td>
                {/* Actual Batch */}
                <td className={classes}>
                  <input
                    type="number"
                    min="0"
                    defaultValue={pr.actual_batch}
                    autoFocus={false}
                    onBlur={(e) => {
                      productSchedInput(e.target.value, "actual_batch", pr.id);
                    }}
                    className="w-20 border border-1 border-blue-gray-300 p-1"
                  />
                </td>
                {/* Theoretical Output */}
                <td className={classes}>{pr.theo_yield}</td>
                {/* Actual Weight */}
                <td className={classes}>
                  <input
                    type="number"
                    step="0.01"
                    defaultValue={pr.actual_weight}
                    autoFocus={false}
                    onBlur={(e) => {
                      productSchedInput(e.target.value, "actual_weight", pr.id);
                    }}
                    className="w-20 border border-1 border-blue-gray-300 p-1"
                  />
                </td>
                {/* Theoretical Weight */}
                <td className={classes}>{pr.theo_weight}</td>
                {/* Weight in pack */}
                <td className={classes}>{pr.weight_in_pack}</td>
                {/* Actual Yield */}
                <td className={classes}>{pr.actual_yield}</td>
                {/* Good */}
                <td className={classes}>
                  <input
                    type="number"
                    min="0"
                    defaultValue={pr.good}
                    autoFocus={false}
                    onBlur={(e) => {
                      productSchedInput(e.target.value, "good", pr.id);
                    }}
                    className="w-20 border border-1 border-blue-gray-300 p-1"
                  />
                </td>
                {/* Reject */}
                <td className={classes}>
                  <input
                    type="number"
                    min="0"
                    defaultValue={pr.reject}
                    autoFocus={false}
                    onBlur={(e) => {
                      productSchedInput(e.target.value, "reject", pr.id);
                    }}
                    className="w-20 border border-1 border-blue-gray-300 p-1"
                  />
                </td>
              </tr>
            );
          })}
      </>
    );
  }

  const handle_submit_disable = () => {
    if (count_null <= 0){
      return false
    }
    if (isLoading === true){
      return true
    }
    return true
  }

  console.log(count_null)

  let als = [];
  let selected_receiver;
  if (accouint_list_select.isLoading) {
    //pass
  } else if (accouint_list_select.error) {
    //pass
  } else {
    als = accouint_list_select.data.data;
    if (fetch_item_complete.data?.data.recieve_by != null) {
      selected_receiver = (
        <option value={fetch_item_complete.data?.data.recieve_by} selected>
          {als.find((rev) => rev.user_id === fetch_item_complete.data?.data.recieve_by).full_name}{" "}
        </option>
      );
    }
  }

  const productSchedInput = async (value, type, id) => {
    let formData = new FormData();
    formData.append("id", id);
    formData.append("client_id", account_detailed.client.id);

    if (type === "actual_batch") {
      formData.append("value", value);
      formData.append("type", "actual_batch");
    }
    if (type === "actual_weight") {
      formData.append("value", value);
      formData.append("type", "actual_weight");
    }
    if (type === "actual_yield") {
      formData.append("value", value);
      formData.append("type", "actual_yield");
    }
    if (type === "good") {
      formData.append("value", value);
      formData.append("type", "good");
    }
    if (type === "reject") {
      formData.append("value", value);
      formData.append("type", "reject");
    }

    const ssp = await saveSchedItem(formData).unwrap();
    if (ssp.success === true) {
      toast.success(ssp.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(ssp.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    fetch_item_complete.refetch();
  };

  const arrayEmployee = async (value1, value2) => {
    let form = {
      id: prod_id,
      employees_id: value1,
    };
    const sec = await saveEmpSched(form).unwrap();
    if (sec.success === true) {
      toast.success(sec.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sec.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    selectedEmpSched.refetch();
    emp_sched_out.refetch();
  };

  const handleSaveSchedInput = async (value, type) => {
    let formData = new FormData();
    formData.append("id", prod_id);

    console.log(value);
    if (type === "recieve_by") {
      formData.append("value", value);
      formData.append("type", "recieve_by");
    }
    if (type === "transfer_to") {
      formData.append("value", value);
      formData.append("type", "transfer_to");
    }
    if (type === "remarks") {
      formData.append("value", value);
      formData.append("type", "remarks");
    }

    if (type === "status") {
      formData.append("value", value);
      formData.append("type", "status");
    }

    const ssi = await saveSchedInput(formData).unwrap();
    if (ssi.success === true) {
      toast.success(ssi.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(ssi.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };

  const handleSave = () => {
    let result = [];

    assignatories.map((sig) => {
      if (sig.ref.current !== null) {
        let sigi = sig.ref.current.getTrimmedCanvas().toDataURL();
        let sigi_split = sigi.split(",");
        result.push({
          assignatories: sig.label,
          dataURLs: sigi_split[1],
        });
      }
      return result;
    });

    // Do something with dataURLs array, for example, log it
    setSignature(result);
  };

  const saveSignature = async () => {
    let formData = {
      id: prod_id,
      signature: signature,
    };

    const sss = await saveSchedSignature(formData).unwrap();
    if (sss.success === true) {
      toast.success(sss.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sss.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    handleOpen();
    prod_refetch();
    fetch_item_complete.refetch();
  };

  // Over all save Product schedule
  const handleSaveProduct = async () => {
    let formData = new FormData();
    formData.append("id", prod_id);
    formData.append("user_id", user_info.id);
    formData.append("client_id", account_detailed.client.id);

    const ssp = await saveSchedProd(formData).unwrap();
    if (ssp.success === true) {
      toast.success(ssp.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(ssp.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    handleOpen();
    prod_refetch();
    fetch_item_complete.refetch();
  };

  let content_emp;
  if (emp_sched_out.isLoading) {
    return <div>Loading....</div>;
  } else if (emp_sched_out.error) {
    return <div>Refresh the page...</div>;
  } else {
    let emp_involve_data = emp_sched_out.data.results;
    let emp_involve = [];
    for (let i = 0; i < emp_involve_data.length; i++) {
      let eachEmpIn = emp_involve_data[i];
      let full_name =
        eachEmpIn.employee.first_name + " " + eachEmpIn.employee.last_name;
      emp_involve.push({
        id: eachEmpIn.id,
        name: full_name,
        assign: eachEmpIn.assign,
        output: eachEmpIn.output,
        remark: eachEmpIn.remark,
      });
    }
    content_emp = (
      <>
        {emp_involve
          .sort((a, b) => b.id - a.id)
          .map((emp, index) => {
            const isLast = emp.length - 1;
            const classes = isLast
              ? "p-4 text-[#252525] text-sm"
              : "p-4 border-b border-blue-gray-50 text-[#252525] text-sm";
            {
              return (
                <tr key={index}>
                  <td className={classes}>{emp.name}</td>
                  <td className={classes}>
                    <select
                      onChange={(e) => {
                        handleEmplInvolveInput(
                          e.target.value,
                          emp.id,
                          "assign"
                        );
                      }}
                      className="w-[101%] inline-flex justify-center ml-2 rounded-md px-5 py-3 text-sm text-black-500 border-[1px] ring-blue-gray-200 border-blue-gray-200"
                    >
                      <option
                        value="2"
                        selected={emp.assign == 2 ? true : false}
                      >
                        Helper
                      </option>
                      <option
                        value="1"
                        selected={emp.assign == 1 ? true : false}
                      >
                        Molder
                      </option>
                      <option
                        value="3"
                        selected={emp.assign == 3 ? true : false}
                      >
                        Repacker
                      </option>
                    </select>
                  </td>
                  <td className={classes}>
                    <Input
                      type="number"
                      defaultValue={emp.output}
                      autoFocus={false}
                      onBlur={(e) => {
                        handleEmplInvolveInput(
                          e.target.value,
                          emp.id,
                          "output"
                        );
                      }}
                    />
                  </td>
                  <td className={classes}>
                    <textarea
                      placeholder="Enter your remarks here..."
                      name=""
                      id=""
                      cols="40"
                      rows="2"
                      className="border-2 border-blue-gray-500 text-black p-2"
                      autoFocus={false}
                      onBlur={(e) => {
                        handleEmplInvolveInput(
                          e.target.value,
                          emp.id,
                          "remarks"
                        );
                      }}
                    >
                      {emp.remark}
                    </textarea>
                  </td>
                </tr>
              );
            }
          })}
      </>
    );
  }
  const handleEmplInvolveInput = async (e, id, type) => {
    let formData = new FormData();
    formData.append("spl_id", prod_id);
    formData.append("id", id);

    if (type === "output") {
      formData.append("output", e);
    }

    if (type === "remarks") {
      formData.append("remarks", e);
    }

    if (type === "assign") {
      formData.append("assign", e);
    }

    const seim = await saveEmployeInvoMutation(formData).unwrap();

    if (seim.success === true) {
      toast.success(seim.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(seim.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    emp_sched_out.refetch();
  };

  return (
    <>
      <Button onClick={handleOpen} color="teal" size="sm">
        View
      </Button>
      <Dialog
        open={open}
        handler={handleOpen}
        className="relative max-h-min"
        size="xl"
      >
        <DialogHeader className="w-full space-y-3 md:space-y-0 md:space-x-10 rounded-sm flex flex-col md:flex-row justify-center">
          <img
            src={burgerMachineLogo}
            alt="bmLogo"
            className="aspect-auto w-72"
          />
          <Typography className="text-green-900" variant="h3">
            Production Report
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[50vh]">
          <form>
            <div className="grid md:grid-cols-4 gap-5 grid-auto-rows-auto">
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input
                    label="Issuance From"
                    name="issuance_from"
                    value={fetch_item_complete.data?.data.issuance_from}
                  />
                </div>
                <div className="relative h-10 w-full">
                  {/* fist select */}
                  <select
                    label="Department"
                    className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    onChange={(evt) =>
                      handleSaveSchedInput(evt.target.value, "transfer_to")
                    }
                    disabled
                  >
                    {fetch_item_complete.data?.data.transfer_to != null ? (
                      <option selected>{fetch_item_complete.data?.data.transfer_to}</option>
                    ) : (
                      <option disabled selected className="text-center">
                        ----- Select location -----
                      </option>
                    )}
                    {WarehouseLocation.map((wl, i) => (
                      <option key={i} value={wl.loc}>
                        {wl.loc}
                      </option>
                    ))}
                  </select>
                  <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                    Transfer to
                  </label>
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Transfer Request Date"
                    name="trd"
                    value={fetch_item_complete.data?.data.transfer_request_date}
                  />
                </div>
              </div>
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input
                    label="Name of Issuer"
                    name="name_issuer"
                    value={fetch_item_complete.data.data?.requested_by}
                  />
                </div>
                <div className={inputClasses}>
                  <select
                    className="w-[101%] inline-flex justify-center rounded-md px-2 py-3 text-sm text-black-500 border-[1px] ring-blue-gray-200 border-blue-gray-200"
                    required
                    onChange={(evt) =>
                      handleSaveSchedInput(evt.target.value, "recieve_by")
                    }
                    disabled
                  >
                    {selected_receiver}
                    {als.map((rev, i) => {
                      return (
                        <>
                          {/* <option value={rev.user_id}>{rev.full_name}</option>LEONI FE PLARISAN */}
                          <option value={rev.user_id}>Leoni Fe Palrisan</option>
                        </>
                      );
                    })}
                  </select>
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Transfer Tracking Number"
                    name="ttn"
                    value={fetch_item_complete.data?.data.transfer_number}
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TableHead.map((head) => (
                      <th
                        key={head}
                        className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  <ToastContainer />
                  {content}
                </tbody>
              </table>
              <div className="flex justify-between mt-5">
                <div className="w-50 space-y-2">
                  <SelectEmployeeLIst
                    prod_id={prod_id}
                    selectEmployee={arrayEmployee}
                    employee_list_id={selected_emp_arr}
                  />
                  <Typography>
                    {"Total of " +
                      selected_emp_arr.length +
                      " Repaker and Molders "}
                  </Typography>
                </div>
              </div>
            </div>
            <Card className="h-full w-full overflow-scroll" component={"span"}>
              <table className="w-full min-w-max table-auto text-left ">
                <thead>
                  <tr>
                    {TABLE_HEAD_EMP.map((head) => (
                      <th
                        key={head}
                        className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                      >
                        <Typography
                          component={"span"}
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>{content_emp}</tbody>
              </table>
            </Card>
            <div className="flex justify-center space-x-10">
              {fetch_item_complete.data?.data.bakery_team_leader == null ? (
                ""
              ) : (
                <div className="flex flex-col justify-center items-center mt-10 space-y-3">
                  <div className="border border-blue-gray-300 rounded-lg py-1 px-2">
                    <img
                      src={apiDomain + fetch_item_complete.data?.data.bakery_team_leader}
                      alt=""
                    />
                  </div>
                  <Typography>Bakery Team Leader</Typography>
                </div>
              )}
              {fetch_item_complete.data?.data.production_supervisor == null ? (
                ""
              ) : (
                <div className="flex flex-col justify-center items-center mt-10 space-y-3">
                  <div className="border border-blue-gray-300 rounded-lg py-1 px-2">
                    <img
                      src={apiDomain + fetch_item_complete.data?.data.production_supervisor}
                      alt=""
                    />
                  </div>
                  <Typography>Production Supervisor</Typography>
                </div>
              )}
              <div className="md:flex md:space-x-3">
                {assignatories.map((sig) => {
                  if (fetch_item_complete.data?.data.bakery_team_leader === null) {
                    if (sig.label === "bakery_team_leader") {
                      return (
                        <>
                          <div key={sig.id} className="flex flex-col">
                            <div className="flex flex-row mt-8">
                              <SignatureCanvas
                                penColor="black"
                                canvasProps={{
                                  width: 200,
                                  height: 100,
                                  className:
                                    "border-black border-2 rounded-l-xl",
                                }}
                                ref={sig.ref}
                                onEnd={handleSave}
                              />
                              <div className={boxResetClasses}>
                                <GrPowerReset
                                  size={"30px"}
                                  onClick={() => clearSignature(sig.ref)}
                                  className="cursor-pointer"
                                  color="white"
                                />
                              </div>
                            </div>
                            <Typography className="text-center mt-3">
                              {sig.title}
                            </Typography>
                          </div>
                        </>
                      );
                    }
                  }
                  if (fetch_item_complete.data?.data.production_supervisor === null) {
                    if (sig.label === "production_supervisor") {
                      return (
                        <>
                          <div key={sig.id} className="flex flex-col">
                            <div className="flex flex-row mt-8">
                              <SignatureCanvas
                                penColor="black"
                                canvasProps={{
                                  width: 200,
                                  height: 100,
                                  className:
                                    "border-black border-2 rounded-l-xl",
                                }}
                                ref={sig.ref}
                                onEnd={handleSave}
                              />
                              <div className={boxResetClasses}>
                                <GrPowerReset
                                  size={"30px"}
                                  onClick={() => clearSignature(sig.ref)}
                                  className="cursor-pointer"
                                  color="white"
                                />
                              </div>
                            </div>
                            <Typography className="text-center mt-3">
                              {sig.title}
                            </Typography>
                          </div>
                        </>
                      );
                    }
                  }
                })}
              </div>
            </div>
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            className="bg-green-900 ml-3"
            disabled={handle_submit_disable()}
            onClick={handleSaveProduct}
          >
            <span>Submit</span>
          </Button>
          <Button
            variant="text"
            color="red"
            className="mr-3 ml-3"
            onClick={handleOpen}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default ProdItemTransSlipModal;
