import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Checkbox,
  CardFooter,
  Select,
  Option,
  Card,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { FaSyncAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { GrPowerReset } from "react-icons/gr";
import ReactSignatureCanvas from "react-signature-canvas";
import { useFetchOrderNumberListPageQuery } from "../../../store";
import { useFetchOutletListPageQuery } from "../../../store";
import { useFetchWithdrawalOrderRequestQuery } from "../../../store";
import { useAddWithdrawalRequestMutation } from "../../../store";
import { useFetchDeliveryItemsAdjustQuery } from "../../../store";

import { Confirmation } from "../Error_trap_components/ErrorTrapComponent";

import { ToastContainer, toast } from "react-toastify";
const WithdrawModal = ({ refetchDeliveryList }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const inputClasses = "flex flex-row items-center";
  const signatureClasses = "grid grid-flow-row grid-cols-1";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const tableHead = ["", ""];
  const tableHeadSelectOrder = [
    "",
    "ORDER #",
    "OUTLET",
    "FC",
    "ROUTE",
    "ADDRESS",
  ];
  const tableHheadSelectOutlet = ["", "OUTLET", "FC", "ROUTE", "ADDRESS"];
  const trapMessage = "Are you sure to return these products?";
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const classes = "p-4 border-b border-blue-gray-50";
  const TABLE_HEAD = [
    "Code",
    "Products",
    "Weight Box/Unit Measure",
    // "SL",
    "Delivered Quantity in WB",
    // "Order",
    "Return Quantity in Pieces",
  ];

  let outlet_list_page_result = [];
  let order_result = [];
  let order_item_result = [];

  const [selectedCage, setSelectedCage] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [orderSeachTerm, setOrderSeachTerm] = useState("");
  const [selectedOutlets, setSelectedOutlets] = useState(null);
  const [selectedOrdernumber, setSelectedOrdernumber] = useState(null);
  const [currentOutletPage, setCurrentOutletPage] = useState(1);
  const [pageOutletSize, setPageOutletSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentOrderPage, setCurrentOrderPage] = useState(1);
  const [orderPageSize, setOrderPageSize] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [isOutletDialogOpen, setIsOutletDialogOpen] = useState(false);
  const [isOrderNumDialogOpen, setIsOrderNumDialogOpen] = useState(false);
  const [currentOutletID, setCurrentOutletID] = useState(1);
  const [currentOrderID, setCurrentOrderID] = useState(1);
  let [productActualQty, setProductActualQty] = useState([]);
  const [productAdjustedQty, setProductAdjustedQty] = useState([]);
  const [driverName, setDriverName] = useState("");

  let [isReadyToSave, setIsReadyToSave] = useState(false);

  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const token = localStorage.getItem("mytoken");
  let navigate = useNavigate();

  const {
    data: outletListData,
    error: outletListErr,
    isLoading: outletListLoding,
    isSuccess: outletListSuccess,
  } = useFetchOutletListPageQuery({
    page: currentOutletPage,
    page_size: 300,
    search: searchTerm,
  });

  useEffect(() => {
    if (
      account_detailed.job_position == 1 ||
      account_detailed.user_type == 1 ||
      account_detailed.job_position === 13 ||
      account_detailed.job_position == 15
    ) {
      if (account_detailed.job_position === 15 && outletListSuccess) {
        const outlet = outletListData.results.find(
          (outlet) => outlet.id === account_detailed.outlet
        );
        if (outlet) {
          setSelectedOutlets(outlet);
          setCurrentOutletID(account_detailed.outlet);
          console.log(account_detailed);
        }
      }
    } else {
      navigate("/base/dashboard");

      return;
    }
  }, [token, outletListSuccess, outletListData]);

  const {
    data: orderData,
    error: orderError,
    isLoading: orderLoding,
    isSuccess: orderSuccess,
  } = useFetchWithdrawalOrderRequestQuery({
    page: currentOrderPage,
    page_size: orderPageSize,
    search: orderSeachTerm,
    outlet_id: currentOutletID,
  });

  const {
    data: orderItemData,
    error: orderItemError,
    isLoading: orderItemLoading,
    isSuccess: orderItemSuccess,
    refetch,
  } = useFetchDeliveryItemsAdjustQuery({
    page: currentPage,
    page_size: 500,
    order_number: currentOrderID,
  });

  //fetching logo/img from localstorage
  const account_detail = JSON.parse(localStorage.getItem("account_detail"));
  // const burgerMachineLogo =
  //   process.env.REACT_APP_API_DOMAIN + "" + logo.client.logo;

  const burgerMachineLogo = "../images/BurgerMachineLogo.jpg";

  const generateFC = () => {
    return selectedOutlets ? selectedOutlets.fc.name : "";
  };
  const generateRoute = () => {
    return selectedOutlets ? selectedOutlets.route.name : "";
  };
  const generateAddress = () => {
    return selectedOutlets ? selectedOutlets.address : "";
  };

  const handleRejectQtyChange = (e, index) => {
    let weightBoxStr = orderItemData.results[index].product.weight_box;

    // Remove all non-numeric characters using regular expression
    weightBoxStr = weightBoxStr.replace(/\D/g, "");

    // Convert the string to an integer
    let weightBoxInt = parseInt(weightBoxStr, 10);
    let value = parseInt(e.target.value, 10);

    // Ensure the value is not less than 0
    if (value < 0) {
      value = 0;
    }

    // Ensure the value does not exceed the maximum quantity
    const maxQty = orderItemData.results[index].received_qty * weightBoxInt;
    if (value > maxQty) {
      value = maxQty;
    }

    setProductAdjustedQty((prevQty) => {
      // Create a copy of the previous state
      let updatedQty = [...prevQty];
      // Update the value at the specified index
      updatedQty[index] = value;

      // Use the updatedQty to calculate filteredProductData
      let filteredProductData = updatedQty
        .map((qty, idx) => ({
          itemId: orderItemData.results[idx].id,
          itemQty: qty,
        }))
        .filter((item) => item.itemQty !== undefined && item.itemQty !== "");

      // Call onQtyChange with the updated data
      setProductActualQty(filteredProductData);

      // Log the filteredProductData
      console.log(account_detail);

      // Return the updated state
      return updatedQty;
    });
  };

  const [addWithrdrawalRequest] = useAddWithdrawalRequestMutation();

  const [signature, setSignature] = useState("");
  const signatureRef = React.createRef();
  const handleSignatureReset = () => {
    signatureRef.current.clear();
    setSignature(""); // Reset the stored base64 representation
  };
  // Handle change event
  const handleSignatureChange = () => {
    const base64Signature = signatureRef.current.toDataURL(); // Get base64 representation
    setSignature(base64Signature);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleOrderSearchChange = (event) => {
    setOrderSeachTerm(event.target.value);
    setCurrentOrderPage(1);
  };
  const handlePreviousOutletPage = () => {
    setCurrentOutletPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextOutletPage = () => {
    setCurrentOutletPage((prevPage) => prevPage + 1);
  };

  // const handlePreviousOrderPage = () => {
  //   setCurrentOrderPage((prevPage) => Math.max(prevPage - 1, 1));
  // };
  // const handleNextOrderPage = () => {
  //   setCurrentOrderPage((prevPage) => Math.max(prevPage - 1, 1));
  //   ((prevPage) => prevPage + 1);
  // };

  const handleClearOutlet = () => {
    setSelectedOutlets(null);
  };
  const handleOrderClear = () => {
    setSelectedOrdernumber(null);
  };

  useEffect(() => {
    // Check if all conditions are met
    const isReady = signature !== "";
    // Update the state based on the condition
    setIsReadyToSave(isReady);
  }, [signature]);

  const handleOpenOutletDialog = () => {
    // Your logic to handle opening the dialog
    setIsOutletDialogOpen(true);
  };

  const handleDialogClose = () => {
    // Your logic to handle closing the dialog
    setIsOrderNumDialogOpen(false);
    setIsOutletDialogOpen(false);
  };

  const handleOrderNumberDialog = () => {
    // Your logic to handle opening the dialog
    setIsOrderNumDialogOpen(true);
  };

  const handleOutletChange = (selectedOutlet) => {
    // Set the selected outlet
    setSelectedOutlets(selectedOutlet);
    setCurrentPage(1);
    handleDialogClose();

    setCurrentOutletID(selectedOutlet.id);
    console.log(currentOutletID);
  };

  const handleOrderChange = (order) => {
    // Set the selected outlet
    setSelectedOrdernumber(order);
    setCurrentOrderPage(1);
    handleDialogClose();

    setCurrentOrderID(order.id);
  };

  const handleMainClose = () => {
    setOpen(false);
    setSignature("");
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    let allData = {
      outlet: selectedOutlets,
      user: account_detailed.user.id,
      order: selectedOrdernumber.id,
      withdrawItems: productActualQty,
    };
    console.log(allData);
    try {
      const sea = await addWithrdrawalRequest(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // refetch();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // formRef.current?.reset();
      }
    } catch (error) {
      // Handle the error here
      // You can display a generic error message to the user if needed
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };

  if (outletListLoding) {
    return <div>Loading...</div>;
  } else if (outletListErr) {
    return <div>Error loading data</div>;
  } else if (outletListData.length === 0) {
    return <div>No data found.</div>;
  } else if (outletListSuccess) {
    outlet_list_page_result = outletListData.results;
  }

  if (orderLoding) {
    return <div>Loading...</div>;
  } else if (orderError) {
    return <div>Error loading data</div>;
  } else if (orderData.length === 0) {
    return <div>No data found.</div>;
  } else if (orderSuccess) {
    order_result = orderData.results;
    // console.log(order_result)
  }
  if (orderItemLoading) {
    return <div>Loading...</div>;
  } else if (orderItemError) {
    return <div>Error loading data</div>;
  } else if (orderItemData.length === 0) {
    return <div>No data found.</div>;
  } else if (orderItemSuccess) {
    order_item_result = orderItemData.results;
  }
  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]" size="sm">
        Withdrawal Form
      </Button>
      <Dialog
        open={open}
        size="xl"
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          {/* <img
            src={burgerMachineLogo}
            alt="logo"
            className="aspect-auto w-72"
          /> */}
          <Typography className="text-green-900" variant="h3">
            Withdrawal Form
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <form>
            <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto">
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input
                    label="Outlet"
                    value={selectedOutlets ? selectedOutlets.outlet : ""}
                    onClick={
                      account_detailed.job_position !== 15
                        ? handleOpenOutletDialog
                        : null
                    }
                    readOnly
                  />
                  <Dialog
                    size="xl"
                    open={isOutletDialogOpen}
                    handler={handleDialogClose}
                    className="relative max-h-min"
                  >
                    <DialogHeader>
                      <Typography className="text-green-900" variant="h3">
                        Select Outlets
                      </Typography>
                    </DialogHeader>
                    <DialogBody className="overflow-y-auto max-h-[50vh]">
                      <Input
                        label="Search"
                        icon={<FiSearch className="h-5 w-5" />}
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />

                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {tableHheadSelectOutlet.map((head, index) => (
                              <th
                                key={index}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {outlet_list_page_result.map((outlet) => {
                            const classes = "p-4 border-b border-blue-gray-50";
                            return (
                              <tr
                                key={outlet.id}
                                onClick={() => handleOutletChange(outlet)}
                              >
                                <td className={classes}>
                                  <input
                                    type="radio"
                                    color="teal"
                                    name="selectedOutlet"
                                    checked={
                                      selectedOutlets &&
                                      selectedOutlets.id === outlet.id
                                    }
                                    onChange={() => handleOutletChange(outlet)}
                                  />
                                </td>
                                <td className={classes}>{outlet.outlet}</td>
                                <td className={classes}>{outlet.fc.name}</td>
                                <td className={classes}>{outlet.route.name}</td>
                                <td className={classes}>{outlet.address}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </DialogBody>
                    <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        Page{" "}
                        {`${currentOutletPage} of ${
                          Math.ceil(outletListData?.count / pageOutletSize) || 1
                        }`}
                      </Typography>
                      <div className="flex gap-2">
                        <Button
                          variant="outlined"
                          size="sm"
                          onClick={handlePreviousOutletPage}
                          disabled={!outletListData?.previous}
                        >
                          Previous
                        </Button>
                        <Button
                          variant="outlined"
                          size="sm"
                          onClick={handleNextOutletPage}
                          disabled={!outletListData?.next}
                        >
                          {outletListData?.next ? "Next" : "No More Pages"}
                        </Button>
                      </div>
                    </CardFooter>
                  </Dialog>
                  {/* {selectedOutlets !== null && (
                    <Button
                      onClick={handleClearOutlet}
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "green",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <FaSyncAlt style={{ color: "white" }} />
                    </Button>
                  )} */}
                </div>

                <div className={inputClasses}>
                  <Input
                    label="Address"
                    name="address"
                    value={generateAddress()}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input label="FC" name="fc" value={generateFC()} readOnly />
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Route"
                    name="route"
                    value={generateRoute()}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div>
              <div className={inputClasses}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  <Input
                    label="Order Number"
                    value={
                      selectedOrdernumber
                        ? selectedOrdernumber.order_number
                        : ""
                    }
                    onClick={handleOrderNumberDialog}
                    readOnly
                  />
                </div>
                <Dialog
                  size="xl"
                  open={isOrderNumDialogOpen}
                  handler={handleDialogClose}
                  className="relative max-h-min"
                >
                  <DialogHeader>
                    <Typography className="text-green-900" variant="h3">
                      Select Order Number
                    </Typography>
                  </DialogHeader>
                  <DialogBody className="overflow-y-auto max-h-[50vh]">
                    <Input
                      label="Search"
                      icon={<FiSearch className="h-5 w-5" />}
                      value={orderSeachTerm}
                      onChange={handleOrderSearchChange}
                    />

                    <table className="w-full min-w-max table-auto text-left">
                      <thead>
                        <tr>
                          {tableHeadSelectOrder.map((head, index) => (
                            <th
                              key={index}
                              className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                            >
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal leading-none opacity-70"
                              >
                                {head}
                              </Typography>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {order_result.map((order) => {
                          const classes = "p-4 border-b border-blue-gray-50";
                          return (
                            <tr
                              key={order.id}
                              onClick={() => handleOrderChange(order)}
                            >
                              <td className={classes}>
                                <input
                                  type="radio"
                                  color="teal"
                                  name="selectedOutlet"
                                  checked={
                                    selectedOrdernumber &&
                                    selectedOrdernumber.id === order.id
                                  }
                                  onChange={() => handleOrderChange(order)}
                                />
                              </td>
                              <td className={classes}>{order.order_number}</td>
                              <td className={classes}>{order.outlet.outlet}</td>
                              <td className={classes}>
                                {order.outlet.fc.name}
                              </td>
                              <td className={classes}>
                                {order.outlet.route.name}
                              </td>
                              <td className={classes}>
                                {order.outlet.address}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </DialogBody>
                  <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal"
                    >
                      Page{" "}
                      {`${currentOutletPage} of ${
                        Math.ceil(orderData?.count / orderPageSize) || 1
                      }`}
                    </Typography>
                    <div className="flex gap-2">
                      <Button
                        variant="outlined"
                        size="sm"
                        onClick={handlePreviousOutletPage}
                        disabled={!orderData?.previous}
                      >
                        Previous
                      </Button>
                      <Button
                        variant="outlined"
                        size="sm"
                        onClick={handleNextOutletPage}
                        disabled={!orderData?.next}
                      >
                        {orderData?.next ? "Next" : "No More Pages"}
                      </Button>
                    </div>
                  </CardFooter>
                </Dialog>
                {selectedOrdernumber !== null && (
                  <Button
                    onClick={handleOrderClear}
                    style={{
                      marginLeft: "8px",
                      backgroundColor: "green",
                      padding: "10px",
                      cursor: "pointer",
                    }}
                  >
                    <FaSyncAlt style={{ color: "white" }} />
                  </Button>
                )}
              </div>
            </div>
            {selectedOutlets !== null && selectedOrdernumber !== null && (
              <div className="m-5">
                <Card className="h-full w-full overflow-scroll">
                  <table className="w-full min-w-max table-auto text-left ">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head) => (
                          <th
                            key={head}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {order_item_result.map((product, index) => (
                        <tr key={index}>
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <div className="flex flex-col">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                  component={"span"}
                                >
                                  {product.product.product_code}
                                </Typography>
                              </div>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <div className="flex flex-col">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                  component={"span"}
                                >
                                  {product.product.name}
                                </Typography>
                              </div>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <div className="flex flex-col">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                  component={"span"}
                                >
                                  {product.product.weight_box}/{" "}
                                  {product.product.unit_measurement}
                                </Typography>
                              </div>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <div className="flex flex-col">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                  component={"span"}
                                >
                                  {product.received_qty} in{" "}
                                  {product.product.unit_measurement}
                                </Typography>
                              </div>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <div className="flex flex-col">
                                <input
                                  type="number"
                                  value={productAdjustedQty[index] || ""}
                                  onChange={(e) =>
                                    handleRejectQtyChange(e, index)
                                  }
                                  style={{
                                    width: "80px",
                                    border: "1px solid #ccc",
                                    padding: "5px",
                                  }}
                                />
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Card>
              </div>
            )}

            {selectedOrdernumber !== null && (
              <>
                <div className="col-span-1 flex items-center justify-center space-y-5">
                  <div className={signatureBoxclasses}>
                    <ReactSignatureCanvas
                      ref={signatureRef}
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 100,
                        className: "border-black border-2 rounded-s-xl",
                      }}
                      onEnd={handleSignatureChange}
                    />
                    <div className={boxResetClasses}>
                      <GrPowerReset
                        size={30}
                        className="cursor-pointer"
                        onClick={handleSignatureReset}
                        color="black"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-1 flex items-center justify-center space-y-5">
                  <Typography className="text-justify text-black" variant="h6">
                    {account_detail.user.first_name
                      ? account_detail.user.last_name
                        ? `${account_detail.user.first_name} ${account_detail.user.last_name}`
                        : "Receiver/Driver"
                      : "Receiver/Driver"}
                  </Typography>
                </div>
              </>
            )}
            <div className="col-span-1 flex items-center justify-center space-y-5 mt-10">
              <Confirmation
                onConfirm={handleUpdate}
                isReadyToSave={isReadyToSave}
                trapMessage={trapMessage}
              />
            </div>
          </form>
        </DialogBody>
        <ToastContainer />
        <DialogFooter>
          {signature == "" && (
            <Typography variant="lead" color="red">
              Please provide a signature.
            </Typography>
          )}
          <Button
            variant="text"
            color="red"
            className="mr-10 ml-10"
            onClick={handleMainClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default WithdrawModal;
