import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Select,
  Option,
} from "@material-tailwind/react";
import { useCreateNewUserMutation } from "../../../store";
import { Confirmation } from "../Error_trap_components/ErrorTrapComponent";
import { ToastContainer, toast } from "react-toastify";
import { BiPhone } from "react-icons/bi";
const trapMessage = "Are you sure you want to add this new user info? ";
function AddnewUserModal({ refetchMainTable }) {
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const token = localStorage.getItem("mytoken");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [firstName, setFirstName] = useState("");
  const [midName, setMidName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const [address, setAdress] = useState("");
  const [number, setNumber] = useState("");
  const [emailAdd, setEmailAdd] = useState("");
  const [gender, setGender] = useState(null);
  const [jobPosition, setjobPosition] = useState(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  let [isReadyToSave, setIsReadyToSave] = useState(false);
  const [requirements, setRequirements] = useState({
    minLength: false,
    hasNumber: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasSpecialChar: false,
    passwordsMatch: false,
  });

  const checkPasswordComplexity = (password, confirmPassword) => {
    const complexity = {
      minLength: password.length >= 8,
      hasNumber: /\d/.test(password),
      hasUpperCase: /[A-Z]/.test(password),
      hasLowerCase: /[a-z]/.test(password),
      hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
      passwordsMatch: password === confirmPassword && confirmPassword !== "",
    };

    setRequirements(complexity);
  };

  useEffect(() => {
    const allRequirementsMet = Object.values(requirements).every(
      (value) => value === true
    );

    const anyArrayHasData =
      address !== "" &&
      firstName !== "" &&
      midName !== "" &&
      lastName !== "" &&
      number !== "" &&
      gender !== null &&
      jobPosition !== null &&
      userName !== "" &&
      password !== "" &&
      confirmPassword !== "" &&
      allRequirementsMet;

    setIsReadyToSave(anyArrayHasData);
  }, [
    address,
    firstName,
    midName,
    lastName,
    number,
    gender,
    jobPosition,
    userName,
    password,
    confirmPassword,
    requirements,
  ]);

  function handleAddressChange(event) {
    const newValue = event.target.value;
    setAdress(newValue);
  }
  function handleEmailAddressChange(event) {
    const newValue = event.target.value;
    setEmailAdd(newValue);
  }
  function handleChangeFirstName(event) {
    const newValue = event.target.value;
    setFirstName(newValue);
  }
  function handleChangeLastName(event) {
    const newValue = event.target.value;
    setLastName(newValue);
  }
  function handleChangeMiddleName(event) {
    const newValue = event.target.value;
    setMidName(newValue);
  }
  function handleChangeUserName(event) {
    const newValue = event.target.value;
    setUserName(newValue);
  }
  function handleChangeNumber(event) {
    const newValue = event.target.value;
    setNumber(newValue);
  }
  const handleChangeJobPosition = (e) => {
    setjobPosition(e.target.value);
  };

  const handleChangeGender = (value) => {
    const gender = parseInt(value, 10);
    setGender(gender);
  };
  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    checkPasswordComplexity(newPassword, confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    checkPasswordComplexity(password, newConfirmPassword);
  };

  const jobPositions = {
    1: "Plant Manager",
    2: "Finance Officer",
    3: "Finance Disbursement",
    4: "PICS Encoder",
    5: "Warehouse Supervisor",
    6: "Raw Materials Custodian",
    7: "Foods Duty Area Custodian",
    8: "Beverages Consumables Custodian",
    9: "Processing Supervisor",
    10: "Bakery Team Leader",
    11: "Patty's Line Team Leader",
    12: "Sanzrival Musa Team Leader",
    13: "General Manager",
    14: "Treasury Manager",
    16: "Driver",
    17: "Purchasing Manager",
    18: "Operations Staff",
    19: "Raw Mats Repacking Custodian",
    20: "Regional Manager",
    21: "Franchise Manager",
    22: "Auditor",
    23: "Plant Director",
    24: "Area Supervisor",
  };

  const [saveNewUser] = useCreateNewUserMutation();
  const handleSave = async (event) => {
    event.preventDefault();
    // Gather data from each table component

    // const formattedDate = deliveryDate.format("YYYY-MM-DD");
    // Repeat for other table components

    // Combine all data as needed
    let allData = {
      // f_name = data.get('fName')
      // m_name = data.get('mName')
      // l_name = data.get('lName')
      // address = data.get('address')
      // u_name = data.get('userName')
      // user_id = data.get('userId')
      // client_id = data.get('clientId')
      // phone_num = data.get('phoneNum')
      // gender = data.get('gender')
      // position = data.get('position')
      fName: firstName,
      mName: midName,
      lName: lastName,
      emailAddress: emailAdd,
      userName: userName,
      position: jobPosition,
      phoneNum: number,
      address: address,
      gender: gender,
      password: password,
      clientId: account_detailed.client.id,
      user: account_detailed.user.id,

      // deliveryDate: formattedDate,

      // Add other data as needed
    };
    console.log(allData);
    try {
      const sea = await saveNewUser(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        handleClose();
        setIsReadyToSave(false);
        refetchMainTable();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // formRef.current?.reset();
      }
    } catch (error) {
      // Handle the error here
      // You can display a generic error message to the user if needed
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]">
        Create New User
      </Button>

      <Dialog
        size="lg"
        open={open}
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          <Typography className="text-green-900" variant="h3">
            Create New User
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <form>
            <Typography className="text-green-600" variant="h5">
              Basic Information
            </Typography>
            <div className="grid grid-cols-2 gap-5 mb-10 mt-5">
              <div className="space-y-5">
                <div className="flex items-center">
                  <Input
                    label="First Name"
                    name="fname"
                    value={firstName}
                    onChange={handleChangeFirstName}
                    className="flex-grow"
                  />
                  {firstName === "" && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
                <div className="flex items-center">
                  <Input
                    label="Middle Name"
                    name="mname"
                    onChange={handleChangeMiddleName}
                  />
                  {midName === "" && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
                <div className="flex items-center">
                  <Input
                    label="Last Name"
                    name="lname"
                    onChange={handleChangeLastName}
                  />
                  {lastName === "" && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
                <div className="flex items-center">
                  <Input
                    label="Email Address"
                    name="email"
                    onChange={handleEmailAddressChange}
                  />
                  {emailAdd === "" && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
              </div>
              <div className="space-y-5">
                <div className="flex items-center">
                  <Select
                    label="Gender"
                    onChange={handleChangeGender}
                    value={gender ? `${gender}` : null}
                    variant="outlined"
                  >
                    <Option value="1">{"Male"}</Option>
                    <Option value="2">{"Female"}</Option>
                  </Select>
                  {gender === null && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
                <div className="flex items-center">
                  <select
                    id="position"
                    name="position"
                    value={jobPosition}
                    onChange={handleChangeJobPosition}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="">Select Job Position</option>
                    {Object.keys(jobPositions).map((key) => (
                      <option key={key} value={key}>
                        {jobPositions[key]}
                      </option>
                    ))}
                  </select>
                  {jobPosition === null && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>

                <div className="flex items-center">
                  <Input
                    label="Phone"
                    name="phone"
                    type="number"
                    onChange={handleChangeNumber}
                  />
                  {number === "" && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
                <div className="flex items-center">
                  <textarea
                    id="address"
                    name="address"
                    value={address}
                    onChange={handleAddressChange}
                    rows="4"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter your address here"
                  />
                  {address === "" && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
              </div>
            </div>
            <Typography className="text-green-600" variant="h5">
              Login Credentials
            </Typography>
            <div className="grid grid-cols-2 gap-5 mb-10 mt-5">
              <div className="space-y-5">
                <div className="flex items-center">
                  <Input
                    label="User Name"
                    name="uname"
                    onChange={handleChangeUserName}
                  />
                  {userName === "" && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
                <div className="flex items-center">
                  <Input
                    label="Password"
                    name="pword"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                  />
                  {password === "" && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>

                <div className="flex items-center">
                  <Input
                    label="Confirm Password"
                    name="cppword"
                    type="password"
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  {confirmPassword === "" && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
              </div>
              <div className="space-y-5">
                <div className="requirements">
                  {/* <p>Password must meet the following requirements:</p> */}
                  <ul>
                    <li
                      style={{
                        color: requirements.minLength ? "green" : "red",
                      }}
                    >
                      Minimum 8 characters
                    </li>
                    <li
                      style={{
                        color: requirements.hasNumber ? "green" : "red",
                      }}
                    >
                      At least one number
                    </li>
                    <li
                      style={{
                        color: requirements.hasUpperCase ? "green" : "red",
                      }}
                    >
                      At least one uppercase letter
                    </li>
                    <li
                      style={{
                        color: requirements.hasLowerCase ? "green" : "red",
                      }}
                    >
                      At least one lowercase letter
                    </li>
                    <li
                      style={{
                        color: requirements.hasSpecialChar ? "green" : "red",
                      }}
                    >
                      At least one special character (!@#$%^&*)
                    </li>
                    <li
                      style={{
                        color: requirements.passwordsMatch ? "green" : "red",
                      }}
                    >
                      Passwords match
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <Confirmation
              onConfirm={handleSave}
              trapMessage={trapMessage}
              isReadyToSave={isReadyToSave}
            />
          </form>
        </DialogBody>
        <ToastContainer />
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            className="mr-3"
            onClick={handleClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default AddnewUserModal;
