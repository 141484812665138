import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { FaSync } from "react-icons/fa";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Select,
  Option,
} from "@material-tailwind/react";
import { FiSearch } from "react-icons/fi";
import { useFetchDSRListPageQuery } from "../../store";
import { useFetchFCListQuery } from "../../store";
import { useFetchRouteListQuery } from "../../store";
import OutletReportModal from "../modal_form_components/Delivery/OutletLogReportModal";
import EditOutletModal from "../modal_form_components/Delivery/EditOutletForm";
import { debounce } from "lodash";
import SalesReportModal from "../modal_form_components/Delivery/SalesReportModal";
import { CompleteTableSLView } from "./Complete_Table_Outlet_SL";
import AuditSOHModal from "../modal_form_components/Delivery/AuditSOHDailyReport";
const TABLE_HEAD = [
  "SR #",
  "Name",
  "FC",
  "Route",
  // "Submitted By",
  "Action",
];
export function CompleteTableSRView() {
  let result = [];
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "desc",
  });

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(1);
  const [selectedFC, setSelectedFC] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("");
  const [startDate, setStartDate] = useState(getTodayDate());
  const columnMapping = {
    "Order Number": "order_number",
    Outlet: "outlet",
    Route: "outlet__route",
    "Order Date": "order_date",
    Status: "status",
  };

  const { data, error, isLoading, isSuccess, refetch } =
  useFetchDSRListPageQuery({
      page: currentPage,
      page_size: pageSize,
      search: searchTerm,
      order_by: sortConfig.key,
      order_dir: sortConfig.direction,
      startDate: startDate,
      types: 1,
      fc: selectedFC,
      route: selectedRoute,
    });

    const {
      data: fcData,
      error: fcError,
      isLoading: fcLoading,
      isSuccess: fcSuccess,
    } = useFetchFCListQuery();
    const {
      data: routeData,
      error: routeError,
      isLoading: loadingRoute,
      isSuccess: routeSucess,
    } = useFetchRouteListQuery({
      page: 1,
      page_size: 50,
      schedule: 1,
    });

  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleClickRefresh = () => {
    setIsRefreshing(true);

    // Trigger the refetch function
    refetch();

    // Disable the button after 2 seconds
    setTimeout(() => {
      setIsRefreshing(false);
    }, 2000);
  };

  const handleSort = (column) => {
    let direction = "asc";
    if (
      sortConfig.key === columnMapping[column] &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key: columnMapping[column], direction });
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handlePageSizeChange = (e) => {
    const selectedSize = parseInt(e.target.value);
    console.log("Selected Page Size:", selectedSize);
    setPageSize(selectedSize);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleTabChange = (value) => {
    setCurrentPage(1);
    setSelectedStatus(value);
    refetch();
  };

  const handleFCChange = (value) => {
    const fcId = parseInt(value, 10);
    setSelectedFC(fcId);
    setCurrentPage(1);
  };

  const handleRouteChange = (value) => {
    const rID = parseInt(value, 10);
    setSelectedRoute(rID);
    setCurrentPage(1);
  };
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const filterCategory = [];



    filterCategory.push(
      {
        label: "All",
        value: 0,
      },
      {
        label: "Repacking",
        value: 6,
      },
      {
        label: "Consumables",
        value: 4,
      }
      ,
      {
        label: "Plastics",
        value: 7,
      }
    )


  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    result = data.results;
    console.log(result)
    return (
      <Card className="h-full w-full mb-20 lg:mb-0">
        <CardHeader floated={false} shadow={false} className="rounded-none">


            {account_detailed.job_position !== 24 && account_detailed.job_position !== 22 &&(
             <>
               <OutletReportModal />


                <CompleteTableSLView />
              </>
            )}


          {account_detailed.job_position === 1 || account_detailed.job_position === 24 &&(
             <>
              <AuditSOHModal refetchDeliveryList={refetch} />
              </>
            )}
          {/* <div className="mb-8 flex items-center justify-end gap-8 mt-5">
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              <DelCalendar />
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                <AdvOrderModal refetchDeliveryList={refetch} />
              </div>
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                <AddDeliveryModal refetchDeliveryList={refetch} />
              </div>
              <Button className="bg-[#1AA55D]" size="sm">
                Add Outlet
              </Button>
            </div>
          </div> */}

          <div className="md:flex justify-between mt-5 md:mt-10">
            <div className="md:ml-5">
              <Input
                label="Search"
                icon={<FiSearch className="h-5 w-5" />}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="md:mr-10 flex justify-end mt-3 md:mt-0 items-center space-x-4">
              <Typography className=" font-medium text-sm">
                Page size:
              </Typography>
              <select
                className="block appearance-none bg-white border border-gray-300 rounded-md py-2 px-3 leading-tight focus:outline-none focus:border-indigo-500"
                value={pageSize}
                onChange={(e) => handlePageSizeChange(e)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>

          <div className="flex items-center justify-between md:flex-row mt-5">
          <div className="flex flex-col gap-2">
    <Input
      label="Start Date"
      name="start_date"
      type="date"
      onChange={handleStartDateChange}
      value={startDate}
    />
  </div>
  <div className="flex flex-col gap-2 mr-5">
    {routeSucess && (
      <div className="w-50">
        <Select
          label="Route"
          onChange={handleRouteChange}
          value={selectedRoute ? `${selectedRoute}` : null}
          variant="outlined"
          lockScroll={true}
        >
          {routeData.results.map((route) => (
            <Option key={route.id} value={`${route.id}`}>
              {route.name}
            </Option>
          ))}
        </Select>
      </div>
    )}
  </div>
  <div className="flex flex-col gap-2 mr-5">
    {fcSuccess && (
      <div className="w-50">
        <Select
          label="FC"
          onChange={handleFCChange}
          value={selectedFC ? `${selectedFC}` : null}
          variant="outlined"
          lockScroll={true}
        >
          {fcData.data.map((fc) => (
            <Option key={fc.id} value={`${fc.id}`}>
              {fc.name}
            </Option>
          ))}
        </Select>
      </div>
    )}
  </div>


  <div className="flex flex-col gap-4">

  </div>
  <div className="flex flex-col gap-4">

  </div>

            {/* <Tabs value={selectedStatus} className="w-full md:w-[70%]">
              <TabsHeader>
                {filterCategory.map(({ label, value }) => (
                  <Tab
                    className="text-sm"
                    key={value}
                    value={value}
                    onClick={() => handleTabChange(value)}
                  >
                    &nbsp;&nbsp;{label}&nbsp;&nbsp;
                  </Tab>
                ))}
              </TabsHeader>
            </Tabs> */}
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-b border-green-600 bg-[#1AA55D] text-white p-4"
                    onClick={() => handleSort(head)}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography
                      variant="small"
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {result.map((sr, index) => {
                const isLast = index === result.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                // const formattedDate = item.updated_at
                //   ? new Intl.DateTimeFormat("en-US", {
                //       year: "numeric",
                //       month: "short",
                //       day: "2-digit",
                //     }).format(new Date(item.updated_at))
                //   : "";

                return (
                  <tr key={sr.id}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {sr.sales_number || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {sr.outlet.outlet || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {sr.outlet.fc.name || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>

                    {/* <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                         {outlet.dealer || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td> */}
                    {/* <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {formattedDate}
                          </Typography>
                        </div>
                      </div>
                    </td> */}
                   <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            className="font-normal"
                          >
                             {sr.outlet.route.name || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                        <SalesReportModal
                        srData={sr}
                        />
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {`${currentPage} of ${Math.ceil(data?.count / pageSize) || 1}`}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              size="sm"
              onClick={handlePreviousPage}
              disabled={!data?.previous}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleClickRefresh}
              disabled={isRefreshing}
            >
              Refresh
            </Button>

            <Button
              variant="outlined"
              size="sm"
              onClick={handleNextPage}
              disabled={!data?.next}
            >
              {data?.next ? "Next" : "No More Pages"}
            </Button>
          </div>
        </CardFooter>
      </Card>
    );
  }
}
