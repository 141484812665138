function UMShowDetails({
  detail: { topic, detail, num, itemNum, mainTopic, itemNumsub },
  onClose,
}) {
  const handleClose = () => {
    onClose(3);
  };

  return (
    <div className="px-3">
      {mainTopic ? (
        <>
          <div>
            <h3>
              <span className={`${"text-[#117B34FF] font-bold text-2xl"}`}>
                {num + 1}. {mainTopic}
              </span>
            </h3>
          </div>
          <div className="flex justify-between items-center mb-1 px-7">
            <h3>
              <span className={`${"text-[#117B34FF] font-bold text-xl"}`}>
                {itemNum} {topic}
              </span>
            </h3>
          </div>
        </>
      ) : (
        <div className="flex justify-between items-center mb-2">
          <h3>
            <span className={`${"text-[#117B34FF] font-bold text-2xl"}`}>
              {num + 1}. {topic}
            </span>
          </h3>
        </div>
      )}

      <div className="ml-7">
        {mainTopic ? (
          <span className={`${"text-[#117B34FF] font-semibold text-xl px-8"}`}>
            {itemNumsub} {detail.topic}
          </span>
        ) : (
          <span className={`${"text-[#117B34FF] font-semibold text-xl"}`}>
            {itemNum} {detail.topic}
          </span>
        )}
        <p className="ml-9 text-lg">{detail.details}</p>
        {detail.info ? (
          <>
            <ul className="ml-9 mt-6 text-lg list-disc">
              {detail.info.map((item) => (
                <li className="mt-2">{item}</li>
              ))}
            </ul>
          </>
        ) : (
          ""
        )}
        {detail.infoObjects ? (
          <>
            {detail.infoObjects.map((item, index) => (
              <div key={`item-${index}`} className="mt-1">
                <span className="ml-9 text-lg font-semibold">{item.name}</span>
                <ul className="list-disc ml-4">
                  {item.details.map((item, index) => (
                    <li className="ml-9 text-lg" key={`item-${index}`}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </>
        ) : (
          ""
        )}
        {detail.infoSteps ? (
          <div className="grid grid-cols-8 w-full gap-4">
            <div className="col-span-5">
              <ul className="list-decimal">
                {detail.infoSteps.map((item, index) => (
                  <li key={`item-${index}`} className="ml-14 text-lg">
                    {item}
                  </li>
                ))}
              </ul>
            </div>
            <div className="col-span-3 flex items-center justify-center">
              <div>
                <img src={`${detail.img}`} alt="" />
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {detail.infoSubDetails ? (
          <div className="w-full ml-12">
            <div className="">
              <ul className="list-roman flex flex-col gap-2">
                {detail.infoSubDetails.map((item, index) => (
                  <>{item}</>
                ))}
              </ul>
            </div>
          </div>
        ) : (
          ""
        )}
        <p className="ml-9 text-lg mt-8">{detail.footer}</p>
      </div>
      <div className="flex justify-end">
        <button
          className="text-[#117B34FF] border border-[#117B34FF] py-2 px-4 rounded-full 
             hover:text-white hover:bg-[#117B34FF] 
              transition-colors duration-300"
          onClick={handleClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default UMShowDetails;
