import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardHeader, Typography } from "@material-tailwind/react";
import PageTitle from "../page_components/PageTitle";
import ApprovalTabComponents from "../page_components/ApprovalTabComponents";
import { CompleteTableView } from "../page_components/Complete_Table_Audit";
import { CompleteTableProductView } from "../page_components/Complete_Table_Product_Inventory";
import { CompleteRejectTable } from "../page_components/Complete_Table_Reject_Product";
import { FaRegRectangleList } from "react-icons/fa6";

function AuditSummary() {
  const token = localStorage.getItem("mytoken");
  let navigate = useNavigate();
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

  useEffect(() => {
    if (
      account_detailed.job_position == 1 ||
      account_detailed.job_position == 23 ||
      account_detailed.user_type == 14 ||
      account_detailed.job_position === 13
    ) {
    } else {
      // pass
      return;
    }
  }, [token]);

  let data = [];
    data.unshift({
      label: "Summary",
      value: "summary",
      desc: <CompleteTableView />,
    });

  return (
    <>
      <div className="container mx-auto px-4 md:mb-28 lg:mb-0">
        <div className="md:hidden flex justify-end mt-[-20px] mb-5 mr-3">
          <PageTitle />
        </div>
        <div className="flex flex-row justify-center md:justify-between items-center ml-3 lg:ml-10 mr-5 lg:mr-28 mb-10 md:mb-16 lg:mb-5">
          <div className="flex items-center space-x-2">
            <FaRegRectangleList className="h-10 w-10  text-[#137944]" />
            <Typography className="text-[#137944] font-bold text-3xl tracking-normal">
              Audit Report
            </Typography>
          </div>
          <div className="hidden md:block">
            <PageTitle />
          </div>
        </div>
        <div className="w-[105%] ml-[-3%] md:w-[110%] md:ml-[-5%] lg:w-[100%] lg:ml-[0%]">
          <ApprovalTabComponents data={data} />
        </div>
      </div>
    </>
  );
}

export default AuditSummary;
