import { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { LuFactory } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
} from "@material-tailwind/react";
import CreateProdSched from "../modal_form_components/Production/CreateProdSched";
import { useFetchScheduleQuery } from "../../store";
import { HashLoader } from "react-spinners";
import ProdTransSlipModal from "../modal_form_components/Production/ProdTransSlipModal";
import PageTitle from "../page_components/PageTitle";
import ProdCompletedSlip from "../modal_form_components/Production/ProdCompletedSlip";
import ProdItemTransSlipModal from "../modal_form_components/Production/ProdItemTransSlipModal";
import ProdCompletedItem from "../modal_form_components/Production/ProdCompletedItem";

function Production() {
  const token = localStorage.getItem("mytoken");
  let navigate = useNavigate();
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

  useEffect(() => {
    if (
      account_detailed.job_position === 1 ||
      account_detailed.user_type === 1 ||
      account_detailed.job_position === 10 ||
      account_detailed.user_type === 9 ||
      account_detailed.user_type === 11 ||
      account_detailed.user_type === 12 ||
      account_detailed.user_type === 19
    ) {
      // pass
    } else {
      return;
    }
  }, [token]);

  const filterStatus = [
    {
      label: "On-queue",
      value: 1,
      bg: "text-yellow-800 font-semibold",
    },
    {
      label: "Working",
      value: 2,
      bg: "text-blue-700 font-semibold",
    },
    {
      label: "Completed",
      value: 3,
      bg: "text-green-700 font-semibold",
    },
  ];

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [status, setStatus] = useState(1);
  const [active] = useState(filterStatus[0].value);
  const { data, isLoading, error, refetch } = useFetchScheduleQuery({
    page: page,
    page_size: pageSize,
    status: status,
    job_position: account_detailed.job_position
  });

  const totalPage = Math.ceil(data?.count / pageSize);
  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePageSizeChange = (e) => {
    const selectedSize = parseInt(e.target.value);
    console.log("Selected Page Size:", selectedSize);
    setPageSize(selectedSize);
    setPage(1);
  };
  const [production] = useState([
    {
      label: "Bakery",
      value: 1,
    },
    {
      label: "patty",
      value: 2,
    },
    {
      label: "Sandwich Line",
      value: 3,
    },
    {
      label: "Catsup",
      value: 4,
    },
    {
      label: "Cake & Pastries",
      value: 5,
    },
    {
      label: "Premixes",
      value: 6,
    },
    {
      label: "Bread Crumbs Buns",
      value: 7,
    },
    {
      label: "Bread Crumbs",
      value: 8,
    },
  ]);
  const TABLE_HEAD = [
    "Production Date",
    "Production Line",
    "Transaction Number",
    "Action",
    "Status",
  ];
  let content = [];
  // handle change of tab
  const tabChange = (value) => {
    setStatus(value);
  };
  // for fetching api
  if (isLoading) {
    return <HashLoader />;
  } else if (error) {
    return <div>Error Loading...</div>;
  } else {
    content = data?.results;
  }

  return (
    <>
      <div className="container mx-auto lg:px-4 md:mb-28 lg:mb-0">
        <div className="md:hidden flex justify-end mt-[-20px] mb-5 mr-3">
          <PageTitle />
        </div>
        <div className="flex flex-row justify-center md:justify-between items-center ml-3 lg:ml-10 mr-5 lg:mr-28 mb-5 md:mb-14 lg:mb-5">
          <div className="flex items-center space-x-2">
            <LuFactory className="h-10 w-10  text-[#137944]" />
            <Typography className="text-[#137944] font-bold text-3xl tracking-normal">
              Production
            </Typography>
          </div>
          <div className="hidden md:block">
            <PageTitle />
          </div>
        </div>
        <div className="px-5 md:px-0 lg:px-5 mt-5">
          <Card className="h-full w-full">
            <CardHeader floated={false} shadow={false} className="rounded-none">
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row mb-5">
                <div className="w-full md:w-72 mt-6 lg:mt-0">
                  <Input
                    label="Search"
                    icon={<FiSearch className="h-5 w-5" />}
                  />
                </div>
              </div>
              <div className="flex flex-col items-center md:justify-between md:gap-4 md:flex-row">
                <Tabs className="w-full md:w-[60%] z-0" value={active}>
                  <TabsHeader>
                    {filterStatus.map(({ label, value }) => (
                      <Tab
                        className=" text-sm"
                        key={value}
                        value={value}
                        onClick={() => tabChange(value)}
                      >
                        &nbsp;&nbsp;{label}&nbsp;&nbsp;
                      </Tab>
                    ))}
                  </TabsHeader>
                </Tabs>
                <div className="flex flex-col justify-end md:mr-5 mt-6 md:mt-0">
                  <CreateProdSched refetch={refetch} />
                </div>
              </div>
            </CardHeader>
            <CardBody className="overflow-scroll px-0">
              <table className="mt-4 w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {content.map((p, i) => {
                    const isLast = p === data.results.length - 1;
                    const statusValue = filterStatus.find(
                      (stat) => stat.value === p.process_status
                    );
                    const productionLine = production.find(
                      (prod) => prod.value === parseInt(p.product_line)
                    );
                    let process_list = [6, 7, 8]
                    const classes = isLast
                      ? "p-4"
                      : "p-4 border-b border-blue-gray-50";
                    return (
                      <tr key={i}>
                        <td className={classes}>{p.transfer_request_date}</td>
                        <td className={classes}>{productionLine?.label}</td>
                        <td className={classes}>{p.transfer_number}</td>
                        <td className={classes}>
                        {/* ON_QUEUE = 1 WORKING = 2 COMPLETED = 3 CANCEL = 4 */}
                          {p.process_status === 1 ? "N/A" : ""}
                          {(p.process_status === 2) & (
                            process_list.includes(p.product_line)) ? (
                            <ProdItemTransSlipModal
                              prod_refetch={refetch}
                              prod_id={p.id}
                            />
                          ) : (
                            ""
                          )}
                          {(p.process_status === 2) & (
                            !process_list.includes(p.product_line)
                          ) ? (
                            <ProdTransSlipModal
                              prod_refetch={refetch}
                              prod_id={p.id}
                            />
                          ) : (
                            ""
                          )}
                          {(p.process_status === 3) & (
                            process_list.includes(p.product_line)
                          ) ? (
                            <ProdCompletedItem prod_id={p.id} />
                          ) : (
                            ""
                          )}
                          {(p.process_status === 3) & (
                            !process_list.includes(p.product_line)
                          ) ? (
                            <ProdCompletedSlip prod_id={p.id} />
                          ) : (
                            ""
                          )}
                        </td>
                        <td className={classes}>
                          <Typography className={statusValue.bg}>
                            {statusValue.label}
                          </Typography>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </CardBody>
            <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal"
              >
                Page {page} of {totalPage}
              </Typography>
              <div className="flex gap-2">
                <Button
                  variant="outlined"
                  size="sm"
                  onClick={handlePreviousPage}
                >
                  Previous
                </Button>
                <Button
                  variant="outlined"
                  size="sm"
                  onClick={handleNextPage}
                  disabled={page === totalPage}
                >
                  Next
                </Button>
              </div>
            </CardFooter>
          </Card>
        </div>
      </div>
    </>
  );
}

export default Production;
