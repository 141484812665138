import React, { useState } from "react";
import { IoBagHandle, IoPieChart, IoPeople, IoCart } from "react-icons/io5";
import { FaTruckMoving, FaRegChartBar, FaFolderOpen } from "react-icons/fa";

const moduleColors = {
  PurchaseOrder: "bg-blue-900",
  Inventory: "bg-orange-600",
  Production: "bg-purple-900",
  Delivery: "bg-green-600",
};

export default function DashboardStatsGrid({ moduleView }) {
  const [currentModule, setCurrentModule] = useState("PurchaseOrder");

  const handleBoxClick = (module) => {
    setCurrentModule(module);
    moduleView(module);
  };

  const modules = [
    { name: "PurchaseOrder", icon: <IoBagHandle className="text-xl" /> },
    { name: "Inventory", icon: <FaFolderOpen className="text-xl" /> },
    { name: "Production", icon: <FaRegChartBar className="text-xl" /> },
    { name: "Delivery", icon: <FaTruckMoving className="text-xl" /> },
  ];

  return (
    <div className="block space-y-3 md:space-y-0 md:flex gap-4 mx-2">
      {modules.map((module) => (
        <BoxWrapper
          key={module.name}
          onClick={() => handleBoxClick(module.name)}
          active={currentModule === module.name}
          color={moduleColors[module.name]}
        >
          <div
            className={`rounded-full h-12 w-12 flex items-center justify-center text-white ${
              currentModule === module.name
                ? moduleColors[module.name]
                : "bg-white"
            }`}
          >
            {module.icon}
          </div>
          <div
            className={`sm:pl-2 lg:pl-4 ${
              currentModule === module.name ? "text-white" : "text-gray-700"
            }`}
          >
            <strong className=" text-base lg:text-xl font-semibold">
              {module.name}
            </strong>
          </div>
        </BoxWrapper>
      ))}
    </div>
  );
}

function BoxWrapper({ children, onClick, active, color }) {
  return (
    <div
      className={`rounded-lg p-4 flex-1 border border-gray-200 flex items-center cursor-pointer ${
        active ? color : ""
      } shadow-md`}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
