import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
import { CiReceipt } from "react-icons/ci";
import "react-datepicker/dist/react-datepicker.css";


function ViewFileReceivingReport({ file }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const api_domain = process.env.REACT_APP_API_DOMAIN;
  return (
    <>
      <div className="container">
        <Button
          onClick={handleOpen}
          className=" flex lg:gap-2 bg-yellow-700 w-[120%] md:w-[110%] lg:w-[70%]"
          size="sm"
        >
          <CiReceipt strokeWidth={2} className="h-4 w-4" />
          Receipt File
        </Button>
        <Dialog
          open={open}
          handler={handleOpen}
          className="relative max-h-min"
          size="xl"
        >
          <DialogHeader className="w-full bg-[#0B6E4F] rounded-sm flex justify-center">
            <Typography className="text-center text-white" variant="h5">
              Receipt File
            </Typography>
          </DialogHeader>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
            <object
              type="application/pdf"
              data={api_domain  + file}
              width="100%"
              height="800"
            ></object>
            {file && <a href={api_domain +  file} target="_blank">View link file</a>}
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpen}
              className="mr-2"
            >
              <span>Close</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    </>
  );
}

export default ViewFileReceivingReport;
