import React from "react";
import DeliveryDetailsApprovalModal from "../../modal_form_components/Approval/DeliveryApprovalModal";
import {
  Typography,
  CardBody,
  CardFooter,
  Button,
} from "@material-tailwind/react";
import { ApproveAdjustedRequiredQty } from "../../modal_form_components/Approval/ApproveAdjustedRequiredQty";

function RequiredQtyApprovalTbl({
  RequiredAdjustedHead,
  delPageSize,
  handleDeliveryPageSize,
  fetchRAResult,
  raCurrentPage,
  raTotalPage,
  handleRaPreviousPage,
  handleRaNextPage,
  data_refetch,
}) {
  return (
    <>
      <div className="container mx-auto lg:px-4">
        <div className="md:px-5">
          <div className="flex justify-end mx-2">
            <div className="flex justify-end items-center mt-5 md:mt-0 mr-10 space-x-4">
              <Typography>Page size:</Typography>
              <select
                defaultValue={delPageSize}
                onChange={handleDeliveryPageSize}
                className="border-2 border-blue-gray-500 cursor-pointer"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <CardBody className="px-0 overflow-y-auto max-h-[60vh]">
            <table className=" w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {RequiredAdjustedHead.map((head) => (
                    <th
                      key={head}
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {fetchRAResult.map((v, index) => {
                  const isLast = index === fetchRAResult.length - 1;
                  const classes = isLast
                    ? "pt-3 px-5"
                    : "py-3 px-5 border-b border-blue-gray-50";

                  return (
                    <>
                      <tr key={index}>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {v.request.po_number}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {v.item.name}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {v.required_qty}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {v.quantity_adjustment}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <div className="flex items-center gap-3">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {v.qa_remarks}
                            </Typography>
                          </div>
                        </td>
                        <td className={classes}>
                          <ApproveAdjustedRequiredQty
                            request_item_id={v.id}
                            item_name={v.item.name}
                            ri_status={v.status}
                            po_number={v.request.po_number}
                            unit_measurement={v.item.unit_measurement}
                            data_refetch={data_refetch}
                            qa_remarks={v.qa_remarks}
                            adjusted_required_qty={v.quantity_adjustment}
                            tre_signature={v.tre_signature}
                            required_qty={v.required_qty}
                          />
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              Page {raCurrentPage} of {raTotalPage}
            </Typography>
            <div className="flex gap-2">
              <Button
                variant="outlined"
                size="sm"
                onClick={handleRaPreviousPage}
                disabled={raCurrentPage === 1}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                size="sm"
                onClick={handleRaNextPage}
                disabled={raCurrentPage === raTotalPage}
              >
                Next
              </Button>
            </div>
          </CardFooter>
        </div>
      </div>
    </>
  );
}

export default RequiredQtyApprovalTbl;
