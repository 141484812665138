import { useState } from "react";
import { MdOutlineArrowDropDown, MdOutlineArrowRight } from "react-icons/md";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

function UMAccordionItem({
  faqs: { topic, subsec },
  onOpen,
  currOpen,
  num,
  setOpenInfo,
  setDetail,
}) {
  const isOpen = num === currOpen;
  const handleToggle = () => {
    onOpen(isOpen ? null : num);
  };
  const handleShowDetail = (topic, detail, num, itemNum) => {
    setOpenInfo(true);
    setDetail({
      topic,
      detail,
      num,
      itemNum,
    });
  };
  return (
    <div
      className={`${
        isOpen ? "border-t-4 border-[#117B34FF]" : "border-t-2"
      } px-2 py-6 `}
      // onClick={handleToggle}
    >
      <div className="flex justify-between px-3 items-center mb-2">
        <span
          className={`hover:cursor-pointer ${
            !isOpen
              ? "text-[20px] text-[#72767e] leading-8"
              : "text-[#117B34FF] font-semibold text-2xl"
          }`}
          onClick={handleToggle}
        >
          {num + 1}. {topic}
        </span>
        <div
          className="flex items-center justify-center rounded-full h-[30px] w-[30px] text-[18px] border-2 border-[#72767e] text-[#72767e] hover:cursor-pointer"
          onClick={handleToggle}
        >
          {isOpen ? <IoIosArrowDown /> : <IoIosArrowForward />}
        </div>
      </div>
      <div className="px-3">
        {/* {isOpen && <p className="pr-11 text-[#117B34FF]">{subtop}</p>} */}
        {isOpen && (
          <div className="ml-7 flex flex-col gap-1 w-[240px]">
            {subsec.map((item, index) => {
              console.log(item.subsec);
              const itemNum = `${num + 1}.${index + 1}`;
              return (
                <div
                  key={`item-${index}`}
                  className="grid grid-cols-[30px_700px_1fr]"
                >
                  <span>{`${num + 1}.${index + 1}`}</span>
                  <span className="">
                    {/* check if the question contains a subsection of a question */}
                    {item.subsec ? (
                      <>
                        <Details
                          detail={item.subsec}
                          mainTopic={topic}
                          topic={item.topic}
                          itemNum={itemNum}
                          setOpenInfo={setOpenInfo}
                          setDetail={setDetail}
                          num={num}
                        />
                      </>
                    ) : (
                      <button
                        className="underline underline-offset-4"
                        onClick={() =>
                          handleShowDetail(topic, item, num, itemNum)
                        }
                      >
                        {item.topic}
                      </button>
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}

function Details({
  detail,
  mainTopic,
  topic,
  itemNum,
  setOpenInfo,
  setDetail,
  num,
}) {
  console.log(detail);
  const [isOpen, setIsOpen] = useState(false);
  const handleShowTopics = () => {
    setIsOpen(!isOpen);
  };
  const handleShowDetail = (
    mainTopic,
    topic,
    subTopic,
    detail,
    num,
    itemNum,
    itemNumsub
  ) => {
    setOpenInfo(true);
    setDetail({
      mainTopic,
      topic,
      subTopic,
      detail,
      num,
      itemNum,
      itemNumsub,
    });
  };
  return (
    <>
      <button
        className={`${
          isOpen ? "text-[#117B34FF]" : ""
        } underline underline-offset-4`}
        onClick={handleShowTopics}
      >
        {topic}
      </button>
      {isOpen && (
        <>
          {detail.map((item, index) => {
            const itemnumsub = `${itemNum}.${index + 1}`;
            return (
              <div className="flex gap-2">
                <span>
                  {itemNum}.{index + 1}
                </span>
                <button
                  className="underline underline-offset-4"
                  onClick={() =>
                    handleShowDetail(
                      mainTopic,
                      topic,
                      item.topic,
                      item,
                      num,
                      itemNum,
                      itemnumsub
                    )
                  }
                >
                  {item.topic}
                </button>
              </div>
            );
          })}
        </>
      )}
    </>
  );
}
export default UMAccordionItem;
