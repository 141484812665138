import { Input } from "@material-tailwind/react";
import PageTitle from "../../page_components/PageTitle";
import { PiMagnifyingGlassBold } from "react-icons/pi";
import { useEffect, useState } from "react";
import KBAccordion from "./KBAccordion";
import { sampleFaqs, sampleTroubleshooting, sampleUserManual } from "./KBQAs";
import UMAccordion from "./UMAccordion/UMAccordion";

function KbFAQs() {
  const [search, setSearch] = useState("");
  const [isFocused, setIsFocused] = useState(true);

  const [data, setData] = useState(sampleFaqs);
  const [switchCategory, setSwitchCategory] = useState(1);
  const [openInfo, setOpenInfo] = useState(false);
  const [currOpen, setCurrOpen] = useState(null);

  useEffect(() => {
    setCurrOpen(null);
  }, [switchCategory]);

  const handleSwitchCategory = (cat) => {
    if (cat === 1) {
      setData(sampleFaqs);
      setSwitchCategory(1);
    } else if (cat === 2) {
      setData(sampleTroubleshooting);
      setSwitchCategory(2);
    } else if (cat === 3) {
      setData(sampleUserManual);
      setOpenInfo(false);
      setSwitchCategory(3);
    }
  };

  const handleFocus = () => {
    setIsFocused(false);
  };

  const handleBlur = () => {
    setIsFocused(true);
  };
  const handleSearch = () => {};
  return (
    <>
      <div className="flex flex-row justify-end  md:justify-end items-center ml-3 lg:ml-10 mr-5 lg:mr-28 mb-10 md:mb-16 lg:mb-5">
        <div className="">
          <PageTitle />
        </div>
      </div>
      <div className="mx-5 mt-14">
        <div className="flex flex-col items-center ">
          <div>
            {switchCategory === 1 ? (
              <div className="text-[#117B34FF] font-bold flex flex-col text-center text-6xl leading-10 gap-6 tracking-wide">
                <span>Frequently asked</span>
                <span>question</span>
              </div>
            ) : switchCategory === 2 ? (
              <div className="text-[#117B34FF] font-bold flex flex-col text-center text-6xl leading-10 gap-6 tracking-wide">
                <span>Troubleshooting</span>
              </div>
            ) : (
              <div className="text-[#117B34FF] font-bold flex flex-col text-center text-6xl leading-10 gap-6 tracking-wide">
                <span>User Manual</span>
              </div>
            )}
          </div>
          <div className="flex justify-center mt-9">
            <div className="relative w-full">
              <div
                className={`${
                  isFocused
                    ? "absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
                    : "hidden"
                }`}
              >
                <PiMagnifyingGlassBold className="w-5 h-5 text-gray-400" />
              </div>
              <input
                onFocus={handleFocus}
                onBlur={handleBlur}
                type="text"
                placeholder="Search"
                className={`${
                  isFocused ? "pl-10 " : "pl-2"
                } py-2 bg-green-50 border border-green-400 rounded-lg focus:border-green-500 focus:ring-green-500 w-96`}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-4 mt-8">
          <button
            className={`${
              switchCategory === 1
                ? "bg-[#117b34] py-2 px-3 text-white outline-none rounded-full w-[120px] transform hover:-translate-y-2 hover:shadow-[0_8px_15px_rgba(17,123,52,0.5)] transition-transform duration-150 delay-100 ease-in-out p-4"
                : "border-2 border-[#117b34] py-2 px-3 text-[#117b34] outline-none rounded-full w-[120px]  transform hover:-translate-y-2 hover:shadow-[0_8px_15px_rgba(17,123,52,0.5)] transition-transform duration-150 delay-100 ease-in-out p-4"
            }`}
            onClick={() => handleSwitchCategory(1)}
          >
            {/* <button className="bg-[#117b34] py-2 px-3 text-white outline-none rounded-full w-[120px] transform hover:-translate-y-2 hover:shadow-2xl transition-transform duration-150 delay-100  p-4"> */}
            FAQs
          </button>
          <button
            className={`${
              switchCategory === 2
                ? "bg-[#117b34] py-2 px-3 text-white outline-none rounded-full w-[160px] transform hover:-translate-y-2 hover:shadow-[0_8px_15px_rgba(17,123,52,0.5)] transition-transform duration-150 delay-100 ease-in-out p-4"
                : "border-2 border-[#117b34] py-2 px-3 text-[#117b34] outline-none rounded-full w-[160px]  transform hover:-translate-y-2 hover:shadow-[0_8px_15px_rgba(17,123,52,0.5)] transition-transform duration-150 delay-100 ease-in-out p-4"
            }`}
            onClick={() => handleSwitchCategory(2)}
          >
            Troubleshooting
          </button>
          <button
            className={`${
              switchCategory === 3
                ? "bg-[#117b34] py-2 px-3 text-white outline-none rounded-full w-[160px] transform hover:-translate-y-2 hover:shadow-[0_8px_15px_rgba(17,123,52,0.5)] transition-transform duration-150 delay-100 ease-in-out p-4"
                : "border-2 border-[#117b34] py-2 px-3 text-[#117b34] outline-none rounded-full w-[160px]  transform hover:-translate-y-2 hover:shadow-[0_8px_15px_rgba(17,123,52,0.5)] transition-transform duration-150 delay-100 ease-in-out p-4"
            }`}
            onClick={() => handleSwitchCategory(3)}
          >
            User Manual
          </button>
        </div>
        <div className="mx-7 mt-7">
          {switchCategory === 3 ? (
            <UMAccordion
              data={data}
              currOpen={currOpen}
              setCurrOpen={setCurrOpen}
              openInfo={openInfo}
              setOpenInfo={setOpenInfo}
              onClose={handleSwitchCategory}
            />
          ) : (
            <KBAccordion
              data={data}
              currOpen={currOpen}
              setCurrOpen={setCurrOpen}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default KbFAQs;
