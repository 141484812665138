import {
  Card,
  CardBody,
  CardHeader,
  Typography,
} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import { RiPassPendingLine } from "react-icons/ri";
import React, { useState, useEffect } from "react";


export default function Example({approve_status}) {
  const [pmCount, setPMCount] = useState(0);
  const [tdCount, setTDCount] = useState(0);
  const [gmCount, setGMCount] = useState(0);

  console.log(approve_status)
  // useEffect to fetch pending approvals counts
  useEffect(() => {
    // const fakePendingApprovalsCounts = { pm: 3, td: 5, gm: 2 }; // Example fake counts
    setPMCount(approve_status.count_new_po); // Set PM count
    setTDCount(approve_status.count_treasury_po); // Set TD count
    setGMCount(0); // Set GM count
  }, []);

  const chartConfig = {
    type: "pie",
    width: 220,
    height: 220,
    series: [pmCount, tdCount, gmCount],
    options: {
      chart: {
        toolbar: {
          show: false,
        },
      },
      title: {
        show: "",
      },
      labels: ['PM', 'TD', 'GM'],
      dataLabels: {
        enabled: false,
      },
      colors: ["#0B6477", "#ff8f00", "#00897b"],
      legend: {
        show: false,
      },
    },
  };
  return (
    <Card className="pr-4 pl-4">
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="flex flex-col gap-4 rounded-none md:flex-row md:items-center"
      >
        <div className="w-max rounded-lg bg-blue-900 p-2 text-white">
          <RiPassPendingLine />
        </div>
        <div>
          <Typography variant="h6" color="blue-gray">
            PENDING PO APPROVALS
          </Typography>
        </div>
      </CardHeader>
      <CardBody className="mt-2 grid place-items-center">
        <Chart {...chartConfig} />
      </CardBody>
    </Card>
  );
}