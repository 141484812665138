import { Select,Option } from "@material-tailwind/react";
function SelectField({selectlabel, options}){
    
    return<>
        <Select label={selectlabel}>

                {options.map((option, index) => (
                <Option key={index} value={option}>
                    {option}
                </Option>
                ))}
                
        </Select>
    </>
}
export default SelectField;