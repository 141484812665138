import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Card,
  CardFooter,
} from "@material-tailwind/react";
import { useFetchItemHistoryQuery } from "../../../../store";
import Logo from "./../../../../img/BurgerMachineLogo.jpg";

const TABLE_HEAD = [
  "Transfer #",
  "Prev. Quantity",
  "Quantity",
  "Current Quantity",
  "Type",
  "Modified By",
  "Date/Time",
];
const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
const trapMessage = "Are you sure to Transfer these products?";
const user_info = JSON.parse(localStorage.getItem("user_info"));

const currentDate = new Date();
const transferDate = currentDate.toLocaleString(undefined, {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});
let result = [];
let result_item = [];

function ItemHistoryModal({ itemData }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = "p-4 border-b border-blue-gray-50";
  const inputClasses = "flex flex-row items-center";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const {
    data: historyData,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useFetchItemHistoryQuery({
    page: currentPage,
    page_size: pageSize,
    item: itemData.id,
  });

  const handleClickRefresh = () => {
    setIsRefreshing(true);

    // Trigger the refetch function
    refetch();

    // Disable the button after 2 seconds
    setTimeout(() => {
      setIsRefreshing(false);
    }, 2000);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleMainClose = () => {
    setOpen(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (isSuccess) {
    result = historyData.results;

    const formattedDate = result.transfer_request_date
      ? new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(new Date(result.transfer_request_date))
      : "";

    return (
      <>
        <Button onClick={handleOpen} className="bg-[#1AA55D]">
          View
        </Button>

        <Dialog
          size="lg"
          open={open}
          handler={handleOpen}
          className="relative max-h-min"
        >
          <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
            {/* <img src={Logo} className="aspect-auto w-48" alt="logo" /> */}
            <Typography
              className="text-green-900"
              variant="h3"
              component={"span"}
            >
              Item History
            </Typography>
          </DialogHeader>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
            <form>
              <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto">
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Item Name"
                      name="item_name"
                      value={itemData.name}
                      readOnly
                    />
                  </div>
                </div>
                {/* <div className="col-span-2 space-y-5">
                    <div className={inputClasses}>
                      <Input label="Product Code" name="code" value={prodCode} readOnly/>
                    </div>
                  </div> */}
              </div>
              <div className="m-5">
                <Card
                  className="h-full w-full overflow-scroll"
                  component={"span"}
                >
                  <table className="w-full min-w-max table-auto text-left ">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head) => (
                          <th
                            key={head}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                            <Typography
                              component={"span"}
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {result.map((item, index) => {
                        {
                          const formattedDate = item.record_at
                            ? new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }).format(new Date(item.record_at))
                            : "";
                          console.log(itemData.name)
                          console.log(item)
                          let modified_by = ''
                          if (item.modified_by){
                            modified_by = `${item.modified_by.first_name} ${item.modified_by.last_name}`
                          }
                          else{
                            if (item.tpl){
                              if (item.tpl.requested_by){
                                modified_by = `${item.tpl.requested_by.first_name} ${item.tpl.requested_by.last_name}`
                              }
                            }
                            if (item.trans_sched_prod){
                              if(item.trans_sched_prod.trans_product_line){
                                if(item.trans_sched_prod.trans_product_line.requested_by){
                                  modified_by = `${
                                    item.trans_sched_prod.trans_product_line.requested_by.first_name
                                  } ${item.trans_sched_prod.trans_product_line.requested_by.last_name}`
                                }
                              }
                            }
                            if (item.request) {
                              if (item.request.requested_by) {
                                modified_by = `${item.request.requested_by.first_name} ${item.request.requested_by.last_name}`
                              }
                            }
                          }
                          return (
                            <tr key={index}>
                              <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <div className="flex flex-col">
                                      <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                        component={"span"}
                                      >
                                        {item.trans_good_reject?.trans_prod_line?.transfer_number ?? item.transfer?.transfer_number ?? item.audit?.audit_number ?? item.request?.po_number ?? item.tpl?.transaction_number ?? "RR Number"}
                                      </Typography>
                                    </div>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <div className="flex flex-col">
                                      <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                        component={"span"}
                                      >
                                        {item.prev_qty || "N/A"}
                                      </Typography>
                                    </div>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <div className="flex flex-col">
                                      <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className={`font-normal ${
                                          item.transfer?.types === 1
                                            ? "text-red-500"
                                            : "text-green-500"
                                        }`}
                                        component={"span"}
                                      >
                                        {item.transfer?.types === 1 && (
                                          <span style={{ color: "red" }}>- </span>
                                        )}
                                        {item.transfer?.types === 2 && (
                                          <span style={{ color: "green" }}>
                                            +{" "}
                                          </span>
                                        )}
                                        {item.stated_qty || "N/A"}
                                      </Typography>
                                    </div>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <div className="flex flex-col">
                                      <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                        component={"span"}
                                      >
                                        {item.current_qty || "N/A"}
                                      </Typography>
                                    </div>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <div className="flex flex-col">
                                      <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                        component={"span"}
                                      >
                                        {item.transfer?.types === 1
                                          ? "ISSUE"
                                          : item.transfer?.types === 2
                                          ? "RETURN"
                                          : item?.audit !== null
                                          ? "AUDIT"
                                          : ""}

                                        {
                                          item.type === 1
                                          ? "RR"
                                          : item.type === 2
                                          ? "TRANSFER"
                                          : item.type === 3
                                          ? "RECEIVED"
                                          : item.type === 4
                                          ? "GOODS"
                                          : item.type === 5
                                          ? "REJECT"
                                          : item.type === 6
                                          ? "AUDIT"
                                          : ""
                                        }
                                      </Typography>
                                    </div>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <div className="flex flex-col">
                                      <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                        component={"span"}
                                      >
                                        {item.transfer &&
                                        item.transfer.types === 1
                                          ? item.transfer.issued_by
                                            ? `${item.transfer.issued_by.first_name} ${item.transfer.issued_by.last_name}`
                                            : ""
                                          : ""}

                                        {item.transfer &&
                                        item.transfer.types === 2
                                          ? item.transfer.issued_by
                                            ? `${item.transfer.issued_by.first_name} ${item.transfer.issued_by.last_name}`
                                            : ""
                                          : ""}
                                          {item.audit && item.audit?.auditor_name
                                              ? item.audit.auditor_name
                                              : ""}
                                        {modified_by}
                                      </Typography>
                                    </div>
                                  </div>
                                </td>
                                <td className={classes}>
                                  <div className="flex items-center gap-3">
                                    <div className="flex flex-col">
                                      <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                        component={"span"}
                                      >
                                        {formattedDate}
                                      </Typography>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </Card>
                <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    Page{" "}
                    {`${currentPage} of ${
                      Math.ceil(historyData?.count / pageSize) || 1
                    }`}
                  </Typography>
                  <div className="flex gap-2">
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={handlePreviousPage}
                      disabled={!historyData?.previous}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={handleClickRefresh}
                      disabled={isRefreshing}
                    >
                      Refresh
                    </Button>
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={handleNextPage}
                      disabled={!historyData?.next}
                    >
                      {historyData?.next ? "Next" : "No More Pages"}
                    </Button>
                  </div>
                </CardFooter>
              </div>
            </form>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              className="mr-3"
              onClick={handleMainClose}
            >
              <span>Close</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

export default ItemHistoryModal;
