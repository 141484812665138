import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Card,
  CardFooter,
} from "@material-tailwind/react";
import { toast } from "react-toastify";
import { GrPowerReset } from "react-icons/gr";
import ReactSignatureCanvas from "react-signature-canvas";
import { useFetchDeliveryItemsAdjustQuery } from "../../../store";
import { useUpdateOrderToDeliverMutation } from "../../../store";
import { useUpdateOrderToReturnMutation } from "../../../store";
import { Confirmation } from "../Error_trap_components/ErrorTrapComponent";
import { ReturnOrderConfirmation } from "../Error_trap_components/ReturnOrderConfirmation";

const TABLE_HEAD = [
  "Item Code",
  "Description",
  "Weight/UM",
  "Ordered Quantity",
  "Actual Received Quantity",
  "Rejected Quantity",
  "Remarks",
];
const trapMessage = "Are you sure to deliver these products?";
const user_info = JSON.parse(localStorage.getItem("user_info"));

const currentDate = new Date();
const formattedDate = currentDate.toLocaleString(undefined, {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});
let result = [];

function DeliveryReceiptForm({ getOrderId, delData, refetchMainTable }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const classes = "p-4 border-b border-blue-gray-50";
  const inputClasses = "flex flex-row items-center";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);

  const { data, isError, isLoading, isSuccess, refetch } =
    useFetchDeliveryItemsAdjustQuery({
      page: currentPage,
      page_size: pageSize,
      order_number: getOrderId,
    });

  const [isReadyToSave, setIsReadyToSave] = useState(false);
  const [isReadyToReturn, setIsReadyToReturn] = useState(false);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState([true, true]);
  let [receiverName, setReceiverName] = useState("");
  const [signatures, setSignatures] = useState(["", ""]);
  const signatureRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const handleReset = (index) => {
    if (signatureRefs[index].current) {
      signatureRefs[index].current.clear();
      setSignatures((prevSignatures) => {
        const updatedSignatures = [...prevSignatures];
        updatedSignatures[index] = "";
        return updatedSignatures;
      });

      setIsSignatureEmpty((prevIsSignatureEmpty) => {
        const updatedIsSignatureEmpty = [...prevIsSignatureEmpty];
        updatedIsSignatureEmpty[index] = true;
        return updatedIsSignatureEmpty;
      });
    }
  };

  let [actualQtys, setActualQtys] = useState([]);
  let [rejectQty, setRejectQty] = useState([]);

  function handleReicverNameChange(event) {
    const newValue = event.target.value;
    setReceiverName(newValue);
  }

  useEffect(() => {
    if (isSuccess) {
      // Initialize actualQtys after data fetching is successful
      setActualQtys(data.results.map((result) => result.ordered_qty || 0));
    }
  }, [isSuccess, data]);

  const [hasMismatch, setHasMismatch] = useState(false);

  useEffect(() => {
    if (isSuccess && data.results.length > 0) {
      // Check for any mismatches among all rows
      const mismatch = actualQtys.some((qty, index) => {
        // Get rejectQty for the current index or default to 0 if it's NaN
        const rejectQtyForIndex = isNaN(rejectQty[index])
          ? 0
          : rejectQty[index];

        // Get actualQty for the current index or default to 0 if it's NaN
        const actualQtyForIndex = isNaN(qty) ? 0 : qty;

        // Calculate the difference between ordered_qty and (rejectQty + actualQty)
        const difference =
          (data.results[index].ordered_qty || 0) -
          (rejectQtyForIndex + actualQtyForIndex);

        // Check if the difference is greater than 0
        return difference > 0;
      });
      // Update the state based on the presence of a mismatch
      setHasMismatch(mismatch);
      console.log(mismatch); // Log mismatch directly
    }
  }, [actualQtys, isSuccess, rejectQty]);

  // useEffect(() => {
  //   if (isSuccess && data.results.length > 0 && !isError && !isLoading) {
  //     // Check if any value in actualQtys is greater than ordered_qty
  //     const adjustedQtys = actualQtys.map((qty, index) => {
  //       // Ensure the quantity is at least 1
  //       qty = Math.max(qty, 0);
  //       // Ensure the quantity is not greater than ordered_qty
  //       qty = Math.min(qty, data.results[index].ordered_qty || 0); // Use data.results instead of result
  //       return qty;
  //     });
  //     // Check if adjustedQtys is different from actualQtys before updating
  //     if (!adjustedQtys.every((qty, index) => qty === actualQtys[index])) {
  //       setActualQtys(adjustedQtys);
  //     }
  //   }
  // }, [actualQtys, isSuccess, data, isError, isLoading]);

  const handleActualQtyChange = (e, index) => {
    const inputValue = parseInt(e.target.value, 10);
    const requestedQty = data.results[index].ordered_qty || 0;
    const currentReturnedQty = rejectQty[index] || 0;
    let newActualQty = inputValue;

    // Ensure inputValue is within the range of 0 to requestedQty - currentReturnedQty
    newActualQty = Math.min(
      Math.max(newActualQty, 0),
      requestedQty - currentReturnedQty
    );

    setActualQtys((prevActualQtys) => {
      const newQtys = [...prevActualQtys];
      newQtys[index] = newActualQty;
      return newQtys;
    });
  };

  const handleRejectQty = (e, index) => {
    const inputValue = parseInt(e.target.value, 10);
    const MaxQty = data.results[index].ordered_qty;

    // Ensure inputValue is within the range of 0 to MaxQty
    const newRejectQty = Math.min(Math.max(inputValue, 0), MaxQty);

    // Calculate the new actual quantity based on the total quantity
    const totalQty = actualQtys[index] + newRejectQty;
    const newActualQty = MaxQty - newRejectQty;

    setRejectQty((prevQtys) => {
      const newQtys = [...prevQtys];
      newQtys[index] = newRejectQty;
      return newQtys;
    });

    setActualQtys((prevQtys) => {
      const newQtys = [...prevQtys];
      newQtys[index] = newActualQty;
      return newQtys;
    });
  };

  function handlePreviousPage() {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  }
  function handleNextPage() {
    setCurrentPage(currentPage + 1);
  }

  let handleMainClose = () => {
    setOpen(false);
    setSignatures(["", ""]);
    refetch();
  };
  //fetching logo/img from localstorage
  const logo = JSON.parse(localStorage.getItem("account_detail"));
  // const burgerMachineLogo =
  //   process.env.REACT_APP_API_DOMAIN + "" + logo.client.logo;

  const burgerMachineLogo = "../images/BurgerMachineLogo.jpg";

  const handleSignatureChange = (index) => {
    const isEmpty = signatureRefs[index].current.isEmpty();
    setIsSignatureEmpty((prev) => {
      console.log(signatures);
      const updated = [...prev];
      updated[index] = isEmpty;
      return updated;
    });
    const base64Signature = signatureRefs[index].current.toDataURL();
    setSignatures((prevSignatures) => {
      const updatedSignatures = [...prevSignatures];
      updatedSignatures[index] = base64Signature;
      return updatedSignatures;
    });
  };
  const [updateToReceived] = useUpdateOrderToDeliverMutation();
  const [updateToReturn] = useUpdateOrderToReturnMutation();
  const handleSaveReturn = async () => {
    let base64DataDriver = signatures[0].slice("data:image/png;base64,".length);
    let base64DataReceiver = signatures[1].slice(
      "data:image/png;base64,".length
    );

    let allData = {
      orderId: getOrderId,
      user: parseInt(user_info.id),
      deliveredQty: actualQtys.map((qty, index) => ({
        proId: data.results[index].id,
        delQty: qty,
      })),
      signatureDriver: base64DataDriver,
      signatureReceiver: base64DataReceiver,
      status: hasMismatch,
    };
    console.log(allData);
    const sea = await updateToReturn(allData).unwrap();
    if (sea.success === true) {
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetchMainTable();
    handleMainClose();
  };
  const handleSaveSignatures = async () => {
    let base64DataDriver = signatures[0].slice("data:image/png;base64,".length);
    let base64DataReceiver = signatures[1].slice(
      "data:image/png;base64,".length
    );

    let allData = {
      orderId: getOrderId,
      user: parseInt(user_info.id),
      deliveredQty: actualQtys.map((qty, index) => ({
        proId: data.results[index].id,
        delQty: qty,
        rejQty: rejectQty[index] || 0,
      })),
      signatureDriver: base64DataDriver,
      signatureReceiver: base64DataReceiver,
      status: hasMismatch,
      delId: delData.delivery_tracking_number.id,
      receiverName: receiverName,
    };
    console.log(allData);
    const sea = await updateToReceived(allData).unwrap();
    if (sea.success === true) {
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetchMainTable();
    handleMainClose();
  };

  useEffect(() => {
    // Check if all conditions are met
    const isReady = signatures[0] !== "" && signatures[1] !== "";
    // Update the state based on the condition
    setIsReadyToSave(isReady);
  }, [signatures[0], signatures[1]]);

  useEffect(() => {
    // Check if all conditions are met
    const isReady = signatures[0] !== "";
    // Update the state based on the condition
    setIsReadyToReturn(isReady);
  }, [signatures[0]]);

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError) {
    return <div>Error loading data</div>;
  } else if (isSuccess) {
    result = data.results;
    // console.log(account_detailed)
    let totalPage = Math.ceil((data.count || 0) / pageSize);

    return (
      <>
        <Button onClick={handleOpen} className="bg-[#1AA55D]">
          Delivery Receipt
        </Button>

        <Dialog
          size="xl"
          open={open}
          handler={handleOpen}
          className="relative max-h-min"
        >
          <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
            {/* <img
              src={burgerMachineLogo}
              className="aspect-auto w-72"
              alt="logo"
            /> */}
            <Typography
              className="text-green-900"
              variant="h3"
              component={"span"}
            >
              Delivery Order Receipt
            </Typography>
          </DialogHeader>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
            <form>
              <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto">
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Delivery Date"
                      name="date_ordered"
                      value={`${formattedDate}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Delivery Number & Cage Number"
                      name="requested_at"
                      value={`${delData.delivery_tracking_number.delivery_number} - Cage ${delData.delivery_tracking_number.cage_number}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Order Number"
                      name="requested_at"
                      value={delData.order_number}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Outlet"
                      name="outlet"
                      value={`${delData.outlet.outlet}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Address"
                      name="address"
                      value={`${delData.outlet.address}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Route"
                      name="route"
                      value={`${delData.outlet.route.name}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Receiver Name"
                      name="receiver"
                      onChange={handleReicverNameChange}
                    />
                  </div>
                  {receiverName === "" && (
                    <span className="text-red-500 text-xs">
                      Input receiver name!
                    </span>
                  )}
                </div>
              </div>
              <div className="m-5">
                <Card
                  className="h-full w-full overflow-scroll"
                  component={"span"}
                >
                  <table className="w-full min-w-max table-auto text-left ">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head) => (
                          <th
                            key={head}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                            <Typography
                              component={"span"}
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {result.map((item, index) => {
                        let difference =
                          item.ordered_qty -
                          (actualQtys[index] || 0) -
                          (rejectQty[index] || 0);

                        return (
                          <tr key={index}>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.product.product_code || "NA"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.product.name || "NA"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.product.weight_box || "NA"}
                                    {"/"}
                                    {item.product.unit_measurement || "NA"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.ordered_qty || "No Data"}
                                    {"/"}
                                    {item.product.unit_measurement || "NA"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <input
                                    type="number"
                                    value={actualQtys[index] || ""}
                                    onChange={(e) =>
                                      handleActualQtyChange(e, index)
                                    }
                                    style={{
                                      width: "80px",
                                      border: "1px solid #ccc",
                                      padding: "5px",
                                    }}
                                  />
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <input
                                    type="number"
                                    value={rejectQty[index] || ""}
                                    onChange={(e) => handleRejectQty(e, index)}
                                    style={{
                                      width: "80px",
                                      border: "1px solid #ccc",
                                      padding: "5px",
                                    }}
                                  />
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  {item.ordered_qty &&
                                    actualQtys[index] !== undefined && (
                                      <Typography
                                        variant="small"
                                        color={
                                          difference === 0 ? "green" : "red"
                                        }
                                        className="font-normal"
                                        component={"span"}
                                      >
                                        {difference === 0
                                          ? "Complete"
                                          : `Missing (${difference} )`}
                                      </Typography>
                                    )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
                <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    Page {currentPage} of {totalPage}
                  </Typography>
                  <div className="flex gap-2">
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={handlePreviousPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={handleNextPage}
                      disabled={currentPage === totalPage}
                    >
                      Next
                    </Button>
                  </div>
                </CardFooter>
                <div className="m-5"></div>
                {/* <Typography className="text-center">
                Total Requested Quantity
            </Typography> */}
              </div>

              <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto ml-8">
                <div className="col-span-2 space-y-5">
                  <div className={signatureBoxclasses}>
                    <ReactSignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 100,
                        className: "border-black border-2 rounded-s-xl",
                      }}
                      ref={signatureRefs[0]}
                      onEnd={() => handleSignatureChange(0)}
                    />
                    <div className={boxResetClasses}>
                      <GrPowerReset
                        size={"30px"}
                        onClick={() => handleReset(0)}
                        className="cursor-pointer"
                        color="white"
                      />
                    </div>
                  </div>
                  <Typography
                    className="text-center text-black"
                    variant="h6"
                    component="span"
                  >
                    Driver
                  </Typography>
                  <>&nbsp;</>
                  {isSignatureEmpty[0] && (
                    <Typography variant="lead" color="red">
                      Please provide a signature.
                    </Typography>
                  )}
                </div>
                <div className="col-span-2 space-y-5">
                  <div className={signatureBoxclasses}>
                    <ReactSignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 100,
                        className: "border-black border-2 rounded-s-xl",
                      }}
                      ref={signatureRefs[1]}
                      onEnd={() => handleSignatureChange(1)}
                    />
                    <div className={boxResetClasses}>
                      <GrPowerReset
                        size={"30px"}
                        onClick={() => handleReset(1)}
                        className="cursor-pointer"
                        color="white"
                      />
                    </div>
                  </div>
                  <Typography
                    className="text-center text-black m"
                    variant="h6"
                    component={"span"}
                  >
                    {receiverName || "Receiver"}
                  </Typography>
                  <>&nbsp;</>
                  {isSignatureEmpty[1] && (
                    <Typography variant="lead" color="red">
                      Please provide a signature.
                    </Typography>
                  )}
                </div>
              </div>
            </form>
          </DialogBody>
          <DialogFooter>
            <div style={{ marginLeft: 0 }}>
              {" "}
              {/* Adjust the marginLeft to move the button to the left */}
              <ReturnOrderConfirmation
                onConfirm={handleSaveReturn}
                isReadyToSave={isReadyToReturn}
              />
            </div>
            <Confirmation
              onConfirm={handleSaveSignatures}
              trapMessage={trapMessage}
              isReadyToSave={isReadyToSave}
            />
            <Button
              variant="text"
              color="red"
              className="mr-3 ml-5"
              onClick={handleMainClose}
            >
              <span>Cancel</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

export default DeliveryReceiptForm;
