import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
import { HiMiniHandThumbDown } from "react-icons/hi2";
import { toast } from "react-toastify";
import { useUpdateItemRemarksMutation } from "../../../store/apis/RequestApi";

function RejectApprovedModal({
  id,
  edit,
  signature,
  handleRequestItemOpen,
  user_id,
  categoryValue,
  approve_type,
  autoFetch,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const [input, setInput] = useState("");
  const [itemRemarks] = useUpdateItemRemarksMutation();
  const request_id = id;
  const statusValue = 5;
  // handle change of input
  function handleChange(e) {
    setInput(e.target.value);
  }
  // handle save
  async function handleSave() {
    // let signature_split = signature[0].split(",");
    let gm_sig = "";
    if (categoryValue === "Hardware") {
      let gm_sig_split = signature[1].split(",");
      gm_sig = gm_sig_split[1];
    }
    const formValue = {
      id: request_id,
      user_id: user_id,
      status: statusValue,
      categoryValue: categoryValue,
    };
    if (approve_type == 1) {
      let signature_filter_gen = signature.filter(
        (elm) => elm.assignatories === "General Manager"
      );
      if (signature_filter_gen.length > 0) {
        formValue.signature_general_manager = signature_filter_gen[0].dataURLs;
        formValue.gm_approval_remarks = input;
      }
      let signature_filter_plant = signature.filter(
        (elm) => elm.assignatories === "Plant Manager"
      );
      if (signature_filter_plant.length > 0) {
        formValue.signature_plant_manager = signature_filter_plant[0].dataURLs;
        formValue.plant_approval_remarks = input;
      }
    }
    if (approve_type == 2) {
      let signature_filter_trea = signature.filter(
        (elm) => elm.assignatories === "Treasury Manager"
      );
      formValue.signature_treasury_manager = signature_filter_trea.dataURLs;
      formValue.treasury_approval_remarks = input;
    }

    // after click the reject button
    const sea = await itemRemarks(formValue).unwrap();
    if (sea.success === true) {
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    setOpen(!open);
    setInput("");
    handleRequestItemOpen();
    autoFetch();
  }
  return (
    <>
      <div className="container">
        <Button
          onClick={handleOpen}
          className="flex items-center bg-red-800 space-x-1"
          size="sm"
          disabled={edit === false}
        >
          <HiMiniHandThumbDown />
          <span>Reject</span>
        </Button>
        <Dialog open={open} handler={handleOpen} className="relative max-h-min">
          <DialogHeader className="w-full bg-red-800 rounded-sm flex justify-center">
            <Typography className="text-center text-white" variant="h5">
              REJECT PURCHASE ORDER
            </Typography>
          </DialogHeader>
          <DialogBody className="container overflow-y-auto max-h-[60vh]">
            <form>
              <div className="flex flex-col mt-5">
                <div className="flex flex-col space-y-4 mx-7 my-5">
                  <div className="flex flex-col space-y-4 mx-7 my-5">
                    <textarea
                      placeholder="Enter your remarks here..."
                      value={input}
                      name=""
                      id=""
                      cols="30"
                      rows="10"
                      className="border-2 border-blue-gray-500 text-black p-2"
                      onChange={handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
            </form>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpen}
              className="mr-1"
            >
              <span>Close</span>
            </Button>
            <Button className="bg-red-800" onClick={handleSave}>
              <span>Reject</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </div>
    </>
  );
}

export default RejectApprovedModal;
