import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
} from "@material-tailwind/react";
import PrintRRTbl from "../../../table_components/Warehouse/DeliveryRawMaterials/PrintRRTbl";
import {
  useRawMatPrintQuery,
  useUpdateReceivingReportMutation,
} from "../../../../store";
import { HashLoader } from "react-spinners";
import GenerateReceivingReceipt from "../../../form_components/pdf/GenerateReceivingReceipt";
import { toast, ToastContainer } from "react-toastify";
import bmLogo from "./../../../../img/BurgerMachineLogo.jpg";
import moment from "moment";


function PrintRR({ vrr_id }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);

  const Base64ToImage = (base64img) => {
    let con_cat_base64 = "data:image/png;base64," + base64img;
    var img = new Image();
    return (img.src = con_cat_base64);
  };

  let print_raw_mat = useRawMatPrintQuery(vrr_id);

  const [update_receiving_report] = useUpdateReceivingReportMutation();
  const saveCheckNumber = async (value, type, id) => {
    let form = {
      vrr_id: id,
    };

    if (type === "check_number") {
      form.check_number = value;
    }

    const urr = await update_receiving_report(form).unwrap();
    if (urr.success === true) {
      toast.success(urr.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(urr.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };

  let content;
  if (print_raw_mat.isLoading) {
    content = <HashLoader />;
  } else if (print_raw_mat.error) {
    content = <div>Error Loading...</div>;
  } else {
    let result = print_raw_mat.data.data;
    console.log(result.delivery_date);

    content = (
      <>
        <form>
          <div className="flex flex-row">
            <div className="flex flex-col space-y-3 w-[40%] md:w-[50%]">
              <Input label="PO Number:"
                defaultValue={result.po_number}
                readOnly
              />
              <Typography>Vendor: {result.vendor.name}</Typography>
              <Typography>Driver Name: {result.vendor_driver_name}</Typography>
              <Input
                readOnly
                defaultValue={moment(new Date(result.delivery_date)).format("YYYY/MM/DD")}
                label="Commited Date"
                name="currentDate"
                disabled
              />
              <Input
                label="Check Number"
                name="check_number"
                defaultValue={result.check_number}
                autoFocus={false}
                onBlur={(e) => {
                  saveCheckNumber(e.target.value, "check_number", result.id);
                }}
              />

            </div>
            <div className="ml-5 w-[55%] space-y-3">
              <Typography>Receiver1: {result.receiver1_name}</Typography>
              <Typography>
                Receiver2:{" "}
                {result.receiver2_user
                  ? result.receiver2_user.first_name +
                    " " +
                    result.receiver2_user.last_name
                  : ""}
              </Typography>
              <Typography>
                Delivery Receipt/Sales Invoice Receipt: {result.drsi_receipt}
              </Typography>
              <Typography>
                Receiving Receipt Number: {result.receiving_receipt_number}
              </Typography>
            </div>
          </div>
        </form>
        <div className="my-10">
          <PrintRRTbl vendor_items={result.vendor_items} />
          <div className="w-42 flex justify-between items-center my-7">
            <div className="w-68">
              <Typography variant="h5">
                Total: ₱{result.sub_total_amount}
              </Typography>
            </div>
          </div>
          <div className="flex flex-row justify-center items-center space-x-3">
            <div className="flex flex-col justify-center items-center mt-10 space-y-3">
              <div className="border border-blue-gray-300 rounded-lg py-1 px-2">
                <img src={Base64ToImage(result.receiver1)} alt="" />
              </div>
              <Typography>{result.receiver1_name}</Typography>
            </div>
            <div className="flex flex-col justify-center items-center mt-10 space-y-3">
              <div className="border border-blue-gray-300 rounded-lg py-1 px-2">
                <img src={Base64ToImage(result.receiver2)} alt="" />
              </div>
              <Typography>
                {result.receiver2_user
                  ? result.receiver2_user.first_name +
                    " " +
                    result.receiver2_user.last_name
                  : ""}
              </Typography>
            </div>
            <div className="flex flex-col justify-center items-center mt-10 space-y-3">
              <div className="border border-blue-gray-300 rounded-lg py-1 px-2">
                <img src={Base64ToImage(result.vendor_driver_sig)} alt="" />
              </div>
              <Typography >{result.vendor_driver_name}</Typography>
            </div>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <div className="container">
        <Button onClick={handleOpen} className=" bg-blue-gray-800" size="sm">
          Print RR
        </Button>
        <Dialog
          open={open}
          handler={handleOpen}
          className="relative max-h-min"
          size="xxl"
        >
          <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
            <img src={bmLogo} className="aspect-auto w-48" alt="logo" />
            <Typography className="text-green-900 font-bold text-2xl lg:text-3xl leading-normal">
              RECEIVING RECEIPT
            </Typography>
          </DialogHeader>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
            <div className="px-5 mt-5">{content}</div>
            <ToastContainer />
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpen}
              className="mr-2"
            >
              <span>Close</span>
            </Button>
            <GenerateReceivingReceipt vrr_id={vrr_id} />
          </DialogFooter>
        </Dialog>
      </div>
    </>
  );
}

export default PrintRR;
