import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

export function AddDeliveryConfirmation({ onConfirm, isReadyToSave }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  const handleConfirm = (event) => {
    // Execute the confirmation action
    onConfirm(event);
    // Close the dialog
    handleOpen();
    console.log(isReadyToSave)
  };

  return (
    <>
      <Button onClick={handleOpen} className="bg-green-900"
      disabled={!isReadyToSave}>
        Save
      </Button>
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Add Delivery Confirmation</DialogHeader>
        <DialogBody>Are you sure to deliver these orders?</DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="green" onClick={handleConfirm}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}


