import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Option,
  Select,
  Input,
} from "@material-tailwind/react";
import React, { useState, useEffect } from "react";
import { VscNotebook } from "react-icons/vsc";
import PageTitle from "../page_components/PageTitle";
import { useFetchActionLogsQuery } from "../../store";
import { FiSearch } from "react-icons/fi";
import { isError } from "react-query";
import { LogDetailsModal } from "../modal_form_components/Logs/LogsDetailsModal";
import { type } from "@testing-library/user-event/dist/type";

const TABS = [
  {
    label: "Login",
    value: 5,
  },
  {
    label: "Sales Report",
    value: 1,
  },
  {
    label: "Purchase Order",
    value: 4,
  },
  {
    label: "Production",
    value: 3,
  },
  {
    label: "Delivery",
    value: 2,
  },
  {
    label: "Approval",
    value: 7,
  },
  {
    label: "Transfer",
    value: 6,
  }
];

const TABLE_HEAD = ["Action", "Log By", "Date/Time", "View"];
let result = [];
let totalPage = 0;
function formatDate(dateString) {
  const options = { month: 'long', day: 'numeric', year: 'numeric' };
  return new Date(dateString).toLocaleDateString('en-US', options);
}

function Logs() {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [types, setTypes] = useState(1);
  const [selectStartDate, setSelectStartDate] = useState("");
  const [selectEndDate, setSelectEndDate] = useState("");

  const { data, isError, isLoading, isSuccess, refetch } = useFetchActionLogsQuery({
    page: currentPage,
    page_size: pageSize,
    search: searchTerm,
    types: types,
    startDate: selectStartDate,
    endDate: selectEndDate,
  });
  const handleStartDateChange = (event) => {
    setSelectStartDate(event.target.value);
    setSelectEndDate("");
    setCurrentPage(1);
  };

  const handleEndDateChange = (event) => {
    setSelectEndDate(event.target.value);
    setCurrentPage(1);
  };
  function handlePreviousPage() {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  }
  function handleNextPage() {
    if (currentPage === totalPage) return;
    setCurrentPage(currentPage + 1);
  }
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };
  const handlePageSizeChange = (e) => {
    const selectedSize = parseInt(e.target.value);
    console.log("Selected Page Size:", selectedSize);
    setPageSize(selectedSize);
    setCurrentPage(1);
  };
  const handleTabChange = (value) => {
    setCurrentPage(1);
    setTypes(value);
    refetch();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    result = data.results;

    totalPage = Math.ceil(data.data?.count / pageSize);

    return (
      <>
        <div className="container mx-auto px-4 md:mb-28 lg:mb-0">
          <div className="md:hidden flex justify-end mt-[-20px] mb-5 mr-3">
            <PageTitle />
          </div>
          <div className="flex flex-row justify-center md:justify-between items-center ml-3 lg:ml-10 mr-5 lg:mr-28 mb-10 md:mb-16 lg:mb-5">
            <div className="flex items-center space-x-2">
              <VscNotebook className="h-10 w-10  text-[#137944]" />
              <Typography className="text-[#137944] font-bold text-2xl tracking-normal">
                Logs
              </Typography>
            </div>
            <div className="hidden md:block">
              <PageTitle />
            </div>
          </div>
          <div className="px-0 lg:px-2 mt-5">
            <Card className="h-full w-full">
            <CardHeader floated={false} shadow={false} className="rounded-none">


          <div className="md:flex justify-between mt-5 md:mt-10">
            <div className="md:ml-5 mb-3">
              <Input
                label="Search"
                icon={<FiSearch className="h-5 w-5" />}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="md:mr-10 flex justify-end mt-3 md:mt-0 items-center space-x-4">
              <Typography className=" font-medium text-sm">
                Page size:
              </Typography>
              <select
                className="block appearance-none bg-white border border-gray-300 rounded-md py-2 px-3 leading-tight focus:outline-none focus:border-indigo-500"
                value={pageSize}
                onChange={(e) => handlePageSizeChange(e)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>

          <div className="flex flex-col items-center justify-between gap-4 md:flex-row mb-5">
            <Tabs value={types} className="w-full md:w-[70%]">
              <TabsHeader>
                {TABS.map(({ label, value }) => (
                  <Tab
                    className="text-sm"
                    key={value}
                    value={value}
                    onClick={() => handleTabChange(value)}
                  >
                    &nbsp;&nbsp;{label}&nbsp;&nbsp;
                  </Tab>
                ))}
              </TabsHeader>
            </Tabs>
          </div>
          <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
          <div className="flex flex-col md:w-1/2">
                <Input
                label="Select Start Date"
                type="date"
                id="end-date"
                value={selectStartDate}
                onChange={handleStartDateChange}
                />
          </div>
          <div className="flex flex-col md:w-1/2">
                <Input
                label="Select End Date"
                type="date"
                id="end-date"
                value={selectEndDate}
                onChange={handleEndDateChange}
                min={selectStartDate}
                disabled={!selectStartDate}
                />
          </div>
        </div>
        </CardHeader>
              <CardBody className="overflow-scroll px-0">
                <table className="mt-4 w-full min-w-max table-auto text-left">
                  <thead>
                    <tr>
                      {TABLE_HEAD.map((head) => (
                        <th
                          key={head}
                          className="border-b border-green-600 bg-[#1AA55D] text-white p-4"
                        >
                          <Typography
                            variant="small"
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              textTransform: "uppercase",
                            }}
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {result.map((log, index) => {
                      const isLast = index === result.length - 1;
                      const classes = isLast
                        ? "p-4"
                        : "p-4 border-b border-blue-gray-50";
                      const formattedDate = log.action_time
                        ? new Intl.DateTimeFormat("en-ASIA", {
                            year: "numeric",
                            month: "short",
                            day: "2-digit",
                            hour: "numeric",
                            minute: "numeric",
                            hour12: true,
                          }).format(new Date(log.action_time))
                        : "";
                      return (
                        <tr key={log.id}>
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <div className="flex flex-col">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {log.action_name !== null &&
                                  log.action_name !== undefined
                                    ? log.action_name
                                    : "N/A"}
                                </Typography>
                              </div>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <div className="flex flex-col">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {log.user?.first_name || "N/A"}{" "}
                                  {log.user?.last_name || "N/A"}
                                </Typography>
                              </div>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <div className="flex flex-col">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {formattedDate}
                                </Typography>
                              </div>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <div className="flex flex-col">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  <LogDetailsModal
                                    logDetails={log.change_message}
                                  />
                                </Typography>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </CardBody>
              <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  Page{" "}
                  {`${currentPage} of ${
                    Math.ceil(data?.count / pageSize) || 1
                  }`}
                </Typography>
                <div className="flex gap-2">
                  <Button
                    variant="outlined"
                    size="sm"
                    onClick={handlePreviousPage}
                    disabled={!data?.previous}
                  >
                    Previous
                  </Button>
                  {/* <Button
              variant="outlined"
              size="sm"
              onClick={handleClickRefresh}
              disabled={isRefreshing}
            >
              Refresh
            </Button> */}

                  <Button
                    variant="outlined"
                    size="sm"
                    onClick={handleNextPage}
                    disabled={!data?.next}
                  >
                    {data?.next ? "Next" : "No More Pages"}
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </div>
        </div>
      </>
    );
  }
}
export default Logs;
