import React, {
    useState, useEffect
  } from "react";
  import {
    Typography,
    Card, CardBody, CardFooter
  } from "@material-tailwind/react";
  import { IoMdCodeWorking } from "react-icons/io";
  import { MdOutlinePendingActions } from "react-icons/md";
  import { GiFinishLine } from "react-icons/gi";


  export function POStatusesWidget({prod_line}) {

    const [workingCount, setWorkingCount] = useState(0);
    const [waitingForDeliveryCount, setWaitingForDeliveryCount] = useState(0);
    const [deliveredCount, setDeliveredCount] = useState(0);

    useEffect(() => {
      setWorkingCount(prod_line.count_on_queue); // Set working count
      setWaitingForDeliveryCount(prod_line.count_working); // Set waiting for delivery count
      setDeliveredCount(prod_line.count_completed); // Set delivered count
    }, []);

    return (
      <Card className="w-full sm:w-1/2 md:w-2/3 p-4 bg-white rounded-lg shadow-md">
        <CardBody>
          <Typography
            variant="h6"
            color="blue-gray"
            className="font-bold mb-1 flex items-center justify-center"
          >
            Production Line Status
          </Typography>
          <table className="w-full" style={{borderCollapse: 'separate', borderSpacing: 0, borderRadius: '10px', overflow: 'hidden'}}>
            <thead>
              <tr>
                <th style={{backgroundColor: '#4a148c', color: '#fff', fontWeight: 'bold', textAlign: 'center', padding: '10px', border: '1px solid #dee2e6', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'}}><MdOutlinePendingActions/> Queue</th>
                <th style={{backgroundColor: '#4a148c', color: '#fff', fontWeight: 'bold', textAlign: 'center', padding: '10px', border: '1px solid #dee2e6', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'}}><IoMdCodeWorking/> Working</th>
                <th style={{backgroundColor: '#4a148c', color: '#fff', fontWeight: 'bold', textAlign: 'center', padding: '10px', border: '1px solid #dee2e6', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'}}><GiFinishLine /> Completed</th>
              </tr>
            </thead>
            <tbody className="text-center">
              <tr>
                <td style={{padding: '10px', border: '1px solid #dee2e6', backgroundColor: '#fff', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'}}>{workingCount}</td>
                <td style={{padding: '10px', border: '1px solid #dee2e6', backgroundColor: '#fff', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'}}>{waitingForDeliveryCount}</td>
                <td style={{padding: '10px', border: '1px solid #dee2e6', backgroundColor: '#fff', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'}}>{deliveredCount}</td>
              </tr>
            </tbody>
          </table>
        </CardBody>
        <CardFooter>
        </CardFooter>
      </Card>
    );
  }

  export default POStatusesWidget;
