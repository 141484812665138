import React, {
  useState, useEffect
} from "react";
import {
  Button, Typography,
  Card, CardBody, CardFooter
} from "@material-tailwind/react";
import {
  FaTools, FaTruck,
  FaCheckCircle
} from 'react-icons/fa';


export function POStatusesWidget({po_status, po_working}) {

  const [workingCount, setWorkingCount] = useState(0);
  const [waitingForDeliveryCount, setWaitingForDeliveryCount] = useState(0);
  const [deliveredCount, setDeliveredCount] = useState(0);

  console.log(po_status)
  useEffect(() => {
    setWorkingCount(po_working.count_working); // Set working count
    setWaitingForDeliveryCount(po_status.count_queue); // Set waiting for delivery count
    setDeliveredCount(po_status.count_submitted); // Set delivered count
  }, []);

  return (
    <Card className="w-full sm:w-1/2 md:w-2/3 p-4 bg-white rounded-lg shadow-md">
      <CardBody>
        <Typography
          variant="h6"
          color="blue-gray"
          className="font-bold mb-1 flex items-center justify-center"
        >
           PO STATUS
        </Typography>
        <table className="w-full" style={{borderCollapse: 'separate', borderSpacing: 0, borderRadius: '10px', overflow: 'hidden'}}>
          <thead>
            <tr>
              <th style={{
                backgroundColor: '#0d47a1',
                color: '#fff',
                fontWeight: 'bold',
                textAlign: 'center',
                padding: '4px',
                border: '1px solid #dee2e6',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
              }}>
                <FaTools style={{ marginRight: '8px' }}/> Working
              </th>
              <th style={{
                backgroundColor: '#0d47a1',
                color: '#fff',
                fontWeight: 'bold',
                textAlign: 'center',
                padding: '4px',
                border: '1px solid #dee2e6',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
              }}>
                <FaTruck /> Waiting for Delivery
              </th>
              <th style={{
                backgroundColor: '#0d47a1',
                color: '#fff',
                fontWeight: 'bold',
                textAlign: 'center',
                padding: '4px',
                border: '1px solid #dee2e6',
                boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'
              }}>
                <FaCheckCircle /> Delivered
              </th>
            </tr>
          </thead>
          <tbody className="text-center">
            <tr>
              <td style={{padding: '10px', border: '1px solid #dee2e6', backgroundColor: '#fff', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'}}>{workingCount}</td>
              <td style={{padding: '10px', border: '1px solid #dee2e6', backgroundColor: '#fff', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'}}>{waitingForDeliveryCount}</td>
              <td style={{padding: '10px', border: '1px solid #dee2e6', backgroundColor: '#fff', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)'}}>{deliveredCount}</td>
            </tr>
          </tbody>
        </table>
      </CardBody>
      <CardFooter>
        <Button className="text-xs bg-blue-900">
          View Status
        </Button>
      </CardFooter>
    </Card>
  );
}

export default POStatusesWidget;
