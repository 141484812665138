import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");

const notificationApi = createApi({
  reducerPath: "notification",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  endpoints(builder) {
    return {
      fetchNotification: builder.query({
        query: ({ page, page_size, receiver }) => {
          return {
            url: `/api/notification/request/get_notification/?page=${page}&receiver=${receiver}&page_size=${page_size}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchDetailNotification: builder.query({
        query: ({ id }) => {
          return {
            url: `/api/notification/request/${id}/detail_notification/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchNotificationCount: builder.query({
        query: (user_id) => {
          return {
            url: `/api/notification/request/${user_id}/unseen_notification/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      setSeenNotification: builder.mutation({
        query: ({ notifId }) => {
          return {
            url: `/api/notification/request/${notifId}/seen_notification/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
          };
        },
      }),
      deleteNotification: builder.mutation({
        query: ({ notifId }) => {
          return {
            url: `/api/notification/request/${notifId}/del_detail_notif/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
          };
        },
      }),
      deleteNotificationList: builder.mutation({
        query: (notifId) => {
          return {
            url: "/api/notification/request/del_notification/",
            headers: {
              Authorization: "token " + token,
              "Content-Type": "application/json",
              Accept: "*/*",
            },
            method: "PUT",
            body: notifId,
          };
        },
      }),
    };
  },
});
export const {
  useFetchNotificationQuery,
  useFetchNotificationCountQuery,
  useSetSeenNotificationMutation,
  useDeleteNotificationMutation,
  useFetchDetailNotificationQuery,
  useDeleteNotificationListMutation,
} = notificationApi;
export { notificationApi };
