import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

export function Confirmation({ onConfirm, isReadyToSave, trapMessage }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  const handleConfirm = (event) => {
    onConfirm(event);
    handleOpen();
    console.log(isReadyToSave);
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        className="bg-green-900 ml-10"
        disabled={!isReadyToSave}
      >
        Confirm
      </Button>
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Confirmation</DialogHeader>
        <DialogBody>{trapMessage}</DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="green" onClick={handleConfirm}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
