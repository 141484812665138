import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Card,
  CardFooter,
  Select,
  Option,
} from "@material-tailwind/react";
import { useFetchDSRListPageQuery } from "../../../store";
import { useFetchRouteListQuery } from "../../../store";
import { useFetchFCListQuery } from "../../../store";
import { FiSearch } from "react-icons/fi";

const TABLE_HEAD = [
  "Outlet",
  "FC",
  "Route",
];
const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
const trapMessage = "Are you sure to Transfer these products?";
const user_info = JSON.parse(localStorage.getItem("user_info"));

const currentDate = new Date();
const transferDate = currentDate.toLocaleString(undefined, {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});
let result = [];
let result_item = [];

function OutletReportModal({  }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = "p-4 border-b border-blue-gray-50";
  const inputClasses = "flex flex-row items-center";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";

    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedStatus, setSelectedStatus] = useState(1);
    const [selectedFC, setSelectedFC] = useState("");
    const [selectedRoute, setSelectedRoute] = useState("");
    const [startDate, setStartDate] = useState(getTodayDate());

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const {
    data,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useFetchDSRListPageQuery({
    page: currentPage,
    page_size: pageSize,
    search: searchTerm,
    startDate: startDate,
    types: 2,
    fc: selectedFC,
    route: selectedRoute,
  });

  const {
    data: fcData,
    error: fcError,
    isLoading: fcLoading,
    isSuccess: fcSuccess,
  } = useFetchFCListQuery();
  const {
    data: routeData,
    error: routeError,
    isLoading: loadingRoute,
    isSuccess: routeSucess,
  } = useFetchRouteListQuery({
    page: 1,
    page_size: 50,
    schedule: 1,
  });

  const handleClickRefresh = () => {
    setIsRefreshing(true);

    // Trigger the refetch function
    refetch();

    // Disable the button after 2 seconds
    setTimeout(() => {
      setIsRefreshing(false);
    }, 2000);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handlePageSizeChange = (e) => {
    const selectedSize = parseInt(e.target.value);
    console.log("Selected Page Size:", selectedSize);
    setPageSize(selectedSize);
    setCurrentPage(1);
  };

  const handleFCChange = (value) => {
    const fcId = parseInt(value, 10);
    setSelectedFC(fcId);
    setCurrentPage(1);
  };

  const handleRouteChange = (value) => {
    const rID = parseInt(value, 10);
    setSelectedRoute(rID);
    setCurrentPage(1);
  };

  const handleMainClose = () => {
    setOpen(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (isSuccess) {
    result = data.results;

    const formattedDate = result.transfer_request_date
      ? new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(new Date(result.transfer_request_date))
      : "";

    return (
      <>
        <Button onClick={handleOpen} className="bg-orange-500">
         No Ending Inventory
        </Button>

        <Dialog
          size="lg"
          open={open}
          handler={handleOpen}
          className="relative max-h-min"
        >
          <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
            {/* <img src={Logo} className="aspect-auto w-48" alt="logo" /> */}
            <Typography
              className="text-green-900"
              variant="h3"
              component={"span"}
            >
             No Ending Inventory Outlets
            </Typography>
          </DialogHeader>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
            <form>
            <div className="md:flex justify-between mt-5 md:mt-10">
            <div className="md:ml-5">
              <Input
                label="Search"
                icon={<FiSearch className="h-5 w-5" />}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="md:mr-10 flex justify-end mt-3 md:mt-0 items-center space-x-4">
              <Typography className=" font-medium text-sm">
                Page size:
              </Typography>
              <select
                className="block appearance-none bg-white border border-gray-300 rounded-md py-2 px-3 leading-tight focus:outline-none focus:border-indigo-500"
                value={pageSize}
                onChange={(e) => handlePageSizeChange(e)}
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>

          <div className="flex items-center justify-between md:flex-row mt-5">
          <div className="flex flex-col gap-2">
    <Input
      label="Start Date"
      name="start_date"
      type="date"
      onChange={handleStartDateChange}
      value={startDate}
    />
  </div>
  <div className="flex flex-col gap-2 mr-5">
    {routeSucess && (
      <div className="w-50">
        <Select
          label="Route"
          onChange={handleRouteChange}
          value={selectedRoute ? `${selectedRoute}` : null}
          variant="outlined"
        >
          {routeData.results.map((route) => (
            <Option key={route.id} value={`${route.id}`}>
              {route.name}
            </Option>
          ))}
        </Select>
      </div>
    )}
  </div>
  <div className="flex flex-col gap-2 mr-5">
    {fcSuccess && (
      <div className="w-50">
        <Select
          label="FC"
          onChange={handleFCChange}
          value={selectedFC ? `${selectedFC}` : null}
          variant="outlined"
        >
          {fcData.data.map((fc) => (
            <Option key={fc.id} value={`${fc.id}`}>
              {fc.name}
            </Option>
          ))}
        </Select>
      </div>
    )}
  </div>
  </div>
              <div className="m-5">
                <Card
                  className="h-full w-full overflow-scroll"
                  component={"span"}
                >
                  <table className="w-full min-w-max table-auto text-left ">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head) => (
                          <th
                            key={head}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                            <Typography
                              component={"span"}
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {result.map((outlet, index) => {
                        {
                          // const formattedDate = item.record_at
                          //   ? new Intl.DateTimeFormat("en-US", {
                          //       year: "numeric",
                          //       month: "short",
                          //       day: "2-digit",
                          //       hour: "numeric",
                          //       minute: "numeric",
                          //       hour12: true,
                          //     }).format(new Date(item.record_at))
                          //   : "";
                          return (
                            <tr key={index}>
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {outlet?.outlet ||
                                        "Outlet"}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {outlet.fc.name || "N/A"}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <Typography
                                      variant="small"
                                      color="blue-gray"
                                      className="font-normal"
                                      component={"span"}
                                    >
                                      {outlet.route.name || "N/A"}
                                    </Typography>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </table>
                </Card>
                <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    Page{" "}
                    {`${currentPage} of ${
                      Math.ceil(data?.count / pageSize) || 1
                    }`}
                  </Typography>
                  <div className="flex gap-2">
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={handlePreviousPage}
                      disabled={!data?.previous}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={handleClickRefresh}
                      disabled={isRefreshing}
                    >
                      Refresh
                    </Button>
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={handleNextPage}
                      disabled={!data?.next}
                    >
                      {data?.next ? "Next" : "No More Pages"}
                    </Button>
                  </div>
                </CardFooter>
              </div>
            </form>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              className="mr-3"
              onClick={handleMainClose}
            >
              <span>Close</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

export default OutletReportModal;
