import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { FaTrash } from "react-icons/fa";
import { useDeleteNotificationMutation } from "../../../store/apis/NotificationApi";

function DeleteNotification({ id }) {
  const [open, setOpen] = React.useState(false);
  const [deleteNotif] = useDeleteNotificationMutation();

  const handleOpen = () => setOpen(!open);
  const handleDelete = () => {
    deleteNotif({ notifId: id });
    setOpen(!open);
  };
  console.log(id);
  return (
    <>
      <FaTrash className=" cursor-pointer" onClick={handleOpen} />
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Confim delete notification.</DialogHeader>
        <DialogBody>Are you sure you want to delete?</DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="green" onClick={handleDelete}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
export default DeleteNotification;
