import { Card, Typography } from "@material-tailwind/react";

const TABLE_HEAD = [
  "Items",
  "Unit Measure",
  "Required Quantity",
  "Quoted Price",
  "Subtotal",
  "Payment Terms (Days)",
];


function PoListViewButtonTable() {
  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left ">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* // const isLast = index === sampleJson.length - 1;
            // const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50"; */}
        </tbody>
      </table>
    </Card>
  );
}

export default PoListViewButtonTable;
