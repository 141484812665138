import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

export function ReturnOrderConfirmation({ onConfirm, isReadyToSave }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  const handleConfirm = (event) => {
    // Execute the confirmation action
    onConfirm(event);
    // Close the dialog
    handleOpen();

  };

  return (
    <>
      <div className="flex justify-start">
      <Button
        onClick={handleOpen}
        className="bg-red-900"
        disabled={!isReadyToSave}
        style={{ marginLeft: 0 }}
      >
        Return
      </Button>
      </div>
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Return Delivery Confirmation</DialogHeader>
        <DialogBody>Are you sure to return these orders?</DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="green" onClick={handleConfirm}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}


