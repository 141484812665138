import { useState, useEffect } from "react";
import { Card, Typography } from "@material-tailwind/react";
import { useFetchAuditFormProductsQuery } from "../../../store";
import { useFetchAuditFormItemsQuery } from "../../../store";
import { useAddUpdateAuditedItemsQtyMutation } from "../../../store";
import { ToastContainer, toast } from "react-toastify";


const TABLE_HEAD = [
  "Code",
  "Products",
  "Unit Measure",
  "Audit SOH",
  "Audit Qty",
  "Short/Over"

];

const ITEM_TABLE_HEAD = [
    "Item",
    "Unit Measure",
    "Audit SOH",
    "Audit Qty",
    "Short/Over"


  ];
let product_result = [];

const classes = "p-4 border-b border-blue-gray-50";

function capitalizeWords(str) {
  return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
  });
}


function RepackingTbl( {onOrderChange, auditQtys, auditId, refetchTable, noDataCount} ) {
  const { data, error, isLoading, isSuccess } = useFetchAuditFormItemsQuery({
    location: 6,
  })
  let item_result = [];
  let [productActualQty, setProductActualQty] = useState([]);
  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: item_result[index].id,
      auditQty: qty
    }));


    onOrderChange(product_data);
  };

  const handleRejectQty = (e, index) => {
    const inputValue = parseInt(e.target.value, 10);
    const MaxQty = productActualQty[index];

    // Ensure inputValue is within the range of 0 to MaxQty
    const newRejectQty = Math.min(Math.max(inputValue, 0), MaxQty);

    // Calculate the new actual quantity based on the total quantity
    const totalQty = productActualQty[index] + newRejectQty;
    const newActualQty = MaxQty - newRejectQty;

  };
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [updateQty] = useAddUpdateAuditedItemsQtyMutation();

  const handleUpdateAuditQty = async (event, prod_id, section, qty,) => {
    event.preventDefault();
    let allData = {
      userId: account_detailed.user.id,
      section: section,
      auditQty: qty,
      itemId: prod_id,
      auditId: auditId,
    };

    try {
      const sea = await updateQty(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // refetch();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetchTable();
  };
  let noDataCountNum = 0
  if (isLoading) {
    return <div>Loading...</div>;
  }
  else if (error) {
    return <div>Error loading data</div>;
  }
  else if (data.length === 0) {
    return <div>No data found.</div>;
  }
  else if (isSuccess){

    item_result = data.data;

  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left mt-3">
        <thead>
          <tr>
            {ITEM_TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
           {item_result.map((items, index) => {
                    const item = auditQtys.find(auditItem => auditItem?.item?.id === items.item.id);
                    let prevQty = item ? item.audited_qty : "No Data";
                    let resultQty = item ? item.result_qty : 0;
                    let currentSOH = item ? item.current_soh : 0;
                    if(prevQty=="No Data"){
                      noDataCountNum += 1
                    }
                    else{
                      noDataCountNum += 0
                    }
                    noDataCount(noDataCountNum);

            return (
               <tr key={index}>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {items.item.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {items.item.weight_box} /   {items.item.unit_measurement}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {currentSOH || 0}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {prevQty || "No Data"}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 {/* <td key={items.item_id}>
                      <div className="flex items-center">
                        <div className="flex flex-col flex-grow">
                          <input
                            type="number"
                            value={productActualQty[index] || ""}
                            onBlur={(e) => handleUpdateAuditQty(e, items.item.id, 1, e.target.value)}
                            onInput={(e) => handleOrderChange(index, e.target.value)}
                            className="w-20 border border-1 border-blue-gray-300 p-1"
                          />
                        </div>
                      </div>
                    </td> */}
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color={resultQty > 0 ? "red" :   "green"}
                          className="font-normal"
                          component={'span'}
                        >
                          {(resultQty ?? 0) > 0
                            ? `- ${Math.abs(resultQty) ?? 0}`
                            : `+ ${Math.abs(resultQty) ?? 0}`}
                        </Typography>
                      </div>
                    </div>
                  </td>

               </tr>
             );
            })}
          </tbody>
      </table>
    </Card>
  );
}

}

function PlasticTbl( {onOrderChange, auditQtys, auditId, refetchTable, noDataCount} ) {
  const { data, error, isLoading, isSuccess } = useFetchAuditFormItemsQuery({
    location: 7,
  })
  let item_result = [];
  let [productActualQty, setProductActualQty] = useState([]);
  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: item_result[index].id,
      auditQty: qty
    }));


    onOrderChange(product_data);
  };

  const handleRejectQty = (e, index) => {
    const inputValue = parseInt(e.target.value, 10);
    const MaxQty = productActualQty[index];

    // Ensure inputValue is within the range of 0 to MaxQty
    const newRejectQty = Math.min(Math.max(inputValue, 0), MaxQty);

    // Calculate the new actual quantity based on the total quantity
    const totalQty = productActualQty[index] + newRejectQty;
    const newActualQty = MaxQty - newRejectQty;

  };
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [updateQty] = useAddUpdateAuditedItemsQtyMutation();

  const handleUpdateAuditQty = async (event, prod_id, section, qty,) => {
    event.preventDefault();
    let allData = {
      userId: account_detailed.user.id,
      section: section,
      auditQty: qty,
      itemId: prod_id,
      auditId: auditId,
    };

    try {
      const sea = await updateQty(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // refetch();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetchTable();
  };
  let noDataCountNum = 0
  if (isLoading) {
    return <div>Loading...</div>;
  }
  else if (error) {
    return <div>Error loading data</div>;
  }
  else if (data.length === 0) {
    return <div>No data found.</div>;
  }
  else if (isSuccess){

    item_result = data.data;

  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left mt-3">
        <thead>
          <tr>
            {ITEM_TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
           {item_result.map((items, index) => {
                    const item = auditQtys.find(auditItem => auditItem?.item?.id === items.item.id);
                    let prevQty = item ? item.audited_qty : "No Data";
                    let resultQty = item ? item.result_qty : 0;
                    let currentSOH = item ? item.current_soh : 0;
                    if(prevQty=="No Data"){
                      noDataCountNum += 1
                    }
                    else{
                      noDataCountNum += 0
                    }
                    noDataCount(noDataCountNum);

            return (
               <tr key={index}>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {items.item.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {items.item.weight_box} /   {items.item.unit_measurement}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {currentSOH || 0}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {prevQty || "No Data"}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 {/* <td key={items.item_id}>
                      <div className="flex items-center">
                        <div className="flex flex-col flex-grow">
                          <input
                            type="number"
                            value={productActualQty[index] || ""}
                            onBlur={(e) => handleUpdateAuditQty(e, items.item.id, 1, e.target.value)}
                            onInput={(e) => handleOrderChange(index, e.target.value)}
                            className="w-20 border border-1 border-blue-gray-300 p-1"
                          />
                        </div>
                      </div>
                    </td> */}
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color={resultQty > 0 ? "red" :   "green"}
                          className="font-normal"
                          component={'span'}
                        >
                          {(resultQty ?? 0) > 0
                            ? `- ${Math.abs(resultQty) ?? 0}`
                            : `+ ${Math.abs(resultQty) ?? 0}`}
                        </Typography>
                      </div>
                    </div>
                  </td>

               </tr>
             );
            })}
          </tbody>
      </table>
    </Card>
  );
}

}



function RawmaterialsTbl( {onOrderChange, auditQtys, auditId, refetchTable, noDataCount} ) {
  const { data, error, isLoading, isSuccess } = useFetchAuditFormItemsQuery({
    location: 1,
  })
  let item_result = [];
  let [productActualQty, setProductActualQty] = useState([]);
  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: item_result[index].id,
      auditQty: qty
    }));


    onOrderChange(product_data);
  };

  const handleRejectQty = (e, index) => {
    const inputValue = parseInt(e.target.value, 10);
    const MaxQty = productActualQty[index];

    // Ensure inputValue is within the range of 0 to MaxQty
    const newRejectQty = Math.min(Math.max(inputValue, 0), MaxQty);

    // Calculate the new actual quantity based on the total quantity
    const totalQty = productActualQty[index] + newRejectQty;
    const newActualQty = MaxQty - newRejectQty;

  };
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [updateQty] = useAddUpdateAuditedItemsQtyMutation();

  const handleUpdateAuditQty = async (event, prod_id, section, qty,) => {
    event.preventDefault();
    let allData = {
      userId: account_detailed.user.id,
      section: section,
      auditQty: qty,
      itemId: prod_id,
      auditId: auditId,
    };

    try {
      const sea = await updateQty(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // refetch();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetchTable();
  };
  let noDataCountNum = 0
  if (isLoading) {
    return <div>Loading...</div>;
  }
  else if (error) {
    return <div>Error loading data</div>;
  }
  else if (data.length === 0) {
    return <div>No data found.</div>;
  }
  else if (isSuccess){

    item_result = data.data;

  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left mt-3">
        <thead>
          <tr>
            {ITEM_TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
           {item_result.map((items, index) => {
                    const item = auditQtys.find(auditItem => auditItem?.item?.id === items.item.id);
                    let prevQty = item ? item.audited_qty : "No Data";
                    let resultQty = item ? item.result_qty : 0;
                    let currentSOH = item ? item.current_soh : 0;
                    if(prevQty=="No Data"){
                      noDataCountNum += 1
                    }
                    else{
                      noDataCountNum += 0
                    }
                    noDataCount(noDataCountNum);

            return (
               <tr key={index}>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {items.item.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {items.item.weight_box} /   {items.item.unit_measurement}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {currentSOH || 0}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {prevQty || "No Data"}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 {/* <td key={items.id}>
                      <div className="flex items-center">
                        <div className="flex flex-col flex-grow">
                          <input
                            type="number"
                            value={productActualQty[index] || ""}
                            onBlur={(e) => handleUpdateAuditQty(e, items.item.id, 1, e.target.value)}
                            onInput={(e) => handleOrderChange(index, e.target.value)}
                            className="w-20 border border-1 border-blue-gray-300 p-1"
                          />
                        </div>
                      </div>
                    </td> */}
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color={resultQty > 0 ? "red" :   "green"}
                          className="font-normal"
                          component={'span'}
                        >
                          {(resultQty ?? 0) > 0
                            ? `- ${Math.abs(resultQty) ?? 0}`
                            : `+ ${Math.abs(resultQty) ?? 0}`}
                        </Typography>
                      </div>
                    </div>
                  </td>

               </tr>
             );
            })}
          </tbody>
      </table>
    </Card>
  );
}

}

function FoodsDutyTbl( {onOrderChange, auditQtys, auditId, refetchTable, noDataCount} ) {
  const { data, error, isLoading, isSuccess } = useFetchAuditFormProductsQuery({
    category: 1,
  })

  let [productActualQty, setProductActualQty] = useState([]);
  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: product_result[index].id,
      auditQty: qty
    }));


    onOrderChange(product_data);
  };

  const handleRejectQty = (e, index) => {
    const inputValue = parseInt(e.target.value, 10);
    const MaxQty = productActualQty[index];

    // Ensure inputValue is within the range of 0 to MaxQty
    const newRejectQty = Math.min(Math.max(inputValue, 0), MaxQty);

    // Calculate the new actual quantity based on the total quantity
    const totalQty = productActualQty[index] + newRejectQty;
    const newActualQty = MaxQty - newRejectQty;

  };
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [updateQty] = useAddUpdateAuditedItemsQtyMutation();

  const handleUpdateAuditQty = async (event, prod_id, section, qty,) => {
    event.preventDefault();
    let allData = {
      userId: account_detailed.user.id,
      section: section,
      auditQty: qty,
      prodId: prod_id,
      auditId: auditId,
    };

    try {
      const sea = await updateQty(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // refetch();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetchTable();
  };
  let noDataCountNum = 0
  if (isLoading) {
    return <div>Loading...</div>;
  }
  else if (error) {
    return <div>Error loading data</div>;
  }
  else if (data.length === 0) {
    return <div>No data found.</div>;
  }
  else if (isSuccess){

    product_result = data.data;

  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left mt-3">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
           {product_result.map((product, index) => {
                    const item = auditQtys.find(auditItem => auditItem?.product?.id === product.product.id);
                    let prevQty = item ? item.audited_qty : "No Data";
                    let resultQty = item ? item.result_qty : 0;
                    let currentSOH = item ? item.current_soh : 0;
                    if(prevQty=="No Data"){
                      noDataCountNum += 1
                    }
                    else{
                      noDataCountNum += 0
                    }
                    noDataCount(noDataCountNum);

            return (
               <tr key={index}>
                  <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {product.product.product_code}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {product.product.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {product.product.weight_box} /   {product.product.unit_measurement}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {currentSOH || 0}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {prevQty || "No Data"}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 {/* <td key={product.item_id}>
                      <div className="flex items-center">
                        <div className="flex flex-col flex-grow">
                          <input
                            type="number"
                            value={productActualQty[index] || ""}
                            onBlur={(e) => handleUpdateAuditQty(e, product.product.id, 2, e.target.value)}
                            onInput={(e) => handleOrderChange(index, e.target.value)}
                            className="w-20 border border-1 border-blue-gray-300 p-1"
                          />
                        </div>
                      </div>
                    </td> */}
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color={resultQty > 0 ? "red" : "green"}
                          className="font-normal"
                          component={'span'}
                        >
                          {(resultQty ?? 0) > 0
                            ? `- ${Math.abs(resultQty) ?? 0}`
                            : `+ ${Math.abs(resultQty) ?? 0}`}
                        </Typography>
                      </div>
                    </div>
                  </td>

               </tr>
             );
            })}
          </tbody>
      </table>
    </Card>
  );
}

}

function BeveragesTbl( {onOrderChange, auditQtys, auditId, refetchTable, noDataCount} ) {
  const { data, error, isLoading, isSuccess } = useFetchAuditFormProductsQuery({
    category: 2,
  })

  let [productActualQty, setProductActualQty] = useState([]);
  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: product_result[index].id,
      auditQty: qty
    }));


    onOrderChange(product_data);
  };

  const handleRejectQty = (e, index) => {
    const inputValue = parseInt(e.target.value, 10);
    const MaxQty = productActualQty[index];

    // Ensure inputValue is within the range of 0 to MaxQty
    const newRejectQty = Math.min(Math.max(inputValue, 0), MaxQty);

    // Calculate the new actual quantity based on the total quantity
    const totalQty = productActualQty[index] + newRejectQty;
    const newActualQty = MaxQty - newRejectQty;

  };
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [updateQty] = useAddUpdateAuditedItemsQtyMutation();

  const handleUpdateAuditQty = async (event, prod_id, section, qty,) => {
    event.preventDefault();
    let allData = {
      userId: account_detailed.user.id,
      section: section,
      auditQty: qty,
      prodId: prod_id,
      auditId: auditId,
    };

    try {
      const sea = await updateQty(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // refetch();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetchTable();
  };
  let noDataCountNum = 0
  if (isLoading) {
    return <div>Loading...</div>;
  }
  else if (error) {
    return <div>Error loading data</div>;
  }
  else if (data.length === 0) {
    return <div>No data found.</div>;
  }
  else if (isSuccess){

    product_result = data.data;

  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left mt-3">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
           {product_result.map((product, index) => {
                    const item = auditQtys.find(auditItem => auditItem?.product?.id === product.product.id);
                    let prevQty = item ? item.audited_qty : "No Data";
                    let resultQty = item ? item.result_qty : 0;
                    let currentSOH = item ? item.current_soh : 0;
                    if(prevQty=="No Data"){
                      noDataCountNum += 1
                    }
                    else{
                      noDataCountNum += 0
                    }
                    noDataCount(noDataCountNum);

            return (
               <tr key={index}>
                  <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {product.product.product_code}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {product.product.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {product.product.weight_box} /   {product.product.unit_measurement}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                               {currentSOH || 0}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {prevQty || "No Data"}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 {/* <td key={product.item_id}>
                      <div className="flex items-center">
                        <div className="flex flex-col flex-grow">
                          <input
                            type="number"
                            value={productActualQty[index] || ""}
                            onBlur={(e) => handleUpdateAuditQty(e, product.product.id, 3, e.target.value)}
                            onInput={(e) => handleOrderChange(index, e.target.value)}
                            className="w-20 border border-1 border-blue-gray-300 p-1"
                          />
                        </div>
                      </div>
                    </td> */}
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color={resultQty > 0 ? "red" : "green"}
                          className="font-normal"
                          component={'span'}
                        >
                          {(resultQty ?? 0) > 0
                            ? `- ${Math.abs(resultQty) ?? 0}`
                            : `+ ${Math.abs(resultQty) ?? 0}`}
                        </Typography>
                      </div>
                    </div>
                  </td>

               </tr>
             );
            })}
          </tbody>
      </table>
    </Card>
  );
}

}

function ConsumablesTbl( {onOrderChange, auditQtys, auditId, refetchTable, noDataCount} ) {
  const { data, error, isLoading, isSuccess } = useFetchAuditFormProductsQuery({
    category: 3,
  })

  let [productActualQty, setProductActualQty] = useState([]);
  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: product_result[index].id,
      auditQty: qty
    }));


    onOrderChange(product_data);
  };
  let noDataCountNum = 0
  const handleRejectQty = (e, index) => {
    const inputValue = parseInt(e.target.value, 10);
    const MaxQty = productActualQty[index];

    // Ensure inputValue is within the range of 0 to MaxQty
    const newRejectQty = Math.min(Math.max(inputValue, 0), MaxQty);

    // Calculate the new actual quantity based on the total quantity
    const totalQty = productActualQty[index] + newRejectQty;
    const newActualQty = MaxQty - newRejectQty;

  };
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [updateQty] = useAddUpdateAuditedItemsQtyMutation();

  const handleUpdateAuditQty = async (event, prod_id, section, qty,) => {
    event.preventDefault();
    let allData = {
      userId: account_detailed.user.id,
      section: section,
      auditQty: qty,
      prodId: prod_id,
      auditId: auditId,
    };

    try {
      const sea = await updateQty(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // refetch();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetchTable();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  else if (error) {
    return <div>Error loading data</div>;
  }
  else if (data.length === 0) {
    return <div>No data found.</div>;
  }
  else if (isSuccess){

    product_result = data.data;

  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left mt-3">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
           {product_result.map((product, index) => {
                    const item = auditQtys.find(auditItem => auditItem?.product?.id === product.product.id);
                    let prevQty = item ? item.audited_qty : "No Data";
                    let resultQty = item ? item.result_qty : 0;
                    let currentSOH = item ? item.current_soh : 0;
                    if(prevQty=="No Data"){
                      noDataCountNum += 1
                    }
                    else{
                      noDataCountNum += 0
                    }
                    noDataCount(noDataCountNum);

            return (
               <tr key={index}>
                  <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {product.product.product_code}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {product.product.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {product.product.weight_box} /   {product.product.unit_measurement}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {currentSOH || 0}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {prevQty || "No Data"}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 {/* <td key={product.item_id}>
                      <div className="flex items-center">
                        <div className="flex flex-col flex-grow">
                          <input
                            type="number"
                            value={productActualQty[index] || ""}
                            onBlur={(e) => handleUpdateAuditQty(e, product.product.id, 4, e.target.value)}
                            onInput={(e) => handleOrderChange(index, e.target.value)}
                            className="w-20 border border-1 border-blue-gray-300 p-1"
                          />
                        </div>
                      </div>
                    </td> */}
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color={resultQty > 0 ? "red" : "green"}
                          className="font-normal"
                          component={'span'}
                        >
                          {(resultQty ?? 0) > 0
                            ? `- ${Math.abs(resultQty) ?? 0}`
                            : `+ ${Math.abs(resultQty) ?? 0}`}
                        </Typography>
                      </div>
                    </div>
                  </td>

               </tr>
             );
            })}
          </tbody>
      </table>
    </Card>
  );
}

}

function ItemConsumablesTbl( {onOrderChange, auditQtys, auditId, refetchTable, noDataCount} ) {
  const { data, error, isLoading, isSuccess } = useFetchAuditFormItemsQuery({
    location: 4,
  })
  let item_result = [];
  let [productActualQty, setProductActualQty] = useState([]);
  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: item_result[index].id,
      auditQty: qty
    }));


    onOrderChange(product_data);
  };
  let noDataCountNum = 0
  //   useEffect(() => {
  //   if (noDataCountNum > 0) {
  //     noDataCount(noDataCountNum);
  //   }
  // }, [noDataCountNum]);

  const handleRejectQty = (e, index) => {
    const inputValue = parseInt(e.target.value, 10);
    const MaxQty = productActualQty[index];

    // Ensure inputValue is within the range of 0 to MaxQty
    const newRejectQty = Math.min(Math.max(inputValue, 0), MaxQty);

    // Calculate the new actual quantity based on the total quantity
    const totalQty = productActualQty[index] + newRejectQty;
    const newActualQty = MaxQty - newRejectQty;

  };

  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [updateQty] = useAddUpdateAuditedItemsQtyMutation();

  const handleUpdateAuditQty = async (event, prod_id, section, qty,) => {
    event.preventDefault();
    let allData = {
      userId: account_detailed.user.id,
      section: section,
      auditQty: qty,
      itemId: prod_id,
      auditId: auditId,
    };

    try {
      const sea = await updateQty(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // refetch();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetchTable();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  else if (error) {
    return <div>Error loading data</div>;
  }
  else if (data.length === 0) {
    return <div>No data found.</div>;
  }
  else if (isSuccess){

    item_result = data.data;

  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left mt-3">
        <thead>
          <tr>
            {ITEM_TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
           {item_result.map((items, index) => {
                    const item = auditQtys.find(auditItem => auditItem?.item?.id === items.item.id);
                    let prevQty = item ? item.audited_qty : "No Data";
                    let resultQty = item ? item.result_qty : 0;
                    let currentSOH = item ? item.current_soh : 0;

                    if(prevQty=="No Data"){
                      noDataCountNum += 1
                    }
                    else{
                      noDataCountNum += 0
                    }
                    noDataCount(noDataCountNum);

                    console.log(noDataCountNum)
                    // console.log(item_result)
            return (
               <tr key={index}>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {items.item.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {items.item.weight_box} /   {items.item.unit_measurement}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {currentSOH || 0}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {prevQty || "No Data"}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 {/* <td key={items.item_id}>
                      <div className="flex items-center">
                        <div className="flex flex-col flex-grow">
                          <input
                            type="number"
                            value={productActualQty[index] || ""}
                            onBlur={(e) => handleUpdateAuditQty(e, items.item.id, 1, e.target.value)}
                            onInput={(e) => handleOrderChange(index, e.target.value)}
                            className="w-20 border border-1 border-blue-gray-300 p-1"
                          />
                        </div>
                      </div>
                    </td> */}
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color={resultQty > 0 ? "red" :   "green"}
                          className="font-normal"
                          component={'span'}
                        >
                          {(resultQty ?? 0) > 0
                            ? `- ${Math.abs(resultQty) ?? 0}`
                            : `+ ${Math.abs(resultQty) ?? 0}`}
                        </Typography>
                      </div>
                    </div>
                  </td>

               </tr>
             );
            })}
          </tbody>
      </table>
    </Card>
  );
}

}
export {
    FoodsDutyTbl,
    BeveragesTbl,
    ConsumablesTbl,
    RawmaterialsTbl,
    RepackingTbl,
    PlasticTbl,
    ItemConsumablesTbl,
};
