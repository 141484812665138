import { useState, useEffect } from "react";
import { Card, Typography } from "@material-tailwind/react";
import { useFetchDeliveryProductsQuery } from "../../../store";
import { useFetchDeliveryBeveragesQuery } from "../../../store";
import { useFetchDeliveryBunsQuery } from "../../../store";
import { useFetchDeliverySundriesQuery } from "../../../store";
import { useFetchDeliveryWrappersQuery } from "../../../store";
import { useFetchDeliveryTankQuery } from "../../../store";
import { useFetchDeliveryOSQuery } from "../../../store";
import { useFetchDeliveryCigarettesQuery } from "../../../store";
const TABLE_HEAD = [
  "Code",
  "Name",
  "Daily AVG. Cons.",
  "Low",
  "High",
  "New AVG. Cons",
  "Unit Measure",
];
let product_result = [];
let beverages_result = [];
let buns_result = [];
let wrappers_result = [];
let os_result = [];
let tank_result = [];
let sundries_result = [];
let cigarettes_result = [];
const classes = "p-4 border-b border-blue-gray-50";

// const [bunsActualQty, setBunsActualQty] = useState([]);
// const [beveragesActualQty, setBeveragesActualQty] = useState([]);

function ProductTbl({ onOrderChange, slData }) {
  const { data, error, isLoading, isSuccess } = useFetchDeliveryProductsQuery();
  let [productActualQty, setProductActualQty] = useState([]);

  // useEffect(() => {
  //   if (isSuccess && data) {
  //     setProductActualQty(new Array(data.data.length).fill(0));
  //   }
  // }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : value > 9999.99 ? 9999.99 : value;
    value = parseFloat(value).toFixed(2);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: product_result[index].id,
      prodQty: qty,
    }));
    product_data = product_data.filter(
      (item) => item.prodQty !== undefined && item.prodQty !== 0
    );
    console.log(product_data);

    onOrderChange(product_data);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    product_result = data.data;

    return (
      <Card className="h-full w-full overflow-scroll">
        <table className="w-full min-w-max table-auto text-left ">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {product_result.map((product, index) => {
              const item = slData.find(
                (item) => item.product.id === product.id
              );
              // Extract the stock level if the item exists
              const stockLevel = item ? item.stock_level : "No Data";
              const stockLevelNum = item ? item.stock_level : 0;

              return (
                <tr key={index}>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {product.product_code}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {product.name
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {stockLevel}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {(stockLevelNum * 3).toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {(stockLevelNum * 4).toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <input
                          type="number"
                          defaultValue={productActualQty[index] || ""}
                          onInput={(e) =>
                            handleOrderChange(index, e.target.value)
                          }
                          className="w-40 border border-1 border-blue-gray-300 p-0"
                        />
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {product.weight_box}/ {product.unit_measurement}
                        </Typography>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    );
  }
}
function BeveragesTbl({ onOrderChange, slData }) {
  const { data, error, isLoading, isSuccess } =
    useFetchDeliveryBeveragesQuery();
  let [productActualQty, setProductActualQty] = useState([]);
  // useEffect(() => {
  //   if (isSuccess && data) {

  //     // Initialize productActualQty with 0 for each product
  //     setProductActualQty(new Array(data.data.length).fill(0));
  //   }
  // }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : value > 9999.99 ? 9999.99 : value;
    value = parseFloat(value).toFixed(2);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: beverages_result[index].id,
      prodQty: qty,
    }));
    product_data = product_data.filter(
      (item) => item.prodQty !== undefined && item.prodQty !== 0
    );
    console.log(product_data);

    onOrderChange(product_data);
  };
  if (isLoading) {
    // console.log('Data:', data);

    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    beverages_result = data.data;
    return (
      <Card className="h-full w-full overflow-scroll">
        <table className="w-full min-w-max table-auto text-left ">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {beverages_result.map((beverages, index) => {
              const item = slData.find(
                (item) => item.product.id === beverages.id
              );
              // Extract the stock level if the item exists
              const stockLevel = item ? item.stock_level : "No Data";
              const stockLevelNum = item ? item.stock_level : 0;

              return (
                <tr key={index}>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {beverages.product_code}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {beverages.name
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {stockLevel}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {(stockLevelNum * 3).toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {(stockLevelNum * 4).toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <input
                          type="number"
                          defaultValue={productActualQty[index] || ""}
                          onInput={(e) =>
                            handleOrderChange(index, e.target.value)
                          }
                          className="w-40 border border-1 border-blue-gray-300 p-0"
                        />
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {beverages.weight_box}/ {beverages.unit_measurement}
                        </Typography>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    );
  }
}
function BunsTbl({ onOrderChange, slData }) {
  const { data, error, isLoading, isSuccess } = useFetchDeliveryBunsQuery();
  let [productActualQty, setProductActualQty] = useState([]);
  useEffect(() => {
    if (isSuccess && data) {
      setProductActualQty(new Array(data.data.length).fill(0));
    }
  }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : value > 9999.99 ? 9999.99 : value;
    value = parseFloat(value).toFixed(2);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: buns_result[index].id,
      prodQty: qty,
    }));
    product_data = product_data.filter(
      (item) => item.prodQty !== undefined && item.prodQty !== 0
    );
    console.log(product_data);

    onOrderChange(product_data);
  };
  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    buns_result = data.data;
    return (
      <Card className="h-full w-full overflow-scroll">
        <table className="w-full min-w-max table-auto text-left ">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {buns_result.map((buns, index) => {
              const item = slData.find((item) => item.product.id === buns.id);
              // Extract the stock level if the item exists
              const stockLevel = item ? item.stock_level : "No Data";
              const stockLevelNum = item ? item.stock_level : 0;
              return (
                <tr key={index}>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {buns.product_code}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {buns.name
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {stockLevel}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {(stockLevelNum * 3).toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {(stockLevelNum * 4).toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <input
                          type="number"
                          defaultValue={productActualQty[index] || ""}
                          onInput={(e) =>
                            handleOrderChange(index, e.target.value)
                          }
                          className="w-40 border border-1 border-blue-gray-300 p-0"
                        />
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {buns.weight_box}/ {buns.unit_measurement}
                        </Typography>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    );
  }
}
function WrappersTbl({ onOrderChange, slData }) {
  const { data, error, isLoading, isSuccess } = useFetchDeliveryWrappersQuery();
  let [productActualQty, setProductActualQty] = useState([]);
  useEffect(() => {
    if (isSuccess && data) {
      setProductActualQty(new Array(data.data.length).fill(0));
    }
  }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : value > 9999.99 ? 9999.99 : value;
    value = parseFloat(value).toFixed(2);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: wrappers_result[index].id,
      prodQty: qty,
    }));
    product_data = product_data.filter(
      (item) => item.prodQty !== undefined && item.prodQty !== 0
    );
    console.log(product_data);

    onOrderChange(product_data);
  };
  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    wrappers_result = data.data;
    return (
      <Card className="h-full w-full overflow-scroll">
        <table className="w-full min-w-max table-auto text-left ">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {wrappers_result.map((wrappers, index) => {
              const item = slData.find(
                (item) => item.product.id === wrappers.id
              );
              // Extract the stock level if the item exists
              const stockLevel = item ? item.stock_level : "No Data";
              const stockLevelNum = item ? item.stock_level : 0;
              return (
                <tr key={index}>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {wrappers.product_code}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {wrappers.name
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {stockLevel}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {(stockLevelNum * 3).toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {(stockLevelNum * 4).toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <input
                          type="number"
                          defaultValue={productActualQty[index] || ""}
                          onInput={(e) =>
                            handleOrderChange(index, e.target.value)
                          }
                          className="w-40 border border-1 border-blue-gray-300 p-0"
                        />
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {wrappers.weight_box}/ {wrappers.unit_measurement}
                        </Typography>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    );
  }
}
function SundriesTbl({ onOrderChange, slData }) {
  const { data, error, isLoading, isSuccess } = useFetchDeliverySundriesQuery();
  let [productActualQty, setProductActualQty] = useState([]);
  useEffect(() => {
    if (isSuccess && data) {
      setProductActualQty(new Array(data.data.length).fill(0));
    }
  }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : value > 9999.99 ? 9999.99 : value;
    value = parseFloat(value).toFixed(2);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: sundries_result[index].id,
      prodQty: qty,
    }));
    product_data = product_data.filter(
      (item) => item.prodQty !== undefined && item.prodQty !== 0
    );
    console.log(product_data);

    onOrderChange(product_data);
  };
  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    sundries_result = data.data;
    return (
      <Card className="h-full w-full overflow-scroll">
        <table className="w-full min-w-max table-auto text-left ">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {sundries_result.map((sundries, index) => {
              const item = slData.find(
                (item) => item.product.id === sundries.id
              );
              // Extract the stock level if the item exists
              const stockLevel = item ? item.stock_level : "No Data";
              const stockLevelNum = item ? item.stock_level : 0;
              return (
                <tr key={index}>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {sundries.product_code}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {sundries.name
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {stockLevel}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {(stockLevelNum * 3).toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {(stockLevelNum * 4).toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <input
                          type="number"
                          defaultValue={productActualQty[index] || ""}
                          onInput={(e) =>
                            handleOrderChange(index, e.target.value)
                          }
                          className="w-40 border border-1 border-blue-gray-300 p-0"
                        />
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {sundries.weight_box}/ {sundries.unit_measurement}
                        </Typography>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    );
  }
}
function CigaretteTbl({ onOrderChange, slData }) {
  const { data, error, isLoading, isSuccess } =
    useFetchDeliveryCigarettesQuery();
  let [productActualQty, setProductActualQty] = useState([]);
  useEffect(() => {
    if (isSuccess && data) {
      setProductActualQty(new Array(data.data.length).fill(0));
    }
  }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : value > 9999.99 ? 9999.99 : value;
    value = parseFloat(value).toFixed(2);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: cigarettes_result[index].id,
      prodQty: qty,
    }));
    product_data = product_data.filter(
      (item) => item.prodQty !== undefined && item.prodQty !== 0
    );
    console.log(product_data);

    onOrderChange(product_data);
  };
  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    cigarettes_result = data.data;
    return (
      <Card className="h-full w-full overflow-scroll">
        <table className="w-full min-w-max table-auto text-left ">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {cigarettes_result.map((cigarettes, index) => {
              const item = slData.find(
                (item) => item.product.id === cigarettes.id
              );
              // Extract the stock level if the item exists
              const stockLevel = item ? item.stock_level : "No Data";
              const stockLevelNum = item ? item.stock_level : 0;
              return (
                <tr key={index}>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {cigarettes.product_code}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {cigarettes.name
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {stockLevel}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {(stockLevelNum * 3).toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {(stockLevelNum * 4).toFixed(2)}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <input
                          type="number"
                          defaultValue={productActualQty[index] || ""}
                          onInput={(e) =>
                            handleOrderChange(index, e.target.value)
                          }
                          className="w-40 border border-1 border-blue-gray-300 p-0"
                        />
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {cigarettes.weight_box}/ {cigarettes.unit_measurement}
                        </Typography>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    );
  }
}

function OSTbl({ onOrderChange, slData }) {
  const { data, error, isLoading, isSuccess } = useFetchDeliveryOSQuery();
  let [productActualQty, setProductActualQty] = useState([]);
  useEffect(() => {
    if (isSuccess && data) {
      setProductActualQty(new Array(data.data.length).fill(0));
    }
  }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : value > 9999.99 ? 9999.99 : value;
    value = parseFloat(value).toFixed(2);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: os_result[index].id,
      prodQty: qty,
    }));
    product_data = product_data.filter(
      (item) => item.prodQty !== undefined && item.prodQty !== 0
    );
    console.log(product_data);

    onOrderChange(product_data);
  };
  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    os_result = data.data;
    return (
      <Card className="h-full w-full overflow-scroll">
        <table className="w-full min-w-max table-auto text-left ">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {os_result.map((os, index) => {
              const item = slData.find((item) => item.product.id === os.id);
              // Extract the stock level if the item exists
              const stockLevel = item ? item.stock_level : "No Data";
              const stockLevelNum = item ? item.stock_level : 0;
              return (
                <tr key={index}>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {os.product_code}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {os.name
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {stockLevel}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {stockLevelNum}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {stockLevelNum}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <input
                          type="number"
                          defaultValue={productActualQty[index] || ""}
                          onInput={(e) =>
                            handleOrderChange(index, e.target.value)
                          }
                          className="w-40 border border-1 border-blue-gray-300 p-0"
                        />
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {os.weight_box}/ {os.unit_measurement}
                        </Typography>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    );
  }
}
function TankTbl({ onOrderChange, slData }) {
  const { data, error, isLoading, isSuccess } = useFetchDeliveryTankQuery();
  let [productActualQty, setProductActualQty] = useState([]);
  useEffect(() => {
    if (isSuccess && data) {
      setProductActualQty(new Array(data.data.length).fill(0));
    }
  }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : value > 9999.99 ? 9999.99 : value;
    value = parseFloat(value).toFixed(2);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: tank_result[index].id,
      prodQty: qty,
    }));
    product_data = product_data.filter(
      (item) => item.prodQty !== undefined && item.prodQty !== 0
    );
    console.log(product_data);

    onOrderChange(product_data);
  };
  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    tank_result = data.data;
    return (
      <Card className="h-full w-full overflow-scroll">
        <table className="w-full min-w-max table-auto text-left ">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tank_result.map((tank, index) => {
              const item = slData.find((item) => item.product.id === tank.id);
              // Extract the stock level if the item exists
              const stockLevel = item ? item.stock_level : "No Data";
              const stockLevelNum = item ? item.stock_level : 0;
              return (
                <tr key={index}>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {tank.product_code}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {tank.name
                            .split(" ")
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            )
                            .join(" ")}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {stockLevel}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {stockLevelNum}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {stockLevelNum}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <input
                          type="number"
                          defaultValue={productActualQty[index] || ""}
                          onInput={(e) =>
                            handleOrderChange(index, e.target.value)
                          }
                          className="w-40 border border-1 border-blue-gray-300 p-0"
                        />
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal"
                          component={"span"}
                        >
                          {tank.weight_box}/ {tank.unit_measurement}
                        </Typography>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Card>
    );
  }
}

export {
  ProductTbl,
  BeveragesTbl,
  BunsTbl,
  WrappersTbl,
  SundriesTbl,
  CigaretteTbl,
  OSTbl,
  TankTbl,
};
