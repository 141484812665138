import React from "react";
import Img from "./../../img/welcomeImg.png";
import { Typography } from "@material-tailwind/react";

const Home = () => {
  return (
    <>
      <div>
        <div className=" col-span-5 block">
          <img
            src={Img}
            alt="welcome"
            className=" object-contain aspect-auto w-72 h-72 mx-auto lg:ml-[37%]  mt-16"
          />
          <Typography className="text-green-800 text-2xl md:text-4xl font-bold lg:ml-30 text-center">
            Integrated <span className=" text-black">Inventory System</span>
          </Typography>
          <Typography className="text-sm md:text-lg lg:text-base text-center lg:ml-[1rem]">
            Welcome to Burger Machine`s Integrated Inventory System.
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Home;
