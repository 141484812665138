import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { useAddNewOutletMutation } from './DeliveryApi';
const apiDomain = process.env.REACT_APP_API_DOMAIN
const token = localStorage.getItem('mytoken')

const inventoryApi = createApi({
    reducerPath: 'inventory',
    baseQuery: fetchBaseQuery({
      baseUrl: apiDomain
    }),
    endpoints(builder){
      return {
        fetchItemInventoryList: builder.query({
          query: ({
            page = 1,
            page_size = 10,
            order_by = "id",
            order_dir = "desc",
            search = "",
            location = 1,
          }) => {
            return {
              url: `/api/warehouse/inventory/inventory_item_list_page/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&search=${search}&location=${location}`,
              headers: { Authorization: "token " + token },
              method: "GET",
            };
          },
        }),
        fetchProductInventoryList: builder.query({
          query: ({
            page = 1,
            page_size = 10,
            order_by = "id",
            order_dir = "desc",
            search = "",
            category
          }) => {
            return {
              url: `/api/warehouse/inventory/inventory_product_list_page/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&search=${search}&category=${category}`,
              headers: { Authorization: "token " + token },
              method: "GET",
            };
          },
        }),
        fetchProductHistory: builder.query({
          query: ({
            page = 1,
            page_size = 10,
            order_by = "-id",
            order_dir = "desc",
            product = 1
          }) => {
            return {
              url: `/api/warehouse/inventory/product_history_list/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&product=${product}`,
              headers: { Authorization: "token " + token },
              method: "GET",
            };
          },
        }),
        fetchItemHistory: builder.query({
          query: ({
            page = 1,
            page_size = 10,
            order_by = "-id",
            order_dir = "desc",
            item
          }) => {
            return {
              url: `/api/warehouse/inventory/item_history_list/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&item=${item}`,
              headers: { Authorization: "token " + token },
              method: "GET",
            };
          },
        }),
        fetctDelRejectProductList: builder.query({
          query: ({
            page,
            page_size,
            order_by = "id",
            order_dir = "desc",
            search,
            category = 1,
            m_category
          }) => {
            return {
              url: `/api/warehouse/inventory/inventory_reject_delivery_list_page/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&search=${search}&category=${category}&m_category=${m_category}`,
              headers: { Authorization: "token " + token },
              method: "GET",
            };
          },
        }),
        fetchDeliveryRejHistory: builder.query({
          query: ({
            page,
            page_size,
            order_by = "-id",
            order_dir = "desc",
            product
          }) => {
            return {
              url: `/api/warehouse/inventory/reject_product_delivery_history_list/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&product=${product}`,
              headers: { Authorization: "token " + token },
              method: "GET",
            };
          },
        }),
        fetchAuditReportListPageQuery: builder.query({
          query: ({
            page = 1,
            page_size = 10,
            search="",

          }) => {
            return {
              url: `/api/delivery/delivery/audit_report_list_view/?page=${page}&page_size=${page_size}&search=${search}`,
              headers: { Authorization: "token " + token },
              method: "GET",
            };
          },
        }),
        addNewAudit: builder.mutation({
          query: (form) => {
              return {
                  url: `/api/delivery/delivery/create_audit_report/`,
                  headers: { "Authorization": "token " + token },
                  method: 'POST',
                  body: form
              }
          }
        }),
        fetchAuditFormProducts: builder.query({
          query: ({
            category,
          }) => {
            return {
              url: `/api/warehouse/inventory/audit_product_list_page/?category=${category}`,
              headers: { Authorization: "token " + token },
              method: "GET",
            };
          },
        }),
        fetchAuditedDataQty: builder.query({
          query: ({
            id,
          }) => {
            return {
              url: `/api/warehouse/inventory/audit_items_details/?id=${id}`,
              headers: { Authorization: "token " + token },
              method: "GET",
            };
          },
        }),
        addUpdateAuditedItemsQty: builder.mutation({
          query: (form) => {
              return {
                  url: `/api/warehouse/inventory/update_audit_items/`,
                  headers: { "Authorization": "token " + token },
                  method: 'PUT',
                  body: form
              }
          }
        }),
        fetchAuditFormItems: builder.query({
          query: ({
            location,
          }) => {
            return {
              url: `/api/warehouse/inventory/audit_item_list/?location=${location}`,
              headers: { Authorization: "token " + token },
              method: "GET",
            };
          },
        }),
        updatedAuditReportComplete: builder.mutation({
          query: (form) => {
              return {
                  url: `/api/warehouse/inventory/update_audit_signature/`,
                  headers: { "Authorization": "token " + token },
                  method: 'PUT',
                  body: form
              }
          }
        }),
        updatedAuditReportStatus: builder.mutation({
          query: (form) => {
              return {
                  url: `/api/warehouse/inventory/updated_audit_report/`,
                  headers: { "Authorization": "token " + token },
                  method: 'PUT',
                  body: form
              }
          }
        }),
        fetchItemRejectPage: builder.query({
          query: ({
            page,
            page_size,
            search,
            order_by,
            order_dir,
          }) => {
            return {
              url: `/api/warehouse/inventory/item_reject_list/?page=${page}&page_size=${page_size}&search=${search}&order_by=${order_by}`,
              headers: { Authorization: "token " + token },
              method: "GET",
            };
          },
        }),
        fetchProductRejectPage: builder.query({
          query: ({
            page,
            page_size,
            search,
            order_by,
            order_dir,
          }) => {
            return {
              url: `/api/warehouse/inventory/prod_reject_list/?page=${page}&page_size=${page_size}&search=${search}&order_by=${order_by}`,
              headers: { Authorization: "token " + token },
              method: "GET",
            };
          },
        }),
        fetchItemProdRejectHistory: builder.query({
          query: ({
            id,
            page,
            page_size,
            type
          }) => {
            return {
              url: `/api/warehouse/inventory/${id}/item_prod_reject_list/?page=${page}&page_size=${page_size}&type=${type}`,
              headers: { Authorization: "token " + token },
              method: "GET",
            };
          },
        }),
        saveMaterialInput: builder.mutation({
          query: (request) => {
            return {
              url: `/api/warehouse/inventory/save_material_input/`,
              headers: { Authorization: "token " + token },
              method: "POST",
              body: request,
            };
          },
        }),
      }
    }
});
export const {
useUpdatedAuditReportStatusMutation,
useFetchItemInventoryListQuery,
useFetchProductInventoryListQuery,
useFetchProductHistoryQuery,
useFetchItemHistoryQuery,
useFetctDelRejectProductListQuery,
useFetchDeliveryRejHistoryQuery,
useFetchAuditReportListPageQueryQuery,
useAddNewAuditMutation,
useFetchAuditFormProductsQuery,
useAddUpdateAuditedItemsQtyMutation,
useFetchAuditedDataQtyQuery,
useFetchAuditFormItemsQuery,
useUpdatedAuditReportCompleteMutation,
useFetchItemRejectPageQuery,
useFetchProductRejectPageQuery,
useFetchItemProdRejectHistoryQuery,
useSaveMaterialInputMutation,
} = inventoryApi;
export { inventoryApi }