import React, { useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { useFetchDelScheduleQuery } from "../../../store";
import EditCalendarModal from "./EditDelCalendar";
import moment from "moment";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
let sched_result = [];
const DelCalendar = () => {
  const [open, setOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment());

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const goToToday = () => setCurrentDate(moment());
  const goToNextMonth = () =>
    setCurrentDate(moment(currentDate).add(1, "month"));
  const goToPreviousMonth = () =>
    setCurrentDate(moment(currentDate).subtract(1, "month"));
  const {
    data: schedData,
    error,
    isLoading,
    isSuccess,
    refetch,
  } = useFetchDelScheduleQuery();

  const monthYearString = currentDate.format("MMMM YYYY");

  const CustomMonthView = () => {
    if (isLoading) {
      // console.log('Data:', data);
      return <div>Loading...</div>;
    } else if (error) {
      return <div>Error loading data</div>;
    } else if (schedData.length === 0) {
      return <div>No data found.</div>;
    } else if (isSuccess) {
      const firstDayOfMonth = moment(currentDate).startOf("month");
      const startingDayOfWeek = firstDayOfMonth.day();

      const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      const adjustedDayNames = [
        ...dayNames.slice(startingDayOfWeek),
        ...dayNames.slice(0, startingDayOfWeek),
      ];

      const start = moment(currentDate).startOf("month");
      const end = moment(currentDate).endOf("month");
      const days = [];
      let currentDay = moment(start);
      sched_result = schedData.data;

      const monthValue = currentDate.month() + 1;

      const filteredData = schedData.data.filter(
        (item) => item.month === monthValue
      );

      const weeklyData = {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      };

      filteredData.forEach((item) => {
        weeklyData.monday.push(...item.monday);
        weeklyData.tuesday.push(...item.tuesday);
        weeklyData.wednesday.push(...item.wednesday);
        weeklyData.thursday.push(...item.thursday);
        weeklyData.friday.push(...item.friday);
        weeklyData.saturday.push(...item.saturday);
        weeklyData.sunday.push(...item.sunday);
      });

      while (currentDay <= end) {
        days.push(moment(currentDay));
        currentDay = moment(currentDay).add(1, "day");
      }

      let currentWeek = [];
      const weeks = [];

      days.forEach((day) => {
        currentWeek.push(day);

        if (currentWeek.length === 7) {
          weeks.push(currentWeek);
          currentWeek = [];
        }
      });

      if (currentWeek.length > 0) {
        weeks.push(currentWeek);
      }

      const formattedWeeks = weeks.map((week, index) => {
        return (
          <div key={index} className="grid grid-cols-7 gap-2">
            {week.map((day, index) => {
              const dayOfWeek = day.format("dddd").toLowerCase();
              return (
                <div
                  key={index}
                  className={`bg-white text-center p-2 border-b border-gray-200 ${
                    day.isSame(moment(), "day") ? "bg-yellow-200" : ""
                  }`}
                >
                  <div className="text-sm">{day.format("D")}</div>
                  {/* Render routes for each specific day */}
                  <div className="text-xs text-gray-500">
                    {weeklyData[dayOfWeek].map((route, idx) => (
                      <div key={idx}>{route.name}</div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        );
      });

      return (
        <div className="bg-gray-100 p-5 rounded-sm">
          <div className="grid grid-cols-7 gap-2">
            {adjustedDayNames.map((day, index) => (
              <div
                key={index}
                className="bg-white text-center p-2 border-b border-gray-200"
              >
                <div className="text-sm">{day}</div>
              </div>
            ))}
          </div>
          {formattedWeeks}
        </div>
      );
    }
  };
  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]" size="sm">
        Delivery Schedule
      </Button>

      <Dialog
        size="xl"
        open={open}
        onClose={handleClose}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          <Typography
            className="text-green-900"
            variant="h3"
            component={"span"}
          >
            {monthYearString} Delivery Schedule
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <CustomMonthView />
        </DialogBody>
        <DialogFooter>
          <EditCalendarModal schedData={sched_result} refetchSched={refetch} />
          <Button variant="text" className="ml-10" onClick={goToToday}>
            <span>Today</span>
          </Button>
          <Button variant="text" className="mr-3" onClick={goToPreviousMonth}>
            <span>Back</span>
          </Button>
          <Button variant="text" className="mr-3" onClick={goToNextMonth}>
            <span>Next</span>
          </Button>

          <Button
            variant="text"
            color="red"
            className="mr-3 ml-5"
            onClick={handleClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default DelCalendar;
