import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Card,
} from "@material-tailwind/react";
import { ToastContainer, toast } from "react-toastify";
import { GrPowerReset } from "react-icons/gr";
import ReactSignatureCanvas from "react-signature-canvas";
import { useFetchTransferDetailsQuery } from "../../../../store";
import { useUpdateReceivedWithdrawalTransferMutation } from "../../../../store";
import { Confirmation } from "../../Error_trap_components/ErrorTrapComponent";

const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
const trapMessage = "Are you sure to Receive these return products?";
const user_info = JSON.parse(localStorage.getItem("user_info"));

const currentDate = new Date();
const transferDate = currentDate.toLocaleString(undefined, {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});
let result = [];
let result_item = [];

function ReturnWithdrawalModal({
  requestId,
  delData,
  refetchMainTable,
  types,
  section,
}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = "p-4 border-b border-blue-gray-50";
  const inputClasses = "flex flex-row items-center";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  let TABLE_HEAD = [
    "Item Code",
    "Description",
    "UM",
    "Requested Quantity",
    "Issued Quantity",
    "Rejected Quantity",
    "Missing Quantity",
  ];

  if (types === 3) {
    TABLE_HEAD = [
      "Item Code",
      "Description",
      "UM",
      "Turnover Quantity in Pcs",
      "Actual Received Quantity in Pcs",
      // "Rejected Quantity in Pcs",
      "Variance Quantity in Pcs",
    ];
  }

  const { data, isError, isLoading, isSuccess } =
    useFetchTransferDetailsQuery(requestId);

  const [isReadyToSave, setIsReadyToSave] = useState(false);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState([true, true]);

  const [signatures, setSignatures] = useState(["", ""]);
  const signatureRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  let [actualQtys, setActualQtys] = useState([]);
  let [rejectQty, setRejectQty] = useState([]);

  useEffect(() => {
    if (isSuccess) {
      // Initialize actualQtys after data fetching is successful
      setActualQtys(
        data.data.items.map((result_item) => result_item.requested_qty || 0)
      );
    }
  }, [isSuccess, data]);

  const [hasMismatch, setHasMismatch] = useState(false);

  // useEffect(() => {
  //   // Check for any mismatches among all rows
  //   const mismatch = actualQtys.some((qty, index) => qty !== (result_item[index].requested_qty || 0));
  //   // Update the state based on the presence of a mismatch
  //   setHasMismatch(mismatch);
  // }, [actualQtys, result_item]);

  //   useEffect(() => {
  //     // Check if any value in actualQtys is greater than ordered_qty
  //     const adjustedQtys = actualQtys.map((qty, index) => Math.min(qty, result_item[index].requested_qty));
  //     // Check if adjustedQtys is different from actualQtys before updating
  //     if (!adjustedQtys.every((qty, index) => qty === actualQtys[index])) {
  //       setActualQtys(adjustedQtys);
  //     }
  //   }, [actualQtys, result_item]);

  const handleActualQtyChange = (e, index) => {
    const inputValue = parseInt(e.target.value, 10);
    const requestedQty = data.data.items[index].requested_qty || 0;
    const currentReturnedQty = rejectQty[index] || 0;
    let newActualQty = inputValue;

    // Ensure inputValue is within the range of 0 to requestedQty - currentReturnedQty
    newActualQty = Math.min(
      Math.max(newActualQty, 0),
      requestedQty - currentReturnedQty
    );

    setActualQtys((prevActualQtys) => {
      const newQtys = [...prevActualQtys];
      newQtys[index] = newActualQty;
      return newQtys;
    });
  };

  const handleRejectQty = (e, index) => {
    const inputValue = parseInt(e.target.value, 10);
    const MaxQty = data.data.items[index].requested_qty;

    // Ensure inputValue is within the range of 0 to MaxQty
    const newRejectQty = Math.min(Math.max(inputValue, 0), MaxQty);

    // Calculate the new actual quantity based on the total quantity
    const totalQty = actualQtys[index] + newRejectQty;
    const newActualQty = MaxQty - newRejectQty;

    setRejectQty((prevQtys) => {
      const newQtys = [...prevQtys];
      newQtys[index] = newRejectQty;
      return newQtys;
    });

    setActualQtys((prevQtys) => {
      const newQtys = [...prevQtys];
      newQtys[index] = newActualQty;
      return newQtys;
    });

    console.log(delData);
  };

  const logo = JSON.parse(localStorage.getItem("account_detail"));
  const burgerMachineLogo = "../images/BurgerMachineLogo.jpg";
  // const burgerMachineLogo =
  //   process.env.REACT_APP_API_DOMAIN + "" + logo.client.logo;
  const user_info = JSON.parse(localStorage.getItem("user_info"));

  const handleReset = (index) => {
    if (signatureRefs[index].current) {
      signatureRefs[index].current.clear();
      setSignatures((prevSignatures) => {
        const updatedSignatures = [...prevSignatures];
        updatedSignatures[index] = "";
        return updatedSignatures;
      });

      setIsSignatureEmpty((prevIsSignatureEmpty) => {
        const updatedIsSignatureEmpty = [...prevIsSignatureEmpty];
        updatedIsSignatureEmpty[index] = true;
        return updatedIsSignatureEmpty;
      });
    }
  };

  const handleSignatureChange = (index) => {
    const isEmpty = signatureRefs[index].current.isEmpty();
    setIsSignatureEmpty((prev) => {
      const updated = [...prev];
      updated[index] = isEmpty;
      return updated;
    });
    const base64Signature = signatureRefs[index].current.toDataURL();
    setSignatures((prevSignatures) => {
      const updatedSignatures = [...prevSignatures];
      updatedSignatures[index] = base64Signature;
      return updatedSignatures;
    });
  };
  const [updateToReceived] = useUpdateReceivedWithdrawalTransferMutation();
  const handleSaveSignatures = async () => {
    let base64DataIssuer = signatures[0].slice("data:image/png;base64,".length);
    let base64DataReceiver = signatures[1].slice(
      "data:image/png;base64,".length
    );

    let allData = {
      transfer_id: requestId,
      issuer_id: parseInt(user_info.id),
      issuedQtyArray: actualQtys.map((qty, index) => ({
        itemId: data.data.items[index].transfer_item_id,
        issuedQty: qty,
        rejQty: rejectQty[index] || 0,
      })),
      signatureIssuer: base64DataIssuer,
      signatureReceiver: base64DataReceiver,
      types: types,
      // checked_by: selectedCageChecker,
      section: section,
    };
    console.log(allData);
    try {
      const sea = await updateToReceived(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
      setRejectQty([]);
      refetchMainTable();
      handleMainClose();
    } catch (error) {
      // Handle the error here
      // You can display a generic error message to the user if needed
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    // Check if all conditions are met
    const isReady = signatures[0] !== "" && signatures[1] !== "";
    // Update the state based on the condition
    setIsReadyToSave(isReady);
  }, [signatures[0], signatures[1]]);

  const handleMainClose = () => {
    setOpen(false);
    setSignatures(["", ""]);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError) {
    return <div>Error loading data</div>;
  } else if (isSuccess) {
    result = data.data;
    result_item = data.data.items;
    // console.log(account_detailed)
    const formattedDate = result.transfer_request_date
      ? new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(new Date(result.transfer_request_date))
      : "";

    return (
      <>
        <Button onClick={handleOpen} className="bg-[#1AA55D]">
          View
        </Button>

        <Dialog
          size="xl"
          open={open}
          handler={handleOpen}
          className="relative max-h-min"
        >
          <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
            <img
              src={burgerMachineLogo}
              className="aspect-auto w-72"
              alt="logo"
            />
            <Typography
              className="text-green-900"
              variant="h3"
              component={"span"}
            >
              {types === 2 && "Return Transfer Slip"}
              {types === 3 && "Withdrawal Transfer Slip"}
            </Typography>
          </DialogHeader>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
            <form>
              <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto">
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Issuance From"
                      name="trans_to"
                      value={
                        types === 1 || types === 2
                          ? `Delivery Cage #${delData.cage_number}`
                          : ""
                      }
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Transfer To"
                      name="issuance_from"
                      value={
                        section === 1
                          ? "Warehouse Foods Duty"
                          : section === 2
                          ? "Warehouse Beverages"
                          : section === 3
                          ? "Warehouse Consumables"
                          : "Unknown Section"
                      }
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Requested Date"
                      name="requested_at"
                      value={`${formattedDate}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Transfer Request Date"
                      name="issue_date"
                      value={`${transferDate}`}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Name of Issuer"
                      name="name_issuer"
                      value={`${account_detailed.user.first_name || "First"}  ${
                        account_detailed.user.last_name || "Last"
                      }`}
                      readOnly
                    />
                  </div>
                  {/* <div className={inputClasses}>
                    <Input
                        label="Name of Receiver"
                        name="name_receiver"
                        onChange={(e) => setReceiverName(e.target.value)}
                      />
                    </div> */}
                  <div className={inputClasses}>
                    <Input
                      label="Name of Requester"
                      name="name_requester"
                      value={`${result.requested_by_first_name || "First"} ${
                        result.requested_by_middle_name || "M."
                      } ${result.requested_by_last_name || "Last"}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Tracking Number"
                      name="ttn"
                      value={`${result.transfer_tracking_number}`}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="m-5">
                <Card
                  className="h-full w-full overflow-scroll"
                  component={"span"}
                >
                  <table className="w-full min-w-max table-auto text-left ">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head) => (
                          <th
                            key={head}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                            <Typography
                              component={"span"}
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {result.items.map((item, index) => {
                        let difference =
                          item.requested_qty -
                          (actualQtys[index] || 0) -
                          (rejectQty[index] || 0);
                        return (
                          <tr key={index}>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.prod_code}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.prod_description || "N/A"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.weight_box || item.weight_box} /
                                    {item.unit_measurement ||
                                      item.product_unit_measurement}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td
                              className={classes}
                              style={{ textAlign: "center" }}
                            >
                              <div className="flex items-center justify-center">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.requested_qty}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <input
                                    type="number"
                                    value={actualQtys[index] || ""}
                                    onChange={(e) =>
                                      handleActualQtyChange(e, index)
                                    }
                                    style={{
                                      width: "80px",
                                      border: "1px solid #ccc",
                                      padding: "5px",
                                    }}
                                  />
                                </div>
                              </div>
                            </td>
                            {types !== 3 && (
                              <td className={classes}>
                                <div className="flex items-center gap-3">
                                  <div className="flex flex-col">
                                    <input
                                      type="number"
                                      value={rejectQty[index] || ""}
                                      onChange={(e) =>
                                        handleRejectQty(e, index)
                                      }
                                      style={{
                                        width: "80px",
                                        border: "1px solid #ccc",
                                        padding: "5px",
                                      }}
                                    />
                                  </div>
                                </div>
                              </td>
                            )}
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  {item.requested_qty &&
                                    actualQtys[index] !== undefined && (
                                      <Typography
                                        variant="small"
                                        color={
                                          difference === 0 ? "green" : "red"
                                        }
                                        className="font-normal"
                                        component={"span"}
                                      >
                                        {difference === 0
                                          ? "Complete"
                                          : `Missing (${difference})`}
                                      </Typography>
                                    )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
                <div className="m-5"></div>
                <Typography className="text-center">
                  - - - - - Nothing Follows - - - - -{" "}
                </Typography>
              </div>
              {/* <div className="w-[50%] mt-5 space-y-2">
                {" "}
                <Input
                  // value={input.deliverydate}
                  // onChange={handleChange}
                  label="Total Requested Qty"
                  name="totalrequestedqty"
                />
              </div> */}

              <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto ml-8">
                <div className="col-span-2 space-y-5">
                  <div className={signatureBoxclasses}>
                    <ReactSignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 100,
                        className: "border-black border-2 rounded-s-xl",
                      }}
                      ref={signatureRefs[0]}
                      onEnd={() => handleSignatureChange(0)}
                    />
                    <div className={boxResetClasses}>
                      <GrPowerReset
                        size={"30px"}
                        onClick={() => handleReset(0)}
                        className="cursor-pointer"
                        color="white"
                      />
                    </div>
                  </div>
                  <Typography
                    className="text-center text-black"
                    variant="h6"
                    component="span"
                  >
                    Checker
                  </Typography>
                  <>&nbsp;</>
                  {isSignatureEmpty[0] && (
                    <Typography variant="lead" color="red">
                      Please provide a signature.
                    </Typography>
                  )}
                </div>
                <div className="col-span-2 space-y-5">
                  <div className={signatureBoxclasses}>
                    <ReactSignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 100,
                        className: "border-black border-2 rounded-s-xl",
                      }}
                      ref={signatureRefs[1]}
                      onEnd={() => handleSignatureChange(1)}
                    />
                    <div className={boxResetClasses}>
                      <GrPowerReset
                        size={"30px"}
                        onClick={() => handleReset(1)}
                        className="cursor-pointer"
                        color="white"
                      />
                    </div>
                  </div>
                  <Typography
                    className="text-center text-black m"
                    variant="h6"
                    component={"span"}
                  >
                    {account_detailed.user.first_name || "Receiver"}{" "}
                    {account_detailed.user.last_name || ""}
                  </Typography>
                  <>&nbsp;</>
                  {isSignatureEmpty[1] && (
                    <Typography variant="lead" color="red">
                      Please provide a signature.
                    </Typography>
                  )}
                </div>
              </div>
            </form>
          </DialogBody>
          <ToastContainer />
          <DialogFooter>
            <Confirmation
              onConfirm={handleSaveSignatures}
              trapMessage={trapMessage}
              isReadyToSave={isReadyToSave}
            />
            <Button
              variant="text"
              color="red"
              className="mr-3"
              onClick={handleMainClose}
            >
              <span>Cancel</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

export default ReturnWithdrawalModal;
