import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");

const vendorItemApi = createApi({
  reducerPath: "vendorItem",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  endpoints(builder) {
    return {
      fetchItem: builder.query({
        query: () => {
          return {
            url: "/api/warehouse/vendor/item_list_page/?page=1&page_size=50",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchVendorListPage: builder.query({
        query: ({
          page = 1,
          page_size = 10,
          order_by = "id",
          order_dir = "asc",
          search_str,
          location = 1,
          client_id,
        }) => {
          return {
            url: `/api/warehouse/vendor/item_list_page/?page=${page}&client_id=${client_id}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&location=${location}&search_str=${search_str}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchVendorListSearchPage: builder.query({
        query: ({
          page = 1,
          page_size = 10,
          order_by = "id",
          order_dir = "asc",
          search_str,
          client_id
        }) => {
          return {
            url: `/api/warehouse/vendor/item_list_page/?page=${page}&client_id=${client_id}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&search_str=${search_str}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      addVendorItem: builder.mutation({
        query: (ItemInput) => {
          return {
            url: "/api/warehouse/vendor/add_item/",
            headers: {
              Authorization: "token " + token,
              "Content-Type": "application/json",
              Accept: "*/*",
            },
            method: "POST",
            body: ItemInput,
          };
        },
      }),
      rawMatListPage: builder.query({
        query: ({
          page,
          page_size,
          order_by = "id",
          order_dir = "asc",
          status
        }) => {
          return {
            url: `/api/warehouse/request/raw_mat_list_page/?status=${status}&page=${page}&page_size=${page_size}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      rawMatDetailedPage: builder.query({
        query: (
          raw_id
        ) => {
          return {
            url: `/api/warehouse/request/${raw_id}/raw_mat_detailed/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
    };
  },
});

export const {
  useFetchItemQuery,
  useFetchVendorListPageQuery,
  useAddVendorItemMutation,
  useFetchVendorListSearchPageQuery,
  useRawMatListPageQuery,
  useRawMatDetailedPageQuery,
} = vendorItemApi;
export { vendorItemApi };
