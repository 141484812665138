import POStatusesWidget from "./POStatusesWidget";
import POTable from "./POTable";
import { useDashBoardPOQuery } from "../../../store";
import PurchaseBarChart from "./PurchaseBarChart";


export function POPageDash() {
	const account_detailed = JSON.parse(
		localStorage.getItem(
			"account_detail"
		)
	);

	const dash_board_po = useDashBoardPOQuery({
		client: account_detailed.client.id
	})

  let content;
  if (dash_board_po.isLoading) {
    return <div>Loading....</div>;
  } else if (dash_board_po.error) {
    return <div>Refresh the page....</div>;
  } else {
		content = <>
			<div className="block space-y-3 md:space-y-0 md:flex gap-4 mx-2">
				{
				<PurchaseBarChart approve_status={
					dash_board_po.data?.data.approve
				}/>
				}
				<POStatusesWidget po_status={
					dash_board_po.data?.data.vrr
				}
				po_working={
					dash_board_po.data?.data.vrr_working
				}
				/>
			</div>
			<div className="flex flex-col gap-4">
				<POTable/>
			</div>
		</>
	}

  return (
    <>
		<div className="mb-20 lg:mb-5 mt-3 md:mt-0">
			{content}
		</div>
    </>
  );
}

export default POPageDash;
