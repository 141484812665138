import { Card, Typography, Button, CardFooter } from "@material-tailwind/react";
import { useFetchForDelOrderNumberListPageQuery } from "../../../store";
import React, { useState } from "react";
import moment from 'moment';
import { FaTrash } from 'react-icons/fa';

const TABLE_HEAD = ["OUTLET", "ORDER #", "FC", "ROUTE", "Order Date", "Delivery Date", ""];
const classes = "p-4 border-b border-blue-gray-50";

const AddDelTbl = ({ selectedOrderNumbers, removeOrderNumber  }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const {
    data: orderNumberData,
    error: orderNumberError,
    isLoading: orderNumberIsLoading,
    isSuccess: orderNumberIsSuccess,
  } = useFetchForDelOrderNumberListPageQuery({
    page: currentPage,
    page_size: pageSize,
    search: "",
    order_number: selectedOrderNumbers,
  });


  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  let content;
  if (orderNumberIsLoading) {
    content = <div>Loading...</div>;
  } else if (orderNumberError) {
    content = <div>Error loading data</div>;
  } else if (!orderNumberData || !orderNumberData.results || orderNumberData.results.length === 0) {
    content = <div>No data found.</div>;
  } else if (orderNumberIsSuccess) {
    const for_delivery_order_number_result = orderNumberData.results;
    content = for_delivery_order_number_result.map((order_number, index) => {
      const isLast = index === for_delivery_order_number_result.length - 1;
      const classes = isLast
        ? "p-4"
        : "p-4 border-b border-blue-gray-50";

      return (
        <tr key={order_number.id}>
          <td className={classes}>
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {order_number.outlet?.outlet ?? "N/A"}
                </Typography>
              </div>
            </div>
          </td>
          <td className={classes}>
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {order_number.order_number ?? "N/A"}
                </Typography>
              </div>
            </div>
          </td>
          <td className={classes}>
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {order_number.outlet?.fc.name ?? "N/A"}
                </Typography>
              </div>
            </div>
          </td>
          <td className={classes}>
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {order_number.outlet?.route.name ?? "N/A"}
                </Typography>
              </div>
            </div>
          </td>
          <td className={classes}>
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {order_number?.order_date ? moment(order_number.order_date).format('MMM DD YYYY') : "N/A"}
                </Typography>
              </div>
            </div>
          </td>
          <td className={classes}>
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                  {order_number?.expected_delivery_date ? moment(order_number.expected_delivery_date).format('MMM DD YYYY') : "N/A"}
                </Typography>
              </div>
            </div>
            </td>
            <td className={classes}>
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal"
                >
                   <Button
                     style={{
                       marginLeft: '8px',
                       backgroundColor: 'green',
                       padding: '10px',
                       cursor: 'pointer',
                     }}
                     onClick={() => removeOrderNumber(order_number.order_number)}

                   >
                     <FaTrash style={{ color: 'white' }} />
                   </Button>
                </Typography>
              </div>
            </div>
          </td>
        </tr>

      );
    });
  }

  return (
    <>
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left ">
        <thead>
        <tr>
          {TABLE_HEAD.map((head) => (
            <th
              key={head}
              className="border-b border-green-800 bg-green-800 text-white p-4"
            >
              <Typography
                variant="small"
                className="font-normal leading-none"
              >
                {head}
              </Typography>
            </th>
          ))}
        </tr>
        </thead>
        <tbody>
          {content}
        </tbody>
      </table>
    </Card>
       <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
       <Typography variant="small" color="blue-gray" className="font-normal">
         Page {`${currentPage} of ${Math.ceil(orderNumberData?.count / pageSize) || 1}`}
       </Typography>
       <div className="flex gap-2">
         <Button variant="outlined" size="sm" onClick={handlePreviousPage} disabled={!orderNumberData?.previous}>
           Previous
         </Button>
         <Button
           variant="outlined"
           size="sm"
           onClick={handleNextPage}
           disabled={!orderNumberData?.next}
         >
           {orderNumberData?.next ? "Next" : "No More Pages"}
         </Button>
       </div>
     </CardFooter>
     </>
  );
};

export default AddDelTbl;
