import React, { useRef, useState, createRef } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Radio,
  Input,
} from "@material-tailwind/react";
import ReactSignatureCanvas from "react-signature-canvas";
import { GrPowerReset } from "react-icons/gr";
import { useApprovalAdjustedRequiredQtyMutation } from "../../../../store";
import { ToastContainer, toast } from "react-toastify";

export function AdjustedQuantityApprovalForm({
  request_item_id,
  item_name,
  ri_status,
  po_number,
  unit_measurement,
  data_refetch,
}) {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const signatureBoxclasses = "flex mb-1 mt-5";
  let formRef = useRef();
  const [open, setOpen] = useState(false);
  // const signatureRef = createRef();
  const [signature, setSignature] = useState("");
  const [adjusted_rqty, setAdjusted_rqty] = useState("");
  const [adjusted_rqty_remarks, setAdjusted_rqty_remarks] = useState("");

  const handleOpen = () => setOpen(!open);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);

  const handleSignatureReset = () => {
    // signatureRef.current.clear();
    setSignature(""); // Reset the stored base64 representation
    setIsSignatureEmpty(true);
    signature.clear();
  };

  const handleSignatureChange = (dataURLs) => {
    // Set the signature data in the state
    setSignature(dataURLs);
  };

  const [approval_required_qty] = useApprovalAdjustedRequiredQtyMutation();
  const sendAdjustedQuantity = async (e) => {
    e.preventDefault();
    let sig_split = signature.toDataURL().split(",");
    let formData = new FormData();

    formData.append("user_id", parseInt(user_info.id));
    formData.append("request_item_id", request_item_id);
    formData.append("quantity_adjustment", adjusted_rqty);
    formData.append("qa_remarks", adjusted_rqty_remarks);
    formData.append("tre_signature", sig_split[1]);

    const arqm = await approval_required_qty(formData).unwrap();
    if (arqm.success === true) {
      toast.success(arqm.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(arqm.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    data_refetch();
    handleOpen();
  };
  const sendApproveOpen = () => {
    if (ri_status == 2) {
      toast.error("Required quantity adjustment already sent.", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      handleOpen();
    }
  };
  return (
    <>
      <div className="justify-center items-center">
        <Button
          onClick={sendApproveOpen}
          className={ri_status == 2 ? "bg-yellow-700" : "bg-green-700"}
        >
          {ri_status == 2 ? "Pending" : "Send Adjusted Required Quantity"}
        </Button>
      </div>

      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Send Adjusted Required Quantity</DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <div
            id="form"
            className="w-full flex justify-center items-center b-slate-50 rounded-xl shadow-md shadow-slate-300 p-8"
          >
            <form
              ref={formRef}
              onSubmit={sendAdjustedQuantity}
              className="w-full p-8"
            >
              <div className="flex flex-row gap-2 my-4">
                <div id="firstname" className="w-1/2 mr-1">
                  <Typography>
                    PO Number: <span>{po_number}</span>
                  </Typography>
                </div>
              </div>
              <div className="flex flex-row gap-2 my-4">
                <div className="w-1/2 mr-1">
                  <Typography>
                    Item: <span>{item_name}</span>
                  </Typography>
                </div>
              </div>
              <div className="flex flex-row gap-2 my-4">
                <div className="w-1/2 mr-1">
                  <Typography>
                    Measurement: <span>{unit_measurement}</span>
                  </Typography>
                </div>
              </div>
              <div className="flex flex-row gap-2 my-4">
                {/* onChange={evt => setFirstname(evt.target.value)} */}
                <div className="w-1/2 mr-1">
                  <Input
                    label="Adjusted required Quantity"
                    onChange={(evt) => setAdjusted_rqty(evt.target.value)}
                  />
                </div>
              </div>
              <div className="flex flex-row gap-2 my-4">
                <div className="w-1/2 mr-1">
                  <textarea
                    placeholder="Enter your remarks here..."
                    // value={input}
                    name=""
                    id=""
                    cols="30"
                    rows="10"
                    className="border-2 border-blue-gray-500 text-black p-2"
                    onChange={(evt) =>
                      setAdjusted_rqty_remarks(evt.target.value)
                    }
                  ></textarea>
                </div>
              </div>
              <div className="flex flex-row gap-2 my-4">
                <div className="w-1/2 mr-1">
                  <div className={signatureBoxclasses}>
                    <ReactSignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 100,
                        className: "border-black border-2 rounded-s-xl",
                      }}
                      ref={(data) => setSignature(data)}
                    />
                    <div className={boxResetClasses}>
                      <GrPowerReset
                        size={"30px"}
                        onClick={() => handleSignatureReset(0)}
                        className="cursor-pointer"
                        color="white"
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* Submit button */}
              <Button
                type="submit"
                className="bg-blue-700 w-full h-10 cursor-pointer text-white rounded-md
              hover:bg-blue-600 hover:outline outline-2 outline-blue-600 outline-offset-2 text-sm mt-2"
              >
                <span className="">Send</span>
              </Button>
            </form>
          </div>
          <ToastContainer />
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
