import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Select,
  Option,
  CheckCircle,
  Circle,
} from "@material-tailwind/react";
import { useUpdateUserDetailsMutation } from "../../../store";
import { useUpdateOutletPasswordMutation } from "../../../store";
import { Confirmation } from "../Error_trap_components/ErrorTrapComponent";
import { ConfirmationV2 } from "../Error_trap_components/ErrorTrapComponentV2";
import { ToastContainer, toast } from "react-toastify";
const trapMessage = "Are you sure you want to submit these user info changes? ";
const buttonName = "Change Password";

const jobPositions = {
  1: "Plant Manager",
  2: "Finance Officer",
  3: "Finance Disbursement",
  4: "PICS Encoder",
  5: "Warehouse Supervisor",
  6: "Raw Materials Custodian",
  7: "Foods Duty Area Custodian",
  8: "Beverages Consumables Custodian",
  9: "Processing Supervisor",
  10: "Bakery Team Leader",
  11: "Patty's Line Team Leader",
  12: "Sanzrival Musa Team Leader",
  13: "General Manager",
  14: "Treasury Manager",
  16: "Driver",
  17: "Purchasing Manager",
  18: "Operations Staff",
  19: "Raw Mats Repacking Custodian",
  20: "Regional Manager",
  21: "Franchise Manager",
  22: "Auditor",
  23: "Plant Director",
  24: "Area Supervisor",
};

const trapMessageUpdatePassword =
  "Are you sure you want to reset this outlet username and password? ";
function ModifyUserModal({ userData, refetchMainTable }) {
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const token = localStorage.getItem("mytoken");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [firstName, setFirstName] = useState(userData.user.first_name);
  const [midName, setMidName] = useState(userData.user.middle_name);
  const [lastName, setLastName] = useState(userData.user.last_name);
  const [userName, setUserName] = useState("");
  const [isEditable, setIsEdit] = useState(false);
  const [address, setAdress] = useState(userData.address);
  const [number, setNumber] = useState(userData.phone_number);
  const [emailAdd, setEmailAdd] = useState(userData.user.email);
  const [gender, setGender] = useState(userData.gender);
  const [jobPosition, setjobPosition] = useState(userData.job_position);
  const [isActive, setIsActive] = useState(userData.user.is_active.toString());

  let [isReadyToSave, setIsReadyToSave] = useState(false);

  useEffect(() => {
    // Check if any of the arrays contain data and selectedOutlet is not null
    console.log(userData);
    const anyArrayHasData = address !== "";
    setIsReadyToSave(anyArrayHasData);
  }, [address]);

  function handleAddressChange(event) {
    const newValue = event.target.value;
    setAdress(newValue);
  }
  function handleEmailAddressChange(event) {
    const newValue = event.target.value;
    setEmailAdd(newValue);
  }
  function handleChangeFirstName(event) {
    const newValue = event.target.value;
    setFirstName(newValue);
  }
  function handleChangeLastName(event) {
    const newValue = event.target.value;
    setLastName(newValue);
  }
  function handleChangeMiddleName(event) {
    const newValue = event.target.value;
    setMidName(newValue);
  }
  function handleChangeUserName(event) {
    const newValue = event.target.value;
    setUserName(newValue);
  }
  function handleChangeNumber(event) {
    const newValue = event.target.value;
    setNumber(newValue);
  }
  const handleChangeJobPosition = (e) => {
    setjobPosition(e.target.value);
  };

  const handleChangeGender = (value) => {
    const gender = parseInt(value, 10);
    setGender(gender);
  };

  function handleStatusChange(value) {
    setIsActive(value);
  }

  const toggleEdit = () => {
    setIsEdit(!isEditable);
  };

  const [updateUserInifo] = useUpdateUserDetailsMutation();

  const handleUpdateUserInformation = async (event) => {
    event.preventDefault();
    let allData = {
      outlet: userData.id,
      user: account_detailed.user.id,
    };
    console.log(allData);
    try {
      const sea = await updateUserInifo(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        handleClose();
        setIsReadyToSave(false);
        refetchMainTable();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };

  const handleSave = async (event) => {
    event.preventDefault();
    let allData = {
      fName: firstName,
      mName: midName,
      lName: lastName,
      emailAddress: emailAdd,
      userName: userName,
      position: jobPosition,
      phoneNum: number,
      address: address,
      gender: gender,
      clientId: account_detailed.client.id,
      userId: userData.user.id,
      // userId: account_detailed.user.id,
      isActive: isActive,
    };
    console.log(allData);
    try {
      const sea = await updateUserInifo(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        handleClose();
        refetchMainTable();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]">
        View
      </Button>

      <Dialog
        size="lg"
        open={open}
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          <Typography className="text-green-900" variant="h3">
            Modify User Form
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <form>
            <Typography className="text-green-600" variant="h5">
              Basic Information
            </Typography>
            <div className="grid grid-cols-2 gap-5 mb-10 mt-5">
              <div className="space-y-5">
                <div className="flex items-center">
                  <Input
                    label="First Name"
                    name="fname"
                    value={firstName}
                    onChange={handleChangeFirstName}
                    className="flex-grow"
                    readOnly={!isEditable}
                  />
                  {firstName === "" && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
                <div className="flex items-center">
                  <Input
                    label="Middle Name"
                    name="mname"
                    onChange={handleChangeMiddleName}
                    value={midName}
                    readOnly={!isEditable}
                  />
                  {midName === "" && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
                <div className="flex items-center">
                  <Input
                    label="Last Name"
                    name="lname"
                    value={lastName}
                    onChange={handleChangeLastName}
                    readOnly={!isEditable}
                  />
                  {lastName === "" && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
                <div className="flex items-center">
                  <Input
                    label="Email Address"
                    name="email"
                    value={emailAdd}
                    onChange={handleEmailAddressChange}
                    readOnly={!isEditable}
                  />
                  {emailAdd === "" && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
              </div>
              <div className="space-y-5">
                <div className="flex items-center">
                  <Select
                    label="Gender"
                    onChange={handleChangeGender}
                    value={gender ? `${gender}` : null}
                    variant="outlined"
                    readOnly={!isEditable}
                  >
                    <Option value="1">{"Male"}</Option>
                    <Option value="2">{"Female"}</Option>
                  </Select>
                  {gender === null && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
                <div className="flex items-center">
                  <select
                    id="position"
                    name="position"
                    value={jobPosition}
                    onChange={handleChangeJobPosition}
                    readOnly={!isEditable}
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="">Select Job Position</option>
                    {Object.keys(jobPositions).map((key) => (
                      <option key={key} value={key}>
                        {jobPositions[key]}
                      </option>
                    ))}
                  </select>
                  {jobPosition === null && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>

                <div className="flex items-center">
                  <Input
                    label="Phone"
                    name="phone"
                    type="number"
                    value={number}
                    onChange={handleChangeNumber}
                    readOnly={!isEditable}
                  />
                  {number === "" && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
                <div className="flex items-center">
                  <textarea
                    id="address"
                    name="address"
                    value={address}
                    onChange={handleAddressChange}
                    rows="4"
                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    placeholder="Enter your address here"
                    readOnly={!isEditable}
                  />
                  {address === "" && (
                    <span className="text-red-500 text-lg ml-2">*</span>
                  )}
                </div>
                <Select
                  label="User Status"
                  onChange={handleStatusChange}
                  value={isActive}
                  variant="outlined"
                  readOnly={!isEditable}
                >
                  <Option value="true">Active</Option>
                  <Option value="false">Inactive</Option>
                </Select>
              </div>
            </div>
          </form>
        </DialogBody>
        <ToastContainer />
        <DialogFooter>
          <Button className="bg-orange-900 ml-10" onClick={toggleEdit}>
            <span>{isEditable ? "Set Read-Only" : "Edit"}</span>
          </Button>
          {/* <ConfirmationV2
            onConfirm={handleUpdatePassword}
            trapMessage={trapMessageUpdatePassword}
            isReadyToSave={true}
            buttonName={buttonName}
          /> */}
          <Confirmation
            onConfirm={handleSave}
            trapMessage={trapMessage}
            isReadyToSave={isReadyToSave}
          />
          <Button
            variant="text"
            color="red"
            className="mr-3 ml-5"
            onClick={handleClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default ModifyUserModal;
