import React, { useState } from "react";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
} from "@material-tailwind/react";
import { FiSearch } from "react-icons/fi";
import { useFetchItemInventoryListQuery } from "../../store";
import ItemHistoryModal from "../modal_form_components/Warehouse/WarehouseInventory/ItemHistory";
const TABLE_HEAD = [
  "Name",
  "UM",
  "Weight Box",
  "SOH",
  "Last Modified",
  "Action",
  "Status",
];
export function CompleteTableView() {
  let result = [];
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "desc",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(1);
  const columnMapping = {
    "Order Number": "order_number",
    Outlet: "outlet",
    Route: "outlet__route",
    "Order Date": "order_date",
    Status: "status",
  };

  const { data, error, isLoading, isSuccess, refetch } =
    useFetchItemInventoryListQuery({
      page: currentPage,
      page_size: pageSize,
      search: searchTerm,
      order_by: sortConfig.key,
      order_dir: sortConfig.direction,
      location: selectedStatus,
    });

  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleClickRefresh = () => {
    setIsRefreshing(true);

    // Trigger the refetch function
    refetch();

    // Disable the button after 2 seconds
    setTimeout(() => {
      setIsRefreshing(false);
    }, 2000);
  };

  const handleSort = (column) => {
    let direction = "asc";
    if (
      sortConfig.key === columnMapping[column] &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key: columnMapping[column], direction });
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePageSizeChange = (e) => {
    const selectedSize = parseInt(e.target.value);
    console.log("Selected Page Size:", selectedSize);
    setPageSize(selectedSize);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleTabChange = (value) => {
    setCurrentPage(1);
    setSelectedStatus(value);
    refetch();
  };
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const filterCategory = [];

  if (account_detailed.job_position === 7) {
    filterCategory.push({
      label: "Foods Duty",
      value: 1,
    });
  } else if (account_detailed.job_position === 8) {
    filterCategory.push(
      {
        label: "Beverages",
        value: 2,
      },
      {
        label: "Consumables",
        value: 3,
      }
    );
  } else {
    filterCategory.push(
      {
        label: "Raw Materials",
        value: 1,
      },
      {
        label: "Repacking",
        value: 6,
      },
      {
        label: "Consumables",
        value: 4,
      },
      {
        label: "Plastics",
        value: 7,
      }
    );
  }

  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    result = data.results;

    return (
      <Card className="h-full w-full mb-20 lg:mb-0">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div>
            {/* <Typography className="text-lg text-blue-gray-800 font-semibold tracking-normal">
              Track your inventory
            </Typography> */}
            {/* <Typography className=" text-sm">
              Monitor the Inventory data
            </Typography> */}
          </div>
          {/* <div className="mb-8 flex items-center justify-end gap-8 mt-5">
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              <DelCalendar />
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                <AdvOrderModal refetchDeliveryList={refetch} />
              </div>
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                <AddDeliveryModal refetchDeliveryList={refetch} />
              </div>
              <Button className="bg-[#1AA55D]" size="sm">
                Add Outlet
              </Button>
            </div>
          </div> */}

          <div className="md:flex justify-between mt-5 md:mt-10">
            <div className="md:ml-5 mb-5">
              <Input
                label="Search"
                icon={<FiSearch className="h-5 w-5" />}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="md:mr-10 flex justify-end mt-3 md:mt-0 items-center space-x-4">
              <Typography className=" font-medium text-sm">
                Page size:
              </Typography>
              <select
                className="block appearance-none bg-white border border-gray-300 rounded-md py-2 px-3 leading-tight focus:outline-none focus:border-indigo-500"
                value={pageSize}
                onChange={(e) => handlePageSizeChange(e)}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
              </select>
            </div>
          </div>

          <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            <Tabs value={selectedStatus} className="w-full md:w-[70%]">
              <TabsHeader>
                {filterCategory.map(({ label, value }) => (
                  <Tab
                    className="text-sm"
                    key={value}
                    value={value}
                    onClick={() => handleTabChange(value)}
                  >
                    &nbsp;&nbsp;{label}&nbsp;&nbsp;
                  </Tab>
                ))}
              </TabsHeader>
            </Tabs>
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-b border-green-600 bg-[#1AA55D] text-white p-4"
                    onClick={() => handleSort(head)}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography
                      variant="small"
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {result.map((item, index) => {
                const isLast = index === result.length - 1;
                let item_weight_box = "";
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                // Calculate the percentage of stock on hand relative to average consumption
                const stockOnHandPercentage =
                  (item.stock_on_hand / item.average_consume) * 100 || 0;

                // Define status based on stock on hand percentage
                let status = "SUFFICIENT";
                let statusColor = "green";
                if (stockOnHandPercentage < 50) {
                  status = "REORDER";
                  statusColor = "orange";
                }
                if (stockOnHandPercentage < 25) {
                  status = "CRITICAL";
                  statusColor = "red";
                }

                const formattedDate = item.updated_at
                  ? new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    }).format(new Date(item.updated_at))
                  : "";

                if (item.soh_location === 2) {
                  item_weight_box = item.item.weight_pack_decimal || "N|A";
                } else {
                  item_weight_box = item.item.weight_in_pack || "N/A";
                }

                console.log(item);
                return (
                  <tr key={item.id}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.item.name || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.item.unit_measurement || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item_weight_box}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    {/* <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {(parseFloat(item.average_consume) || 0).toFixed(2)}
                          </Typography>
                        </div>
                      </div>
                    </td> */}
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color={statusColor}
                            className="font-normal"
                          >
                            {item.item.unit_measurement === "kilos" &&
                            item.item.location !== 6
                              ? (parseFloat(item.into_kilo) || 0).toFixed(2)
                              : item.item.location === 6
                              ? (parseFloat(item.into_kilo) || 0).toFixed(2)
                              : (parseFloat(item.stock_on_hand) || 0).toFixed(
                                  2
                                )}
                          </Typography>
                        </div>
                      </div>
                    </td>

                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {formattedDate}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <ItemHistoryModal itemData={item.item} />
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color={statusColor}
                            className="font-normal"
                          >
                            {status}
                          </Typography>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {`${currentPage} of ${Math.ceil(data?.count / pageSize) || 1}`}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              size="sm"
              onClick={handlePreviousPage}
              disabled={!data?.previous}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleClickRefresh}
              disabled={isRefreshing}
            >
              Refresh
            </Button>

            <Button
              variant="outlined"
              size="sm"
              onClick={handleNextPage}
              disabled={!data?.next}
            >
              {data?.next ? "Next" : "No More Pages"}
            </Button>
          </div>
        </CardFooter>
      </Card>
    );
  }
}
