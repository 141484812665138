import React, { CSSProperties } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Checkbox,
} from "@material-tailwind/react";
import { useFetchVendorListPageQuery } from "../../../../store/apis/VendorItemApi";
import { FiMousePointer } from "react-icons/fi";
import { IconButton } from "@material-tailwind/react";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import HashLoader from "react-spinners/HashLoader";

function PurchaseRequestFormItemList({
  onSelectItem,
  addselectedCategory,
  location,
}) {
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [open, setOpen] = React.useState(false);
  const tableHead = [
    "",
    "Id",
    "Name",
    "Unit measurement",
    "Standard Measurement",
    "Stock on hand",
    "Status",
  ];
  const [checkVendorItemId, setCheckVendorItemId] = React.useState([]);
  const [checkVendorItem, setCheckVendorItem] = React.useState([]);
  const [checkUnitMeasurement, setCheckUnitMeasurement] = React.useState([]);
  const [checkItemList, setCheckItemList] = React.useState([]);
  const [selectActive, setSelectActive] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(10);
  const [str, setStr] = React.useState("");
  const { data, error, isLoading } = useFetchVendorListPageQuery({
    page: selectActive,
    page_size: pageSize,
    location: addselectedCategory,
    search_str: str,
    client_id: account_detailed.client.id,
  });

  const totalPage = Math.ceil(data?.count / pageSize);
  // variable for data of api
  let result = [];

  // Update the state based on whether the checkbox is checked or unchecked
  const handleChange = (e) => {
    const { checked, value } = e.target;
    const newCheckboxvalue = value.split(",");

    if (checked) {
      setCheckVendorItemId([
        ...checkVendorItemId,
        parseInt(newCheckboxvalue[0]),
      ]);
      setCheckVendorItem([...checkVendorItem, newCheckboxvalue[1]]);
      setCheckUnitMeasurement([...checkUnitMeasurement, newCheckboxvalue[2]]);
      setCheckItemList([...checkItemList, value]);
    } else {
      const filteredVendorItemID = checkVendorItemId.filter(
        (itemID) => itemID !== parseInt(newCheckboxvalue[0])
      );
      const filteredVendorItem = checkVendorItem.filter(
        (vendorItem) => vendorItem !== newCheckboxvalue[1]
      );
      const filteredVendorMeasurement = checkUnitMeasurement.filter(
        (vendorMesure) => vendorMesure !== newCheckboxvalue[2]
      );
      const filteredVendorList = checkItemList.filter(
        (vendorList) => vendorList !== value
      );

      setCheckVendorItemId(filteredVendorItemID);
      setCheckVendorItem(filteredVendorItem);
      setCheckUnitMeasurement(filteredVendorMeasurement);
      setCheckItemList(filteredVendorList);
    }
  };
  // preview of pagination
  const prev = () => {
    if (selectActive === 1) return;
    setSelectActive(selectActive - 1);
  };
  // next of pagination
  const next = () => {
    if (selectActive === totalPage) return;
    setSelectActive(selectActive + 1);
  };
  // handle selection of checkbox
  const handleSelect = () => {
    onSelectItem(
      checkVendorItemId,
      checkVendorItem,
      checkUnitMeasurement,
      checkItemList
    );
    setOpen(!open);
  };
  // handle the page size of table
  const changePagesize = (e) => {
    setPageSize(e.target.value);
    setSelectActive(1);
  };
  // open/closing of dialog
  const handleOpen = () => setOpen(!open);
  //handle searching of data
  const handleSearch = (e) => setStr(e.target.value);
  // for data fetching
  if (isLoading) {
    return <HashLoader color="#36d7b7" />;
  } else if (data) {
    result = data.results;
  } else {
    return <div>Error Loading..</div>;
  }
  return (
    <div>
      <Button
        onClick={handleOpen}
        variant="outlined"
        color="green"
        size="sm"
        disabled={addselectedCategory === ""}
      >
        <FiMousePointer className="inline" />
        <span className="ml-1">Select Item</span>
      </Button>
      <Dialog
        open={open}
        size="xl"
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="text-green-900">
          Complete List of Items
        </DialogHeader>
        <div className="mr-16 md:mr-10 md:flex justify-between">
          <div className="mb-10 md:mb-0 ml-8 w-full md:w-72">
            <Input type="search" label="search" onKeyUp={handleSearch} />
          </div>
          <div className="flex justify-end mb5 md:mb-0 items-center space-x-2">
            <Typography>Page size:</Typography>
            <select
              defaultValue={pageSize}
              onChange={changePagesize}
              className="border-2 border-blue-gray-500"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {tableHead.map((head, index) => (
                  <th
                    key={index}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {result.map((item, index) => {
                const isLast = index === data.results.length - 1;
                let status = (
                  <Typography className="text-red-700">Insufficient</Typography>
                );
                let checkboxValue =
                  item.id +
                  "," +
                  item.name +
                  "," +
                  item.unit_measurement +
                  "," +
                  item.stock_on_hand +
                  "," +
                  item.past_3_stock_sl +
                  "," +
                  item.past_2_stock_sl +
                  "," +
                  item.past_1_stock_sl +
                  "," +
                  item.into_kilo +
                  "," +
                  item.con_into_kilo +
                  "," +
                  item.standard_measurement +
                  "," +
                  item.is_product;

                let total_sl =
                  parseInt(item.past_3_stock_sl) +
                  parseInt(item.past_2_stock_sl) +
                  parseInt(item.past_2_stock_sl);
                total_sl = (total_sl / 3) * 2;
                total_sl = total_sl.toFixed(2);

                if (total_sl < item.into_kilo) {
                  status = (
                    <Typography className="text-green-700">
                      Sufficient
                    </Typography>
                  );
                }

                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                return (
                  <tr key={item.id}>
                    <td className={classes}>
                      <Checkbox
                        type="checkbox"
                        color="teal"
                        value={checkboxValue}
                        onChange={handleChange}
                        defaultChecked={checkVendorItemId.includes(item.id)}
                      />
                    </td>
                    <td className={classes}>{item.id}</td>
                    <td className={classes}>{item.name}</td>
                    <td className={classes}>{item.unit_measurement}</td>
                    <td className={classes}>{item.standard_measurement}</td>
                    <td className={classes}>
                      {item.is_product == true
                        ? item.stock_on_hand
                        : parseFloat(item.into_kilo).toFixed(2)}
                    </td>
                    <td className={classes}>{status}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </DialogBody>
        <DialogFooter className="flex justify-between">
          <div className="flex justify-center items-center gap-8 ml-[25%] lg:ml-[41%] md:ml-[5%] mb-6 md:mb-0">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={prev}
              disabled={selectActive === 1}
            >
              <IoArrowBackOutline strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <Typography color="gray" className="font-normal">
              <strong className="text-gray-900">{selectActive}</strong> of
              <strong className="text-gray-900 ml-1">{totalPage}</strong>
            </Typography>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={next}
              disabled={selectActive === totalPage}
            >
              <IoArrowForwardOutline strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
          <div>
            <Button
              color="red"
              variant="text"
              onClick={handleOpen}
              className="mr-2"
            >
              <span>Close</span>
            </Button>
            <Button onClick={handleSelect} className="bg-green-900">
              <span>Select</span>
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </div>
  );
}

export default PurchaseRequestFormItemList;
