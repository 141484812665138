import { useState } from "react";
import { BiHighlight, BiCheckDouble, BiFileBlank } from "react-icons/bi";
import {
  DialogHeader,
  DialogBody,
  Input,
  Typography,
  Button,
  DialogFooter,
  Dialog,
} from "@material-tailwind/react";
import ApprovalSignatureCanvas from "../../signature_canvas_components/ApprovalSignatureCanvas";
import ApprovedModal from "./ApprovedModal";
import RejectApprovedModal from "./ApprovedRejectModal";
import DatePickerReact from "../../input/DatePickerReact";
import moment from "moment";
import {
  useFetchRequestItemQuery,
  useFetchDepartmentListQuery,
} from "../../../store/apis/RequestApi";
import { HashLoader } from "react-spinners";
import { useUpdateRequestItemMutation } from "../../../store/apis/RequestApi";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import ApprovalDeleteItem from "../Error_trap_components/ApprovalDeleteItem";
import PurchaseOrderTableSubTable from "../../table_components/Approval/PurchaseOrderTableSubTable";

function ApprovalPurchaseOrder({ po_id, autoFetch }) {
  const TABLE_HEAD = ["Name", "Unit Measurement"];

  const today = new Date();
  const currentMonth = today.getMonth();
  const currentYear = today.getFullYear();

  let pastThreeMonthsDates = [];

  for (let i = 1; i < 4; i++) {
    const month = (currentMonth - i + 12) % 12;
    const year = currentYear - Math.floor((currentMonth - i + 12) / 12);
    pastThreeMonthsDates.unshift(
      moment(new Date(year, month, 1)).format("MMM")
    );
  }
  for (let ptmd in pastThreeMonthsDates) {
    let each_ptmd = pastThreeMonthsDates[ptmd];
    TABLE_HEAD.push(each_ptmd);
  }

  const trclasses = "items-center p-4 border-b border-blue-gray-50";
  const [open, setOpen] = useState(false);
  const [addRequestDate, setAddRequestDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const api_domain = process.env.REACT_APP_API_DOMAIN;
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const full_name = user_info.first_name + " " + user_info.last_name;
  const [edit, setEdit] = useState(false);
  const [editApprove, setEditApprove] = useState(false);
  const fetch_request_item = useFetchRequestItemQuery(po_id); // for fetching API
  const fetch_department_list = useFetchDepartmentListQuery(); // for fetching API
  const [qtyInput, setQtyInput] = useState([]);
  const [itemDate, setItemDate] = useState([]);
  const [dropDown, setDropDown] = useState({
    category: "",
    department: "",
  });

  TABLE_HEAD.push(
    "Stock Level",
    "Stock on Hand",
    "Required Quantity",
    "Delivery Date"
  );

  if (fetch_request_item.data?.data.approval_type == 2) {
    TABLE_HEAD.push("Vendor");
  }
  TABLE_HEAD.push("Action");
  const [sig, setSig] = useState();
  const [updateVendorItem] = useUpdateRequestItemMutation();
  // const [msgTrap, setMsgTrap] = useState(false);
  let signature_list = [];

  const requestorSignature = fetch_request_item.data?.data.signature;
  const plantSignature = fetch_request_item.data?.data.signature_plant_manager;
  const treaSignature =
    fetch_request_item.data?.data.signature_treasury_manager;
  const gmSignature = fetch_request_item.data?.data.signature_general_manager;

  if (requestorSignature) {
    signature_list.push({
      requestor_sig: api_domain + requestorSignature,
      full_name: fetch_request_item.data?.data.requested_by,
    });
  }
  if (plantSignature) {
    signature_list.push({
      pm_sig: api_domain + plantSignature,
      full_name: "Herman F. Gatela",
    });
  }
  if (treaSignature) {
    signature_list.push({
      treasury_sig: api_domain + treaSignature,
      full_name: "Marisa BM",
    });
  }
  if (gmSignature) {
    signature_list.push({
      gm_sig: api_domain + gmSignature,
      full_name: "Thesa Acuna",
    });
  }

  const categoryList = [
    {
      id: "1",
      name: "Raw Materials",
    },
    {
      id: "2",
      name: "Foods duty"
    },
    {
      id: "3",
      name: "Beverages",
    },
    {
      id: "4",
      name: "Consumables"
    },
    {
      id: "5",
      name: "Hardware"
    },
    {
      id: "8",
      name: "Office Supplies",
    },
  ];

  const handleOpen = () => setOpen(!open);
  // function for edit button
  function handleEdit() {
    setEdit(!edit);
  }

  // displaying the data
  let result = [];
  let content;
  if (fetch_request_item.isLoading) {
    content = <HashLoader />;
  } else if (fetch_request_item.error) {
    content = <div>Error Loading...</div>;
  } else {
    result = fetch_request_item.data?.data;
  }

  if (fetch_department_list.isLoading) {
    content = <HashLoader />;
  } else if (fetch_department_list.error) {
    content = <div>Error Loading...</div>;
  }

  const categoryValue = fetch_request_item.data?.data.category;
  const departmentValue = fetch_request_item.data?.data.department;
  //handle onchange of the item
  function handleQtyChange(e) {
    setQtyInput({ ...qtyInput, [e.target.name]: e.target.value });
  }
  // handle change of delivery date
  function handleDeliveryChange(date, index) {
    const updatedItemDate = [...itemDate];
    updatedItemDate[index] = date;
    setItemDate(updatedItemDate);
  }
  // handle dropdown change
  function handleChangeDropdown(e) {
    setDropDown({ ...dropDown, [e.target.name]: e.target.value });
  }
  //handle save of the item
  async function handleSave(requestor) {
    console.log(requestor);
    // length of data from api that will use in the loop
    const arrayLength = fetch_request_item.data?.data.request_items.map(
      (i) => i
    );
    const id = fetch_request_item.data?.data.request_items.map((i) => i.id); // fetch id
    const idItem = fetch_request_item.data?.data.request_items.map(
      (i) => i.id_item
    ); // fetch item id
    const name = fetch_request_item.data?.data.request_items.map((i) => i.name); // fetch item
    const unitMeasurement = fetch_request_item.data?.data.request_items.map(
      (i) => i.unit_measurement
    ); // fetch unit measurement
    let items = [];
    // looping for updating the input field inside of items array
    for (let index = 0; index < arrayLength.length; index++) {
      let obj = {};
      if (qtyInput[index]) {
        obj.des_required_qty = qtyInput[index];
      }
      if (id[index]) {
        obj.id = id[index];
      }
      if (idItem[index]) {
        obj.id_item = idItem[index];
      }
      if (name[index]) {
        obj.name = name[index];
      }
      if (unitMeasurement[index]) {
        obj.unit_measurement = unitMeasurement[index];
      }
      if (itemDate[index]) {
        obj.delivery_date = moment(new Date(itemDate[index])).format(
          "YYYY-MM-DD"
        );
      }
      items[index] = obj;
    }
    // value to submit on API
    let updatedValue = {
      request_id: fetch_request_item.data?.data.id,
      user_id: parseInt(user_info.id),
      department_id: dropDown.department,
      category: dropDown.category,
      date: moment(new Date(fetch_request_item.data?.data.date)).format(
        "YYYY-MM-DD"
      ),
      request_items: items,
    };

    const sea = await updateVendorItem(updatedValue).unwrap();
    // after submitted the request
    if (sea.success === true) {
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    setOpen(!open);
    autoFetch();
    fetch_request_item.refetch();
  }

  // function that will be pass on the child component
  const savingSignature = (val) => {
    setSig(val);
    setEditApprove(true);
  };

  return (
    <>
      <div className="container">
        <Button
          onClick={handleOpen}
          className="flex items-center bg-green-900 space-x-1"
          size="sm"
        >
          <BiCheckDouble />
          <span>Purchase Order</span>
        </Button>
        <Dialog
          open={open}
          handler={handleOpen}
          className="relative max-h-min"
          size="xl"
        >
          <DialogHeader className="w-full bg-green-900 rounded-sm flex justify-center">
            <Typography className="text-center text-white" variant="h5">
              Approval Purchase Order information
            </Typography>
          </DialogHeader>
          <DialogBody className="container overflow-y-auto max-h-[60vh]">
            <div className="dialog_body_div">
              <form>
                <div className="grid grid-flow-row col-span-2 md:grid-cols-2 items-center gap-2 mx-5 mb-10">
                  <div className="relative h-10 w-full">
                    <Typography>
                      PO Number: {fetch_request_item.data?.data.po_number}
                    </Typography>
                  </div>
                  <div className="relative h-10 w-full">
                    <DatePickerReact
                      handleDatePicker={(date) => setAddRequestDate(date)}
                      defaultValue={fetch_request_item.data?.data.date}
                      id="start_date"
                      className="mb-2 mt-2"
                      edit={edit}
                    />
                  </div>
                  <div className="relative h-10 w-full">
                    <select
                      label="Category"
                      name="category"
                      defaultValue={categoryValue}
                      disabled={edit === false}
                      onChange={handleChangeDropdown}
                      className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    >
                      <option disabled>{categoryValue}</option>
                      {categoryList.map((cl, i) => {
                        if (cl === fetch_request_item.data?.data.category){
                          return <>
                            <option key={i} value={cl.id}>
                              {fetch_request_item.data?.data.category}
                            </option>
                          </>
                        }
                      })}
                    </select>
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Category
                    </label>
                  </div>
                  <div className="relative h-10 w-full">
                    <select
                      label="Department"
                      name="department"
                      defaultValue={departmentValue}
                      onChange={handleChangeDropdown}
                      disabled={edit === false}
                      className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    >
                      <option disabled>{departmentValue}</option>
                      {fetch_department_list.data?.data.map((dept, i) => (
                        <option key={i} value={dept.id}>
                          {dept.name}
                        </option>
                      ))}
                    </select>
                    <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Department
                    </label>
                  </div>
                </div>
                <div className="container">
                  <div className="flex items-center space-x-2 lg:justify-between mt-12 ">
                    <div className="flex space-x-2 items-center">
                      <Button
                        className="flex items-center bg-blue-900 space-x-1 w-full"
                        size="sm"
                        onClick={handleEdit}
                      >
                        <BiHighlight />
                        <span>Edit</span>
                      </Button>
                      <Button
                        className="flex items-center bg-yellow-800 my-3 ml-3 w-full"
                        size="sm"
                        disabled={edit === false}
                        onClick={() =>
                          handleSave(fetch_request_item.data?.data.requested_by)
                        }
                      >
                        <BiFileBlank />
                        <span>Save</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </form>
              <table className="mt-4 w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head, index) => (
                      <th
                        key={index}
                        className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {fetch_request_item.data?.data.request_items.map((ri, i) => {
                    return (
                      <>
                        <tr className={trclasses} key={i}>
                          <td className={trclasses}>
                            <Typography>{ri.name}</Typography>
                          </td>
                          <td className={trclasses}>
                            <Typography>{ri.um} </Typography>
                          </td>
                          <td className={trclasses}>
                            <Typography>{ri.past_3_stock_level} </Typography>
                          </td>
                          <td className={trclasses}>
                            <Typography>{ri.past_2_stock_level} </Typography>
                          </td>
                          <td className={trclasses}>
                            <Typography>{ri.past_1_stock_level} </Typography>
                          </td>
                          <td className={trclasses}>
                            <Typography>{ri.stock_level} </Typography>
                          </td>
                          <td className={trclasses}>
                            <Typography>{ri.stock_on_hand} </Typography>
                          </td>
                          <td className={trclasses}>
                            <Input
                              type="number"
                              step="0.01"
                              label="Quantity"
                              disabled={edit === false}
                              name={i}
                              defaultValue={ri.des_required_qty}
                              onChange={handleQtyChange}
                            />
                          </td>
                          <td className={trclasses}>
                            <DatePicker
                              className="bordered border p-1 border-blue-gray-300 rounded-lg"
                              selected={
                                itemDate[i] || new Date(ri.delivery_date)
                              }
                              onChange={(date) => handleDeliveryChange(date, i)}
                              dateFormat="yyyy/MM/dd"
                              peekNextMonth
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                              placeholderText="Select"
                            />
                          </td>
                          {fetch_request_item.data?.data.approval_type == 2 ? (
                            <td>
                              <PurchaseOrderTableSubTable
                                request_item_id={ri.id}
                              />
                            </td>
                          ) : (
                            ""
                          )}
                          <td>
                            <ApprovalDeleteItem
                              id={ri.id}
                              disable={edit}
                              user_id={parseInt(user_info.id)}
                              requestor={
                                fetch_request_item.data?.data.requested_by
                              }
                              refetchRequestItem={fetch_request_item.refetch}
                            />
                          </td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
              <div className="mb-10">
                <ApprovalSignatureCanvas
                  assignatories={fetch_request_item.data?.data.category}
                  fetchSignature={signature_list}
                  approve_type={fetch_request_item.data?.data.approval_type}
                  onEnd={savingSignature}
                  saveSignature={savingSignature}
                  account_detailed={account_detailed}
                />
              </div>
            </div>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpen}
              className="mr-1"
            >
              <span>Close</span>
            </Button>
            <div className="flex justify-end mr-2 space-x-1">
              <ApprovedModal
                id={fetch_request_item.data?.data.id}
                signature={sig}
                edit={editApprove}
                user_id={parseInt(user_info.id)}
                handleRequestItemOpen={handleOpen}
                categoryValue={categoryValue}
                approve_type={fetch_request_item.data?.data.approval_type}
                autoFetch={autoFetch}
              />
              <RejectApprovedModal
                id={fetch_request_item.data?.data.id}
                signature={sig}
                edit={editApprove}
                user_id={parseInt(user_info.id)}
                handleRequestItemOpen={handleOpen}
                approve_type={fetch_request_item.data?.data.approval_type}
                categoryValue={categoryValue}
                autoFetch={autoFetch}
              />
            </div>
          </DialogFooter>
        </Dialog>
        <ToastContainer />
      </div>
    </>
  );
}

export default ApprovalPurchaseOrder;
