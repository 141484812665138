import { Card, Typography, Input } from "@material-tailwind/react";
import VendorListOption from "../../../modal_form_components/Warehouse/WarehousePurchaseOrder/VendorListOption";
import {
  useAddSuggestedVendorDetailMutation,
  useAddSuggestedVendorMutation,
} from "../../../../store";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment";
import { useState, useEffect } from "react";

const VendorFieldPRForm = ({
  vendorTableHeader,
  po_id,
  item_id,
  suggested_vendor,
  refetchRequestItemList,
  required_qty,
  des_required_qty,
  inputFlag
}) => {
  const trclasses = "p-4 border-b border-blue-gray-50";
  // save sugested vendor rfq detail
  const [add_suggested_vendor_detail] = useAddSuggestedVendorDetailMutation();

  let suggested_vendor_list = [];
  let vendor_list_id = [];
  let count_null = 0;

  for (let i = 0; i < suggested_vendor.length; i++) {
    let svl = suggested_vendor[i].vendor;
    let sugg_required_qty = suggested_vendor[i].des_required_qty;

    if (suggested_vendor[i].quoted_price === null || suggested_vendor[i].quoted_price === '') {
      count_null += 1
    }

    if (suggested_vendor[i].committed_date === null || suggested_vendor[i].committed_date === ''){
      count_null += 1
    }

    if (suggested_vendor[i].des_required_qty === null || suggested_vendor[i].des_required_qty === ''){
      count_null += 1
    }

    vendor_list_id.push(suggested_vendor[i].vendor.id);
    suggested_vendor_list.push({
      id: suggested_vendor[i].id,
      payment_terms: suggested_vendor[i].payment_terms,
      po_number: suggested_vendor[i].po_number,
      quoted_price: suggested_vendor[i].quoted_price,
      request_item: suggested_vendor[i].request_item,
      prev_price_per_kilo: suggested_vendor[i].prev_price_per_kilo,
      previous_price: suggested_vendor[i].previous_price,
      required_qty: sugg_required_qty == null ? 0 : sugg_required_qty,
      des_required_qty : sugg_required_qty == null ? 0 : sugg_required_qty,
      status: suggested_vendor[i].status,
      committed_date: suggested_vendor[i].committed_date,
      vendor: {
        address: svl.address,
        contact: svl.contact,
        id: svl.id,
        name: svl.name,
        poc: svl.poc,
        terms: svl.terms,
      },
    });
  }
  if (suggested_vendor.length > 0){
    if (count_null <= 0 ){
      inputFlag(true)
    }
    else{
      inputFlag(false)
    }
  }
  console.log(count_null)

  const handleSuggestedVendor = async (e, id, type) => {
    let flag_error = false;
    let formData = new FormData();
    formData.append("sv_id", id);

    if (type === "quoted_price") {
      formData.append("quoted_price", e);
    }
    if (type === "previous_price") {
      formData.append("previous_price", e);
    }

    let request_qty_input = 0;
    if (type === "des_required_qty") {
      for (let svl in suggested_vendor_list) {
        let eachSvl = suggested_vendor_list[svl];
        if (eachSvl.id === id) {
          request_qty_input += parseFloat(e);
        } else {
          request_qty_input += parseFloat(eachSvl.des_required_qty);
        }
      }

      if (des_required_qty >= request_qty_input) {
        formData.append("des_required_qty", e);
      } else {
        flag_error = true;
        toast.error("Total required Quantity is not matched", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    }

    if (type === "committed_date") {
      formData.append(
        "committed_date",
        moment(new Date(e)).format("YYYY-MM-DD")
      );
    }

    if (type === "suggested_file") {
      formData.append("file", e);
    }

    if (flag_error === false) {
      const asvd = await add_suggested_vendor_detail(formData).unwrap();
      if (asvd.success === true) {
        toast.success(asvd.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      } else {
        toast.error(asvd.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
      refetchRequestItemList();
    }
  };

  // function for saving selected vendor
  const [addSuggestedVendor] = useAddSuggestedVendorMutation();
  const handleSelectVendor = async (value1, value2) => {
    console.log(value1);
    let form = {
      request_id: po_id,
      item_id: item_id,
      suggested_vendor_id: value1,
    };
    const asv = await addSuggestedVendor(form).unwrap();
    if (asv.success === true) {
      toast.success(asv.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(asv.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetchRequestItemList();
  };

  return (
    <Card className="h-full w-full overflow-scroll p-2">
      <table className="w-full min-w-max table-auto text-left ">
        <thead>
          <tr>
            {vendorTableHeader.map((head, k) => (
              <th
                key={k}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {suggested_vendor_list
            .sort((a, b) => b.id - a.id)
            .map((sv, index) => {
              return (
                <tr key={index} className={trclasses}>
                  <td className={trclasses}>{sv.vendor.name}</td>
                  <td className={trclasses}>
                    <Input
                      label="Required Quantity"
                      type="number"
                      defaultValue={sv.des_required_qty}
                      autoFocus={false}
                      onBlur={(e) => {
                        handleSuggestedVendor(
                          e.target.value,
                          sv.id,
                          "des_required_qty"
                        );
                      }}
                    />
                  </td>
                  <td className={trclasses}>
                    <Input
                      label="Previous Price"
                      type="text"
                      defaultValue={"₱ " + sv.previous_price}
                      disabled
                    />
                  </td>
                  <td>
                    <Input
                      label="Prev Price per kilo"
                      type="text"
                      defaultValue={sv.prev_price_per_kilo === "N/A" ? sv.prev_price_per_kilo: "₱ " + sv.prev_price_per_kilo}
                      disabled
                    />
                  </td>
                  <td className={trclasses}>
                    <Input
                      label="Quoted Price"
                      type="number"
                      defaultValue={sv.quoted_price}
                      autoFocus={false}
                      onBlur={(e) => {
                        handleSuggestedVendor(
                          e.target.value,
                          sv.id,
                          "quoted_price"
                        );
                      }}
                    />
                  </td>
                  <td className={trclasses}>
                    {sv.vendor.terms}
                    <ToastContainer />
                  </td>
                  <td>
                    <DatePicker
                      id="suggested_commited_date"
                      className="bordered border p-1 border-blue-gray-300 rounded-lg"
                      selected={
                        sv.committed_date === null
                          ? new Date()
                          : new Date(sv.committed_date)
                      }
                      value={
                        sv.committed_date === null
                          ? new Date()
                          : new Date(sv.committed_date)
                      }
                      onChange={(date) =>
                        handleSuggestedVendor(date, sv.id, "committed_date")
                      }
                      dateFormat="yyyy/MM/dd"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText="Select"
                    />
                  </td>
                  <td>
                    <input
                      className="
                      file:bg-gradient-to-b file:from-blue-500 file:to-blue-600
                      file:px-1 file:py-1 file:m-2
                      file:border-none
                      file:rounded-full
                      file:rounde-full
                      file:text-white
                      file:cursor-pointer
                      file:shadow-sm file:shadow-blue-600/50

                      bg-gradient-to-br from-green-600 to-green-700
                      text-white/80
                      rounded-full
                      cursor-pointer
                      shadow-xl shadow-gray-300/60
                      "
                      type="file"
                      name="files"
                      onChange={(evt) => {
                        handleSuggestedVendor(
                          evt.target.files[0],
                          sv.id,
                          "suggested_file"
                        );
                      }}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
        <tfoot>
          <tr>
            <td className="pt-2">
              <VendorListOption
                handleSelectVendor={handleSelectVendor}
                vendor_list_id={vendor_list_id}
                item={item_id}
              />
            </td>
          </tr>
        </tfoot>
      </table>
    </Card>
  );
};

export default VendorFieldPRForm;
