import { Card, Typography } from "@material-tailwind/react";

const TABLE_HEAD = [
  "Item Code",
  "Product",
  "Order",
  "Unit Measurement",
];

const classes = "p-4 border-b border-blue-gray-50";

function AdjDelRecpTbl({ items }) {
  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left ">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {items.map((item) => (
            <tr key={item.id} className={classes}>
               <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                             {item.product.product_code !== null && item.product.product_code  !== undefined ? item.product.product_code : "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                             {item.product.name !== null && item.product.name  !== undefined ? item.product.name : "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                             {item.ordered_qty !== null && item.ordered_qty  !== undefined ? item.ordered_qty : "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                             {item.product.weight_box !== null && item.product.weight_box  !== undefined ? item.product.weight_box : "N/A"}
                             {"/"}
                             {item.product.unit_measurement !== null && item.product.unit_measurement  !== undefined ? item.product.unit_measurement : "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
}

export default AdjDelRecpTbl;
