import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import {
  Card,
  CardBody,
  CardHeader,
  Typography,
  Select,
  Option,
  Input,
} from "@material-tailwind/react";
import { useFetchInventoeyDashBoardGraphQuery } from "../../../store";

export default function InventoryBarChart() {
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [location, setLocation] = useState("1");
  const [chartData, setChartData] = useState(null);

  const handleChangeLocation = (value) => {
    setLocation(value);
  };

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const { data: inventoryData, isSuccess } =
    useFetchInventoeyDashBoardGraphQuery({
      location: location,
      date: selectedDate,
      page: 1,
      page_size: 999,
    });

  useEffect(() => {
    if (isSuccess && inventoryData.results) {
      const prevQtyData = inventoryData.results.map(
        (item) => item.prev_qty - item.sum_qty
      );
      const sumQtyData = inventoryData.results.map((item) => item.sum_qty);

      const newChartData = {
        series: [
          {
            name: "SOH",
            data: prevQtyData,
          },
          {
            name: "Consumption",
            data: sumQtyData,
          },
        ],
        options: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          title: {
            show: "",
          },
          dataLabels: {
            enabled: false,
          },
          colors: ["#C05621", "#2D3748"],
          plotOptions: {
            bar: {
              columnWidth: "40%",
              borderRadius: 2,
              horizontal: false, // Change to display bars vertically
            },
          },
          xaxis: {
            categories: inventoryData.results.map((item) => item.nickname),
            labels: {
              style: {
                colors: "#C05621",
                fontSize: "12px",
                fontFamily: "inherit",
                fontWeight: 400,
              },
            },
          },
          yaxis: {
            labels: {
              style: {
                colors: "#C05621",
                fontSize: "12px",
                fontFamily: "inherit",
                fontWeight: 400,
              },
            },
          },
          grid: {
            show: true,
            borderColor: "#C05621",
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: true,
              },
            },
            padding: {
              top: 5,
              right: 20,
            },
          },
          fill: {
            opacity: 0.8,
          },
          tooltip: {
            theme: "dark",
          },
        },
      };
      setChartData(newChartData);
    }
  }, [isSuccess, inventoryData]);

  return (
    <Card className="flex flex-col w-full min-h-[76vh] m-2 p-2">
      <CardHeader
        floated={false}
        shadow={false}
        color="transparent"
        className="flex flex-col gap-4 rounded-none md:flex-row md:items-center"
      >
        <div className="w-max rounded-lg bg-orange-600 p-5 text-white">
          {/* Placeholder for icon or other content */}
        </div>
        <div>
          <Typography variant="h6" color="blue-gray">
            Inventory Item Consumption
          </Typography>
          {/* <Typography variant="small" color="gray" className="max-w-sm font-normal">

          </Typography> */}
        </div>
      </CardHeader>
      <CardBody className="px-2 pb-0 h-full">
        <div className="flex flex-col space-y-4 md:flex-row md:space-x-4 md:space-y-0">
          <div className="flex flex-col md:w-1/2">
            <Select
              label="Select Location"
              name="location"
              value={location}
              onChange={handleChangeLocation}
            >
              <Option value="1">Raw Materials</Option>
              <Option value="2">Foods Duty</Option>
              <Option value="3">Beverages</Option>
              <Option value="4">Consumables</Option>
              <Option value="5">Hardware</Option>
              <Option value="6">Repacking</Option>
              <Option value="7">Plastic</Option>
              <Option value="8">Office Supplies</Option>
            </Select>
          </div>
          <div className="flex flex-col md:w-1/2">
            <Input
              label="Select Date"
              type="date"
              id="end-date"
              value={selectedDate}
              onChange={handleDateChange}
            />
          </div>
        </div>
        {chartData && (
          <Chart
            type="bar"
            height={"80%"}
            width="100%"
            series={chartData.series}
            options={chartData.options}
          />
        )}
      </CardBody>
    </Card>
  );
}
