import { useState, useEffect } from "react";
import { FiSearch } from "react-icons/fi";
import { IoMdAdd } from "react-icons/io";
import { FiDownload } from "react-icons/fi";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
} from "@material-tailwind/react";
import { useFetchRequestQuery } from "../../store";
import { HashLoader } from "react-spinners";
import PurchaseRequestItemVendorView from "../modal_form_components/Warehouse/WarehousePurchaseOrder/PurchaseRequestItemVendorView";

const CompleteTableView = ({
  TABS,
  ViewTitle,
  TABLE_HEAD,
  buttonForModal,
  extractPOButton,
  TABLE_ROWS,
  onTopButtonClick,
  onTopExtractPOButtonClick,
}) => {
  let content;
  let table;
  // Page usestate
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [orderDir] = useState("asc");
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

  let fetch_request = useFetchRequestQuery({
    page: currentPage,
    page_size: pageSize,
    order_by: "id",
    order_dir: orderDir,
    status: 3,
    search: searchTerm,
  });
  useEffect(() => {
    fetch_request.refetch();
  }, []);

  const handleSearch = (e) => setSearchTerm(e.target.value);

  // total page for purchase order
  const POTotalPage = Math.ceil(fetch_request.data?.count / pageSize);

  // function for previous page
  const handlePreviousPage = () => {
    if (currentPage === 1) return;
    setCurrentPage(currentPage - 1);
  };
  // function for next page
  const handleNextPage = () => {
    if (currentPage === POTotalPage) return;
    setCurrentPage(currentPage + 1);
  };

  // function changing the page size of table
  const handlePageSizeChange = (e) => {
    setPageSize(e.target.value);
    setCurrentPage(1);
  };

  if (fetch_request.isLoading) {
    content = <HashLoader />;
  } else if (fetch_request.error) {
    content = <div>Error Loading...</div>;
  } else {
    let result = fetch_request.data.results;

    if (account_detailed.job_position === 17 || account_detailed.job_position === 1){
      table = <>
        <Card className="h-full w-full">
          <CardHeader floated={false} shadow={false} className="rounded-none">
            <div className="mb-11 md:flex items-center justify-between gap-8 lg:mb-8 mt-5 lg:mt-0">
              <div className="w-full md:w-72">
                <Input
                  label="Search"
                  icon={<FiSearch className="h-5 w-5" />}
                  onKeyUp={handleSearch}
                />
              </div>
            </div>
            <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
              <Tabs value="all" className="w-full md:w-max">
                <TabsHeader>
                  {TABS.map(({ label, value }) => (
                    <Tab key={value} value={value}>
                      &nbsp;&nbsp;{label}&nbsp;&nbsp;
                    </Tab>
                  ))}
                </TabsHeader>
              </Tabs>
              <div className="flex items-center mr-0 md:mr-10 space-x-4">
                <Typography>Page size:</Typography>
                <select
                  defaultValue={pageSize}
                  onChange={handlePageSizeChange}
                  className="border-2 border-blue-gray-500 cursor-pointer"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
          </CardHeader>
          <CardBody className="overflow-scroll px-0">
            <table className="mt-4 w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {result.map((rst, i) => {
                const isLast = i === result.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                return (
                  <tr key={i}>
                    <td className={classes}>{rst.po_number}</td>
                    <td className={classes}>{rst.date}</td>
                    <td className={classes}>
                      <PurchaseRequestItemVendorView
                        po_id={rst.id}
                        refetchRIList={fetch_request.refetch}
                      />
                    </td>
                    {/* <td className={classes}>{extractPOButton}</td> */}
                    <td className={classes}>{rst.status}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          </CardBody>
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              Page {currentPage} of {POTotalPage}
            </Typography>
            <div className="flex gap-2">
              <Button variant="outlined" size="sm" onClick={handlePreviousPage}>
                Previous
              </Button>
              <Button variant="outlined" size="sm" onClick={handleNextPage}>
                Next
              </Button>
            </div>
          </CardFooter>
        </Card>
      </>
    }
    content = (
      <div className="">
        <div className="flex space-x-4 md:mr-10 mt-5 md:mt-0 float-right">
          {(buttonForModal !== null) > 0 && (
            <Button
              className="flex items-center lg:gap-3"
              size="sm"
              onClick={onTopButtonClick}
            >
              <IoMdAdd strokeWidth={2} className="h-4 w-4" />
              <Typography className=" text-xs font-semibold">
                {buttonForModal}
              </Typography>
            </Button>
          )}
        </div>
        {table}
      </div>
    );
  }

  return <>{content}</>;
};

export default CompleteTableView;
