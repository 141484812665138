import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Select,
  Option,
} from "@material-tailwind/react";
import { GrPowerReset } from "react-icons/gr";
import ReactSignatureCanvas from "react-signature-canvas";
import { useUpdatedAuditReportCompleteMutation } from "../../../../store";
import { useUpdatedAuditReportStatusMutation } from "../../../../store";
import { useFetchAuditedDataQtyQuery } from "../../../../store";
import { FoodsDutyTbl,
          BeveragesTbl,
          ConsumablesTbl,
          RawmaterialsTbl,
          RepackingTbl,
          PlasticTbl,
          ItemConsumablesTbl,
 } from "../../../table_components/Audit/AuditTbl";
import { ConfirmationV2 } from "../../Error_trap_components/ErrorTrapComponentV2";
import { Confirmation } from "../../Error_trap_components/ErrorTrapComponent";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
const AuditReportModal = ({ refetchMainTable, auditData }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const inputClasses = "flex flex-row items-center";
  const trapMessage =
    "Are you sure you want to complete this audit report? ";
    const buttonName= "Save & Complete"
    const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
    const signatureBoxclasses = "flex mb-1 mt-5";
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const { data, error, isLoading, isSuccess, refetch } = useFetchAuditedDataQtyQuery({
    id: auditData.id,
  })
  const [countRawmats, setCountRawmats] = useState(0);
  const [countRepacking, setCountRepacking] = useState(0);
  const [countPlastics, setCountPlastics] = useState(0);
  const [countFoods, setCountFoods] = useState(0);
  const [countBevs, setCountBevs] = useState(0);
  const [countCons, setCountCons] = useState(0);
  const [countItemCons, setCountItemCons] = useState(0);
  const [assignedRoute, setAssignedRoute] = useState("Route 1");

  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);

  const [selectedStock, setSelectedStock] = useState("");
  const [crewName, setCrewName] = useState("");
  let [productData, setProductData] = useState([]);
  const [signatures, setSignatures] = useState(["", "", "", "","", "","", "","", "","", "","", "","", "","", ""]);
  const signatureRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const handleReset = (index) => {
    if (signatureRefs[index].current) {
      signatureRefs[index].current.clear();
      setSignatures((prevSignatures) => {
        const updatedSignatures = [...prevSignatures];
        updatedSignatures[index] = "";
        return updatedSignatures;
      });

      setIsSignatureEmpty((prevIsSignatureEmpty) => {
        const updatedIsSignatureEmpty = [...prevIsSignatureEmpty];
        updatedIsSignatureEmpty[index] = true;
        return updatedIsSignatureEmpty;
      });
    }
  };
  const handleSignatureChange = (index) => {
    const isEmpty = signatureRefs[index].current.isEmpty();
    setIsSignatureEmpty((prev) => {
      const updated = [...prev];
      updated[index] = isEmpty;
      return updated;
    });
    const base64Signature = signatureRefs[index].current.toDataURL();
    setSignatures((prevSignatures) => {
      const updatedSignatures = [...prevSignatures];
      updatedSignatures[index] = base64Signature;
      return updatedSignatures;
    });
  };
  let [isReadyToSave, setIsReadyToSave] = useState(false);
  let [isReadyToSaveRawmats, setIsReadyToSaveRawmats] = useState(false);
  let [isReadyToSaveRepacking, setIsReadyToSaveRepacking] = useState(false);
  let [isReadyToSavePlastic, setIsReadyToSavePlastic] = useState(false);
  let [isReadyToSaveFoods, setIsReadyToSaveFoods] = useState(false);
  let [isReadyToSaveBev, setIsReadyToSaveBev] = useState(false);
  let [isReadyToSaveCon, setIsReadyToSaveCon] = useState(false);


  useEffect(() => {
    if (
      countRawmats === 0 &&
      countRepacking === 0 &&
      countPlastics === 0 &&
      countFoods === 0 &&
      countBevs === 0 &&
      countCons === 0 &&
      countItemCons === 0
    ) {
      setIsReadyToSave(true);
    } else {
      setIsReadyToSave(false);
    }


    if (
      isSignatureEmpty[0] == false  &&
      isSignatureEmpty[1] == false  &&
      isSignatureEmpty[2] == false  &&
      countRawmats === 0
      // countPlastics === 0 &&
      // countFoods === 0 &&
      // countBevs === 0 &&
      // countCons === 0 &&
      // countItemCons === 0
    ) {
      setIsReadyToSaveRawmats(true);
    } else {
      setIsReadyToSaveRawmats(false);
    }

    if (
      isSignatureEmpty[3] == false  &&
      isSignatureEmpty[4] == false  &&
      isSignatureEmpty[5] == false  &&
      countRepacking === 0
      // countPlastics === 0 &&
      // countFoods === 0 &&
      // countBevs === 0 &&
      // countCons === 0 &&
      // countItemCons === 0
    ) {
      setIsReadyToSaveRepacking(true);
    } else {
      setIsReadyToSaveRepacking(false);
    }

    if (
      isSignatureEmpty[6] == false &&
      isSignatureEmpty[7] == false  &&
      isSignatureEmpty[8] == false &&
      countPlastics === 0
      // countFoods === 0 &&
      // countBevs === 0 &&
      // countCons === 0 &&
      // countItemCons === 0
    ) {
      setIsReadyToSavePlastic(true);
    } else {
      setIsReadyToSavePlastic(false);
    }

    if (
      isSignatureEmpty[9] == false &&
      isSignatureEmpty[10] == false &&
      isSignatureEmpty[11] == false &&
      countFoods === 0
      // countBevs === 0 &&
      // countCons === 0 &&
      // countItemCons === 0
    ) {
      setIsReadyToSaveFoods(true);
    } else {
      setIsReadyToSaveFoods(false);
    }

    if (
      isSignatureEmpty[12] == false &&
      isSignatureEmpty[13] == false &&
      isSignatureEmpty[14] == false &&
      countBevs === 0
      // countCons === 0 &&
      // countItemCons === 0
    ) {
      setIsReadyToSaveBev(true);
    } else {
      setIsReadyToSaveBev(false);
    }

    if (
      isSignatureEmpty[15] == false &&
      isSignatureEmpty[16] == false &&
      isSignatureEmpty[17] == false &&
      countCons === 0 &&
      countItemCons === 0
    ) {
      setIsReadyToSaveCon(true);
    } else {
      setIsReadyToSaveCon(false);
    }
    console.log(isSignatureEmpty);
  }, [countRawmats, countRepacking, countPlastics, countFoods, countBevs, countCons, countItemCons, isSignatureEmpty]);


  let handlePlasticCount = (data) => {
    setCountPlastics(data);
  };
  let handleRepackingCount = (data) => {
    setCountRepacking(data);
  };
  let handleRawmatsCount = (data) => {
    setCountRawmats(data);
  };
  let handleFoodsCount = (data) => {
    setCountFoods(data);
  };
  let handleBeveragesCount = (data) => {
    setCountBevs(data);
  };
  let handleItemConsumablesCount = (data) => {
    setCountItemCons(data);
  };
  let handleConsumablesCount = (data) => {
    setCountCons(data);
  };


  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const token = localStorage.getItem("mytoken");
  let navigate = useNavigate();




  let handleProductOrderChange = (data) => {
    setProductData(data);
  };


    const handleScroll = (id) => {
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };
    const [completeAuditReport] = useUpdatedAuditReportStatusMutation();
    const handleComplete = async (event) => {
      event.preventDefault();
      let allData = {
        auditId: auditData.id,
        user: account_detailed.user.id,
        status: 5,
      };
      console.log(allData);
      try {
        const sea = await completeAuditReport(allData).unwrap();
        if (sea.success === true) {
          toast.success(sea.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });

          // handleClose();
          refetchMainTable();
          handleClose();
        } else {
          toast.error(sea.message, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            progress: undefined,
          });
          // formRef.current?.reset();
        }
      } catch (error) {
        // Handle the error here
        // You can display a generic error message to the user if needed
        const errorMessage =
          error?.data?.message || "An error occurred. Please try again later.";
        toast.error(errorMessage, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    };


  const [saveAuditedQty] = useUpdatedAuditReportCompleteMutation();
  const handleSave = async (event, section, auditorSign, custodianSign, financeSign) => {
    event.preventDefault();
    let base64Auditor = auditorSign.slice("data:image/png;base64,".length);
    let base64Custodian = custodianSign.slice("data:image/png;base64,".length);
    let base64Finance = financeSign.slice("data:image/png;base64,".length);
    let allData = {
      auditId: auditData.id,
      user: account_detailed.user.id,
      section: section,
      auditorSign: base64Auditor,
      custodianSign: base64Custodian,
      financeSign: base64Finance,
    };
    console.log(allData);
    try {
      const sea = await saveAuditedQty(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });

        // handleClose();
        refetchMainTable();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // formRef.current?.reset();
      }
    } catch (error) {
      // Handle the error here
      // You can display a generic error message to the user if needed
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };
  let outlet_list_result = [];
    return (
      <>
        <Button onClick={handleOpen} className="bg-[#1AA55D]" size="sm">
          View
        </Button>
        <Dialog
          open={open}
          size="xl"
          handler={handleOpen}
          className="relative max-h-min"
        >
          <DialogHeader className=" block w-full space-x-10 rounded-sm justify-center">
            {/* <img
              src={burgerMachineLogo}
              alt="bmlogo"
              className="aspect-auto w-72"
            /> */}
            <div className=" space-x-4 p-2 rounded-md">
            <Typography className="text-center text-green-900" variant="h3">
            Inventory Audit Form
            </Typography>
            </div>
            <div className="justify-center flex space-x-4 p-2 rounded-md">
            <div className="relative inline-block">
          <label
         className="cursor-pointer text-lg text-green-600 border border-green-600 p-2 rounded-md hover:bg-green-600 hover:text-white"
            onClick={() => handleScroll('rawmaterials')}
          >
            Raw Materials
          </label>
          {countRawmats > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
                {countRawmats}
              </span>
            )}
          </div>
          <div className="relative inline-block">
          <label
           className="cursor-pointer text-lg text-green-600 border border-green-600 p-2 rounded-md hover:bg-green-600 hover:text-white"
            onClick={() => handleScroll('repacking')}
          >
            Repacking
          </label>
          {countRepacking > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
                {countRepacking}
              </span>
            )}
          </div>
          <div className="relative inline-block">
          <label
              className="cursor-pointer text-lg text-green-600 border border-green-600 p-2 rounded-md hover:bg-green-600 hover:text-white"
            onClick={() => handleScroll('plastic')}
          >
            Plastic
          </label>
          {countPlastics > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
                {countPlastics}
              </span>
            )}
          </div>
          <div className="relative inline-block">
          <label
            className="cursor-pointer text-lg text-green-600 border border-green-600 p-2 rounded-md hover:bg-green-600 hover:text-white"
            onClick={() => handleScroll('foodsdty')}
          >
            Foods Duty
          </label>
          {countFoods > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
                {countFoods}
              </span>
            )}
          </div>
          <div className="relative inline-block">
          <label
             className="cursor-pointer text-lg text-green-600 border border-green-600 p-2 rounded-md hover:bg-green-600 hover:text-white"
            onClick={() => handleScroll('beverages')}
          >
            Beverages
          </label>
          {countBevs > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
                {countBevs}
              </span>
            )}
          </div>
          <div className="relative inline-block">
          <label
              className="cursor-pointer text-lg text-green-600 border border-green-600 p-2 rounded-md hover:bg-green-600 hover:text-white"
              onClick={() => handleScroll('consumables')}
            >
              Consumables
            </label>
            {countItemCons + countCons > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
                {countItemCons + countCons}
              </span>
            )}
          </div>
        </div>
          </DialogHeader>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
            <form>
              <div className="grid md:grid-cols-4 gap-5 grid-auto-rows-auto">
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Date"
                      name="date"
                      value={formattedDate}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Audit Number"
                      name="audit"
                      value={auditData.audit_number}
                      readOnly
                    />
                  </div>

                </div>
                <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                    <Input
                      label="Auditor Name"
                      name="auditor"
                      value={auditData.auditor_name}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="m-5">
              {isSuccess &&(
                <>
                  <div id="rawmaterials" className="section">
                   <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                   Raw Materials Area
                   <RawmaterialsTbl
                   onOrderChange={handleProductOrderChange}
                   auditQtys={data.data}
                   auditId={auditData.id}
                   refetchTable={refetch}
                   noDataCount={handleRawmatsCount}
                   />
                 </Typography>
                 </div>
                   <div className="grid grid-cols-6 gap-5 grid-auto-rows-auto ml-20">
                   <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[0]}
                          onEnd={() => handleSignatureChange(0)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(0)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                       </div>
                       <Typography className="text-center text-black mr-20" variant="h6">
                    {"Auditor"}
                  </Typography>
                    </div>
                    <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[1]}
                          onEnd={() => handleSignatureChange(1)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(1)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                      </div>
                      <Typography className="text-center text-black mr-20" variant="h6">
                    {"Custodian"}
                  </Typography>

                    </div>
                    <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[2]}
                          onEnd={() => handleSignatureChange(2)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(2)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                      </div>
                      <Typography className="text-center text-black mr-20" variant="h6">
                    {"Finance"}
                  </Typography>
                    </div>
                    </div>
                    <div className="items-center">
                    <Confirmation
                      onConfirm={(e) => handleSave(e, 1, signatures[0], signatures[1], signatures[2], e.target.value)}
                      trapMessage={trapMessage}
                      isReadyToSave={isReadyToSaveRawmats}
                    />
                  </div>
                    </>
                )}
                    {isSuccess &&(
                      <>
                  <div id="repacking" className="section">
                   <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                   Repacking Area
                   <RepackingTbl
                   onOrderChange={handleProductOrderChange}
                   auditQtys={data.data}
                   auditId={auditData.id}
                   refetchTable={refetch}
                   noDataCount={handleRepackingCount}
                   />
                 </Typography>
                 </div>
                 <div className="grid grid-cols-6 gap-5 grid-auto-rows-auto ml-20">
                   <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[3]}
                          onEnd={() => handleSignatureChange(3)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(3)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                       </div>
                       <Typography className="text-center text-black mr-20" variant="h6">
                    {"Auditor"}
                  </Typography>
                    </div>
                    <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[4]}
                          onEnd={() => handleSignatureChange(4)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(4)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                      </div>
                      <Typography className="text-center text-black mr-20" variant="h6">
                    {"Custodian"}
                  </Typography>

                    </div>
                    <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[5]}
                          onEnd={() => handleSignatureChange(5)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(5)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                      </div>
                      <Typography className="text-center text-black mr-20" variant="h6">
                    {"Finance"}
                  </Typography>
                    </div>
                    </div>
                    <div className="items-center">
                    <Confirmation
                      onConfirm={(e) => handleSave(e, 5, signatures[3], signatures[4], signatures[5], e.target.value)}
                      trapMessage={trapMessage}
                      isReadyToSave={isReadyToSaveRepacking}
                    />
                  </div>
                 </>
                )}
                 {isSuccess &&(
                  <>
                  <div id="plastic" className="section">
                   <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                   Plastic Area
                   <PlasticTbl
                   onOrderChange={handleProductOrderChange}
                   auditQtys={data.data}
                   auditId={auditData.id}
                   refetchTable={refetch}
                   noDataCount={handlePlasticCount}
                   />
                 </Typography>
                 </div>
                 <div className="grid grid-cols-6 gap-5 grid-auto-rows-auto ml-20">
                   <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[6]}
                          onEnd={() => handleSignatureChange(6)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(6)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                       </div>
                       <Typography className="text-center text-black mr-20" variant="h6">
                    {"Auditor"}
                  </Typography>
                    </div>
                    <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[7]}
                          onEnd={() => handleSignatureChange(7)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(7)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                      </div>
                      <Typography className="text-center text-black mr-20" variant="h6">
                    {"Custodian"}
                  </Typography>

                    </div>
                    <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-blackborder-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[8]}
                          onEnd={() => handleSignatureChange(8)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(8)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                      </div>
                      <Typography className="text-center text-black mr-20" variant="h6">
                    {"Finance"}
                  </Typography>
                    </div>
                    </div>
                    <div className="items-center">
                    <Confirmation
                      onConfirm={(e) => handleSave(e, 6, signatures[6], signatures[7], signatures[8], e.target.value)}
                      trapMessage={trapMessage}
                      isReadyToSave={isReadyToSavePlastic}
                    />
                  </div>
                 </>
                )}
                {isSuccess &&(
                  <>
                  <div id="foodsdty" className="section">
                   <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                   Foods Duty Area
                   <FoodsDutyTbl
                   onOrderChange={handleProductOrderChange}
                   auditQtys={data.data}
                   auditId={auditData.id}
                   refetchTable={refetch}
                   noDataCount={handleFoodsCount}
                   />
                 </Typography>
                 </div>
                 <div className="grid grid-cols-6 gap-5 grid-auto-rows-auto ml-20">
                   <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[9]}
                          onEnd={() => handleSignatureChange(9)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(9)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                       </div>
                       <Typography className="text-center text-black mr-20" variant="h6">
                    {"Auditor"}
                  </Typography>
                    </div>
                    <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[10]}
                          onEnd={() => handleSignatureChange(10)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(10)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                      </div>
                      <Typography className="text-center text-black mr-20" variant="h6">
                    {"Custodian"}
                  </Typography>

                    </div>
                    <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[11]}
                          onEnd={() => handleSignatureChange(11)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(11)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                      </div>
                      <Typography className="text-center text-black mr-20" variant="h6">
                    {"Finance"}
                  </Typography>
                    </div>
                    </div>
                    <div className="items-center">
                    <Confirmation
                      onConfirm={(e) => handleSave(e, 2, signatures[9], signatures[10], signatures[11], e.target.value)}
                      trapMessage={trapMessage}
                      isReadyToSave={isReadyToSaveFoods}
                    />
                  </div>
                 </>
                )}
                 {isSuccess &&(
                  <>
                   <div id="beverages" className="section">
                   <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                   Beverages Area
                   <BeveragesTbl
                   onOrderChange={handleProductOrderChange}
                   auditQtys={data.data}
                   auditId={auditData.id}
                   refetchTable={refetch}
                   noDataCount={handleBeveragesCount}
                   />
                 </Typography>
                 </div>
                 <div className="grid grid-cols-6 gap-5 grid-auto-rows-auto ml-20">
                   <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[12]}
                          onEnd={() => handleSignatureChange(12)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(12)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                       </div>
                       <Typography className="text-center text-black mr-20" variant="h6">
                    {"Auditor"}
                  </Typography>
                    </div>
                    <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[13]}
                          onEnd={() => handleSignatureChange(13)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(13)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                      </div>
                      <Typography className="text-center text-black mr-20" variant="h6">
                    {"Custodian"}
                  </Typography>

                    </div>
                    <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[14]}
                          onEnd={() => handleSignatureChange(14)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(14)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                      </div>
                      <Typography className="text-center text-black mr-20" variant="h6">
                    {"Finance"}
                  </Typography>
                    </div>
                    </div>
                    <div className="items-center">
                    <Confirmation
                      onConfirm={(e) => handleSave(e, 3, signatures[12], signatures[13], signatures[14], e.target.value)}
                      trapMessage={trapMessage}
                      isReadyToSave={isReadyToSaveBev}
                    />
                  </div>
                 </>
                )}
                {isSuccess &&(
                  <>
                  <div id="consumables" className="section">
                   <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                   Consumables Area
                   <ConsumablesTbl
                   onOrderChange={handleProductOrderChange}
                   auditQtys={data.data}
                   auditId={auditData.id}
                   refetchTable={refetch}
                   noDataCount={handleConsumablesCount}
                   />
                   <ItemConsumablesTbl
                   onOrderChange={handleProductOrderChange}
                   auditQtys={data.data}
                   auditId={auditData.id}
                   refetchTable={refetch}
                   noDataCount={handleItemConsumablesCount}
                   />
                 </Typography>
                 </div>
                 <div className="grid grid-cols-6 gap-5 grid-auto-rows-auto ml-20">
                   <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[15]}
                          onEnd={() => handleSignatureChange(15)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(15)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                       </div>
                       <Typography className="text-center text-black mr-20" variant="h6">
                    {"Auditor"}
                  </Typography>
                    </div>
                    <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[16]}
                          onEnd={() => handleSignatureChange(16)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(16)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                      </div>
                      <Typography className="text-center text-black mr-20" variant="h6">
                    {"Custodian"}
                  </Typography>

                    </div>
                    <div className="col-span-2 space-y-5">
                        <div className={signatureBoxclasses}>
                        <ReactSignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-s-xl",
                          }}
                          ref={signatureRefs[17]}
                          onEnd={() => handleSignatureChange(17)}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => handleReset(17)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                      </div>
                      <Typography className="text-center text-black mr-20" variant="h6">
                    {"Finance"}
                  </Typography>
                    </div>
                    </div>
                    <div className="items-center">
                    <Confirmation
                      onConfirm={(e) => handleSave(e, 4, signatures[15], signatures[16], signatures[17], e.target.value)}
                      trapMessage={trapMessage}
                      isReadyToSave={isReadyToSaveCon}
                    />
                  </div>
                 </>
                )}
              </div>
            </form>
            <ToastContainer />
          </DialogBody>
          <DialogFooter>
            <ConfirmationV2
            onConfirm={handleComplete}
            isReadyToSave={isReadyToSave}
            trapMessage={trapMessage}
            buttonName={buttonName}
            />
            <Button
              variant="text"
              color="red"
              className="mr-3"
              onClick={handleClose}
            >
              <span>Close</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
};

export default AuditReportModal;
