import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Checkbox,
  CardFooter,
  Select,
  Option,
} from "@material-tailwind/react";
import { FaSyncAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import AddDelTbl from "../../table_components/Delivery/AddDelTbl";
import { GrPowerReset } from "react-icons/gr";
import ReactSignatureCanvas from "react-signature-canvas";
import GenDelReceiptModal from "./GenDelReceipt";
import { useFetchDeliveryOutletQuery } from "../../../store";
import { useFetchOrderNumberListPageQuery } from "../../../store";
import { useFetchOutletListPageQuery } from "../../../store";
import { useUpdateOrderToTransferMutation } from "../../../store";
import { useFetchRouteListQuery } from "../../../store";
import { useFetchFCListQuery } from "../../../store";
import { AddDeliveryConfirmation } from "../Error_trap_components/AddDeliveryConfirmation";

import { toast } from "react-toastify";
const AddDeliveryModal = ({ refetchDeliveryList }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const inputClasses = "flex flex-row items-center";
  const signatureClasses = "grid grid-flow-row grid-cols-1";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const tableHead = ["", ""];
  const tableHeadSelectOrder = ["", "ORDER #", "OUTLET", "ROUTE", "FC"];
  const tableHheadSelectOutlet = ["", "OUTLET", "ADDRESS"];
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  let outlet_list_result = [];
  let order_number_list_result = [];
  let outlet_list_page_result = [];
  let route_data = [];
  let fc_data = [];
  const {
    data: outletData,
    error,
    isLoading,
    isSuccess: outletIsSuccess,
  } = useFetchDeliveryOutletQuery();
  const [selectedCage, setSelectedCage] = useState("");
  // const [selectedStock, setSelectedStock] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOutlets, setSelectedOutlets] = useState([]);
  const [currentOutletPage, setCurrentOutletPage] = useState(1);
  const [pageOutletSize, setPageOutletSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [selectedFC, setSelectedFC] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState([]);
  const [selectedOrderNumber, setSelectedOrderNumber] = useState([]);
  const [isOrderNumDialogOpen, setIsOrderNumDialogOpen] = useState(false);
  const [isOutletDialogOpen, setIsOutletDialogOpen] = useState(false);
  const [isFCDialogOpen, setIsFCDialogOpen] = useState(false);
  const [isRouteDialogOpen, setIsRouteDialogOpen] = useState(false);
  const [isReadyToSave, setIsReadyToSave] = useState(false);
  const [isFilterClicked, setIsFilterClicked] = useState(false);
  const [followSchedule, setFollowSchedule] = useState(true);
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const {
    data: fcData,
    error: fcError,
    isLoading: fcLoading,
    isSuccess: fcSuccess,
  } = useFetchFCListQuery();
  const {
    data: routeData,
    error: routeError,
    isLoading: loadingRoute,
    isSuccess: routeSucess,
  } = useFetchRouteListQuery({
    page: 1,
    page_size: 50,
    schedule: followSchedule,
  });
  const selectedFCIds = selectedFC.map((fc) => fc.id);
  const selectedRouteIds = selectedRoute.map((route) => route.id);
  const {
    data: outletListData,
    error: outletListErr,
    isLoading: outletListLoding,
    isSuccess: outletListSuccess,
  } = useFetchOutletListPageQuery({
    page: currentOutletPage,
    page_size: pageOutletSize,
    search: searchTerm,
    fc: "",
    route: "",

  });

  let outletIds = selectedOutlets.map((outlet) => outlet.id);
  const {
    data: orderNumberData,
    error: orderNumberError,
    isLoading: orderNumberIsLoading,
    isSuccess: orderNumberIsSuccess,
    refetch,
  } = useFetchOrderNumberListPageQuery({
    page: currentPage,
    page_size: pageSize,
    search: "",
    fc: selectedFCIds,
    route: selectedRouteIds,
    outlet: outletIds,
    schedule: followSchedule,
  });

  //fetching logo/img from localstorage
  const logo = JSON.parse(localStorage.getItem("account_detail"));
  // const burgerMachineLogo =
  //   process.env.REACT_APP_API_DOMAIN + "" + logo.client.logo;

  const burgerMachineLogo = "../images/BurgerMachineLogo.jpg";

  useEffect(() => {
    if (orderNumberIsSuccess && orderNumberData.results) {
      const orderNumbers = orderNumberData.results.map(
        (result) => result.order_number
      );
      setSelectedOrderNumber(orderNumbers);
    }
  }, [orderNumberIsSuccess, orderNumberData]);

  const [updateToTransfer] = useUpdateOrderToTransferMutation();

  const [signature, setSignature] = useState("");
  const signatureRef = React.createRef();
  const handleSignatureReset = () => {
    signatureRef.current.clear();
    setSignature(""); // Reset the stored base64 representation
  };
  // Handle change event
  const handleSignatureChange = () => {
    const base64Signature = signatureRef.current.toDataURL(); // Get base64 representation
    setSignature(base64Signature);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentOutletPage(1);
    setCurrentPage(1);
  };
  // const handleStockChange = (value) => {
  //   setSelectedStock(value);
  // };
  const handleCageChange = (value) => {
    setSelectedCage(value);
  };
  const handlePreviousOutletPage = () => {
    setCurrentOutletPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextOutletPage = () => {
    setCurrentOutletPage((prevPage) => prevPage + 1);
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleChangeFollowSched = () => {
    setFollowSchedule(!followSchedule);
    console.log(followSchedule);
  };

  const handleClearFc = () => {
    setSelectedFC([]);
  };
  const handleClearRoute = () => {
    setSelectedRoute([]);
    setSelectedOrderNumber([]);
  };
  const handleClearOutlet = () => {
    setSelectedOutlets([]);
  };
  const handleClearOrderNumber = () => {
    setSelectedOrderNumber([]);
  };

  const removeOrderNumber = (orderNumberToRemove) => {
    setSelectedOrderNumber((prevOrderNumbers) =>
      prevOrderNumbers.filter(
        (orderNumber) => orderNumber !== orderNumberToRemove
      )
    );
    setSelectedFC([]);
    setSelectedRoute([]);
    setSelectedOutlets([]);
    setCurrentPage(1);
  };
  const handleOutletChange = ({ outletId, outletName }) => {
    // Check if the outlet is already selected
    const isSelected = selectedOutlets.some((outlet) => outlet.id === outletId);

    // If selected, remove it; otherwise, add it to the array
    setSelectedOutlets((prevSelectedOutlets) =>
      isSelected
        ? prevSelectedOutlets.filter((outlet) => outlet.id !== outletId)
        : [...prevSelectedOutlets, { id: outletId, outlet: outletName }]
    );
    setCurrentPage(1);
    setSelectedFC([]);
    setSelectedRoute([]);
  };

  // const handleOutletChange = (outlet) => {
  //   // Toggle the selectedFC array based on checkbox change
  //   setSelectedOutlet((prevSelected) =>
  //     prevSelected.includes(outlet)
  //       ? prevSelected.filter((selected) => selected !== outlet)
  //       : [...prevSelected, outlet]

  //   );
  //   setCurrentPage(1);
  // };
  useEffect(() => {
    // Check if all conditions are met
    const isReady =
      signature !== "" && selectedCage !== "" && selectedOrderNumber.length > 0;
    // Update the state based on the condition
    setIsReadyToSave(isReady);
  }, [signature, selectedCage, selectedOrderNumber]);

  const handleChangeFCChange = (fc) => {
    const isSelected = selectedFC.some((selectedFC) => selectedFC.id === fc.id);

    if (isSelected) {
      setSelectedFC((prevSelectedFC) =>
        prevSelectedFC.filter((selectedFC) => selectedFC.id !== fc.id)
      );
    } else {
      setSelectedFC((prevSelectedFC) => [
        ...prevSelectedFC,
        { id: fc.id, name: fc.name },
      ]);
    }

    setCurrentPage(1);
  };

  const handleChangeRouteChange = (route) => {
    const isSelected = selectedRoute.some(
      (selectedRoute) => selectedRoute.id === route.id
    );

    if (isSelected) {
      setSelectedRoute((prevSelectedRoute) =>
        prevSelectedRoute.filter(
          (selectedRoute) => selectedRoute.id !== route.id
        )
      );
    } else {
      setSelectedRoute((prevSelectedRoute) => [
        ...prevSelectedRoute,
        { id: route.id, name: route.name },
      ]);
    }

    setCurrentPage(1);

    setIsFilterClicked(true);
  };

  const handleOrderNumberChange = (order_number) => {
    setSelectedOrderNumber((prevSelected) =>
      prevSelected.includes(order_number)
        ? prevSelected.filter((selected) => selected !== order_number)
        : [...prevSelected, order_number]
    );
  };

  const handleOpenOrderNumber = () => {
    // Your logic to handle opening the dialog
    refetch();
    setIsOrderNumDialogOpen(true);
  };

  const handleOpenFCDialog = () => {
    // Your logic to handle opening the dialog
    setIsFCDialogOpen(true);
  };

  const handleOpenRouteDialog = () => {
    // Your logic to handle opening the dialog
    setIsRouteDialogOpen(true);
  };
  const handleOpenOutletDialog = () => {
    // Your logic to handle opening the dialog
    setIsOutletDialogOpen(true);
    console.log(selectedOutlets);
  };

  const handleDialogClose = () => {
    // Your logic to handle closing the dialog
    setIsOutletDialogOpen(false);
    setIsFCDialogOpen(false);
    setIsRouteDialogOpen(false);
    setIsOrderNumDialogOpen(false);
    console.log(isReadyToSave);
  };

  const handleMainClose = () => {
    setOpen(false);
    setSelectedOrderNumber([]);
    setSignature("");
    setSelectedCage("");
  };

  const handleUpdateTransfer = async (event) => {
    event.preventDefault();
    // Gather data from each table component

    // Repeat for other table components

    // Combine all data as needed
    let base64Data = signature.slice("data:image/png;base64,".length);
    let allData = {
      custodianSignature: base64Data,
      orderNumberIds: selectedOrderNumber,
      account: account_detailed,
      cageNumber: selectedCage,
    };
    console.log(account_detailed);
    try {
    const sea = await updateToTransfer(allData).unwrap();
    if (sea.success === true) {
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      // formRef.current?.reset();
    }

    setSelectedOrderNumber([]);
    setSelectedRoute([]);
    setSelectedFC([]);
    setSelectedOutlets([]);
    setCurrentPage(1);
    setCurrentOutletPage(1);
    await refetch();
    handleOpen();
    refetchDeliveryList();
    handleMainClose();
  } catch (error) {
    // Handle the error here
    // You can display a generic error message to the user if needed
    const errorMessage =
      error?.data?.message || "An error occurred. Please try again later.";
    toast.error(errorMessage, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    });
  }
  };

  if (fcLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (fcError) {
    return <div>Error loading data</div>;
  } else if (fcData.length === 0) {
    return <div>No data found.</div>;
  } else if (fcSuccess) {
    fc_data = fcData.data;
  }

  if (loadingRoute) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (routeError) {
    return <div>Error loading data</div>;
  } else if (routeData.length === 0) {
    return <div>No data found.</div>;
  } else if (routeSucess) {
    route_data = routeData.results;
  }

  if (outletListLoding) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (outletListErr) {
    return <div>Error loading data</div>;
  } else if (outletListData.length === 0) {
    return <div>No data found.</div>;
  } else if (outletListSuccess) {
    outlet_list_page_result = outletListData.results;
  }

  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (outletData.length === 0) {
    return <div>No data found.</div>;
  } else if (outletIsSuccess) {
    outlet_list_result = outletData.data;
  }

  if (orderNumberIsLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (orderNumberError) {
    return <div>Error loading data</div>;
  } else if (orderNumberData.length === 0) {
    return <div>No data found.</div>;
  } else if (orderNumberIsSuccess) {
    order_number_list_result = orderNumberData.results;
  }
  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]" size="sm">
        Add Delivery
      </Button>
      <Dialog
        open={open}
        size="xl"
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-y-3 md:space-y-0 md:space-x-10 rounded-sm flex flex-col md:flex-row justify-center">
          <img
            src={burgerMachineLogo}
            alt="logo"
            className="aspect-auto w-72"
          />
          <Typography className="text-green-900" variant="h3">
            Add Delivery
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[55vh]">
          <form>
            <div className="grid md:grid-cols-4 gap-5 grid-auto-rows-auto">
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input
                    label="Delivery"
                    name="delivery"
                    value={formattedDate}
                    readOnly
                  />
                </div>
                <div className={inputClasses}>
                  <Select
                    label="Delivery Cage Number"
                    name="cage"
                    value={selectedCage}
                    onChange={handleCageChange}
                  >
                    <Option value="">Select Cage</Option>
                    <Option value="1">Cage 1</Option>
                    <Option value="2">Cage 2</Option>
                    <Option value="3">Cage 3</Option>
                    <Option value="4">Cage 4</Option>
                    <Option value="5">Cage 5</Option>
                    <Option value="6">Cage 6</Option>
                  </Select>
                </div>
                {/* <div className={inputClasses}>
                  <Select
                    label="Stock Level"
                    name="sl"
                    value={selectedStock}
                    onChange={handleStockChange}
                  >
                    <Option value="">Select SL</Option>
                    <Option value="3">Low Stock Level</Option>
                    <Option value="4">High Stock Level</Option>
                  </Select>
                </div> */}
              </div>
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input
                    label="Route"
                    value={selectedRoute.map((route) => route.name).join(", ")}
                    onClick={handleOpenRouteDialog}
                    readOnly
                  />
                  {selectedRoute.length > 0 && (
                    <Button
                      onClick={handleClearRoute}
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "green",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <FaSyncAlt style={{ color: "white" }} />
                    </Button>
                  )}
                  <Dialog
                    size="sm"
                    open={isRouteDialogOpen}
                    handler={handleDialogClose}
                    className="relative max-h-min"
                  >
                    <DialogHeader>
                      <Typography className="text-green-900" variant="h3">
                        Select Route
                      </Typography>
                    </DialogHeader>
                    <DialogBody className="overflow-y-auto max-h-[50vh]">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {tableHead.map((head, index) => (
                              <th
                                key={index}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {route_data.map((route) => {
                            const classes = "p-4 border-b border-blue-gray-50";
                            return (
                              <tr key={route.id}>
                                <td className={classes}>
                                  <Checkbox
                                    type="checkbox"
                                    color="teal"
                                    checked={selectedRoute.some(
                                      (selectedRoute) =>
                                        selectedRoute.id === route.id
                                    )}
                                    onChange={() => {
                                      handleChangeRouteChange(route);
                                      setIsFilterClicked(true);
                                    }}
                                  />
                                </td>
                                <td className={classes}>{route.name}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </DialogBody>
                    {/* <DialogFooter>
                      <Button
                        variant="text"
                        color="red"
                        onClick={handleDialogClose}
                        className="mr-1"
                      >
                        <span>Cancel</span>
                      </Button>
                      <Button
                        variant="gradient"
                        color="green"
                        onClick={handleDialogClose}
                      >
                        <span>Confirm</span>
                      </Button>
                    </DialogFooter> */}
                  </Dialog>
                </div>
                <div className={inputClasses}>
                  {/* <Input
                    label="FC"
                    value={selectedFC.map((fc) => fc.name).join(", ")}
                    onClick={handleOpenFCDialog}
                    readOnly
                  /> */}
                  {selectedFC.length > 0 && (
                    <Button
                      onClick={handleClearFc}
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "green",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <FaSyncAlt style={{ color: "white" }} />
                    </Button>
                  )}
                  <Dialog
                    size="sm"
                    open={isFCDialogOpen}
                    handler={handleDialogClose}
                    className="relative max-h-min"
                  >
                    <DialogHeader>
                      <Typography className="text-green-900" variant="h3">
                        Select FC
                      </Typography>
                    </DialogHeader>
                    <DialogBody className="overflow-y-auto max-h-[40vh]">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {tableHead.map((head, index) => (
                              <th
                                key={index}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {fc_data.map((fc) => {
                            const classes = "p-4 border-b border-blue-gray-50";
                            return (
                              <tr key={fc.id}>
                                <td className={classes}>
                                  <Checkbox
                                    type="checkbox"
                                    color="teal"
                                    checked={selectedFC.some(
                                      (selectedFC) => selectedFC.id === fc.id
                                    )}
                                    onChange={() => handleChangeFCChange(fc)}
                                  />
                                </td>
                                <td className={classes}>{fc.name}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </DialogBody>
                    {/* <DialogFooter>
                      <Button
                        variant="text"
                        color="red"
                        onClick={handleDialogClose}
                        className="mr-1"
                      >
                        <span>Cancel</span>
                      </Button>
                      <Button
                        variant="gradient"
                        color="green"
                        onClick={handleDialogClose}
                      >
                        <span>Confirm</span>
                      </Button>
                    </DialogFooter> */}
                  </Dialog>
                </div>
                <div className={inputClasses}>
                  {/* <Input
                    label="Outlet"
                    value={selectedOutlets
                      .map((outlet) => outlet.outlet)
                      .join(", ")}
                    onClick={handleOpenOutletDialog}
                    readOnly
                  /> */}
                  <Dialog
                    size="xl"
                    open={isOutletDialogOpen}
                    handler={handleDialogClose}
                    className="relative max-h-min"
                  >
                    <DialogHeader>
                      <Typography className="text-green-900" variant="h3">
                        Select Outlets
                      </Typography>
                    </DialogHeader>
                    <DialogBody className="overflow-y-auto max-h-[50vh]">
                      <Input
                        label="Search"
                        icon={<FiSearch className="h-5 w-5" />}
                        value={searchTerm}
                        onChange={handleSearchChange}
                      />

                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {tableHheadSelectOutlet.map((head, index) => (
                              <th
                                key={index}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {outlet_list_page_result.map((outlet) => {
                            const classes = "p-4 border-b border-blue-gray-50";
                            return (
                              <tr key={outlet.id}>
                                <td className={classes}>
                                  <Checkbox
                                    type="checkbox"
                                    color="teal"
                                    checked={selectedOutlets.some(
                                      (o) => o.id === outlet.id
                                    )}
                                    onChange={() =>
                                      handleOutletChange({
                                        outletId: outlet.id,
                                        outletName: outlet.outlet,
                                      })
                                    }
                                  />
                                </td>
                                <td className={classes}>{outlet.outlet}</td>
                                <td className={classes}>{outlet.address}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </DialogBody>
                    <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        Page{" "}
                        {`${currentOutletPage} of ${
                          Math.ceil(outletListData?.count / pageOutletSize) || 1
                        }`}
                      </Typography>
                      <div className="flex gap-2">
                        <Button
                          variant="outlined"
                          size="sm"
                          onClick={handlePreviousOutletPage}
                          disabled={!outletListData?.previous}
                        >
                          Previous
                        </Button>
                        <Button
                          variant="outlined"
                          size="sm"
                          onClick={handleNextOutletPage}
                          disabled={!outletListData?.next}
                        >
                          {outletListData?.next ? "Next" : "No More Pages"}
                        </Button>
                      </div>
                    </CardFooter>
                    {/* <DialogFooter>
                      <Button
                        variant="text"
                        color="red"
                        onClick={handleDialogClose}
                        className="mr-1"
                      >
                        <span>Cancel</span>
                      </Button>
                      <Button
                        variant="gradient"
                        color="green"
                        onClick={handleDialogClose}
                      >
                        <span>Confirm</span>
                      </Button>
                    </DialogFooter> */}
                  </Dialog>
                  {selectedOutlets.length > 0 && (
                    <Button
                      onClick={handleClearOutlet}
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "green",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <FaSyncAlt style={{ color: "white" }} />
                    </Button>
                  )}
                </div>
              </div>
            </div>
            <div>
              <div
                className="inline-flex items-center mt-5"
                onClick={handleChangeFollowSched}
              >
                <Checkbox
                  type="checkbox"
                  color="green"
                  checked={followSchedule}
                  onChange={handleChangeFollowSched}
                />

                <label className="mt-px font-light text-green-900 cursor-pointer select-none">
                  Follow Schedule?
                </label>
              </div>
              <Typography className="text-green-900 mt-5" variant="h3">
                Order Numbers
              </Typography>
            </div>

            <div className={inputClasses}>
              <Input
                label="Select Order Number.."
                value={selectedOrderNumber.join(", ")}
                onClick={handleOpenOrderNumber}
                readOnly
              />
              {selectedOrderNumber.length > 0 && (
                <Button
                  onClick={handleClearOrderNumber}
                  style={{
                    marginLeft: "8px",
                    backgroundColor: "green",
                    padding: "10px",
                    cursor: "pointer",
                  }}
                >
                  <FaSyncAlt style={{ color: "white" }} />
                </Button>
              )}
              <Dialog
                size="xl"
                open={isOrderNumDialogOpen}
                handler={handleDialogClose}
                className="relative max-h-min"
              >
                <DialogHeader>
                  <Typography className="text-green-900" variant="h3">
                    Select Order Number
                  </Typography>
                </DialogHeader>
                <DialogBody className="overflow-y-auto max-h-[80vh]">
                  <table className="w-full min-w-max table-auto text-left">
                    <thead>
                      <tr>
                        {tableHeadSelectOrder.map((head, index) => (
                          <th
                            key={index}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {order_number_list_result
                        .filter(
                          (order_number, index, self) =>
                            self.findIndex(
                              (o) =>
                                o.order_number === order_number.order_number
                            ) === index
                        )
                        .map((order_number) => {
                          const classes = "p-4 border-b border-blue-gray-50";
                          return (
                            <tr key={order_number.id}>
                              <td className={classes}>
                                <Checkbox
                                  type="checkbox"
                                  color="teal"
                                  checked={selectedOrderNumber.includes(
                                    order_number.order_number
                                  )}
                                  onChange={() =>
                                    handleOrderNumberChange(
                                      order_number.order_number
                                    )
                                  }
                                />
                              </td>
                              <td className={classes}>
                                {order_number.order_number}
                              </td>
                              <td className={classes}>
                                {order_number.outlet.outlet}
                              </td>
                              <td className={classes}>
                                {order_number.outlet.fc.name}
                              </td>
                              <td className={classes}>
                                {order_number.outlet.route.name}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </DialogBody>
                <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal"
                  >
                    Page{" "}
                    {`${currentPage} of ${
                      Math.ceil(orderNumberData?.count / pageSize) || 1
                    }`}
                  </Typography>
                  <div className="flex gap-2">
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={handlePreviousPage}
                      disabled={!orderNumberData?.previous}
                    >
                      Previous
                    </Button>
                    <Button
                      variant="outlined"
                      size="sm"
                      onClick={handleNextPage}
                      disabled={!orderNumberData?.next}
                    >
                      {orderNumberData?.next ? "Next" : "No More Pages"}
                    </Button>
                  </div>
                </CardFooter>
                {/* <DialogFooter>
                      <Button
                        variant="text"
                        color="red"
                        onClick={handleDialogClose}
                        className="mr-1"
                      >
                        <span>Cancel</span>
                      </Button>
                      <Button
                        variant="gradient"
                        color="green"
                        onClick={handleDialogClose}
                      >
                        <span>Confirm</span>
                      </Button>
                    </DialogFooter> */}
              </Dialog>
            </div>
            <div className="m-5">
              <AddDelTbl
                selectedOrderNumbers={selectedOrderNumber}
                removeOrderNumber={removeOrderNumber}
              />
            </div>
            <Typography className="text-green-900" variant="h6">
              Total Selected Order Number: {selectedOrderNumber.length || 0}
            </Typography>
            <div className="col-span-1 flex items-center justify-center space-y-5">
              <div className={signatureBoxclasses}>
                <ReactSignatureCanvas
                  ref={signatureRef}
                  penColor="black"
                  canvasProps={{
                    width: 200,
                    height: 100,
                    className: "border-black border-2 rounded-s-xl",
                  }}
                  onEnd={handleSignatureChange}
                />
                <div className={boxResetClasses}>
                  <GrPowerReset
                    size={30}
                    className="cursor-pointer"
                    onClick={handleSignatureReset}
                    color="black"
                  />
                </div>
              </div>
            </div>
            <div className="col-span-1 flex items-center justify-center space-y-5">
              <Typography className="text-justify text-black" variant="h6">
                {account_detailed.user.first_name
                  ? account_detailed.user.last_name
                    ? `${account_detailed.user.first_name} ${account_detailed.user.last_name}`
                    : "Encoder"
                  : "Encoder"}
              </Typography>
            </div>
            {signature == "" && (
              <Typography variant="lead" color="red">
                Please provide a signature.
              </Typography>
            )}
            {selectedCage == "" && (
              <Typography variant="lead" color="red">
                Please provide a cage number.
              </Typography>
            )}
            {/* {selectedStock == "" && (
              <Typography variant="lead" color="red">
                Please provide a stock level.
              </Typography>
            )} */}
            {selectedOrderNumber.length === 0 && (
              <Typography variant="lead" color="red">
                Please provide a order number.
              </Typography>
            )}
            <div className="col-span-1 flex items-center justify-center space-y-5 mt-10">
              <AddDeliveryConfirmation
                onConfirm={handleUpdateTransfer}
                isReadyToSave={isReadyToSave}
              />
            </div>
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            className="mr-3"
            onClick={handleMainClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default AddDeliveryModal;
