import { Card, Typography } from "@material-tailwind/react";

const TABLE_HEAD = [
  "Items",
  "Unit Measure",
  "Actual Quantity",
  "Price",
  "Amount",
  "Value Amount if VAT",
  "W/TAX PURC (less 1%",
];

function PrintRRTbl({ vendor_items }) {
  const trclasses = "p-4 border-b border-blue-gray-50";
  return (
    <Card className="h-full w-[110%] ml-[-16px] md:ml-0 md:w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left ">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {vendor_items.map((v_item, i) => {
            return (
              <>
                <tr className={trclasses}>
                  <td>{v_item.request_item.item.name}</td>
                  <td className={trclasses}>
                    {v_item.request_item.item.unit_measurement}
                  </td>
                  <td className={trclasses}>
                    {v_item.des_actual_qty}
                  </td>
                  <td className={trclasses}>
                    {"₱ " + v_item.quoted_price}
                  </td>
                  <td className={trclasses}>
                    {"₱ " + v_item.amount}
                  </td>
                  <td className={trclasses}>
                    {"₱ " + v_item.vat_amount}
                  </td>
                  <td className={trclasses}>
                   {"₱ " + v_item.w_tax_purc}
                  </td>
                </tr>
              </>
              )
          })}
        </tbody>
        <tfoot></tfoot>
      </table>
    </Card>
  );
}

export default PrintRRTbl;
