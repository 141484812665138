import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function DatePickerReact({ handleDatePicker, edit }) {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [showDate, setShowDate] = useState(false);

  const handleChange = (date) => {
    setSelectedDate(date);
    let new_date = `${selectedDate.getFullYear()}-${selectedDate.getMonth()}-${selectedDate.getDay()}`;
    handleDatePicker(new_date);
  };

  const openDatePicker = () => setShowDate(!showDate);

  return (
    <div>
      <DatePicker
        className="bordered border p-1 border-blue-gray-300 rounded-lg"
        selected={selectedDate}
        value={selectedDate}
        onChange={handleChange}
        dateFormat="yyyy/MM/dd"
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        placeholderText="Select"
        onInputClick={openDatePicker}
        onClickOutside={openDatePicker}
        open={showDate}
        disabled={edit === false}
      />
    </div>
  );
}

export default DatePickerReact;
