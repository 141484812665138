import {
  Card,
  Typography,
  Input,
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
} from "@material-tailwind/react";
import {
  useCreateNewPoMutation,
  useUpdateSuggestedVendorMutation
} from "../../../../store";
import { ToastContainer, toast } from "react-toastify";
import { useState } from "react";


const RawMatReceiptTbl = ({
  data,
  refetch_data,
  receiving_remarks
}) => {
  const TABLE_HEAD = [
    "Items",
    "Unit Measure",
    "Ordered Quantity",
    "Actual Quantity",
    "Remarks",
    "Status",
    "Action",
  ];
  const trclasses = "p-4 border-b border-blue-gray-50";
  const [openCreateNewPO, setOpenCreateNewPO] = useState(false);
  const handleOpenCreatePO = () => setOpenCreateNewPO(!openCreateNewPO);
  const [newPoData, setNewPOData] = useState();
  const [add_raw_mats] = useUpdateSuggestedVendorMutation();
  const api_domain = process.env.REACT_APP_API_DOMAIN;
  const rawMatActualQTY = async (e, id, type) => {
    let rm_form = {
      id: id,
    };

    if (type === 'actual_qty'){
      rm_form.actual_qty = parseInt(e);
    }

    if (type === 'des_actual_qty'){
      rm_form.des_actual_qty = parseFloat(e)
    }

    if (type === 'remark'){
      rm_form.receiving_remarks = e;
    }

    const armts = await add_raw_mats(rm_form).unwrap();
    if (armts.success === true) {
      toast.success('Successfully update data', {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(armts.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetch_data();
  }

  let raw_mats_list = []

  for (let i = 0; i < data.length; i++) {
    let svl = data[i];
    raw_mats_list.push(
      {
        id: svl.id,
        item_name: svl.request_item.item.name,
        unit_measurement: svl.request_item.item.unit_measurement,
        required_qty: svl.required_qty,
        des_required_qty: svl.des_required_qty,
        des_actual_qty: svl.des_actual_qty,
        actual_qty: svl.actual_qty,
        receiving_remarks: svl.receiving_remarks,
        delivery_status: svl.delivery_status
      }
    )
  }


  const [createNewPOMutation] = useCreateNewPoMutation();
  const createNewPO = async (data) => {
    let data_a = {
      "api_domain": api_domain,
      "data": data
    }
    const cnpm = await createNewPOMutation(data_a).unwrap();
    if (cnpm.success === true) {
      toast.success(cnpm.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }else {
      toast.error(cnpm.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetch_data();
    handleOpenCreatePO();
  }

  const handleCreateNewPO = (item_data) => {
    setNewPOData(item_data)
    handleOpenCreatePO()
  }
  return (
    <>
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
        {raw_mats_list
            .sort((a, b) => b.id - a.id)
            .map((vl, index) => {
              return (
                <tr  key={index} className={trclasses}>
                  <td className={trclasses}>
                      {vl.item_name}
                  </td>
                  <td className={trclasses}>
                      {vl.unit_measurement}
                  </td>
                  <td className={trclasses}>
                      {vl.des_required_qty}
                  </td>
                  <td className={trclasses}>
                    <Input
                      autoFocus={false}
                      label="Actual Quantity"
                      type="number"
                      defaultValue={vl.des_actual_qty}
                      onBlur={(e) => {
                        rawMatActualQTY(
                          e.target.value,
                          vl.id,
                          "des_actual_qty"
                        );
                      }}
                    />
                  </td>
                  <td className={trclasses}>
                    <textarea
                      placeholder="Enter your remarks here..."
                      cols="20"
                      rows="5"
                      autoFocus={false}
                      className="border-2 border-blue-gray-500 text-black p-2"
                      onBlur={(e) => {
                        rawMatActualQTY(
                          e.target.value,
                          vl.id,
                          'remark'
                        );
                      }}
                    >{vl.receiving_remarks}</textarea>
                  </td>
                  <td>
                    {vl.des_required_qty ===  vl.des_actual_qty && <Typography className="text-green-700">Completed</Typography>}
                    {vl.des_required_qty > vl.des_actual_qty && <Typography className="text-red-700">Partially Completed</Typography>}
                    {vl.des_required_qty < vl.des_actual_qty && <Typography className="text-yellow-700">Excess</Typography>}
                  </td>
                  <td>
                    <div className="flex justify-center gap-2 lg:gap-0 pr-2 md:pr-0">
                      { vl.already_created_po === true ? <Typography color="green">Already created new PO</Typography> :
                        <Button
                          className="bg-green-900"
                          size="sm"
                          onClick={() => handleCreateNewPO(vl)}
                          disabled={vl.delivery_status}
                        >
                          Generate new PO
                        </Button>
                      }
                    </div>
                  </td>
                </tr>
              );
          })}
        </tbody>
      </table>
      <ToastContainer />
      </Card>
      <Dialog open={openCreateNewPO} handler={handleOpenCreatePO}>
        <DialogHeader>Create new PO</DialogHeader>
        <DialogBody>Are you sure you'd like to proceed?</DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpenCreatePO}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={() => createNewPO(newPoData)}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
      </>
  );
}

export default RawMatReceiptTbl;
