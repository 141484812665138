import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
import {
  useFetchDetailNotificationQuery,
  useSetSeenNotificationMutation,
} from "../../../store/apis/NotificationApi";
import moment from "moment";
import Moment from "react-moment";
import { Link } from "react-router-dom";

function DetailNotification({ id }) {
  const [open, setOpen] = React.useState(false);
  const { data, isLoading, error } = useFetchDetailNotificationQuery({
    id: id,
  });
  console.log(data, id);
  const [seenNotification] = useSetSeenNotificationMutation();

  // handle for updating unseen - seen and opening the modal
  const handleOpen = () => {
    setOpen(!open);
    seenNotification({ notifId: id });
  };

  return (
    <>
      <Button onClick={handleOpen} variant="gradient" size="sm">
        View Detail
      </Button>
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>
          <div className="">
            <Typography className=" font-bold text-2xl">
              Notification Information
            </Typography>
            <Typography>
              {moment(data?.data.date_delivered).format("YYYY-MM-DD HH:mm")}
            </Typography>
          </div>
        </DialogHeader>
        <DialogBody>
          <div className="flex flex-row space-x-2">
            <Typography className="text-lg ">Subject:</Typography>
            <Typography className=" font-bold text-lg text-blue-gray-900">
              {data?.data.subject}
            </Typography>
          </div>
          <div>
            <Typography className="text-lg text-blue-gray-700 mt-5">
              {data?.data.message}
            </Typography>
          </div>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Link to="/base/approval">
            <Button className=" bg-green-900">
              <span>Navigate</span>
            </Button>
          </Link>
        </DialogFooter>
      </Dialog>
    </>
  );
}
export default DetailNotification;
