import React, { useState} from "react";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
} from "@material-tailwind/react";
import { FiSearch } from "react-icons/fi";
import ProdHistoryModal from "../modal_form_components/Warehouse/WarehouseInventory/ProductHistory";
import { useFetchProductInventoryListQuery } from "../../store";

const TABLE_HEAD = [
  "SKU",
  "Name",
  "UM",
  "Weight Box",
  "SOH",
  "Pieces SOH",
  "Last Modified",
  "Action",
  "Status",
];
export function CompleteTableProductView() {
  let result = [];
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "desc",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(1);
  const columnMapping = {
    "Order Number": "order_number",
    Outlet: "outlet",
    Route: "outlet__route",
    "Order Date": "order_date",
    Status: "status",
  };

  const { data, error, isLoading, isSuccess, refetch } =
    useFetchProductInventoryListQuery({
      page: currentPage,
      page_size: pageSize,
      category: selectedCategory,
      search: searchTerm,
      order_by: sortConfig.key,
      order_dir: sortConfig.direction,
    });

  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleClickRefresh = () => {
    setIsRefreshing(true);

    // Trigger the refetch function
    refetch();

    // Disable the button after 2 seconds
    setTimeout(() => {
      setIsRefreshing(false);
    }, 2000);
  };

  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const filterCategory = [];

  if (account_detailed.job_position === 7) {
    filterCategory.push(
      {
        label: "Foods Duty",
        value: 1,
      }
    );
    } else if (account_detailed.job_position === 8) {
      filterCategory.push(
        {
          label: "Beverages",
          value: 2,
        },
        {
          label: "Consumables",
          value: 3,
        }
      );

  }else{
    filterCategory.push(
      {
        label: "Foods Duty",
        value: 1,
      },
      {
        label: "Beverages",
        value: 2,
      },
      {
        label: "Consumables",
        value: 3,
      }
    )
  }

  // useEffect(() => {
  //   const fetchData = () => {
  //     refetch();
  //   };

  //   const intervalId = setInterval(fetchData, 15000);

  //   return () => clearInterval(intervalId);
  // }, [refetch]);

  const handleSort = (column) => {
    let direction = "asc";
    if (
      sortConfig.key === columnMapping[column] &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key: columnMapping[column], direction });
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePageSizeChange = (e) => {
    const selectedSize = parseInt(e.target.value);
    console.log("Selected Page Size:", selectedSize);
    setPageSize(selectedSize);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleTabChange = (value) => {
    setCurrentPage(1);
    setSelectedCategory(value);
    refetch();
  };
  // variable for select html tag
  const selectedTab = filterCategory.find(
    (item) => item.value === parseInt(selectedCategory)
  );

  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    result = data.results;
    console.log(result);
    return (
      <Card className="h-full w-full mb-20 lg:mb-0">
        <CardHeader floated={false} shadow={false} className="rounded-none">
          <div>
            {/* <Typography className="text-lg text-blue-gray-800 font-semibold tracking-normal">
              Track your inventory
            </Typography> */}
            {/* <Typography className=" text-sm">
              Monitor the Inventory data
            </Typography> */}
          </div>
          {/* <div className="mb-8 flex items-center justify-end gap-8 mt-5">
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              <DelCalendar />
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                <AdvOrderModal refetchDeliveryList={refetch} />
              </div>
              <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                <AddDeliveryModal refetchDeliveryList={refetch} />
              </div>
              <Button className="bg-[#1AA55D]" size="sm">
                Add Outlet
              </Button>
            </div>
          </div> */}

          {/* <div className="flex justify-end mb-3">
            <div className="w-full md:w-72">
              <div className="col-span-2 space-y-5">
                <Input
                  label="Search"
                  icon={<FiSearch className="h-5 w-5" />}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />

                <div className="md:w-5 mt-1">
                  <Select
                    label="Page Size"
                    value={`${pageSize}`}
                    onChange={(e) => handlePageSizeChange(e)}
                  >
                    <Option value={`${10}`}>10</Option>
                    <Option value={`${25}`}>25</Option>
                    <Option value={`${50}`}>50</Option>
                  </Select>
                </div>
              </div>
            </div>
          </div> */}
            <div className="md:flex justify-between mt-5 md:mt-10">
            <div className="ml-5 mb-10 lg:mb-4">
              <Input
                label="Search"
                icon={<FiSearch className="h-5 w-5" />}
                value={searchTerm}
                onChange={handleSearchChange}
              />
            </div>
            <div className="md:mr-10 flex justify-end mt-3 md:mt-0 items-center space-x-4">
              <Typography className=" font-medium text-sm">
                Page size:
              </Typography>
              <select
                className="block appearance-none bg-white border border-gray-300 rounded-md py-2 px-3 leading-tight focus:outline-none focus:border-indigo-500"
                value={pageSize}
                onChange={(e) => handlePageSizeChange(e)}
              >
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
              </select>
            </div>
          </div>

          <div className="flex md:flex-col space-y-5">
            <Tabs value={selectedCategory} className="w-full hidden md:block">
              <TabsHeader>
                {filterCategory.map(({ label, value }) => (
                  <Tab
                    className="text-sm"
                    key={value}
                    value={value}
                    onClick={() => handleTabChange(value)}
                  >
                    <Typography className=" font-normal text-sm">
                      &nbsp;&nbsp;{label}&nbsp;&nbsp;
                    </Typography>
                  </Tab>
                ))}
              </TabsHeader>
            </Tabs>
            <div className="relative md:hidden mr-16">
              <select
                value={selectedTab ? selectedTab.value : ""}
                className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                onChange={(e) => handleTabChange(e.target.value)}
              >
                {filterCategory.map(({ value, label }) => (
                  <option key={value} value={value} className=" z-50">
                    {label}
                  </option>
                ))}
              </select>
              <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                Filter by
              </label>
            </div>

          </div>
        </CardHeader>
        <CardBody className="overflow-scroll px-0">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="border-b border-green-600 bg-[#1AA55D] text-white p-4"
                    onClick={() => handleSort(head)}
                    style={{ cursor: "pointer" }}
                  >
                    <Typography
                      variant="small"
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        textTransform: "uppercase",
                      }}
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {result.map((item, index) => {
                const isLast = index === result.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                const formattedDate = item.updated_at
                  ? new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    }).format(new Date(item.updated_at))
                  : "";

                // Calculate the percentage of stock on hand relative to average consumption
                const percentage =
                  (item.stock_on_hand / item.average_consume) * 100;
                let status = "SUFFICIENT";
                let statusColor = "green";
                if (percentage < 50) {
                  status = "REORDER";
                  statusColor = "orange";
                }
                if (percentage < 25) {
                  status = "CRITICAL";
                  statusColor = "red";
                }

                return (
                  <tr key={item.id}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.product.product_code !== null &&
                            item.product.product_code !== undefined
                              ? item.product.product_code
                              : "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.product.name !== null &&
                            item.product.name !== undefined
                              ? item.product.name
                              : "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.product.unit_measurement !== null &&
                            item.product.unit_measurement !== undefined
                              ? item.product.unit_measurement
                              : "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {item.product.weight_box !== null &&
                            item.product.weight_box !== undefined
                              ? item.product.weight_box
                              : "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    {/* <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {(parseFloat(item.average_consume) || 0).toFixed(2)}
                          </Typography>
                        </div>
                      </div>
                    </td> */}
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color={
                              status === "Reorder"
                                ? "orange"
                                : status === "Critical"
                                ? "red"
                                : "green"
                            }
                            className="font-normal"
                          >
                            {item.stock_on_hand !== null &&
                            item.stock_on_hand !== undefined
                              ? new Intl.NumberFormat().format(
                                item.stock_on_hand
                              )
                              : "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          {item.raw_soh !== null &&
                            item.raw_soh !== undefined
                              ? new Intl.NumberFormat().format(
                                item.raw_soh
                              )
                              : "N/A"}
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {formattedDate}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <ProdHistoryModal
                            prodData={item.product.id}
                            prodName={item.product.name}
                            prodCode={item.product.product_code}
                          />
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color={statusColor}
                            className="font-normal"
                          >
                            {status}
                          </Typography>
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {`${currentPage} of ${Math.ceil(data?.count / pageSize) || 1}`}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              size="sm"
              onClick={handlePreviousPage}
              disabled={!data?.previous}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleClickRefresh}
              disabled={isRefreshing}
            >
              Refresh
            </Button>

            <Button
              variant="outlined"
              size="sm"
              onClick={handleNextPage}
              disabled={!data?.next}
            >
              {data?.next ? "Next" : "No More Pages"}
            </Button>
          </div>
        </CardFooter>
      </Card>
    );
  }
}
