import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Checkbox,
  CardFooter,
  Select,
  Option,
  Card,
} from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { FaSyncAlt } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";
import { GrPowerReset } from "react-icons/gr";
import ReactSignatureCanvas from "react-signature-canvas";
import { Confirmation } from "../../Error_trap_components/ErrorTrapComponent";
import { ToastContainer, toast } from "react-toastify";
import { useTransferCompletedRequestListPageQuery } from "../../../../store";
import { useCreateTransferRequestReturnMutation } from "../../../../store";
import { useFetchTransferRequestDetailsQuery } from "../../../../store";

const formattedDate = (date) => {
  if (!date) {
    return "N/A";
  }

  return new Date(date).toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
};

const getTransferId = (apiData) => {
  return apiData.results.map((trans) => ({
    id: trans.id,
    requested_by:
      (trans.requested_by?.first_name || "") +
      " " +
      (trans.requested_by?.middle_name || "") +
      " " +
      (trans.requested_by?.last_name || ""),
    received_by:
      (trans.received_custodian?.first_name || "") +
      " " +
      (trans.received_custodian?.middle_name || "") +
      " " +
      (trans.received_custodian?.last_name || ""),
    requested_at: formattedDate(trans.requested_at),
    received_at: formattedDate(trans.receive_at),
    tracking_num: trans.transfer_number,
  }));
};

const getTransferItem = (apiData) => {
  return apiData.data.map((t) => ({
    id: t.item.id,
    item_name: t.item.name,
    unit_measurement: t.item.unit_measurement,
    weight_box: t.item.weight_box,
    required_qty: t.required_qty,
  }));
};

const ManualReturntModal = ({ refetchDeliveryList }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const inputClasses = "flex flex-row items-center";
  const signatureClasses = "grid grid-flow-row grid-cols-1";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const tableHead = ["", ""];
  const tableHeadTransferList = [
    "",
    "Tracking #",
    "Rquested By",
    "Received By",
    "Requested Date",
    "Completed Date",
  ];
  const trapMessage = "Are you sure to return these products?";
  const currentDate = new Date();

  const classes = "p-4 border-b border-blue-gray-50";
  const TABLE_HEAD = [
    "Item",
    "Unit Measurement",
    "Received Qty",
    "Return Qty",
    "Consumed Qty",
  ];
  const [currentTransListPage, setCurrentTransListPage] = useState(1);
  const [currentTransListPageSize, setCurrentTransListPageSize] = useState(10);
  const [currentTransSearchTerm, setCurrentTransSearchTerm] = useState("");
  const [returnReceiver, setReturnReceiver] = useState("");
  const [selectedTransId, setSelectedTransID] = useState(null);
  const [selectedTransObj, setSelectedTransObj] = useState(null);
  const [isTransferDialogOpen, setIsTransferDialogOpen] = useState(false);
  const [trasferListData, setTrasferListData] = useState([]);
  const [trasferItemListData, setTrasferItemListData] = useState([]);
  const [isReadyToSave, setIsReadyToSave] = useState(false);
  const [actualQtys, setActualQtys] = useState([]);
  const [signature, setSignature] = useState("");
  const [signatureReceiver, setSignatureReceiver] = useState("");
  const signatureRef = React.createRef();
  const signatureReceiverRef = React.createRef();
  const handleSignatureReset = () => {
    signatureRef.current.clear();
    setSignature("");
  };
  const handleSignaturReceivereReset = () => {
    signatureReceiverRef.current.clear();
    setSignatureReceiver("");
  };
  const account_detail = JSON.parse(localStorage.getItem("account_detail"));
  // const token = localStorage.getItem("mytoken");
  // let navigate = useNavigate();
  const {
    data: transData,
    error: transErr,
    isLoading: transIsLoading,
    isSuccess: transSuccess,
    refetch,
  } = useTransferCompletedRequestListPageQuery({
    page: currentTransListPage,
    page_size: currentTransListPageSize,
  });
  const { data, isError, isLoading, isSuccess } =
    useFetchTransferRequestDetailsQuery(selectedTransId);

  useEffect(() => {
    if (transSuccess) {
      setTrasferListData(getTransferId(transData));
    }

    if (isSuccess) {
      setTrasferItemListData(getTransferItem(data));
    }
  }, [transSuccess, isSuccess]);

  const handleOpenTransferDialog = () => {
    setIsTransferDialogOpen(true);
  };

  const handleTransIdDialogClose = () => {
    setIsTransferDialogOpen(false);
  };

  const handleTrasferIdChange = (value) => {
    setSelectedTransObj(value);
    setSelectedTransID(value.id);
    setIsTransferDialogOpen(false);
  };

  const handleTransPrevPage = () => {
    setCurrentTransListPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleTransNextPage = () => {
    setCurrentTransListPage((prevPage) => prevPage + 1);
  };
  const handleTransIdClear = () => {
    setSelectedTransID(null);
    setSelectedTransObj(null);
  };
  const handleMainClose = () => {
    setOpen(false);
    setSignature("");
  };

  let handleActualQtyChange = (e, index) => {
    let inputValue = parseInt(e.target.value, 10);
    let maxQty = trasferItemListData[index].required_qty;

    if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= maxQty) {
      setActualQtys((prevQtys) => {
        let newQtys = [...prevQtys];
        newQtys[index] = inputValue;
        return newQtys;
      });
    }
  };

  const handleSignatureChange = () => {
    const base64Signature = signatureRef.current.toDataURL();
    setSignature(base64Signature);
  };
  const handleSignatureReceiverChange = () => {
    const base64Signature = signatureReceiverRef.current.toDataURL();
    setSignatureReceiver(base64Signature);
  };

  const [addTransReturnRequest] = useCreateTransferRequestReturnMutation();
  const handleSave = async (event) => {
    let item_data = actualQtys.map((qty, index) => ({
      itemId: trasferItemListData[index].id,
      itemQty: qty,
    }));
    event.preventDefault();
    let allData = {
      requester: account_detail.user.id,
      requestItems: [],
      transId: selectedTransId,
      types: 2,
    };

    allData.requestItems = allData.requestItems.concat(item_data);
    console.log(allData);
    try {
      const sea = await addTransReturnRequest(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
      <Button onClick={handleOpen} className="bg-[#FFA500]" size="sm">
        Return Form
      </Button>
      <Dialog
        open={open}
        size="xl"
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          <Typography className="text-green-900" variant="h3">
            Return Form
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <form>
            <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto">
              <div className="col-span-2 space-y-5">
                <div className="flex items-center justify-center">
                  <Input
                    label="Transfer Tracking Number"
                    value={selectedTransObj?.tracking_num || ""}
                    onClick={handleOpenTransferDialog}
                    readOnly
                  />
                  <Dialog
                    size="xl"
                    open={isTransferDialogOpen}
                    handler={handleTransIdDialogClose}
                    className="relative max-h-min"
                  >
                    <DialogHeader>
                      <Typography className="text-green-900" variant="h3">
                        Select Requested Transfer
                      </Typography>
                    </DialogHeader>
                    <DialogBody className="overflow-y-auto max-h-[50vh]">
                      <Input
                        label="Search"
                        icon={<FiSearch className="h-5 w-5" />}
                        // value={searchTerm}
                        // onChange={handleSearchChange}
                      />

                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {tableHeadTransferList.map((head, index) => (
                              <th
                                key={index}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody className="text-center">
                          {trasferListData.length > 0 ? (
                            trasferListData.map((t) => {
                              return (
                                <tr
                                  key={t.id}
                                  onClick={() => handleTrasferIdChange(t)}
                                >
                                  <td className={classes}>
                                    <input
                                      type="radio"
                                      color="teal"
                                      name="selectedOutlet"
                                      checked={selectedTransId === t.id}
                                      onChange={() => handleTrasferIdChange(t)}
                                    />
                                  </td>
                                  <td className={classes}>{t.tracking_num}</td>
                                  <td className={classes}>{t.requested_by}</td>
                                  <td className={classes}>{t.received_by}</td>
                                  <td className={classes}>{t.requested_at}</td>
                                  <td className={classes}>{t.receive_at}</td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan={8}
                                className="p-4 border-b border-blue-gray-50"
                              >
                                <Typography
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  No data available
                                </Typography>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </DialogBody>
                    <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal"
                      >
                        Page{" "}
                        {`${currentTransListPage} of ${
                          Math.ceil(transData?.count / currentTransListPage) ||
                          1
                        }`}
                      </Typography>
                      <div className="flex gap-2">
                        <Button
                          variant="outlined"
                          size="sm"
                          onClick={handleTransPrevPage}
                          disabled={!transData?.previous}
                        >
                          Previous
                        </Button>
                        <Button
                          variant="outlined"
                          size="sm"
                          onClick={handleTransNextPage}
                          disabled={!transData?.next}
                        >
                          {transData?.next ? "Next" : "No More Pages"}
                        </Button>
                      </div>
                    </CardFooter>
                  </Dialog>
                  {selectedTransId !== null && (
                    <Button
                      onClick={handleTransIdClear}
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "green",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <FaSyncAlt style={{ color: "white" }} />
                    </Button>
                  )}
                </div>
                {selectedTransObj !== null && (
                  <>
                    <div className="flex items-center justify-center">
                      <Input
                        label="Received By"
                        value={selectedTransObj.received_by}
                        readOnly
                      />
                    </div>
                    <div className="flex items-center justify-center">
                      <Input
                        label="Received At"
                        value={selectedTransObj.received_at}
                        readOnly
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="col-span-2 space-y-5">
                {selectedTransObj !== null && (
                  <>
                    <div className="flex items-center justify-center">
                      <Input
                        label="Return Issuance Date"
                        value={formattedDate(currentDate)}
                        readOnly
                      />
                    </div>
                    <div className="flex items-center justify-center">
                      <Input
                        label="Return Issuer"
                        value={`${account_detail.user.first_name} ${account_detail.user.last_name}`}
                        readOnly
                      />
                    </div>
                    {/* <div className="flex items-center justify-center">
                      <Input label="Return Receiver" value={returnReceiver} />
                    </div> */}
                  </>
                )}
              </div>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                {/* <Input
                    label="Order Number"
                    value={
                      selectedOrdernumber
                        ? selectedOrdernumber.order_number
                        : ""
                    }
                    onClick={handleOrderNumberDialog}
                    readOnly
                  /> */}
              </div>
            </div>
            {selectedTransId !== null && (
              <div className="m-5">
                <Card className="h-full w-full overflow-scroll">
                  <table className="w-full min-w-max table-auto text-left ">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head) => (
                          <th
                            key={head}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {trasferItemListData.map((item, index) => {
                        let difference =
                          item.required_qty - (actualQtys[index] || 0);
                        return (
                          <tr key={index}>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.item_name || "N/A"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.weight_box || "N/A"} /{" "}
                                    {item.unit_measurement || "N/A"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td
                              className={classes}
                              style={{ textAlign: "center" }}
                            >
                              <div className="flex items-center justify-center">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.required_qty}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes} key={index}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <input
                                    type="number"
                                    defaultValue={actualQtys[index] || ""}
                                    onChange={(e) =>
                                      handleActualQtyChange(e, index)
                                    }
                                    style={{
                                      width: "80px",
                                      padding: "5px",
                                      border: "1px solid #000",
                                    }}
                                  />
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  {item.required_qty &&
                                    actualQtys[index] !== undefined && (
                                      <Typography
                                        variant="small"
                                        color={
                                          difference === 0 ? "green" : "red"
                                        }
                                        className="font-normal"
                                        component={"span"}
                                      >
                                        {difference === 0
                                          ? "Complete"
                                          : `Missing (${difference})`}
                                      </Typography>
                                    )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
              </div>
            )}

            {selectedTransId !== null && (
              <>
                <div className="col-span-1 flex items-center justify-center space-y-5">
                  <div className={signatureBoxclasses}>
                    <ReactSignatureCanvas
                      ref={signatureRef}
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 100,
                        className: "border-black border-2 rounded-s-xl",
                      }}
                      onEnd={handleSignatureChange}
                    />
                    <div className={boxResetClasses}>
                      <GrPowerReset
                        size={30}
                        className="cursor-pointer"
                        onClick={handleSignatureReset}
                        color="black"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-1 flex items-center justify-center space-y-5">
                  <Typography className="text-justify text-black" variant="h6">
                    {account_detail.user.first_name
                      ? account_detail.user.last_name
                        ? `${account_detail.user.first_name} ${account_detail.user.last_name}`
                        : "Issuer"
                      : "Issuer"}
                  </Typography>
                </div>

                {/* <div className="col-span-1 flex items-center justify-center space-y-5">
                  <div className={signatureBoxclasses}>
                    <ReactSignatureCanvas
                      ref={signatureRef}
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 100,
                        className: "border-black border-2 rounded-s-xl",
                      }}
                      onEnd={handleSignatureChange}
                    />
                    <div className={boxResetClasses}>
                      <GrPowerReset
                        size={30}
                        className="cursor-pointer"
                        onClick={handleSignatureReset}
                        color="black"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-span-1 flex items-center justify-center space-y-5">
                  <Typography className="text-justify text-black" variant="h6">
                    {account_detail.user.first_name
                      ? account_detail.user.last_name
                        ? `${account_detail.user.first_name} ${account_detail.user.last_name}`
                        : "Receiver"
                      : "Receiver"}
                  </Typography>
                </div> */}
              </>
            )}
            <div className="col-span-1 flex items-center justify-center space-y-5 mt-10">
              <Confirmation
                onConfirm={handleSave}
                isReadyToSave={true}
                trapMessage={trapMessage}
              />
            </div>
          </form>
        </DialogBody>
        <ToastContainer />
        <DialogFooter>
          {signature == "" && (
            <Typography variant="lead" color="red">
              Please provide a signature.
            </Typography>
          )}
          <Button
            variant="text"
            color="red"
            className="mr-10 ml-10"
            onClick={handleMainClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default ManualReturntModal;
