import React from "react";
import ApprovalPurchaseOrder from "../../modal_form_components/Approval/ApprovalPurchaseOrder";

import {
  Typography,
  CardBody,
  CardFooter,
  Button,
} from "@material-tailwind/react";

function PurchaseOrderTable({
  handlePoPageSizeChange,
  PurchaseOrderResult,
  POTotalPage,
  handlePreviousPage,
  handlePoNextPage,
  handleAscending,
  handleDescending,
  PoTableHead,
  poPageSize,
  currentPage,
  orderBy,
  autoFetch,
}) {
  return (
    <>
      <div className="container">
        <div className="lg:px-5">
          <div className="flex flex-col md:flex-row justify-between md:items-center">
            <div className="mt-5 lg:mt-0 space-x-2">
              <Button
                size="sm"
                variant="outlined"
                disabled={orderBy === "desc"}
                color="teal"
                onClick={handleDescending}
              >
                Newest
              </Button>
              <Button
                size="sm"
                variant="outlined"
                disabled={orderBy === "asc"}
                onClick={handleAscending}
              >
                Oldest
              </Button>
            </div>
            <div className="flex justify-end items-center md:mr-10 mt-5 md:mt-0 space-x-4">
              <Typography>Page size:</Typography>
              <select
                defaultValue={poPageSize}
                onChange={handlePoPageSizeChange}
                className="border-2 border-blue-gray-500 cursor-pointer"
              >
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
          <CardBody className="px-0 overflow-y-auto max-h-[60vh]">
            <table className=" w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {PoTableHead.map((head) => (
                    <th
                      key={head}
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {PurchaseOrderResult.map((v, index) => {
                  const isLast = index === PurchaseOrderResult.length - 1;
                  const PoIds = v.id;
                  const PoId = PoIds.toString();
                  const classes = isLast
                    ? "pt-3 px-5"
                    : "py-3 px-5 border-b border-blue-gray-50";

                  return (
                    <tr key={index}>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {v.po_number}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {v.date}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {v.category}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {v.requested_by}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {v.department}
                          </Typography>
                        </div>
                      </td>
                      <td className={classes}>
                        <ApprovalPurchaseOrder
                          po_id={v.id}
                          autoFetch={autoFetch}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              Page {currentPage} of {POTotalPage}
            </Typography>
            <div className="flex gap-2">
              <Button
                variant="outlined"
                size="sm"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                size="sm"
                onClick={handlePoNextPage}
                disabled={currentPage === POTotalPage}
              >
                Next
              </Button>
            </div>
          </CardFooter>
        </div>
      </div>
    </>
  );
}

export default PurchaseOrderTable;
