import {
    Button,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
    Typography,
    Input,
  } from "@material-tailwind/react";
  import { IconButton } from "@material-tailwind/react";
  import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
  import HashLoader from "react-spinners/HashLoader";
  import { useState } from "react";
	import { useDetVendorItemListQuery, useUpdateVendorItemMutation } from "../../../../store";
	import { toast, ToastContainer } from "react-toastify";


  function VendorItemList({ vendor_name, vendorId }) {
    const [open, setOpen] = useState(false);
    const tableHead = ["", "Name", "Previous Price"];
    const [selectActive, setSelectActive] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [str, setStr] = useState("");

    const det_Vendor_item= useDetVendorItemListQuery({
    	vendor_id:vendorId,
      page: selectActive,
      pageSize: pageSize,
			search_str: str,
    });

		const [update_vendor_item] = useUpdateVendorItemMutation()

    const totalPage = Math.ceil(det_Vendor_item.data?.count / pageSize);
    // variable for data of api
    let result = [];

    const handleSearch = (e) => setStr(e.target.value);
    // Update the state based on whether the checkbox is checked or unchecked

    // preview of pagination
    const prev = () => {
      if (selectActive === 1) return;
      setSelectActive(selectActive - 1);
    };
    // next of pagination
    const next = () => {
      if (selectActive === totalPage) return;
      setSelectActive(selectActive + 1);
    };
    // handle the page size of table
    const changePagesize = (e) => {
      setPageSize(e.target.value);
      setSelectActive(1);
    };
    // open/closing of dialog
    const handleOpen = () => setOpen(!open);
    // for data fetching
    if (det_Vendor_item.isLoading) {
      return <HashLoader color="#36d7b7" />;
    } else if (det_Vendor_item.data) {
      result = det_Vendor_item.data.results;
    } else {
      return <div>Error Loading..</div>;
    }

		const updatePreviouPrice = async(value, id, type) =>{
			let formData = new FormData();
			formData.append("id", id);

			if (type === "previous_price") {
				formData.append("value", value);
				formData.append("type", "previous_price");
			}

			const uvi = await update_vendor_item(formData).unwrap();
			if (uvi.success === true) {
				toast.success(uvi.message, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
				});
			} else {
				toast.error(uvi.message, {
					position: "top-center",
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: true,
					progress: undefined,
				});
			}
			det_Vendor_item.refetch()
		}

    return (
      <div>
        <Button
          variant="outlined"
          color="teal"
          size="sm"
          onClick={handleOpen}
          className="mr-2"
        >
          <div className="flex items-center">
            Items
          </div>
        </Button>
        <Dialog
          open={open}
          size="xl"
          handler={handleOpen}
          className="relative max-h-min"
        >
          <DialogHeader className="text-green-900">{vendor_name}</DialogHeader>
          <div className=" ml-10 w-full md:w-72">
            <Input type="search" label="search" onKeyUp={handleSearch} />
          </div>
          <div className=" mr-10 flex justify-end space-x-4">
            <Typography>Page size:</Typography>
						<ToastContainer />
            <select
              defaultValue={pageSize}
              onChange={changePagesize}
              className="border-2 border-blue-gray-500"
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {tableHead.map((head, index) => (
                    <th
                      key={index}
                      className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {result.map((item, index) => {
									const pesoSign = '\u20B1';
                  const isLast =
                    index === det_Vendor_item.data.results.length - 1;
                  const classes = isLast
                    ? "p-4"
                    : "p-4 border-b border-blue-gray-50";

                  return (
                    <tr key={index}>
											<td></td>
                      <td className={classes}>{item.items.name}</td>
                      <td className={classes}>
												<Input
													type="number"
													defaultValue={item.previous_price}
													onBlur={(e) => {
														updatePreviouPrice(
															e.target.value,
															item.id,
															'previous_price'
														);
													}}
												/>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </DialogBody>
          <DialogFooter className="flex justify-between">
            <div className="flex justify-center items-center gap-8 ml-[41%]">
              <IconButton
                size="sm"
                variant="outlined"
                onClick={prev}
                disabled={selectActive === 1}
              >
                <IoArrowBackOutline strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="gray" className="font-normal">
                <strong className="text-gray-900">{selectActive}</strong> of
                <strong className="text-gray-900 ml-1">{totalPage}</strong>
              </Typography>
              <IconButton
                size="sm"
                variant="outlined"
                onClick={next}
                disabled={selectActive === totalPage}
              >
                <IoArrowForwardOutline strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
            <div>
              <Button
                color="red"
                variant="text"
                onClick={handleOpen}
                className="mr-2"
              >
                <span>Close</span>
              </Button>
            </div>
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
  
  export default VendorItemList;
