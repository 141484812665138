import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Select,
  Option,
} from "@material-tailwind/react";
import { useUpdatedAuditReportCompleteMutation } from "../../../../store";
import { useFetchAuditedDataQtyQuery } from "../../../../store";
import { FoodsDutyTbl,
          BeveragesTbl,
          ConsumablesTbl,
          RawmaterialsTbl,
          RepackingTbl,
          PlasticTbl,
          ItemConsumablesTbl,
 } from "../../../table_components/Audit/AuditCompletedTbl";
import { Confirmation } from "../../Error_trap_components/ErrorTrapComponent";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
const AuditReportCompltedModal = ({ refetchMainTable, auditData }) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const inputClasses = "flex flex-row items-center";
  const trapMessage =
    "Are you sure you want to complete this audit report? ";
    const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
    const signatureBoxclasses = "flex mb-1 mt-5";
  const currentDate = new Date();
  const formattedDate = currentDate.toLocaleString(undefined, {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  const { data, error, isLoading, isSuccess, refetch } = useFetchAuditedDataQtyQuery({
    id: auditData.id,
  })
  const [countRawmats, setCountRawmats] = useState(0);
  const [countRepacking, setCountRepacking] = useState(0);
  const [countPlastics, setCountPlastics] = useState(0);
  const [countFoods, setCountFoods] = useState(0);
  const [countBevs, setCountBevs] = useState(0);
  const [countCons, setCountCons] = useState(0);
  const [countItemCons, setCountItemCons] = useState(0);
  const [assignedRoute, setAssignedRoute] = useState("Route 1");

  const [selectedOutlet, setSelectedOutlet] = useState(null);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState([
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
    true,
  ]);

  const [selectedStock, setSelectedStock] = useState("");
  const [crewName, setCrewName] = useState("");
  let [productData, setProductData] = useState([]);
  const [signatures, setSignatures] = useState(["", "", "", "","", "","", "","", "","", ""]);
  const signatureRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const handleReset = (index) => {
    if (signatureRefs[index].current) {
      signatureRefs[index].current.clear();
      setSignatures((prevSignatures) => {
        const updatedSignatures = [...prevSignatures];
        updatedSignatures[index] = "";
        return updatedSignatures;
      });

      setIsSignatureEmpty((prevIsSignatureEmpty) => {
        const updatedIsSignatureEmpty = [...prevIsSignatureEmpty];
        updatedIsSignatureEmpty[index] = true;
        return updatedIsSignatureEmpty;
      });
    }
  };
  const handleSignatureChange = (index) => {
    const isEmpty = signatureRefs[index].current.isEmpty();
    setIsSignatureEmpty((prev) => {
      const updated = [...prev];
      updated[index] = isEmpty;
      return updated;
    });
    const base64Signature = signatureRefs[index].current.toDataURL();
    setSignatures((prevSignatures) => {
      const updatedSignatures = [...prevSignatures];
      updatedSignatures[index] = base64Signature;
      return updatedSignatures;
    });
  };

  let [isReadyToSave, setIsReadyToSave] = useState(false);

  useEffect(() => {
    if (
      isSignatureEmpty.every((isSignatureEmpty) => isSignatureEmpty !== true) &&
      countRawmats === 0 &&
      countRepacking === 0 &&
      countPlastics === 0 &&
      countFoods === 0 &&
      countBevs === 0 &&
      countCons === 0 &&
      countItemCons === 0
    ) {
      setIsReadyToSave(true);
    } else {
      setIsReadyToSave(false);
    }
  }, [countRawmats, countRepacking, countPlastics, countFoods, countBevs, countCons, countItemCons, isSignatureEmpty]);
  let handlePlasticCount = (data) => {
    setCountPlastics(data);
  };
  let handleRepackingCount = (data) => {
    setCountRepacking(data);
  };
  let handleRawmatsCount = (data) => {
    setCountRawmats(data);
  };
  let handleFoodsCount = (data) => {
    setCountFoods(data);
  };
  let handleBeveragesCount = (data) => {
    setCountBevs(data);
  };
  let handleItemConsumablesCount = (data) => {
    setCountItemCons(data);
  };
  let handleConsumablesCount = (data) => {
    setCountCons(data);
  };


  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const token = localStorage.getItem("mytoken");
  let navigate = useNavigate();




  let handleProductOrderChange = (data) => {
    setProductData(data);
  };


    const handleScroll = (id) => {
      document.getElementById(id).scrollIntoView({ behavior: 'smooth' });
    };


  const [saveAuditedQty] = useUpdatedAuditReportCompleteMutation();
  const handleSave = async (event) => {
    event.preventDefault();
    // Gather data from each table component

    // const formattedDate = deliveryDate.format("YYYY-MM-DD");
    // Repeat for other table components

    // Combine all data as needed
    let allData = {
      auditId: auditData.id,
      user: account_detailed.user.id,
    };
    console.log(allData);
    try {
      const sea = await saveAuditedQty(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });

        handleClose();
        refetchMainTable();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // formRef.current?.reset();
      }
    } catch (error) {
      // Handle the error here
      // You can display a generic error message to the user if needed
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };
  let outlet_list_result = [];
    return (
      <>
        <Button onClick={handleOpen} className="bg-[#1AA55D]" size="sm">
          View
        </Button>
        <Dialog
          open={open}
          size="xl"
          handler={handleOpen}
          className="relative max-h-min"
        >
          <DialogHeader className=" block w-full space-x-10 rounded-sm justify-center">
            {/* <img
              src={burgerMachineLogo}
              alt="bmlogo"
              className="aspect-auto w-72"
            /> */}
            <div className=" space-x-4 p-2 rounded-md">
            <Typography className="text-center text-green-900" variant="h3">
            Inventory Audit Summary
            </Typography>
            </div>
            <div className="justify-center flex space-x-4 p-2 rounded-md">
            <div className="relative inline-block">
          <label
         className="cursor-pointer text-lg text-green-600 border border-green-600 p-2 rounded-md hover:bg-green-600 hover:text-white"
            onClick={() => handleScroll('rawmaterials')}
          >
            Raw Materials
          </label>
          {countRawmats > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
                {countRawmats}
              </span>
            )}
          </div>
          <div className="relative inline-block">
          <label
           className="cursor-pointer text-lg text-green-600 border border-green-600 p-2 rounded-md hover:bg-green-600 hover:text-white"
            onClick={() => handleScroll('repacking')}
          >
            Repacking
          </label>
          {countRepacking > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
                {countRepacking}
              </span>
            )}
          </div>
          <div className="relative inline-block">
          <label
              className="cursor-pointer text-lg text-green-600 border border-green-600 p-2 rounded-md hover:bg-green-600 hover:text-white"
            onClick={() => handleScroll('plastic')}
          >
            Plastic
          </label>
          {countPlastics > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
                {countPlastics}
              </span>
            )}
          </div>
          <div className="relative inline-block">
          <label
            className="cursor-pointer text-lg text-green-600 border border-green-600 p-2 rounded-md hover:bg-green-600 hover:text-white"
            onClick={() => handleScroll('foodsdty')}
          >
            Foods Duty
          </label>
          {countFoods > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
                {countFoods}
              </span>
            )}
          </div>
          <div className="relative inline-block">
          <label
             className="cursor-pointer text-lg text-green-600 border border-green-600 p-2 rounded-md hover:bg-green-600 hover:text-white"
            onClick={() => handleScroll('beverages')}
          >
            Beverages
          </label>
          {countBevs > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
                {countBevs}
              </span>
            )}
          </div>
          <div className="relative inline-block">
          <label
              className="cursor-pointer text-lg text-green-600 border border-green-600 p-2 rounded-md hover:bg-green-600 hover:text-white"
              onClick={() => handleScroll('consumables')}
            >
              Consumables
            </label>
            {countItemCons + countCons > 0 && (
              <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-red-600 rounded-full transform translate-x-1/2 -translate-y-1/2">
                {countItemCons + countCons}
              </span>
            )}
          </div>
        </div>
          </DialogHeader>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
            <form>
              <div className="grid md:grid-cols-4 gap-5 grid-auto-rows-auto">
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Date"
                      name="date"
                      value={formattedDate}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Audit Number"
                      name="audit"
                      value={auditData.audit_number}
                      readOnly
                    />
                  </div>

                </div>
                <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                    <Input
                      label="Auditor Name"
                      name="auditor"
                      value={auditData.auditor_name}
                      readOnly
                    />
                  </div>
                </div>
              </div>

              <div className="m-5">
              {isSuccess &&(
                <>
                  <div id="rawmaterials" className="section">
                   <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                   Raw Materials Area
                   <RawmaterialsTbl
                   onOrderChange={handleProductOrderChange}
                   auditQtys={data.data}
                   auditId={auditData.id}
                   refetchTable={refetch}
                   noDataCount={handleRawmatsCount}
                   />
                 </Typography>
                 </div>

                    </>
                )}
                    {isSuccess &&(
                      <>
                  <div id="repacking" className="section">
                   <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                   Repacking Area
                   <RepackingTbl
                   onOrderChange={handleProductOrderChange}
                   auditQtys={data.data}
                   auditId={auditData.id}
                   refetchTable={refetch}
                   noDataCount={handleRepackingCount}
                   />
                 </Typography>
                 </div>
                 </>
                )}
                 {isSuccess &&(
                  <>
                  <div id="plastic" className="section">
                   <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                   Plastic Area
                   <PlasticTbl
                   onOrderChange={handleProductOrderChange}
                   auditQtys={data.data}
                   auditId={auditData.id}
                   refetchTable={refetch}
                   noDataCount={handlePlasticCount}
                   />
                 </Typography>
                 </div>
                 </>
                )}
                {isSuccess &&(
                  <>
                  <div id="foodsdty" className="section">
                   <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                   Foods Duty Area
                   <FoodsDutyTbl
                   onOrderChange={handleProductOrderChange}
                   auditQtys={data.data}
                   auditId={auditData.id}
                   refetchTable={refetch}
                   noDataCount={handleFoodsCount}
                   />
                 </Typography>
                 </div>
                 </>
                )}
                 {isSuccess &&(
                  <>
                   <div id="beverages" className="section">
                   <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                   Beverages Area
                   <BeveragesTbl
                   onOrderChange={handleProductOrderChange}
                   auditQtys={data.data}
                   auditId={auditData.id}
                   refetchTable={refetch}
                   noDataCount={handleBeveragesCount}
                   />
                 </Typography>
                 </div>
                 </>
                )}
                {isSuccess &&(
                  <>
                  <div id="consumables" className="section">
                   <Typography className="text-green-900 mt-2 mb-5" variant="h3">
                   Consumables Area
                   <ConsumablesTbl
                   onOrderChange={handleProductOrderChange}
                   auditQtys={data.data}
                   auditId={auditData.id}
                   refetchTable={refetch}
                   noDataCount={handleConsumablesCount}
                   />
                   <ItemConsumablesTbl
                   onOrderChange={handleProductOrderChange}
                   auditQtys={data.data}
                   auditId={auditData.id}
                   refetchTable={refetch}
                   noDataCount={handleItemConsumablesCount}
                   />
                 </Typography>
                 </div>
                 </>
                )}
              </div>
            </form>
            <ToastContainer />
          </DialogBody>
          <DialogFooter>
            {/* {selectedStock == "" && (
              <Typography variant="lead" color="red">
                Please provide a stock level.
              </Typography>
            )} */}
            {/* <Confirmation
              onConfirm={handleSave}
              trapMessage={trapMessage}
              isReadyToSave={isReadyToSave}
            /> */}
            <Button
              variant="text"
              color="red"
              className="mr-3"
              onClick={handleClose}
            >
              <span>Close</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
};

export default AuditReportCompltedModal;
