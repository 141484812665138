import React, {
    useState
} from "react";
import {
Card,
CardHeader,
Input,
Typography,
Button,
CardBody,
CardFooter,
Tabs,
TabsHeader,
Tab,
} from "@material-tailwind/react";
import { FiSearch } from "react-icons/fi";

import ProductRejDel from "../../../modal_form_components/Warehouse/WarehouseInventory/ProductRejDel";
import { useFetchProductRejectPageQuery } from "../../../../store";


function ProductRejectTable() {
    const TABLE_HEAD = [
        // "SKU",
        "Name",
        "UM",
        "Weight Box",
        "SOH",
        "Last Modified",
        "Action",
      ];
    let result = [];
    const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "desc",
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedCategory, setSelectedCategory] = useState(1);
    const columnMapping = {
    "Order Number": "order_number",
    Outlet: "outlet",
    Route: "outlet__route",
    "Order Date": "order_date",
    Status: "status",
    };

    const { data, error, isLoading, isSuccess, refetch } =
    useFetchProductRejectPageQuery({
        page: currentPage,
        page_size: pageSize,
        search: searchTerm,
        order_by: sortConfig.key,
        order_dir: sortConfig.direction,
    });

    const [isRefreshing, setIsRefreshing] = useState(false);
    const handleClickRefresh = () => {
    setIsRefreshing(true);

    // Trigger the refetch function
    refetch();

    // Disable the button after 2 seconds
    setTimeout(() => {
        setIsRefreshing(false);
    }, 2000);
    };

    const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
    const filterCategory = [];

    if (account_detailed.job_position === 7) {
        filterCategory.push(
            {
            label: "Foods Duty",
            value: 1,
            }
        );
    } else if (account_detailed.job_position === 8) {
        filterCategory.push(
            {
                label: "Beverages",
                value: 2,
            },
            {
                label: "Consumables",
                value: 3,
            }
        );
    }else{
        filterCategory.push(
            {
            label: "Foods Duty",
            value: 1,
            },
            {
            label: "Beverages",
            value: 2,
            },
            {
            label: "Consumables",
            value: 3,
            }
        )
    }

    const handleSort = (column) => {
    let direction = "asc";
    if (
        sortConfig.key === columnMapping[column] &&
        sortConfig.direction === "asc"
    ) {
        direction = "desc";
    }
        setSortConfig({ key: columnMapping[column], direction });
    };

    const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
    };

    const handlePageSizeChange = (selectedSize) => {
        console.log("Selected Page Size:", selectedSize);
        setPageSize(selectedSize);
        setCurrentPage(1);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    const handleTabChange = (value) => {
        setCurrentPage(1);
        setSelectedCategory(value);
        refetch();
    };

    if (isLoading) {
        return <div>Loading...</div>;
    } else if (error) {
        return <div>Error loading data</div>;
    } else if (data.length === 0) {
        return <div>No data found.</div>;
    } else if (isSuccess) {
        result = data.results;
        console.log(result)
    }
    return <>
        <Card className="h-full w-full mb-20 lg:mb-0">
            <CardHeader floated={false} shadow={false} className="rounded-none">
                <div className="flex justify-between ">
                    <div className="ml-5 mb-6 lg:mb-4">
                        <Input
                        label="Search"
                        icon={<FiSearch className="h-5 w-5" />}
                        value={searchTerm}
                        onChange={handleSearchChange}
                        />
                    </div>
                </div>
                <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
                    <Tabs value={selectedCategory} className="w-full">
                        <TabsHeader>
                        {filterCategory.map(({ label, value }) => (
                            <Tab
                            className="text-sm"
                            key={value}
                            value={value}
                            onClick={() => handleTabChange(value)}
                            >
                            &nbsp;&nbsp;{label}&nbsp;&nbsp;
                            </Tab>
                        ))}
                        </TabsHeader>
                    </Tabs>{" "}
                    <div className="mt-1">
                        <select
                        label="Page Size"
                        value={`${pageSize}`}
                        onChange={(e) => handlePageSizeChange(e)}
                        >
                        <option value={`${10}`}>10</option>
                        <option value={`${25}`}>25</option>
                        <option value={`${50}`}>50</option>
                        </select>
                    </div>
                </div>
            </CardHeader>
            <CardBody className="overflow-scroll px-0">
                <table className="w-full min-w-max table-auto text-left">
                    <thead>
                        <tr>
                            {TABLE_HEAD.map((head) => (
                                <th
                                key={head}
                                className="border-b border-green-600 bg-[#1AA55D] text-white p-4"
                                onClick={() => handleSort(head)}
                                style={{ cursor: "pointer" }}
                                >
                                <Typography
                                    variant="small"
                                    style={{
                                    color: "white",
                                    fontWeight: "bold",
                                    textTransform: "uppercase",
                                    }}
                                >
                                    {head}
                                </Typography>
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {result.map((item, index) => {
                        const isLast = index === result.length - 1;
                        const classes = isLast
                            ? "p-4"
                            : "p-4 border-b border-blue-gray-50";
                        const formattedDate = item.updated_at
                            ? new Intl.DateTimeFormat("en-US", {
                                year: "numeric",
                                month: "short",
                                day: "2-digit",
                            }).format(new Date(item.updated_at))
                            : "";
                        return (
                            <tr key={item.id}>
                                {/* <td className={classes}>
                                    <div className="flex items-center gap-3">
                                    <div className="flex flex-col">
                                        <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="font-normal"
                                        >
                                        {item.product.product_code !== null &&
                                        item.product.product_code !== undefined
                                            ? item.product.product_code
                                            : "N/A"}
                                        </Typography>
                                    </div>
                                    </div>
                                </td> */}
                                <td className={classes}>
                                    <div className="flex items-center gap-3">
                                        <div className="flex flex-col">
                                            <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            >
                                            {item.product.name !== null &&
                                            item.product.name !== undefined
                                                ? item.product.name
                                                : "N/A"}
                                            </Typography>
                                        </div>
                                    </div>
                                </td>
                                <td className={classes}>
                                    <div className="flex items-center gap-3">
                                        <div className="flex flex-col">
                                            <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            >
                                            {item.product.unit_measurement !== null &&
                                            item.product.unit_measurement !== undefined
                                                ? item.product.unit_measurement
                                                : "N/A"}
                                            </Typography>
                                        </div>
                                    </div>
                                </td>
                                <td className={classes}>
                                    <div className="flex items-center gap-3">
                                        <div className="flex flex-col">
                                            <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            >
                                            {item.product.weight_box !== null &&
                                            item.product.weight_box !== undefined
                                                ? item.product.weight_box
                                                : "N/A"}
                                            </Typography>
                                        </div>
                                    </div>
                                </td>
                                <td className={classes}>
                                    <div className="flex items-center gap-3">
                                        <div className="flex flex-col">
                                            <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            >
                                            {item.rejected_qty !== null &&
                                            item.rejected_qty !== undefined
                                                ? item.rejected_qty
                                                : "N/A"}
                                            </Typography>
                                        </div>
                                    </div>
                                </td>
                                <td className={classes}>
                                    <div className="flex items-center gap-3">
                                        <div className="flex flex-col">
                                            <Typography
                                            variant="small"
                                            color="blue-gray"
                                            className="font-normal"
                                            >
                                            {formattedDate}
                                            </Typography>
                                        </div>
                                    </div>
                                </td>
                                <td className={classes}>
                                    <div className="flex items-center gap-3">
                                        <div className="flex flex-col">
                                            <ProductRejDel
                                                prodData={item.product}
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        );
                        })}
                    </tbody>
                </table>
            </CardBody>
            <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
                <Typography variant="small" color="blue-gray" className="font-normal">
                Page {`${currentPage} of ${Math.ceil(data?.count / pageSize) || 1}`}
                </Typography>
                <div className="flex gap-2">
                <Button
                    variant="outlined"
                    size="sm"
                    onClick={handlePreviousPage}
                    disabled={!data?.previous}
                >
                    Previous
                </Button>
                <Button
                    variant="outlined"
                    size="sm"
                    onClick={handleClickRefresh}
                    disabled={isRefreshing}
                >
                    Refresh
                </Button>

                <Button
                    variant="outlined"
                    size="sm"
                    onClick={handleNextPage}
                    disabled={!data?.next}
                >
                    {data?.next ? "Next" : "No More Pages"}
                </Button>
                </div>
            </CardFooter>
        </Card>
    </>
  }

export default ProductRejectTable;