import { useState, useEffect } from "react";
import { Card, Typography } from "@material-tailwind/react";
import { useTransferRequestItemListPageQuery } from "../../../store";

const TABLE_HEAD = [
  "Name",
  "Conversion/Unit Measure",
  "Requested Qty",
//   "SOH",
  // "Order",

];
let product_result = []
let beverages_result = []
let buns_result = []
let wrappers_result = []
let os_result = []
let tank_result = []
let sundries_result = []
let cigarettes_result = []
const classes = "p-4 border-b border-blue-gray-50";


  // const [bunsActualQty, setBunsActualQty] = useState([]);
  // const [beveragesActualQty, setBeveragesActualQty] = useState([]);

function ConsumablesTbl( {onOrderChange} ) {
  const { data, isError, isLoading, isSuccess, refetch } = useTransferRequestItemListPageQuery({
    page: 1,
    page_size: 100,
    location: 4,
  });
  let [productActualQty, setProductActualQty] = useState([]);

  // useEffect(() => {
  //   if (isSuccess && data) {
  //     setProductActualQty(new Array(data.data.length).fill(0));
  //   }
  // }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      itemId: product_result[index].id,
      itemQty: qty,
    }));
    product_data = product_data.filter(item => item.itemQty !== undefined && item.itemQty !== 0);
    console.log(product_data)

    onOrderChange(product_data);
  };

  if (isLoading) {

    return <div>Loading...</div>;
  }
  else if (isError) {
    return <div>Error loading data</div>;
  }
  else if (data.length === 0) {
    return <div>No data found.</div>;
  }
  else if (isSuccess){
    product_result = data.results;


  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left ">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
                <tbody>
                    {product_result.map((product, index) => {
                    return (
                        <tr key={index}>
                            <td className={classes}>
                                <div className="flex items-center gap-3">
                                    <div className="flex flex-col">
                                        <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                            {product.name}
                                        </Typography>
                                    </div>
                                </div>
                            </td>
                            {/* <td className={classes}>
                                <div className="flex items-center gap-3">
                                    <div className="flex flex-col">
                                        <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                        {product.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                                        </Typography>
                                    </div>
                                </div>
                            </td> */}
                            {/* <td className={classes}>
                                <div className="flex items-center gap-3">
                                    <div className="flex flex-col">
                                        <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>

                                        </Typography>
                                    </div>
                                </div>
                            </td> */}
                            <td className={classes}>
                                <div className="flex items-center gap-3">
                                    <div className="flex flex-col">
                                        <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                            {product.weight_box}/{product.unit_measurement}
                                        </Typography>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="flex items-center">
                                    <div className="flex flex-col flex-grow">
                                        <input
                                            type="number"
                                            value={productActualQty[index] || ""}
                                            onInput={(e) => handleOrderChange(index, e.target.value)}
                                            className="w-20 border border-1 border-blue-gray-300 p-1"
                                        />
                                    </div>
                                </div>
                            </td>
                            {/* <td className={classes}>
                                <div className="flex items-center gap-3">
                                    <div className="flex flex-col">
                                        <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>

                                        </Typography>
                                    </div>
                                </div>
                            </td> */}
                        </tr>
                    );
                })}
            </tbody>
      </table>
    </Card>
  );
}

}

export {
  ConsumablesTbl
};