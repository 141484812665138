import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Checkbox,
  Select,
  Option,
} from "@material-tailwind/react";
import { FaSyncAlt } from "react-icons/fa";

import { useFetchRouteListQuery } from "../../../store";
import { useUpdateRouteScheduleMutation } from "../../../store";
import { useFetchDelScheduleQuery } from "../../../store";
import moment from "moment";
import { toast } from "react-toastify";
import { Confirmation } from "../../modal_form_components/Error_trap_components/ErrorTrapComponent";

function EditCalendarModal({ refetchSched }) {
  let route_data = [];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const inputClasses = "flex flex-row items-center";
  const signatureClasses = "grid grid-flow-row grid-cols-1";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const tableHead = ["", "Route"];
  const [currentDate, setCurrentDate] = useState(moment());
  const monthValue = currentDate.month() + 2;

  const {
    data: schedData,
    error: schedError,
    isLoading: schedLoading,
    isSuccess: schedSuccess,
    refetch: refetchSchedData,
  } = useFetchDelScheduleQuery();

  const [updateDeliverySchedule] = useUpdateRouteScheduleMutation();
  const { data, error, isLoading, isSuccess, refetch } = useFetchRouteListQuery(
    {
      page: 1,
      page_size: 50,
    }
  );
  const [isTuesdayRouteDialogOpen, setIsTuesdayRouteDialogOpen] =
    useState(false);
  const [isMondayRouteDialogOpen, setIsMondayRouteDialogOpen] = useState(false);
  const [isWedRouteDialogOpen, setIsWedRouteDialogOpen] = useState(false);
  const [isThursRouteDialogOpen, setIsThursRouteDialogOpen] = useState(false);
  const [isFriRouteDialogOpen, setIsFriRouteDialogOpen] = useState(false);
  const [isSatRouteDialogOpen, setIsSatRouteDialogOpen] = useState(false);
  const [isSunRouteDialogOpen, setIsSunRouteDialogOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedOldMonRoute, setSelectedOldMonRoute] = useState([]);
  const [selectedOldTueRoute, setSelectedOldTueRoute] = useState([]);
  const [selectedOldWedRoute, setSelectedOldWedRoute] = useState([]);
  const [selectedOldThuRoute, setSelectedOldThuRoute] = useState([]);
  const [selectedOldFriRoute, setSelectedOldFriRoute] = useState([]);
  const [selectedOldSatRoute, setSelectedOldSatRoute] = useState([]);
  const [selectedOldSunRoute, setSelectedOldSunRoute] = useState([]);
  const [selectedMonRoute, setSelectedMonRoute] = useState([]);
  const [selectedTueRoute, setSelectedTueRoute] = useState([]);
  const [selectedWedRoute, setSelectedWedRoute] = useState([]);
  const [selectedThuRoute, setSelectedThuRoute] = useState([]);
  const [selectedFriRoute, setSelectedFriRoute] = useState([]);
  const [selectedSatRoute, setSelectedSatRoute] = useState([]);
  const [selectedSunRoute, setSelectedSunRoute] = useState([]);
  const [areRoutesReadyToSave, setAreRoutesReadyToSave] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(monthValue.toString());

  useEffect(() => {
    // Check if any of the route states have data
    const anyRoutesSelected =
      selectedMonRoute.length > 0 ||
      selectedTueRoute.length > 0 ||
      selectedWedRoute.length > 0 ||
      selectedThuRoute.length > 0 ||
      selectedFriRoute.length > 0 ||
      selectedSatRoute.length > 0 ||
      selectedSunRoute.length > 0;

    // Update the state of areRoutesReadyToSave
    setAreRoutesReadyToSave(anyRoutesSelected);
  }, [
    selectedMonRoute,
    selectedTueRoute,
    selectedWedRoute,
    selectedThuRoute,
    selectedFriRoute,
    selectedSatRoute,
    selectedSunRoute,
  ]);

  const monthString = moment()
    .month(parseInt(selectedMonth) - 1)
    .format("MMMM");

  useEffect(() => {
    if (schedSuccess) {
      const filteredData = schedData.data.filter(
        (item) => item.month === parseInt(selectedMonth)
      );
      setFilteredData(filteredData);

      // Update selectedOldMonRoute here
      setSelectedOldMonRoute(
        filteredData.length > 0 ? filteredData[0].monday : []
      );
      setSelectedOldTueRoute(
        filteredData.length > 0 ? filteredData[0].tuesday : []
      );
      setSelectedOldWedRoute(
        filteredData.length > 0 ? filteredData[0].wednesday : []
      );
      setSelectedOldThuRoute(
        filteredData.length > 0 ? filteredData[0].thursday : []
      );
      setSelectedOldFriRoute(
        filteredData.length > 0 ? filteredData[0].friday : []
      );
      setSelectedOldSatRoute(
        filteredData.length > 0 ? filteredData[0].saturday : []
      );
      setSelectedOldSunRoute(
        filteredData.length > 0 ? filteredData[0].sunday : []
      );
    }
  }, [schedData, schedSuccess, selectedMonth]);

  const handleMonthChange = (value) => {
    setSelectedMonth(value);
  };
  const trapMessage = `Are you sure to update this schedule for the month of ${monthString}?`;

  const handleChangeMonRoute = (route) => {
    setSelectedMonRoute((prevSelected) =>
      prevSelected.some((selectedRoute) => selectedRoute.id === route.id)
        ? prevSelected.filter((selected) => selected.id !== route.id)
        : [...prevSelected, route]
    );
    console.log(selectedMonRoute);
  };

  const handleChangeTueRoute = (route) => {
    setSelectedTueRoute((prevSelected) =>
      prevSelected.some((selectedRoute) => selectedRoute.id === route.id)
        ? prevSelected.filter((selected) => selected.id !== route.id)
        : [...prevSelected, route]
    );
    console.log(selectedTueRoute);
  };

  const handleChangeWedRoute = (route) => {
    setSelectedWedRoute((prevSelected) =>
      prevSelected.some((selectedRoute) => selectedRoute.id === route.id)
        ? prevSelected.filter((selected) => selected.id !== route.id)
        : [...prevSelected, route]
    );
    console.log(selectedWedRoute);
  };

  const handleChangeThuRoute = (route) => {
    setSelectedThuRoute((prevSelected) =>
      prevSelected.some((selectedRoute) => selectedRoute.id === route.id)
        ? prevSelected.filter((selected) => selected.id !== route.id)
        : [...prevSelected, route]
    );
    console.log(selectedThuRoute);
  };

  const handleChangeFriRoute = (route) => {
    setSelectedFriRoute((prevSelected) =>
      prevSelected.some((selectedRoute) => selectedRoute.id === route.id)
        ? prevSelected.filter((selected) => selected.id !== route.id)
        : [...prevSelected, route]
    );
    console.log(selectedFriRoute);
  };

  const handleChangeSatRoute = (route) => {
    setSelectedSatRoute((prevSelected) =>
      prevSelected.some((selectedRoute) => selectedRoute.id === route.id)
        ? prevSelected.filter((selected) => selected.id !== route.id)
        : [...prevSelected, route]
    );
    console.log(selectedSatRoute);
  };

  const handleChangeSunRoute = (route) => {
    setSelectedSunRoute((prevSelected) =>
      prevSelected.some((selectedRoute) => selectedRoute.id === route.id)
        ? prevSelected.filter((selected) => selected.id !== route.id)
        : [...prevSelected, route]
    );
    console.log(selectedSunRoute);
  };

  const handleOpenMonRouteDialog = () => {
    setIsMondayRouteDialogOpen(true);
  };

  const handleOpenTueRouteDialog = () => {
    setIsTuesdayRouteDialogOpen(true);
  };

  const handleOpenWedRouteDialog = () => {
    setIsWedRouteDialogOpen(true);
  };

  const handleOpenThuRouteDialog = () => {
    setIsThursRouteDialogOpen(true);
  };

  const handleOpenFriRouteDialog = () => {
    setIsFriRouteDialogOpen(true);
  };

  const handleOpenSatRouteDialog = () => {
    setIsSatRouteDialogOpen(true);
  };

  const handleOpenSunRouteDialog = () => {
    setIsSunRouteDialogOpen(true);
  };

  const handleClearRoute = () => {
    setSelectedMonRoute([]);
  };

  const handleClearTuesdayRoute = () => {
    setSelectedTueRoute([]);
  };

  const handleClearWednesdayRoute = () => {
    setSelectedWedRoute([]);
  };

  const handleClearThursdayRoute = () => {
    setSelectedThuRoute([]);
  };

  const handleClearFridayRoute = () => {
    setSelectedFriRoute([]);
  };

  const handleClearSaturdayRoute = () => {
    setSelectedSatRoute([]);
  };

  const handleClearSundayRoute = () => {
    setSelectedSunRoute([]);
  };

  const handleDialogClose = () => {
    setIsMondayRouteDialogOpen(false);
    setIsTuesdayRouteDialogOpen(false);
    setIsWedRouteDialogOpen(false);
    setIsThursRouteDialogOpen(false);
    setIsFriRouteDialogOpen(false);
    setIsSatRouteDialogOpen(false);
    setIsSunRouteDialogOpen(false);
  };

  const handleMainClose = () => {
    setOpen(false);
    setSelectedMonRoute([]);
    setSelectedTueRoute([]);
    setSelectedWedRoute([]);
    setSelectedThuRoute([]);
    setSelectedFriRoute([]);
    setSelectedSatRoute([]);
    setSelectedSunRoute([]);
  };

  const handleUpdateSchedule = async (event) => {
    event.preventDefault();
    // Gather data from each table component

    // Repeat for other table components

    // Combine all data as needed
    let allData = {
      monday: selectedMonRoute,
      tuesday: selectedTueRoute,
      wednesday: selectedWedRoute,
      thursday: selectedThuRoute,
      friday: selectedFriRoute,
      saturday: selectedSatRoute,
      sunday: selectedSunRoute,
      month: selectedMonth,
    };
    console.log(allData);
    const sea = await updateDeliverySchedule(allData).unwrap();
    if (sea.success === true) {
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      // formRef.current?.reset();
    }
    refetchSched();
    handleMainClose();
    refetch();
  };
  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    route_data = data.results;
    // console.log(outletData)
  }

  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]">
        Edit
      </Button>
      <Dialog
        size="lg"
        open={open}
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          <Typography className="text-green-900" variant="h3">
            Edit Route Schedule
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <form>
            <Select value={selectedMonth} onChange={handleMonthChange}>
              <Option value="1">January</Option>
              <Option value="2">February</Option>
              <Option value="3">March</Option>
              <Option value="4">April</Option>
              <Option value="5">May</Option>
              <Option value="6">June</Option>
              <Option value="7">July</Option>
              <Option value="8">August</Option>
              <Option value="9">September</Option>
              <Option value="10">October</Option>
              <Option value="11">November</Option>
              <Option value="12">December</Option>
            </Select>

            <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto mt-10">
              <div className="col-span-2 space-y-5">
                <Typography className="text-green-900" variant="h6">
                  Current Route
                </Typography>
                {selectedOldMonRoute.length > 0 ? (
                  <div className={inputClasses}>
                    <Input
                      label="Monday"
                      value={
                        selectedOldMonRoute
                          .map((item) => item.name)
                          .join(", ") || "No data"
                      }
                      readOnly
                    />
                  </div>
                ) : (
                  <div className={inputClasses}>
                    <Input label="Monday" value={"No Data"} readOnly />
                  </div>
                )}
                {selectedOldTueRoute.length > 0 ? (
                  <div className={inputClasses}>
                    <Input
                      label="Tuesday"
                      value={
                        selectedOldTueRoute
                          .map((item) => item.name)
                          .join(", ") || "No data"
                      }
                      readOnly
                    />
                  </div>
                ) : (
                  <div className={inputClasses}>
                    <Input label="Tuesday" value={"No Data"} readOnly />
                  </div>
                )}

                {selectedOldWedRoute.length > 0 ? (
                  <div className={inputClasses}>
                    <Input
                      label="Wednesday"
                      value={
                        selectedOldWedRoute
                          .map((item) => item.name)
                          .join(", ") || "No Data"
                      }
                      readOnly
                    />
                  </div>
                ) : (
                  <div className={inputClasses}>
                    <Input label="Wednesday" value={"No Data"} readOnly />
                  </div>
                )}
                {selectedOldThuRoute.length > 0 ? (
                  <div className={inputClasses}>
                    <Input
                      label="Thursday"
                      value={
                        selectedOldThuRoute
                          .map((item) => item.name)
                          .join(", ") || "No Data"
                      }
                      readOnly
                    />
                  </div>
                ) : (
                  <div className={inputClasses}>
                    <Input label="Thursday" value={"No Data"} readOnly />
                  </div>
                )}
                {selectedOldFriRoute.length > 0 ? (
                  <div className={inputClasses}>
                    <Input
                      label="Friday"
                      value={
                        selectedOldFriRoute
                          .map((item) => item.name)
                          .join(", ") || "No Data"
                      }
                      readOnly
                    />
                  </div>
                ) : (
                  <div className={inputClasses}>
                    <Input label="Friday" value={"No Data"} readOnly />
                  </div>
                )}
                {selectedOldSatRoute.length > 0 ? (
                  <div className={inputClasses}>
                    <Input
                      label="Saturday"
                      value={
                        selectedOldSatRoute
                          .map((item) => item.name)
                          .join(", ") || "No Data"
                      }
                      readOnly
                    />
                  </div>
                ) : (
                  <div className={inputClasses}>
                    <Input label="Saturday" value={"No Data"} readOnly />
                  </div>
                )}
                {selectedOldSunRoute.length > 0 ? (
                  <div className={inputClasses}>
                    <Input
                      label="Sunday"
                      value={
                        selectedOldSunRoute
                          .map((item) => item.name)
                          .join(", ") || "No Data"
                      }
                      readOnly
                    />
                  </div>
                ) : (
                  <div className={inputClasses}>
                    <Input label="Sunday" value={"No Data"} readOnly />
                  </div>
                )}
              </div>
              <div className="col-span-2 space-y-5">
                <Typography className="text-green-900" variant="h6">
                  Set New Route
                </Typography>
                <div className={inputClasses}>
                  <Input
                    label="Monday"
                    value={
                      selectedMonRoute.map((route) => route.name).join(", ") ||
                      ""
                    }
                    onClick={handleOpenMonRouteDialog}
                    readOnly
                  />
                  {selectedMonRoute.length > 0 && (
                    <Button
                      onClick={handleClearRoute}
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "green",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <FaSyncAlt style={{ color: "white" }} />
                    </Button>
                  )}
                  <Dialog
                    size="sm"
                    open={isMondayRouteDialogOpen}
                    handler={handleDialogClose}
                    className="relative max-h-min"
                  >
                    <DialogHeader>
                      <Typography className="text-green-900" variant="h3">
                        Select Monday Route
                      </Typography>
                    </DialogHeader>
                    <DialogBody className="overflow-y-auto max-h-[50vh]">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {tableHead.map((head, index) => (
                              <th
                                key={index}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {route_data.map((route) => {
                            const classes = "p-4 border-b border-blue-gray-50";
                            return (
                              <tr key={route.id}>
                                <td className={classes}>
                                  <Checkbox
                                    type="checkbox"
                                    color="teal"
                                    checked={selectedMonRoute.some(
                                      (selectedRoute) =>
                                        selectedRoute.id === route.id
                                    )}
                                    onChange={() => handleChangeMonRoute(route)}
                                  />
                                </td>
                                <td className={classes}>{route.name}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </DialogBody>
                  </Dialog>
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Tuesday"
                    value={
                      selectedTueRoute.map((route) => route.name).join(", ") ||
                      ""
                    }
                    onClick={handleOpenTueRouteDialog}
                    readOnly
                  />
                  {selectedTueRoute.length > 0 && (
                    <Button
                      onClick={handleClearTuesdayRoute}
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "green",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <FaSyncAlt style={{ color: "white" }} />
                    </Button>
                  )}
                  <Dialog
                    size="sm"
                    open={isTuesdayRouteDialogOpen}
                    handler={handleDialogClose}
                    className="relative max-h-min"
                  >
                    <DialogHeader>
                      <Typography className="text-green-900" variant="h3">
                        Select Tuesday Route
                      </Typography>
                    </DialogHeader>
                    <DialogBody className="overflow-y-auto max-h-[50vh]">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {tableHead.map((head, index) => (
                              <th
                                key={index}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {route_data.map((route) => {
                            const classes = "p-4 border-b border-blue-gray-50";
                            return (
                              <tr key={route.id}>
                                <td className={classes}>
                                  <Checkbox
                                    type="checkbox"
                                    color="teal"
                                    checked={selectedTueRoute.some(
                                      (selectedRoute) =>
                                        selectedRoute.id === route.id
                                    )}
                                    onChange={() => handleChangeTueRoute(route)}
                                  />
                                </td>
                                <td className={classes}>{route.name}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </DialogBody>
                  </Dialog>
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Wednesday"
                    value={
                      selectedWedRoute.map((route) => route.name).join(", ") ||
                      ""
                    }
                    onClick={handleOpenWedRouteDialog}
                    readOnly
                  />
                  {selectedWedRoute.length > 0 && (
                    <Button
                      onClick={handleClearWednesdayRoute}
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "green",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <FaSyncAlt style={{ color: "white" }} />
                    </Button>
                  )}
                  <Dialog
                    size="sm"
                    open={isWedRouteDialogOpen}
                    handler={handleDialogClose}
                    className="relative max-h-min"
                  >
                    <DialogHeader>
                      <Typography className="text-green-900" variant="h3">
                        Select Wednesday Route
                      </Typography>
                    </DialogHeader>
                    <DialogBody className="overflow-y-auto max-h-[50vh]">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {tableHead.map((head, index) => (
                              <th
                                key={index}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {route_data.map((route) => {
                            const classes = "p-4 border-b border-blue-gray-50";
                            return (
                              <tr key={route.id}>
                                <td className={classes}>
                                  <Checkbox
                                    type="checkbox"
                                    color="teal"
                                    checked={selectedWedRoute.some(
                                      (selectedRoute) =>
                                        selectedRoute.id === route.id
                                    )}
                                    onChange={() => handleChangeWedRoute(route)}
                                  />
                                </td>
                                <td className={classes}>{route.name}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </DialogBody>
                  </Dialog>
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Thursday"
                    value={
                      selectedThuRoute.map((route) => route.name).join(", ") ||
                      ""
                    }
                    onClick={handleOpenThuRouteDialog}
                    readOnly
                  />
                  {selectedThuRoute.length > 0 && (
                    <Button
                      onClick={handleClearThursdayRoute}
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "green",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <FaSyncAlt style={{ color: "white" }} />
                    </Button>
                  )}
                  <Dialog
                    size="sm"
                    open={isThursRouteDialogOpen}
                    handler={handleDialogClose}
                    className="relative max-h-min"
                  >
                    <DialogHeader>
                      <Typography className="text-green-900" variant="h3">
                        Select Thursday Route
                      </Typography>
                    </DialogHeader>
                    <DialogBody className="overflow-y-auto max-h-[50vh]">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {tableHead.map((head, index) => (
                              <th
                                key={index}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {route_data.map((route) => {
                            const classes = "p-4 border-b border-blue-gray-50";
                            return (
                              <tr key={route.id}>
                                <td className={classes}>
                                  <Checkbox
                                    type="checkbox"
                                    color="teal"
                                    checked={selectedThuRoute.some(
                                      (selectedRoute) =>
                                        selectedRoute.id === route.id
                                    )}
                                    onChange={() => handleChangeThuRoute(route)}
                                  />
                                </td>
                                <td className={classes}>{route.name}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </DialogBody>
                  </Dialog>
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Friday"
                    value={
                      selectedFriRoute.map((route) => route.name).join(", ") ||
                      ""
                    }
                    onClick={handleOpenFriRouteDialog}
                    readOnly
                  />
                  {selectedFriRoute.length > 0 && (
                    <Button
                      onClick={handleClearFridayRoute}
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "green",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <FaSyncAlt style={{ color: "white" }} />
                    </Button>
                  )}
                  <Dialog
                    size="sm"
                    open={isFriRouteDialogOpen}
                    handler={handleDialogClose}
                    className="relative max-h-min"
                  >
                    <DialogHeader>
                      <Typography className="text-green-900" variant="h3">
                        Select Friday Route
                      </Typography>
                    </DialogHeader>
                    <DialogBody className="overflow-y-auto max-h-[50vh]">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {tableHead.map((head, index) => (
                              <th
                                key={index}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {route_data.map((route) => {
                            const classes = "p-4 border-b border-blue-gray-50";
                            return (
                              <tr key={route.id}>
                                <td className={classes}>
                                  <Checkbox
                                    type="checkbox"
                                    color="teal"
                                    checked={selectedFriRoute.some(
                                      (selectedRoute) =>
                                        selectedRoute.id === route.id
                                    )}
                                    onChange={() => handleChangeFriRoute(route)}
                                  />
                                </td>
                                <td className={classes}>{route.name}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </DialogBody>
                  </Dialog>
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Saturday"
                    value={
                      selectedSatRoute.map((route) => route.name).join(", ") ||
                      ""
                    }
                    onClick={handleOpenSatRouteDialog}
                    readOnly
                  />
                  {selectedSatRoute.length > 0 && (
                    <Button
                      onClick={handleClearSaturdayRoute}
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "green",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <FaSyncAlt style={{ color: "white" }} />
                    </Button>
                  )}
                  <Dialog
                    size="sm"
                    open={isSatRouteDialogOpen}
                    handler={handleDialogClose}
                    className="relative max-h-min"
                  >
                    <DialogHeader>
                      <Typography className="text-green-900" variant="h3">
                        Select Saturday Route
                      </Typography>
                    </DialogHeader>
                    <DialogBody className="overflow-y-auto max-h-[50vh]">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {tableHead.map((head, index) => (
                              <th
                                key={index}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {route_data.map((route) => {
                            const classes = "p-4 border-b border-blue-gray-50";
                            return (
                              <tr key={route.id}>
                                <td className={classes}>
                                  <Checkbox
                                    type="checkbox"
                                    color="teal"
                                    checked={selectedSatRoute.some(
                                      (selectedRoute) =>
                                        selectedRoute.id === route.id
                                    )}
                                    onChange={() => handleChangeSatRoute(route)}
                                  />
                                </td>
                                <td className={classes}>{route.name}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </DialogBody>
                  </Dialog>
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Sunday"
                    value={
                      selectedSunRoute.map((route) => route.name).join(", ") ||
                      ""
                    }
                    onClick={handleOpenSunRouteDialog}
                    readOnly
                  />
                  {selectedSunRoute.length > 0 && (
                    <Button
                      onClick={handleClearSundayRoute}
                      style={{
                        marginLeft: "8px",
                        backgroundColor: "green",
                        padding: "10px",
                        cursor: "pointer",
                      }}
                    >
                      <FaSyncAlt style={{ color: "white" }} />
                    </Button>
                  )}
                  <Dialog
                    size="sm"
                    open={isSunRouteDialogOpen}
                    handler={handleDialogClose}
                    className="relative max-h-min"
                  >
                    <DialogHeader>
                      <Typography className="text-green-900" variant="h3">
                        Select Sunday Route
                      </Typography>
                    </DialogHeader>
                    <DialogBody className="overflow-y-auto max-h-[50vh]">
                      <table className="w-full min-w-max table-auto text-left">
                        <thead>
                          <tr>
                            {tableHead.map((head, index) => (
                              <th
                                key={index}
                                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                              >
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal leading-none opacity-70"
                                >
                                  {head}
                                </Typography>
                              </th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {route_data.map((route) => {
                            const classes = "p-4 border-b border-blue-gray-50";
                            return (
                              <tr key={route.id}>
                                <td className={classes}>
                                  <Checkbox
                                    type="checkbox"
                                    color="teal"
                                    checked={selectedSunRoute.some(
                                      (selectedRoute) =>
                                        selectedRoute.id === route.id
                                    )}
                                    onChange={() => handleChangeSunRoute(route)}
                                  />
                                </td>
                                <td className={classes}>{route.name}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </DialogBody>
                  </Dialog>
                </div>
              </div>
            </div>
          </form>
        </DialogBody>
        <DialogFooter>
          <Confirmation
            onConfirm={handleUpdateSchedule}
            isReadyToSave={areRoutesReadyToSave}
            trapMessage={trapMessage}
          />
          <Button
            variant="text"
            color="red"
            className="mr-3"
            onClick={handleMainClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default EditCalendarModal;
