import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");

const approvalApi = createApi({
  reducerPath: "approval",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  endpoints(builder) {
    return {
      fetchApproval: builder.query({
        query: () => {
          return {
            url: "/api/warehouse/request/request_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchApprovalDetailed: builder.query({
        query: (id) => {
          return {
            url: `/api/warehouse/request/${id}/request_item_detailed/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchApprovalData: builder.query({
        query: ({ pageSize, page, orderDir, orderBy = "id", account_jp}) => {
          return {
            url: `/api/warehouse/request/approval_list_page/?page=${page}&page_size=${pageSize}&order_by=${orderBy}&order_dir=${orderDir}&jp=${account_jp}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchTransferApprovalDetails: builder.query({
        query: (id) => {
          return {
            url: `/api/warehouse/request/${id}/transfer_approval_details/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchTransferItemApproval: builder.query({
        query: ({ pageSize, page, orderDir, orderBy = "id", transfer_id }) => {
          return {
            url: `/api/warehouse/request/transfer_items_approval_list/?page=${page}&page_size=${pageSize}&order_by=${orderBy}&order_dir=${orderDir}&transfer_id=${transfer_id}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
        updateApproveTransfer: builder.mutation({
          query: (form) => {
              return {
                  url: `/api/warehouse/request/approve_transfer_items/`,
                  headers: { "Authorization": "token " + token },
                  method: 'PUT',
                  body: form
              }
          }
      }),
      fetchDeliveryApproval: builder.query({
        query: ({ pageSize, page, orderDir, orderBy = "id",  search="" }) => {
          return {
            url: `/api/delivery/delivery/delivery_approval_list_page/?page=${page}&page_size=${pageSize}&order_by=${orderBy}&order_dir=${orderDir}&search=${search}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateDeliveryItemsApproval: builder.mutation({
        query: (form) => {
            return {
                url: `/api/delivery/delivery/adjust_delivery_order_item/`,
                headers: { "Authorization": "token " + token },
                method: 'PUT',
                body: form
            }
        }
    }),
      updateApproveDelivery: builder.mutation({
        query: (form) => {
            return {
                url: `/api/delivery/delivery/update_approve_delivery/`,
                headers: { "Authorization": "token " + token },
                method: 'PUT',
                body: form
            }
        }
    }),
    updateApprovalDelItemZero: builder.mutation({
      query: (form) => {
          return {
              url: `/api/delivery/delivery/adjust_product_soh_zero/`,
              headers: { "Authorization": "token " + token },
              method: 'PUT',
              body: form
          }
      }
  }),
    };
  },
});
export const {
  useFetchApprovalQuery,
  useFetchApprovalDetailedQuery,
  useFetchApprovalDataQuery,
  useFetchTransferApprovalDetailsQuery,
  useFetchTransferItemApprovalQuery,
  useUpdateApproveTransferMutation,
  useFetchDeliveryApprovalQuery,
  useUpdateDeliveryItemsApprovalMutation,
  useUpdateApproveDeliveryMutation,
  useUpdateApprovalDelItemZeroMutation,
} = approvalApi;
export { approvalApi };
