import { useEffect, useState } from "react";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Checkbox,
  Tooltip,
} from "@material-tailwind/react";
import { useFetchNotificationQuery } from "../../store/apis/NotificationApi";
import { FaTrash } from "react-icons/fa";
import { MdMarkAsUnread } from "react-icons/md";
import { IoMdNotifications } from "react-icons/io";
import { useDeleteNotificationListMutation } from "../../store/apis/NotificationApi";
import DeleteNotification from "../modal_form_components/Error_trap_components/DeleteNotification";
import DetailNotification from "../modal_form_components/Notification/DetailNotification";
import Moment from "react-moment";
import moment from "moment";
const TABLE_HEAD = ["Subject", "Message", "", "Action"];

function Notifications() {
  const [pageSize, setPageSize] = useState(5);
  const [page, setPage] = useState(1);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const [checkDel, setCheckDel] = useState([]);
  const { data, error, isLoading, refetch } = useFetchNotificationQuery({
    page: page,
    page_size: pageSize,
    receiver: user_info.id,
  });
  // const [seenNotification] = useSetSeenNotificationMutation();
  const [deleteNotification] = useDeleteNotificationListMutation();

  // for refetch implementation of data from api
  useEffect(() => {
    const intervalId = setInterval(() => {
      refetch();
    }, 6000);
    return () => clearInterval(intervalId);
  }, [refetch]);

  let content = [];
  const totalPage = Math.ceil(data?.count / pageSize); // total page for purchase order
  // handle page size of table
  const changePagesize = (e) => {
    setPageSize(e.target.value);
    setPage(1);
  };
  // handle for updating unseen - seen
  // const handleSeen = (e) => {
  //   const notifId = e.target.id;
  //   console.log(notifId);
  //   seenNotification({ notifId: notifId });
  // };
  // handle previous of page size
  const handlePrev = () => {
    if (page === 1) return;
    setPage(page - 1);
  };
  // handle previous of page size
  const handleNext = () => {
    if (page === pageSize) return;
    setPage(page + 1);
  };
  // handlec change of checkbox
  const handleCheck = (e) => {
    const newVal = e.target.checked;
    const value = e.target.id;
    if (newVal) {
      setCheckDel([...checkDel, value]);
    } else {
      setCheckDel((prev) => {
        return prev.filter((id) => {
          return id !== value;
        });
      });
    }
  };
  // handle delete of check notifcation
  const handleDelete = async () => {
    console.log(checkDel);
    const notificationId = { notification_id: checkDel };
    await deleteNotification(notificationId);
    window.location.reload();
  };
  // conditioning of data fetching from api
  if (isLoading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error Loading...</div>;
  } else {
    content = data.results;
  }

  return (
    <>
      <div className="container mx-auto lg:px-4">
        <div className="flex items-center space-x-2 mb-5 ml-5">
          <IoMdNotifications className="h-6 w-6  text-[#137944]" />
          <Typography className="text-[#137944] font-bold text-3xl tracking-normal">
            Notifications
          </Typography>
        </div>
        <Card className="h-full w-full mb-5 mt-10 lg:mt-0">
          <CardHeader floated={false} shadow={false} className="rounded-none">
            <div className="mb-8 flex items-center justify-between gap-8">
              <div>
                <Typography className="text-lg text-blue-gray-800 font-semibold tracking-normal">
                  Notification center
                </Typography>
                <Typography color="gray" className="mt-1 text-base">
                  Unseen notification place on the top, followed by the seen
                  notification
                </Typography>
              </div>
            </div>
            <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
              <div className="w-full md:w-72">
                <Input label="Search" />
              </div>
              <div>
                <div className="flex items-center space-x-2">
                  <Typography>Page size:</Typography>
                  <select
                    defaultValue={pageSize}
                    onChange={changePagesize}
                    className="border-2 border-blue-gray-500"
                  >
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                </div>
              </div>
            </div>
          </CardHeader>
          <CardBody className="overflow-y-auto max-h-[60vh]">
            <table className="mt-4 w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  <Tooltip content="Delete selected" placement="right-end">
                    <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4">
                      <FaTrash
                        className="cursor-pointer"
                        onClick={handleDelete}
                      />
                    </th>
                  </Tooltip>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {content.map((nv, i) => {
                  const unseen = nv.is_seen;
                  const classes =
                    unseen === false
                      ? "bg-blue-50 p-3 border-b border-blue-gray-200"
                      : "p-3 border-b border-blue-gray-200";
                  return (
                    <tr key={i}>
                      <td className={classes}>
                        <Checkbox
                          className=" border-blue-gray-600"
                          value={nv.id}
                          id={nv.id}
                          onChange={handleCheck}
                        />
                      </td>
                      <td className={classes}>
                        {nv.date_seen === null && (
                          <div>
                            <Tooltip
                              content="Mark as read"
                              placement="bottom-start"
                            >
                              <div>
                                <MdMarkAsUnread className="h-5 w-5 cursor-pointer" />
                              </div>
                            </Tooltip>
                          </div>
                        )}
                        <Typography className=" text-sm font-semibold text-blue-gray-900">
                          {nv.subject}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <div>
                          <Typography className=" text-gray-900">
                            {nv.message}
                          </Typography>
                          <div className="flex space-x-11">
                            <Typography className=" text-xs">
                              Receive:{" "}
                              {moment(nv.date_delivered).format(
                                "YYYY-MM-DD HH:mm"
                              )}
                            </Typography>
                            <Typography className=" text-xs">
                              Seen:{" "}
                              {nv.date_seen !== null && (
                                <Moment fromNow>{nv.date_seen}</Moment>
                              )}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes} id={i}>
                        {nv.date_seen === null && (
                          <DetailNotification id={nv.id} />
                        )}
                      </td>
                      <td className={classes}>
                        <DeleteNotification id={nv.id} />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              Page {page} of {totalPage}
            </Typography>
            <div className="flex gap-2">
              <Button
                variant="outlined"
                size="sm"
                disabled={page === 1}
                onClick={handlePrev}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                size="sm"
                disabled={page === totalPage}
                onClick={handleNext}
              >
                Next
              </Button>
            </div>
          </CardFooter>
        </Card>
      </div>
    </>
  );
}
export default Notifications;
