import React, { useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Button,
} from "@material-tailwind/react";
import { useRawMatPrintQuery } from "../../../store";
import moment from "moment";


const GenerateReceivingReceipt = ({ vrr_id }) => {
  let result = [];
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const pesoSign = '\u20B1';

  let print_raw_mat = useRawMatPrintQuery(vrr_id);
  const apiDomain = process.env.REACT_APP_API_DOMAIN;

  if (print_raw_mat.isLoading) {
    return <div>Loading...</div>;
  } else if (print_raw_mat.error) {
    return  <div>Error Loading...</div>;
  } else {
    result = print_raw_mat.data.data;
  }

	const generatePDF = (result) => {
    setIsPDFLoading(true);

		const currentDate = new Date();
		const date_now = currentDate.toLocaleDateString("en-US", {
			timeZone: "Asia/Tokyo",
			hour12: false,
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
		});

		const pdfDoc = new jsPDF("p", "mm", "a4");

		// Add logo and title
		pdfDoc.setFontSize(20);
		pdfDoc.setTextColor(0, 128, 0);
		pdfDoc.setFont("helvetica", "bold");
		pdfDoc.text("RECEIVING RECEIPT", 30, 25);
		pdfDoc.setFontSize(12);
		pdfDoc.setTextColor(0);
		pdfDoc.setFont("helvetica", "normal");
		// Add delivery information
		pdfDoc.text(`PO Number: ${result.po_number}`, 20, 45);
		pdfDoc.text(`Vendor: ${result.vendor.name}`, 20, 50);
		pdfDoc.text(`Driver Name: ${result.vendor_driver_name}`, 20, 55);
		pdfDoc.text(`Commited date: ${moment(new Date(result.delivery_date)).format("YYYY/MM/DD")}`, 20, 60);
		pdfDoc.text(`Check Number: ${result.check_number}`, 20, 65);
		pdfDoc.text(`Receiver1: ${result.receiver1_name}`, 120, 45);
		pdfDoc.text(`Receiver2: ${(
			result.receiver2_user ? result.receiver2_user.first_name
			+ " " + result.receiver2_user.last_name : ''
		)}`, 120, 50);
		pdfDoc.text(`Delivery Receipt/Sales Invoice Receipt:`, 120, 55);
		pdfDoc.text(`	${result.drsi_receipt}`, 120, 60);
		pdfDoc.text(`Receiving Receipt Number: ${result.receiving_receipt_number}`, 120, 65);

		const tableStartY = 95;

		pdfDoc.autoTable({
			head: [
			[
				{ content: "Items", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Unit Measure", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Actual Quantity", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Price", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Amount", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Value Amount if VAT", styles: { halign: 'center', valign: 'middle' } },
				{ content: "W/TAX PURC (less 1%)", styles: { halign: 'center', valign: 'middle' } }
			],
			],
			body: result.vendor_items.map((ri) => [
				{ content: ri.request_item.item.name, styles: { halign: 'center', valign: 'middle' } },
				{ content: ri.request_item.item.unit_measurement, styles: { halign: 'center', valign: 'middle' } },
				{ content: ri.des_actual_qty, styles: { halign: 'center', valign: 'middle' } },
				{ content: ri.quoted_price, styles: { halign: 'center', valign: 'middle' } },
				{ content: ri.amount, styles: { halign: 'center', valign: 'middle' } },
				{ content: ri.vat_amount, styles: { halign: 'center', valign: 'middle' } },
				{ content: ri.w_tax_purc, styles: { halign: 'center', valign: 'middle' } },
			]),
			startY: tableStartY,
			theme: "grid",
			styles: {
			fillColor: [255, 255, 255],
			textColor: [0, 0, 0],
			},
		});

		const tableHeight = pdfDoc.previousAutoTable.finalY - tableStartY;

		const startX = 10;
		const startY = tableStartY + tableHeight + 40;
		const columnWidth = 60;

		pdfDoc.text(`Total: ${result.sub_total_amount}`, 30, startY - 30);

		const reciever1X = startX;
		const receiver1Y = startY;
		const receiver1 = pdfDoc.getTextWidth("Receiver1");
		const centeredReceiver1X = reciever1X + (columnWidth - receiver1) / 2;
		pdfDoc.text("Receiver1", centeredReceiver1X, receiver1Y + 5);
		pdfDoc.text(`__________________`, reciever1X + 10, receiver1Y);

		const receiver2 = pdfDoc.getTextWidth("Receiver2");
		const receiver2X = reciever1X + columnWidth;
		const centeredReceiver2X = receiver2X + (columnWidth - receiver2) / 2;
		pdfDoc.text("Receiver2", centeredReceiver2X, receiver1Y + 5);
		pdfDoc.text(`__________________`, receiver2X + 10, receiver1Y);

		const driverY = startY ;
		const driverTextWidth = pdfDoc.getTextWidth("Drive");
		const centeredDriverX = (receiver2X + 50) + (columnWidth - driverTextWidth) / 2;
		pdfDoc.text("Driver", centeredDriverX + 5, driverY + 5);
		pdfDoc.text(`__________________`, centeredDriverX - 5, driverY);

		const imageWidth = 30;
		const imageHeight = 15;

		//signatures
		pdfDoc.addImage(result.receiver1, 'PNG', centeredDriverX, driverY -10, imageWidth, imageHeight);
		pdfDoc.addImage(result.receiver2, 'PNG', centeredReceiver1X, receiver1Y -10, imageWidth, imageHeight);
		pdfDoc.addImage(result.vendor_driver_sig, 'PNG', centeredReceiver2X,receiver1Y  -10, imageWidth, imageHeight);

		pdfDoc.save(`BM_${result.po_number}_Receipt_${result.vendor.name}_${date_now}.pdf`);
		setIsPDFLoading(false)
	};

	return (
		<div>
			<Button
				className="bg-green-900 mr-5"
				onClick={() => generatePDF(result)}
				>
					Print RR
			</Button>
		</div>
	);
}

export default GenerateReceivingReceipt;
