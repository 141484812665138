import KBAccordionItem from "./KBAccordionItem";

function KBAccordion({ data, currOpen, setCurrOpen }) {
  return (
    <div>
      {data.map((faqs, index) => (
        <KBAccordionItem
          key={`faqs-${index}`}
          faqs={faqs}
          num={index}
          currOpen={currOpen}
          onOpen={setCurrOpen}
        />
      ))}
    </div>
  );
}

export default KBAccordion;
