import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { ToastContainer } from "react-toastify";

export function AddNewVendorConfirmation({ handleSave }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);
  function multiFunction() {
    handleSave();
    setOpen(!open);
  }
  return (
    <>
      <Button onClick={handleOpen} variant="gradient" color="yellow">
        Save
      </Button>
      <Dialog open={open} handler={handleOpen}>
        <ToastContainer />
        <DialogHeader>Confirm add new vendor</DialogHeader>
        <DialogBody>Are you sure to add new vendor?</DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="green" onClick={multiFunction}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
