import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");
const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

const dashboardApi = createApi({
  reducerPath: "dashboard",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  endpoints(builder) {
    return {
      fetchDeliveryTotalSales: builder.query({
        query: ({
          route,
          fc,
          startDate,
          endDate,
          page=1,
          page_size=999,
        }) => {
          return {
            url: `/api/client/get_delivery_cage_sales/?page=${page}&page_size=${page_size}&route=${route}&fc=${fc}&startDate=${startDate}&endDate=${endDate}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchInventoeyDashBoardGraph: builder.query({
        query: ({
          location,
          date,
          page=1,
          page_size=999,
        }) => {
          return {
            url: `/api/client/inventory_dashboard_view_list/?page=${page}&page_size=${page_size}&date=${date}&location=${location}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      dashBoardPO: builder.query({
        query: ({client}) => {
          return {
            url: `/api/client/${client}/dashboard_po/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      dashBoardProd: builder.query({
        query: ({client}) => {
          return {
            url: `/api/client/dashboard_prod/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      dashPendingPo: builder.query({
        query: ({
          page,
          page_size,
          search_str,
        }) => {
          return {
            url: `/api/client/dash_pending_po/?page=${
              page
            }&page_size=${
              page_size
            }&search_str=${
              search_str
            }`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      dashProdReport: builder.query({
        query: ({
          page,
          page_size,
          search_str,
        }) => {
          return {
            url: `/api/client/dash_prod_report_item/?page=${
              page
            }&page_size=${
              page_size
            }&search_str=${
              search_str
            }`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
    };
  },
});
export const {
  useFetchDeliveryTotalSalesQuery,
  useFetchInventoeyDashBoardGraphQuery,
  useDashBoardPOQuery,
  useDashPendingPoQuery,
  useDashBoardProdQuery,
  useDashProdReportQuery,
} = dashboardApi;
export { dashboardApi };
