import {
    Card,
    CardHeader,
    Typography,
    Button,
    CardBody,
    CardFooter,
    Dialog,
    DialogHeader,
    DialogBody,
    DialogFooter,
  } from "@material-tailwind/react";
import { useState, useEffect } from "react";
import { HashLoader } from "react-spinners";
import { toast,  } from "react-toastify";
import {
    useGoodsRejectListPageQuery,
    useTransferGoodRejectMutation
} from "../../../store/apis/TransferApi";

const TABLE_HEAD = [
  "Transfer Tracking Number",
  "Product",
  "Issuance From",
  "Requested By",
  "Transfer Request Date",
  "Transfer Quantity",
  "type",
];


function GoodRejectProd() {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [remark, setRemark] = useState('');
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const [transferGoodReject] = useTransferGoodRejectMutation();

  const goodsRejectList = useGoodsRejectListPageQuery({
    page: currentPage,
    page_size: pageSize,
    type: 'product',
    remark: remark
  });
  const totalPage = Math.ceil(
    goodsRejectList.data?.count / pageSize);

  // refetching the data from database every 5 sec
  const interval = 3000;
  useEffect(() => {
    goodsRejectList.refetch();
  }, [interval]);
  const prodLine = [
    {
      id: 1,
      name: "Bakery",
    },
    {
      id: 2,
      name: "Patty",
    },
    {
      id: 3,
      name: "Sandwich line",
    },
    {
      id: 4,
      name: "Catsup",
    },
    {
      id: 5,
      name: "Cake and Pastries",
    },
    {
      id: 6,
      name: "Premixes",
    },
  ];
  let content = [];

  //handle change page sir of table
  const changePagesize = (e) => {
    setPageSize(e.target.value);
  };
  // conditioning for data fetching for api
  if (goodsRejectList.isLoading) {
    return <HashLoader />;
  } else if (goodsRejectList.error) {
    return <div>Refresh the page...</div>;
  } else {
    content = <>
      {goodsRejectList.data.results.map((tp, i) => {
        const isLast = tp === goodsRejectList.data.results.length - 1;
        const issuance = prodLine.find(
          (item) => item.id === tp.transfer_to
        );
        const classes = isLast
          ? "p-4"
          : "p-4 border-b border-blue-gray-50";
        return (
          <tr key={i}>
            <td className={classes}>{tp.transfer_number}</td>
            <td className={classes}>{tp.product.name}</td>
            <td className={classes}>{issuance ? issuance.name : ""}</td>
            <td className={classes}>{tp.requested_by}</td>
            <td className={classes}>{tp.transfer_request_date}</td>
            <td className={classes}>{tp.transfer_qty}</td>
            <td className={classes}>{tp.good_reject == 1 ? 'Goods': 'Reject'}</td>
          </tr>
        );
      })}
    </>
  }
  const handleTransfer = () => {
    handleOpen();
  }
  const handleConfirm = async() => {
    let form = {
      user_id: user_info.id,
      client_id: account_detailed.client.id,
      data : goodsRejectList.data?.results
    };
    const sea = await transferGoodReject(form).unwrap();
    if (sea.success === true) {
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
        goodsRejectList.refetch()
    handleOpen();
  };

  const handleRemark = (val) => {
    setRemark(val)
  }
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };
  return (
    <>
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-8 flex items-center justify-between gap-8"></div>
        <div className="flex justify-end mr-10 space-x-2">
          <Typography>Page size:</Typography>
          <select
            defaultValue={pageSize}
            onChange={changePagesize}
            className="border-2 border-blue-gray-500"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </div>
        <div className="mb-8 flex w-[20%] gap-8">
          <select
            onChange={(e) => {
              handleRemark(
                e.target.value
            )}}
            className="w-[101%] inline-flex justify-center ml-2 rounded-md px-5 py-3 text-sm text-black-500 border-[1px] ring-blue-gray-200 border-blue-gray-200">
            <option value="0" selected>--- Select type ---</option>
            <option value="1">Good</option>
            <option value="2">Reject</option>
          </select>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="mt-4 w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {content}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page {currentPage} of {totalPage}
        </Typography>
        <div className="flex gap-2">
          <Button
            variant="gradient"
            color="teal"
            size="sm"
            onClick={() => handleTransfer()}
          >
            Transfer
          </Button>
          <Button
            variant="outlined"
            size="sm"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            size="sm"
            onClick={handleNextPage}
            disabled={currentPage === totalPage}
          >
            Next
          </Button>
        </div>
      </CardFooter>
    </Card>
    <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Transfer Product</DialogHeader>
        <DialogBody>Are you sure you want to proceed with this action</DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button
            variant="gradient"
            color="green"
            onClick={handleConfirm}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  )
}
export default GoodRejectProd;
