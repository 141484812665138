import GoodRejectItem from "../table_components/Production/GoodRejectItem";
import GoodRejectProd from "../table_components/Production/GoodRejectProd";
import ApprovalTabComponents from "./ApprovalTabComponents";


function TransferGoodReject() {
  const data = [
    {
      label: "Product",
      value: "product",
      desc: <GoodRejectProd/>,
    },
    {
      label: "Item",
      value: "item",
      desc: <GoodRejectItem/>,
    },
  ]

  return (
    <>
      <div className="w-[105%] ml-[-3%] md:w-[110%] md:ml-[-5%] lg:w-[100%] lg:ml-[0%]">
        <ApprovalTabComponents data={data} />
      </div>
     </>
  );
}
export default TransferGoodReject;
