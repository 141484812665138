import React, { useRef, useState, createRef } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Radio,
  Card,
  List,
  ListItem,
  ListItemPrefix,
} from "@material-tailwind/react";
import ReactSignatureCanvas from "react-signature-canvas";
import { GrPowerReset } from "react-icons/gr";
import { ToastContainer, toast } from "react-toastify";
import {
  useApprovalAdjustedRequiredQtyMutation,
  usePmApprovalAdjustedRequiredQtyMutation,
} from "../../../store";

export function ApproveAdjustedRequiredQty({
  request_item_id,
  item_name,
  ri_status,
  po_number,
  unit_measurement,
  data_refetch,
  qa_remarks,
  adjusted_required_qty,
  tre_signature,
  required_qty,
}) {
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const signatureBoxclasses = "flex mb-1 mt-5 py-6 px-2";
  let formRef = useRef();
  const [editApprove, setEditApprove] = useState(false);
  const [open, setOpen] = useState(false);
  // const signatureRef = createRef();
  const [signature, setSignature] = useState("");
  const [pmRemark, setPmRemark] = useState("");
  const [status, setStatus] = useState("");

  const handleOpen = () => setOpen(!open);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState(true);

  const handleSignatureReset = () => {
    // signatureRef.current.clear();
    setSignature(""); // Reset the stored base64 representation
    setIsSignatureEmpty(true);
    signature.clear();
  };

  const handleSignature = () => {
    setEditApprove(true);
  };

  const [pm_approval_adjusted_required_qty] =
    usePmApprovalAdjustedRequiredQtyMutation();
  const sendAdjustedQuantity = async (e) => {
    e.preventDefault();
    let sig_split = signature.toDataURL().split(",");
    let formData = new FormData();

    formData.append("user_id", parseInt(user_info.id));
    formData.append("request_item_id", request_item_id);
    formData.append("pm_replay_remarks", pmRemark);
    formData.append("pm_signature", sig_split[1]);
    formData.append("status", status);

    const pmAArQ = await pm_approval_adjusted_required_qty(formData).unwrap();
    if (pmAArQ.success === true) {
      toast.success(pmAArQ.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(pmAArQ.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    data_refetch();
    handleOpen();
  };
  const sendApproveOpen = () => {
    handleOpen();
  };
  return (
    <>
      <div className="justify-center items-center">
        <Button onClick={sendApproveOpen} className="bg-green-700">
          view
        </Button>
      </div>

      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Approve Adjusted Remarks</DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <div
            id="form"
            className="w-full flex justify-center items-center rounded-xl shadow-md shadow-slate-300 p-2"
          >
            <form
              ref={formRef}
              onSubmit={sendAdjustedQuantity}
              className="w-full p-8"
            >
              <div className="flex flex-row gap-2 my-4">
                <div id="firstname" className="w-1/2 mr-1">
                  <Typography variant="lead">
                    PO Number: <Typography>{po_number}</Typography>
                  </Typography>
                </div>
                <div className="w-1/2 mr-1">
                  <Typography variant="lead">
                    Item: <Typography>{item_name}</Typography>
                  </Typography>
                </div>
              </div>
              <div className="flex flex-row gap-2 my-4">
                <div className="w-1/2 mr-1">
                  <Typography variant="lead">
                    Measurement: <Typography>{unit_measurement}</Typography>
                  </Typography>
                </div>
                <div className="w-1/2 mr-1">
                  <Typography variant="lead">
                    Adjusted Required Quantity:{" "}
                    <Typography>{adjusted_required_qty}</Typography>
                  </Typography>
                </div>
              </div>
              <div className="flex flex-row gap-2 my-4">
                <div className="w-1/2 mr-1">
                  <Typography>Required Quantity:</Typography>
                  <Typography>{required_qty}</Typography>
                </div>
                <div className="w-1/2 mr-1">
                  <Typography variant="lead">
                    Remark: <Typography>{qa_remarks}</Typography>
                  </Typography>
                </div>
              </div>
              <div>
                <Card className="w-full max-w-[24rem]">
                  <List className="flex-row">
                    <ListItem className="p-0">
                      <label
                        htmlFor="horizontal-list-react"
                        className="flex w-full cursor-pointer items-center px-3 py-2"
                      >
                        <ListItemPrefix className="mr-3">
                          <Radio
                            name="horizontal-list"
                            id="horizontal-list-react"
                            ripple={false}
                            value="approve"
                            className="hover:before:opacity-0"
                            containerProps={{
                              className: "p-0",
                            }}
                            onChange={(evt) => setStatus(evt.target.value)}
                          />
                        </ListItemPrefix>
                        <Typography
                          color="blue-gray"
                          className="font-medium text-green-400"
                        >
                          Approve
                        </Typography>
                      </label>
                    </ListItem>
                    <ListItem className="p-0">
                      <label
                        htmlFor="horizontal-list-vue"
                        className="flex w-full cursor-pointer items-center px-3 py-2"
                      >
                        <ListItemPrefix className="mr-3">
                          <Radio
                            name="horizontal-list"
                            id="horizontal-list-vue"
                            ripple={false}
                            value="reject"
                            className="hover:before:opacity-0"
                            containerProps={{
                              className: "p-0",
                            }}
                            onChange={(evt) => setStatus(evt.target.value)}
                          />
                        </ListItemPrefix>
                        <Typography
                          color="blue-gray"
                          className="font-medium text-red-400"
                        >
                          Reject
                        </Typography>
                      </label>
                    </ListItem>
                  </List>
                </Card>
              </div>
              <div className="flex flex-row gap-2 my-4">
                <div className="w-1/2 mr-1">
                  <textarea
                    placeholder="Enter your replay remarks here..."
                    name=""
                    id=""
                    cols="30"
                    rows="5"
                    className="border-2 border-blue-gray-500 text-black p-2"
                    onChange={(evt) => setPmRemark(evt.target.value)}
                  ></textarea>
                </div>
              </div>
              <div className="flex flex-row gap-2 my-4">
                <div className="w-1/2 mr-1">
                  <div className="flex flex-col justify-center items-center mt-10 space-y-3">
                    <div className="border border-blue-gray-300 rounded-lg py-1 px-2">
                      <img src={apiDomain + tre_signature} alt="" />
                    </div>
                    {/* <Typography >Balentong</Typography> */}
                  </div>
                </div>
                <div className="w-1/2 mr-1">
                  <div className={signatureBoxclasses}>
                    <ReactSignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 100,
                        className: "border-black border-2 rounded-s-xl",
                      }}
                      ref={(data) => setSignature(data)}
                      onEnd={handleSignature}
                    />
                    <div className={boxResetClasses}>
                      <GrPowerReset
                        size={"30px"}
                        onClick={() => handleSignatureReset(0)}
                        className="cursor-pointer"
                        color="white"
                      />
                    </div>
                  </div>
                  <Typography className="justify-center items-center">
                    HERMAN F. GATELA
                  </Typography>
                </div>
              </div>
              {/* Submit button */}
              <div>
                <Button
                  type="submit"
                  className="bg-green-700 h-10 cursor-pointer text-white rounded-md
                    hover:bg-white-600 hover:outline outline-2 outline-blue-600 outline-offset-2 text-sm mt-2 m-2"
                  disabled={editApprove == false}
                >
                  <span className="">Save</span>
                </Button>
              </div>
            </form>
          </div>
          <ToastContainer />
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
