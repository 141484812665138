import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");
const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

const accountApi = createApi({
  reducerPath: "employee",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  endpoints(builder) {
    return {
      accountDetailed: builder.query({
        query: (id) => {
          return {
            url: `/api/account/${id}/account_detailed/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      accountListSelect: builder.query({
        query: (user_type) => {
          return {
            url: `/api/account/account_list_select/?client_id=${account_detailed.client.id}&user_type=${user_type}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchClientLogo: builder.query({
        query: () => {
          return {
            url: `api/client/get_logo/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchActionLogs: builder.query({
        query: ({
          page = 1,
          page_size = 10,
          order_by = "id",
          order_dir = "desc",
          search = "",
          startDate = "",
          endDate = "",
          types,
        }) => {
          return {
            url: `/api/account/action_logs_list_page/?page=${page}&page_size=${page_size}&order_by=${order_by}&order_dir=${order_dir}&search=${search}&startDate=${startDate}&endDate=${endDate}&types=${types}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      employeeList: builder.query({
        query: ({ client_id, page, pageSize, jobposition }) => {
          return {
            url: `/api/account/employee_list_page/?client_id=${client_id}&jobposition=${jobposition}&page=${page}&page_size=${pageSize}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      selectedEmployeeSched: builder.query({
        query: (id) => {
          return {
            url: `/api/account/${id}/selected_employee_sched/`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      saveEmployeeSched: builder.mutation({
        query: (form) => {
          return {
            url: `/api/account/save_employee_sched/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      accountListReceivingReport: builder.query({
        query: () => {
          return {
            url: `/api/account/account_vendor_received/?client_id=${account_detailed.client.id}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      empInvolSchedOutput: builder.query({
        query: ({ spl_id, page, pageSize }) => {
          return {
            url: `/api/account/${spl_id}/emp_invol_sched_output/?page=${page}&page_size=${pageSize}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      saveEmployeeInvol: builder.mutation({
        query: (form) => {
          return {
            url: `/api/account/save_invol_emp/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      fetchUserManagementListPage: builder.query({
        query: ({ page, page_size, search = "" }) => {
          return {
            url: `/api/client/user_list_page/?page=${page}&page_size=${page_size}&search=${search}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      createNewUser: builder.mutation({
        query: (form) => {
          return {
            url: `/api/client/create_user/`,
            headers: { Authorization: "token " + token },
            method: "POST",
            body: form,
          };
        },
      }),
      updateUserDetails: builder.mutation({
        query: (form) => {
          return {
            url: `/api/client/update_user_details/`,
            headers: { Authorization: "token " + token },
            method: "PUT",
            body: form,
          };
        },
      }),
    };
  },
});
export const {
  useAccountDetailedQuery,
  useAccountListSelectQuery,
  useFetchClientLogoQuery,
  useFetchActionLogsQuery,
  useEmployeeListQuery,
  useSelectedEmployeeSchedQuery,
  useSaveEmployeeSchedMutation,
  useAccountListReceivingReportQuery,
  useEmpInvolSchedOutputQuery,
  useSaveEmployeeInvolMutation,
  useFetchUserManagementListPageQuery,
  useCreateNewUserMutation,
  useUpdateUserDetailsMutation,
} = accountApi;
export { accountApi };
