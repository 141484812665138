import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Card,
  CardFooter,
} from "@material-tailwind/react";
const TABLE_HEAD = [
  "Code",
  "Description",
  "Order Quantity",
  "SOH",
  "UM",
  "Remarks",
];
const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
const trapMessage = "Are you sure to Transfer these products?";
const user_info = JSON.parse(localStorage.getItem("user_info"));

const currentDate = new Date();
const transferDate = currentDate.toLocaleString(undefined, {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});
let result = [];
let result_item = [];

function FreshGoodsModal({ itemData }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = "p-4 border-b border-blue-gray-50";
  const inputClasses = "flex flex-row items-center";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleMainClose = () => {
    setOpen(false);
  };

 if (itemData) {
    result = itemData.results;

    const formattedDate = result.transfer_request_date
      ? new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(new Date(result.transfer_request_date))
      : "";

    return (
      <>
        <Button onClick={handleOpen} className="bg-[#1AA55D]">
          Fresh Goods Details
        </Button>

        <Dialog
          size="lg"
          open={open}
          handler={handleOpen}
          className="relative max-h-min"
        >
          <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
            {/* <img src={Logo} className="aspect-auto w-48" alt="logo" /> */}
            <Typography
              className="text-green-900"
              variant="h3"
              component={"span"}
            >
              Fresh Goods Details
            </Typography>
          </DialogHeader>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
          <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left ">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
              {result.map((del, index) => {
                const isLast = index === result.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                return (
                  <tr key={del.product_id}>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {del.product__product_code || "N/A"}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                          {del.product__name || "N/A"}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                        {del.ordered_qty || 0}/{del.product__unit_measurement || "N/A"}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                        {del.stock_on_hand || 0}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                        {del.product__weight_box || "N/A"}/{del.product__unit_measurement || "N/A"}
                        </Typography>
                      </div>
                    </div>
                  </td>
                  <td className={classes}>
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <Typography variant="small" color={del.ordered_qty > del.stock_on_hand ? "red" : "green"} className="font-normal">
                        {del.ordered_qty > del.stock_on_hand ? "Insufficient" : "Sufficient"}
                      </Typography>
                    </div>
                  </div>
                </td>
                </tr>

                );
              })}
            </tbody>
      </table>
    </Card>
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              className="mr-3"
              onClick={handleMainClose}
            >
              <span>Close</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

export default FreshGoodsModal;
