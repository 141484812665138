
import { useDashBoardProdQuery } from "../../../store";
import ProdTable from "./ProdTable";
import ProdStatusCount from "./ProdStatusCount"
import ProdlineGraph from "./ProdLineGraph";


export function ProdPageDash() {
	const dash_board_prod = useDashBoardProdQuery({})

    let content;
    if (dash_board_prod.isLoading) {
        return <div>Loading....</div>;
    } else if (dash_board_prod.error) {
        return <div>Refresh the page....</div>;
    } else {
		content = <>
            <div className="block space-y-3 md:space-y-0 md:flex gap-4 mx-2">
                <ProdlineGraph/>
                <ProdStatusCount
                    prod_line={
                        dash_board_prod.data?.data.prod_line
                    }
                />
            </div>
            <div className="flex flex-col gap-4">
                <ProdTable/>
            </div>
		</>
	}

  return (
    <>
        <div className="mb-20 lg:mb-5 mt-3 md:mt-0">
            {content}
        </div>
    </>
  );
}

export default ProdPageDash;
