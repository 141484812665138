import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { useDeleteRequestItemMutation } from "../../../store/apis/RequestApi";
import { toast, ToastContainer } from "react-toastify";
import { FaTrash } from "react-icons/fa";

function ApprovalDeleteItem({
  id,
  disable,
  refetchRequestItem,
  user_id
}) {
  const [msgTrap, setMsgTrap] = useState(false);

	const [deleteRequestItem] = useDeleteRequestItemMutation();
  const handlemsgTrap = async(e) => {
    const dri = await deleteRequestItem(
			{
				request_item_id: id,
        user_id:user_id
			}
		).unwrap();

    if (dri.success === true) {
      toast.success(dri.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(dri.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
		refetchRequestItem();
    handleMgsOpen();
  };

	const handleMgsOpen = () => setMsgTrap(!msgTrap);

  return (
    <>
			<Button
				className="bg-red-700"
				onClick={handleMgsOpen}
				disabled={disable === false}
			>
				<FaTrash
					className="cursor-pointer"
				/>
			</Button>
      <Dialog open={msgTrap} handler={handleMgsOpen}>
        <DialogHeader>Action confirmation</DialogHeader>
        <DialogBody>This data is permanently deleted are you sure to you want to proceed this action</DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            className="mr-1"
						onClick={handleMgsOpen}
          >
            <span>Cancel</span>
          </Button>
          <Button
						variant="gradient"
						color="green"
						onClick={handlemsgTrap}
					>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
      <ToastContainer/>
    </>
  );
}

export default ApprovalDeleteItem;
