import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Button,
} from "@material-tailwind/react";
import {
	useAccountListSelectQuery,
	useFetchProdItemLineCompletedQuery,
	useSelectedEmployeeSchedQuery
} from "../../../store";


const GenerateCompletedItemSlip = ({ prod_id }) => {
  let result = [];
	const prodLine = [
    {
      id: 1,
      name: "Bakery",
    },
    {
      id: 2,
      name: "Patty",
    },
    {
      id: 3,
      name: "Sandwich line",
    },
    {
      id: 4,
      name: "Catsup",
    },
    {
      id: 5,
      name: "Cake and Pastries",
    },
    {
      id: 6,
      name: "Premixes",
    },
  ];
	const fetchScheduleProduct = useFetchProdItemLineCompletedQuery(prod_id);
	const selectedEmpSched = useSelectedEmployeeSchedQuery(prod_id);
	const accouint_list_select = useAccountListSelectQuery(1);

	let selected_emp_arr;
  if (selectedEmpSched.isLoading) {
    return <div>Loading....</div>;
  } else if (selectedEmpSched.error) {
    return <div>Refresh the page...</div>;
  } else {
    selected_emp_arr = selectedEmpSched.data?.data;
  }

	let als;
  if (accouint_list_select.isLoading){
    //pass
  }else if(accouint_list_select.error){
    //pass
  }else{
    als = accouint_list_select.data.data
  }

  let content = [];
  let schedule;
  if (fetchScheduleProduct.isLoading) {
    return <div>Loading....</div>;
  } else if (fetchScheduleProduct.error) {
    return <div>Refresh the page...</div>;
  } else {
    content = fetchScheduleProduct.data?.data.sched_item;
    console.log(content)
	  schedule = fetchScheduleProduct.data?.data
  }

	const generatePDF = (result) => {

		// const [isPDFLoading, setIsPDFLoading] = useState(false);
    // setIsPDFLoading(true);

		const currentDate = new Date();
		const date_now = currentDate.toLocaleDateString("en-US", {
			timeZone: "Asia/Tokyo",
			hour12: false,
			hour: "2-digit",
			minute: "2-digit",
			second: "2-digit",
		});

		const pdfDoc = new jsPDF("p", "mm", "a4");

		// Add logo and title
		pdfDoc.setFontSize(20);
		pdfDoc.setTextColor(0, 128, 0);
		pdfDoc.setFont("helvetica", "bold");
		pdfDoc.text("Production Report", 30, 25);
		pdfDoc.setFontSize(12);
		pdfDoc.setTextColor(0);
		pdfDoc.setFont("helvetica", "normal");
		// Add delivery information
		pdfDoc.text(`Production Line ${schedule?.issuance_from}`, 20, 45);
		pdfDoc.text(`Transfer to: ${schedule?.transfer_to != null ? schedule?.transfer_to : '' }`, 20, 50);
		pdfDoc.text(`Transfer Request Date: ${schedule?.transfer_request_date}`, 20, 55);
		pdfDoc.text(`Issued By: ${schedule?.requested_by}`, 120, 45);
		// pdfDoc.text(`Receiver:  ${als.find((rev) => rev.user_id === fetchScheduleProduct.data?.data.recieve_by).full_name}}`, 120, 55);
		pdfDoc.text(`Transfer Tracking Number: ${schedule?.transaction_number}`, 120, 50);

		const tableStartY = 70;

		pdfDoc.autoTable({
			head: [
			[
				{ content: "Production", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Scheduled Batch", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Actual Batch", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Theoretical Output", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Actual Weight", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Theoretical Weight", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Actual Yield", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Good", styles: { halign: 'center', valign: 'middle' } },
				{ content: "Reject", styles: { halign: 'center', valign: 'middle' } }
			],
			],
			body: content.map((pr) => [
				{ content: pr.name, styles: { halign: 'center', valign: 'middle' } },
				{ content: pr.schedule_batch, styles: { halign: 'center', valign: 'middle' } },
				{ content: pr.actual_batch, styles: { halign: 'center', valign: 'middle' } },
				{ content: pr.theo_yield, styles: { halign: 'center', valign: 'middle' } },
				{ content: pr.actual_weight, styles: { halign: 'center', valign: 'middle' } },
				{ content: pr.theo_weight, styles: { halign: 'center', valign: 'middle' } },
				{ content: pr.actual_yield, styles: { halign: 'center', valign: 'middle' } },
				{ content: pr.good, styles: { halign: 'center', valign: 'middle' } },
				{ content: pr.reject, styles: { halign: 'center', valign: 'middle' } },
			]),
			startY: tableStartY,
			theme: "grid",
			styles: {
			fillColor: [255, 255, 255],
			textColor: [0, 0, 0],
			},
		});

		const tableHeight = pdfDoc.previousAutoTable.finalY - tableStartY;

		const startX = 10;
		const startY = tableStartY + tableHeight + 40;
		const lineHeight = 20;
		const columnWidth = 60;
		const horizontalSpacing = 65;

		const driverY = startY ;

		const imageWidth = 30;
		const imageHeight = 15;

		pdfDoc.save(`BM_${schedule.id}_Receipt_${schedule.id}_${date_now}.pdf`);
	};

	return (
		<div>
			<Button
                className="bg-green-600 ml-3"
                onClick={() => generatePDF(result)}
            >
                <span>Extract to PDF</span>
            </Button>
		</div>
	);
}

export default GenerateCompletedItemSlip;
