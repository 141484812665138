import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Option,
  Select,
} from "@material-tailwind/react";
import {
  GenDelRecTbl,
  DelIssuedItemTbl,
  DRNumTbl,
} from "../../table_components/Delivery/GenDelReciTbl";
import { GrPowerReset } from "react-icons/gr";
import ReactSignatureCanvas from "react-signature-canvas";
import { useUpdateSaveDeliveryDetailsMutation } from "../../../store";
import { useFetchUserListPageQuery } from "../../../store";
import { toast } from "react-toastify";
import { SaveDeliveryDetailsConfirmation } from "../../modal_form_components/Error_trap_components/SaveDeliveryDetailsConfirmation";
import OrderSummaryPdf from "../../form_components/pdf/GenerateOrderPdf";

function GenDelReceiptModal({ getDeliverId, delData, refetchMainTable }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const inputClasses = "flex flex-row items-center";
  const signatureClasses = "grid grid-flow-row grid-cols-1";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";

    const {
      data: userData,
      isError: userErr,
      isLoading: userLoading,
      isSuccess: userSuccess,
    } = useFetchUserListPageQuery({
      page: 1,
      page_size: 50,
      user_type: 16,
    });

    const [driver, SetDriver] = useState(null);
  let [lpgIssuerName, setlpgIssuerName] = useState("");
  const [cCname, setCCname] = useState("");
  const [isSignatureEmpty, setIsSignatureEmpty] = useState([
    true,
    true,
    true,
    true,
  ]);
  const [saveDelivery] = useUpdateSaveDeliveryDetailsMutation();
  const [signatures, setSignatures] = useState(["", "", "", ""]);
  const signatureRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];
  const handleReset = (index) => {
    if (signatureRefs[index].current) {
      signatureRefs[index].current.clear();
      setSignatures((prevSignatures) => {
        const updatedSignatures = [...prevSignatures];
        updatedSignatures[index] = "";
        return updatedSignatures;
      });

      setIsSignatureEmpty((prevIsSignatureEmpty) => {
        const updatedIsSignatureEmpty = [...prevIsSignatureEmpty];
        updatedIsSignatureEmpty[index] = true;
        return updatedIsSignatureEmpty;
      });
    }
  };
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const logo = JSON.parse(localStorage.getItem("account_detail"));
  // const burgerMachineLogo =
  //   process.env.REACT_APP_API_DOMAIN + "" + logo.client.logo;

  const burgerMachineLogo = "../images/BurgerMachineLogo.jpg";

  const handleSignatureChange = (index) => {
    const isEmpty = signatureRefs[index].current.isEmpty();
    setIsSignatureEmpty((prev) => {
      console.log(delData);
      const updated = [...prev];
      updated[index] = isEmpty;
      return updated;
    });
    const base64Signature = signatureRefs[index].current.toDataURL();
    setSignatures((prevSignatures) => {
      const updatedSignatures = [...prevSignatures];
      updatedSignatures[index] = base64Signature;
      return updatedSignatures;
    });
  };

  const handleDriverChange = (value) => {
    const driverId = parseInt(value, 10);
    const selectedDriver = user_list_result.find(
      (driver) => driver.id === driverId
    );
    SetDriver(selectedDriver);
  }

  const handleCCNameChange = (value) => {
    setCCname(value);
  };

  function handleLPGIssuerNameChange(event) {
    const newValue = event.target.value;
    setlpgIssuerName(newValue);
  }

  const [isAllInputsProvided, setIsAllInputsProvided] = useState(false);
  const [isPDFReady, setIsPDFReady] = useState(false);

  useEffect(() => {
    // Check if all inputs are provided
    const allInputsProvided =
      isSignatureEmpty.every((isSignatureEmpty) => isSignatureEmpty !== true) &&
      driver !== null &&
      lpgIssuerName !== "";
    setIsAllInputsProvided(allInputsProvided);
  }, [signatures, driver, lpgIssuerName]);

  const handleMainClose = () => {
    setOpen(false);
    setSignatures([""], [""], [""], [""]);
    setIsSignatureEmpty([true, true, true, true]);
  };

  const handleDeliverySave = async (event) => {
    event.preventDefault();
    // Gather data from each table component

    // Repeat for other table components

    // Combine all data as needed
    let base64DataCustodian = signatures[0].slice(
      "data:image/png;base64,".length
    );
    let base64DataCage = signatures[1].slice("data:image/png;base64,".length);
    let base64DataLpg = signatures[2].slice("data:image/png;base64,".length);
    let base64DataDriver = signatures[3].slice("data:image/png;base64,".length);
    let allData = {
      user: account_detailed.user.id,
      cageCustodian: cCname,
      deliveryId: getDeliverId,
      driver: driver.id,
      lpgIssuer: lpgIssuerName,
      custodianSign: base64DataCustodian,
      cageCustodianSign: base64DataCage,
      lpgIssuerSign: base64DataLpg,
      driverSign: base64DataDriver,
    };
    console.log(allData);
    const sea = await saveDelivery(allData).unwrap();
    if (sea.success === true) {
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      setIsPDFReady(true);
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
      // formRef.current?.reset();
    }
  };

  const currentDate = new Date();

  const formattedDate = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  }).format(currentDate);

  let user_list_result = [];

  if (userLoading) {

    return <div>Loading...</div>;
  } else if (userErr) {
    return <div>Error loading data</div>;
  } else if (userData.length === 0) {
    return <div>No data found.</div>;
  } else if ( userSuccess) {
    user_list_result = userData.results;

    return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]">
        Generate Receipt
      </Button>
      <Dialog
        size="xl"
        open={open}
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
        <img
            src={burgerMachineLogo}
            alt="logo"
            className="aspect-auto w-72"
          />
          <Typography className="text-green-900" variant="h3">
            Generate Delivery Receipt
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <div className="col-span-1 flex items-center justify-center space-y-5 mb-7">
            <Typography variant="h5" color="blue-gray">
              Delivery Summary Per Group
            </Typography>
          </div>
          <form>
            <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto">
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input
                    label="Delivery Date"
                    name="delivery_date"
                    value={formattedDate}
                    readOnly
                  />
                </div>

                <div className={inputClasses}>
                  <Input
                    label="Transper Slip Number (DTN)"
                    name="tsn"
                    value={delData.delivery_tracking_number.delivery_number}
                    readOnly
                  />
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Route"
                    name="route"
                    value={delData.outlet.route.name}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input
                    label="Issuance From"
                    name="issuance_from"
                    value={
                      "Warehouse--Delivery Cage #" +
                      delData.delivery_tracking_number.cage_number
                    }
                    readOnly
                  />
                </div>
                <div className={inputClasses}>
                <Select
                    label="Driver"
                    onChange={handleDriverChange}
                    value={driver ? `${driver.id}` : null} // Use driver.id instead of driver
                  >
                    {user_list_result.map((driver) => (
                      <Option
                        key={driver.id}
                        value={`${driver.id}`} // Use driver.id instead of driver
                      >
                        {driver.user.first_name} {driver.user.last_name}
                      </Option>
                    ))}
                  </Select>


                </div>
                {driver === null && (
                    <span className="text-red-500 text-xs">
                      Select a driver.
                    </span>
                  )}
                <div className={inputClasses}>
                  <Input
                    label="LPG Issuer Name"
                    name="lpg_issue_namer"
                    onChange={handleLPGIssuerNameChange}
                  />
                </div>
                {lpgIssuerName === "" && (
                  <span className="text-red-500 text-xs">
                    Select a LPG Issuer
                  </span>
                )}
                <div className={inputClasses}>
                <Select
                    label="Delivery Cage Number"
                    name="cage"
                    value={cCname}
                    onChange={handleCCNameChange}
                  >
                    <Option value="">Select Cage Checker</Option>
                    <Option value="1">Cage Checker 1</Option>
                    <Option value="2">Cage Checker 2</Option>
                  </Select>
                </div>
                {cCname === "" && (
                  <span className="text-red-500 text-xs">
                    Select a Cage Checker
                  </span>
                )}
              </div>
            </div>
            <div className="m-5">
              <DelIssuedItemTbl getDeliverId={getDeliverId} />
            </div>
            <DRNumTbl getDeliverId={getDeliverId} />
            <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto ml-10">
              <div className="col-span-2 space-y-5">
                <div className={signatureBoxclasses}>
                  <ReactSignatureCanvas
                    penColor="black"
                    canvasProps={{
                      width: 200,
                      height: 100,
                      className: "border-black border-2 rounded-s-xl",
                    }}
                    ref={signatureRefs[0]}
                    onEnd={() => handleSignatureChange(0)}
                  />
                  <div className={boxResetClasses}>
                    <GrPowerReset
                      size={"30px"}
                      onClick={() => handleReset(0)}
                      className="cursor-pointer"
                      color="white"
                    />
                  </div>
                </div>
                <div className={inputClasses}>
                  <Typography className="text-center text-black" variant="h6">
                    {delData.delivery_tracking_number.custodian_name
                      .first_name || "Custodian"}{" "}
                    {delData.delivery_tracking_number.custodian_name
                      .last_name || ""}
                  </Typography>
                  <>&nbsp;</>
                  {isSignatureEmpty[0] && (
                    <Typography variant="lead" color="red">
                      Please provide a signature.
                    </Typography>
                  )}
                </div>

                <div className={signatureBoxclasses}>
                  <ReactSignatureCanvas
                    penColor="black"
                    canvasProps={{
                      width: 200,
                      height: 100,
                      className: "border-black border-2 rounded-s-xl",
                    }}
                    ref={signatureRefs[1]}
                    onEnd={() => handleSignatureChange(1)}
                  />
                  <div className={boxResetClasses}>
                    <GrPowerReset
                      size={"30px"}
                      onClick={() => handleReset(1)}
                      className="cursor-pointer"
                      color="white"
                    />
                  </div>
                </div>
                <div className={inputClasses}>
                <Typography className="text-center text-black" variant="h6">
                  {cCname ? `Cage Checker ${cCname}` : "Cage Checker"}
                </Typography>

                  <>&nbsp;</>
                  {isSignatureEmpty[1] && (
                    <Typography variant="lead" color="red">
                      Please provide a signature.
                    </Typography>
                  )}
                </div>
              </div>
              <div className="col-span-2 space-y-5">
                <div className={signatureBoxclasses}>
                  <ReactSignatureCanvas
                    penColor="black"
                    canvasProps={{
                      width: 200,
                      height: 100,
                      className: "border-black border-2 rounded-s-xl",
                    }}
                    ref={signatureRefs[2]}
                    onEnd={() => handleSignatureChange(2)}
                  />
                  <div className={boxResetClasses}>
                    <GrPowerReset
                      size={"30px"}
                      onClick={() => handleReset(2)}
                      className="cursor-pointer"
                      color="white"
                    />
                  </div>
                </div>
                <div className={inputClasses}>
                  <Typography className="text-center text-black" variant="h6">
                    {lpgIssuerName || "LPG Issuer"}
                  </Typography>
                  <>&nbsp;</>
                  {isSignatureEmpty[2] && (
                    <Typography variant="lead" color="red">
                      please provide a signature.
                    </Typography>
                  )}
                </div>
                <div className={signatureBoxclasses}>
                  <ReactSignatureCanvas
                    penColor="black"
                    canvasProps={{
                      width: 200,
                      height: 100,
                      className: "border-black border-2 rounded-s-xl",
                    }}
                    ref={signatureRefs[3]}
                    onEnd={() => handleSignatureChange(3)}
                  />
                  <div className={boxResetClasses}>
                    <GrPowerReset
                      size={"30px"}
                      onClick={() => handleReset(3)}
                      className="cursor-pointer"
                      color="white"
                    />
                  </div>
                </div>
                <div className={inputClasses}>
                <Typography className="text-justify text-black" variant="h6">
                  {driver?.user?.first_name ?? "Driver"} {driver?.user?.last_name ?? ""}
                </Typography>
                  <>&nbsp;</>
                  {isSignatureEmpty[3] && (
                    <Typography variant="lead" color="red">
                      Please provide a signature.
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </form>
        </DialogBody>
        <DialogFooter>
          <OrderSummaryPdf
            getDeliverId={getDeliverId}
            isPDFReady={isPDFReady}
            handleMainClose={handleMainClose}
            refetchMainTable={refetchMainTable}
            CloseModal={true}
          />
          <SaveDeliveryDetailsConfirmation
            onConfirm={handleDeliverySave}
            isProvided={isAllInputsProvided}
          />
          <Button
            variant="text"
            color="red"
            className="mr-3"
            onClick={handleMainClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
}

export default GenDelReceiptModal;
