import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  // Radio,
  Input,
} from "@material-tailwind/react";
import SignatureCanvas from "react-signature-canvas";
import { GrPowerReset } from "react-icons/gr";
import { IoMdAdd } from "react-icons/io";
import Moment from "react-moment";
import moment from "moment";
import { AddNewVendorConfirmation } from "../../Error_trap_components/AddNewVendorConfirmation";
import { useAddVendorMutation } from "../../../../store/apis/VendorApi";
import PurchaseRequestFormItemList from "./PurchaseRequestFormItemList";
// import { useFetchVendorQuery } from "../../../../store/apis/VendorApi";
import { toast } from "react-toastify";

function VendorListAddNewItem({ vendorFetch }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);
  const currentDate = moment().format("YYYY-MM-DD");
  const [signature, setSignature] = React.useState();
  const [itemId, setItemId] = React.useState();
  const [itemName, setItemName] = React.useState();
  const [input, setInput] = React.useState({
    name: "",
    poc: "",
    term: "",
    material: "",
    contact: "",
    address: "",
    files: "",
    item: "",
  });
  // for API POST
  const [addVendor] = useAddVendorMutation();

  // handle submit of data to api
  async function handleSave() {
    const sign = signature.toDataURL();
    const imageData = sign.split(",")[1];
    console.log(imageData);
    const updatedInput = {
      ...input,
      item: itemId,
      signature: imageData,
    };
    if (signature.isEmpty()) {
      setSignature("");
    } else {
      const sea = await addVendor(updatedInput).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          progress: undefined,
        });
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          progress: undefined,
        });
      }
      setInput({
        name: "",
        poc: "",
        term: "",
        material: "",
        contact: "",
        address: "",
        files: "",
        item: "",
      });
      signature.clear();
      vendorFetch();
      setOpen(!open);
    }
  }
  // clear the signature pad
  function handleReset() {
    signature.clear();
    setSignature("");
  }
  // setting the value of input field
  function handlechange(e) {
    setInput({ ...input, [e.target.name]: e.target.value });
  }
  function handleValue(value1, value2) {
    setItemId(value1);
    setItemName(value2);
  }

  return (
    <>
      <div className="container">
        <Button
          onClick={handleOpen}
          className=" flex gap-3"
          color="teal"
          size="sm"
        >
          <IoMdAdd strokeWidth={2} className="h-4 w-4" />
          Add New Vendor
        </Button>
        <Dialog open={open} handler={handleOpen} className="relative max-h-min">
          <form autoComplete="off">
            <DialogHeader className="w-full bg-[#0B6E4F] rounded-sm flex justify-center">
              <Typography className="text-center text-white" variant="h5">
                Add New Vendor
              </Typography>
            </DialogHeader>
            <DialogBody className="container overflow-y-auto max-h-[60vh]">
              <div className="flex justify-center">
                <div className="w-42 gap-3 mt-1">
                  <Input
                    value={currentDate}
                    readOnly
                    label="Current Date"
                    name="currentDate"
                  />
                </div>
              </div>
              <div className="flex flex-col mt-5">
                <div className="flex flex-col space-y-4 mx-7 my-5">
                  <Input
                    label="Vendor"
                    name="name"
                    value={input.name}
                    onChange={handlechange}
                  />
                  <Input
                    label="Terms"
                    name="term"
                    value={input.term}
                    onChange={handlechange}
                  />
                  <Input
                    label="Marterial"
                    name="material"
                    value={input.material}
                    onChange={handlechange}
                  />
                  <Input
                    label="POC"
                    name="poc"
                    value={input.poc}
                    onChange={handlechange}
                  />
                  <Input
                    label="Contact Number"
                    name="contact"
                    type="number"
                    value={input.contact}
                    onChange={handlechange}
                  />
                  {itemName?.map((val) => (
                    <Input label="Item" type="text" value={val} />
                  ))}
                  <Input
                    label="Address"
                    name="address"
                    value={input.address}
                    onChange={handlechange}
                  />
                  <input
                    type="file"
                    multiple
                    name="files"
                    onChange={handlechange}
                  />
                </div>
                <div className="flex justify-center flex-row items-center mt-5">
                  <SignatureCanvas
                    penColor="black"
                    canvasProps={{
                      width: 300,
                      height: 100,
                      className: "border-black border-2 rounded-l-xl",
                    }}
                    ref={(data) => setSignature(data)}
                    className="rounded-e-lg"
                  />
                  <div className="flex justify-center items-center h-[103px]  w-[60px] bg-[#0B6E4F] shadow-md rounded-r-xl">
                    <GrPowerReset
                      size={"30px"}
                      onClick={handleReset}
                      className="cursor-pointer"
                      color="white"
                    />
                  </div>
                </div>
                <Typography className="text-center text-black" variant="h6">
                  Purchasing Manager
                </Typography>
              </div>
              <Moment format="YYYY-MM-DD" className="ml-2"></Moment>
            </DialogBody>
            <DialogFooter className="flex justify-between">
              <div>
                <PurchaseRequestFormItemList onSelectItem={handleValue} />
              </div>
              <div>
                <Button
                  variant="text"
                  color="red"
                  onClick={handleOpen}
                  className="mr-1"
                >
                  <span>Close</span>
                </Button>
                <AddNewVendorConfirmation handleSave={handleSave} />
              </div>
            </DialogFooter>
          </form>
        </Dialog>
      </div>
    </>
  );
}

export default VendorListAddNewItem;
