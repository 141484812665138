import React, { useRef, useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Card,
  Select,
  Option,
} from "@material-tailwind/react";
import { toast } from "react-toastify";
import { GrPowerReset } from "react-icons/gr";
import ReactSignatureCanvas from "react-signature-canvas";
import { useFetchTransferRequestDetailsQuery } from "../../../../store";
import { useUpdateManualTransferMutation } from "../../../../store";
import { Confirmation } from "../../Error_trap_components/ErrorTrapComponent";
import { useFetchUserManualRequestListQuery } from "../../../../store";
import DelSummaryPdf from "../../../form_components/pdf/GenerateTransferDeliveryPdf";

const TABLE_HEAD = [
  "Name",
  "UM",
  "Requested Quantity",
  "Issued Quantity",
  "Remarks",
];
const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
const trapMessage = "Are you sure to Transfer these products?";
const user_info = JSON.parse(localStorage.getItem("user_info"));

const currentDate = new Date();
const transferDate = currentDate.toLocaleString(undefined, {
  year: "numeric",
  month: "short",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});
let result = [];
let result_item = [];

function RequestModal({ requestId, transData, refetchMainTable, section }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const classes = "p-4 border-b border-blue-gray-50";
  const inputClasses = "flex flex-row items-center";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";

  const { data, isError, isLoading, isSuccess } =
    useFetchTransferRequestDetailsQuery(requestId);

  const [isReadyToSave, setIsReadyToSave] = useState(false);
  const [isSignatureEmpty, setIsSignatureEmpty] = useState([true, true]);
  const [receiver, setReceiver] = useState(null);
  const [signatures, setSignatures] = useState(["", ""]);
  const signatureRefs = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
  ];

  let [actualQtys, setActualQtys] = useState([]);

  const {
    data: userData,
    isError: userErr,
    isLoading: userLoading,
    isSuccess: userSuccess,
  } = useFetchUserManualRequestListQuery({
    page: 1,
    page_size: 50,
    user_type: [5, 6, 7, 8, 9, 10, 11, 12],
  });

  useEffect(() => {
    if (isSuccess) {
      // Initialize actualQtys after data fetching is successful
      setActualQtys(
        data.data.map((result_item) => result_item.required_qty || 0)
      );
    }
  }, [isSuccess, data]);

  const [hasMismatch, setHasMismatch] = useState(false);

  // useEffect(() => {
  //   // Check for any mismatches among all rows
  //   const mismatch = actualQtys.some((qty, index) => qty !== (result_item[index].requested_qty || 0));
  //   // Update the state based on the presence of a mismatch
  //   setHasMismatch(mismatch);
  // }, [actualQtys, result_item]);

  //   useEffect(() => {
  //     // Check if any value in actualQtys is greater than ordered_qty
  //     const adjustedQtys = actualQtys.map((qty, index) => Math.min(qty, result_item[index].requested_qty));
  //     // Check if adjustedQtys is different from actualQtys before updating
  //     if (!adjustedQtys.every((qty, index) => qty === actualQtys[index])) {
  //       setActualQtys(adjustedQtys);
  //     }
  //   }, [actualQtys, result_item]);

  let handleActualQtyChange = (e, index) => {
    let inputValue = parseInt(e.target.value, 10); // Parse input value as integer with base 10
    let maxQty = result_item[index].requested_qty; // Get the maximum allowed quantity for the corresponding index

    // Check if input value is within valid range (0 to maxQty)
    if (!isNaN(inputValue) && inputValue >= 0 && inputValue <= maxQty) {
      setActualQtys((prevQtys) => {
        let newQtys = [...prevQtys];
        newQtys[index] = inputValue; // Update the actualQty for the corresponding index
        return newQtys;
      });
    }
  };

  const handleReset = (index) => {
    if (signatureRefs[index].current) {
      signatureRefs[index].current.clear();
      setSignatures((prevSignatures) => {
        const updatedSignatures = [...prevSignatures];
        updatedSignatures[index] = "";
        return updatedSignatures;
      });

      setIsSignatureEmpty((prevIsSignatureEmpty) => {
        const updatedIsSignatureEmpty = [...prevIsSignatureEmpty];
        updatedIsSignatureEmpty[index] = true;
        return updatedIsSignatureEmpty;
      });
    }
  };

  const handleReceiverChange = (value) => {
    const receiverId = parseInt(value, 10);
    const selectedReceiver = user_list_result.find(
      (receiver) => receiver.id === receiverId
    );
    setReceiver(selectedReceiver);
  };

  const handleSignatureChange = (index) => {
    const isEmpty = signatureRefs[index].current.isEmpty();
    setIsSignatureEmpty((prev) => {
      const updated = [...prev];
      updated[index] = isEmpty;
      return updated;
    });
    const base64Signature = signatureRefs[index].current.toDataURL();
    setSignatures((prevSignatures) => {
      const updatedSignatures = [...prevSignatures];
      updatedSignatures[index] = base64Signature;
      return updatedSignatures;
    });
  };
  const [updateToReceived] = useUpdateManualTransferMutation();
  const handleSaveSignatures = async () => {
    let base64DataIssuer = signatures[0].slice("data:image/png;base64,".length);
    let base64DataReceiver = signatures[1].slice(
      "data:image/png;base64,".length
    );

    let allData = {
      transfer_id: requestId,
      issuer_id: parseInt(user_info.id),
      issuedQtyArray: actualQtys.map((qty, index) => ({
        itemId: data.data[index].id,
        issuedQty: qty,
      })),
      signatureIssuer: base64DataIssuer,
      signatureReceiver: base64DataReceiver,
      receiver_id: receiver.id,
      user: account_detailed.user.id,
      section: section,
    };
    console.log(allData);
    const sea = await updateToReceived(allData).unwrap();
    if (sea.success === true) {
      toast.success(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(sea.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetchMainTable();
    handleMainClose();
  };

  useEffect(() => {
    // Check if all conditions are met
    const isReady =
      signatures[0] !== "" && signatures[1] !== "" && receiver !== null;
    // Update the state based on the condition
    setIsReadyToSave(isReady);
  }, [signatures[0], signatures[1], receiver]);

  const handleMainClose = () => {
    setOpen(false);
    setSignatures(["", ""]);
  };
  let user_list_result = [];
  if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError) {
    return <div>Error loading data</div>;
  } else if (isSuccess && userSuccess) {
    result = data.data;
    user_list_result = userData.results;
    result_item = data;
    console.log(result_item);
    // console.log(account_detailed)
    const formattedDate = transData.requested_at
      ? new Intl.DateTimeFormat("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
          hour: "numeric",
          minute: "numeric",
          hour12: true,
        }).format(new Date(transData.requested_at))
      : "";

    return (
      <>
        <Button onClick={handleOpen} className="bg-[#1AA55D]">
          View
        </Button>

        <Dialog
          size="lg"
          open={open}
          handler={handleOpen}
          className="relative max-h-min"
        >
          <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
            {/* <img
            src="https://oodc.com.ph/wp-content/uploads/2023/05/viber_image_2023-05-02_14-20-42-700.png"
            className="aspect-auto w-48"
            alt="logo"
          /> */}
            <Typography
              className="text-green-900"
              variant="h3"
              component={"span"}
            >
              Manual Transfer Slip
            </Typography>
          </DialogHeader>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
            <form>
              <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto">
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Transfer To"
                      name="issuance_from"
                      value="Warehouse Consumables"
                      // value={section === 1
                      //   ? "Warehouse Foods Duty"
                      //   : section === 2
                      //   ? "Warehouse Beverages"
                      //   : section === 3
                      //   ? "Warehouse Consumables"
                      //   : "Unknown Section"
                      // }
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Requested Date"
                      name="requested_at"
                      value={`${formattedDate}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Transfer Request Date"
                      name="issue_date"
                      value={`${transferDate}`}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Name of Issuer"
                      name="name_issuer"
                      value={`${account_detailed.user.first_name || "First"}  ${
                        account_detailed.user.last_name || "Last"
                      }`}
                      readOnly
                    />
                  </div>
                  {/* <div className={inputClasses}>
                    <Input
                        label="Name of Receiver"
                        name="name_receiver"
                        onChange={(e) => setReceiverName(e.target.value)}
                      />
                    </div> */}
                  <div className={inputClasses}>
                    <Input
                      label="Name of Requester"
                      name="name_requester"
                      value={`${transData.requested_by.first_name || "First"} ${
                        transData.requested_by.last_name || "Last"
                      }`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Tracking Number"
                      name="ttn"
                      value={`${transData.transfer_number}`}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Select
                      label="Receiver"
                      onChange={handleReceiverChange}
                      value={receiver ? `${receiver.id}` : null} // Use driver.id instead of driver
                    >
                      {user_list_result.map((receiver) => (
                        <Option
                          key={receiver.id}
                          value={`${receiver.id}`} // Use driver.id instead of driver
                        >
                          {receiver.user.first_name} {receiver.user.last_name}
                        </Option>
                      ))}
                    </Select>
                  </div>
                  {receiver === null && (
                    <span className="text-red-500 text-xs">
                      Select a receiver.
                    </span>
                  )}
                </div>
              </div>
              <div className="m-5">
                <Card
                  className="h-full w-full overflow-scroll"
                  component={"span"}
                >
                  <table className="w-full min-w-max table-auto text-left ">
                    <thead>
                      <tr>
                        {TABLE_HEAD.map((head) => (
                          <th
                            key={head}
                            className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                          >
                            <Typography
                              component={"span"}
                              variant="small"
                              color="blue-gray"
                              className="font-normal leading-none opacity-70"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {result.map((item, index) => {
                        let difference =
                          item.required_qty - (actualQtys[index] || 0);
                        return (
                          <tr key={index}>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.item.name || "N/A"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.item.unit_measurement || "N/A"}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td
                              className={classes}
                              style={{ textAlign: "center" }}
                            >
                              <div className="flex items-center justify-center">
                                <div className="flex flex-col">
                                  <Typography
                                    variant="small"
                                    color="blue-gray"
                                    className="font-normal"
                                    component={"span"}
                                  >
                                    {item.required_qty}
                                  </Typography>
                                </div>
                              </div>
                            </td>
                            <td className={classes} key={index}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  <input
                                    type="number"
                                    value={actualQtys[index] || ""}
                                    // onChange={(e) => handleActualQtyChange(e, index)}
                                    style={{ width: "80px", padding: "5px" }}
                                    readOnly
                                  />
                                </div>
                              </div>
                            </td>
                            <td className={classes}>
                              <div className="flex items-center gap-3">
                                <div className="flex flex-col">
                                  {item.required_qty &&
                                    actualQtys[index] !== undefined && (
                                      <Typography
                                        variant="small"
                                        color={
                                          difference === 0 ? "green" : "red"
                                        }
                                        className="font-normal"
                                        component={"span"}
                                      >
                                        {difference === 0
                                          ? "Complete"
                                          : `Missing (${difference})`}
                                      </Typography>
                                    )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </Card>
                <div className="m-5"></div>
                <Typography className="text-center">
                  - - - - - Nothing Follows - - - - -{" "}
                </Typography>
              </div>
              {/* <div className="w-[50%] mt-5 space-y-2">
              {" "}
              <Input
                // value={input.deliverydate}
                // onChange={handleChange}
                label="Total Requested Qty"
                name="totalrequestedqty"
              />
            </div> */}

              <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto ml-8">
                <div className="col-span-2 space-y-5">
                  <div className={signatureBoxclasses}>
                    <ReactSignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 100,
                        className: "border-black border-2 rounded-s-xl",
                      }}
                      ref={signatureRefs[0]}
                      onEnd={() => handleSignatureChange(0)}
                    />
                    <div className={boxResetClasses}>
                      <GrPowerReset
                        size={"30px"}
                        onClick={() => handleReset(0)}
                        className="cursor-pointer"
                        color="white"
                      />
                    </div>
                  </div>
                  <Typography
                    className="text-center text-black"
                    variant="h6"
                    component="span"
                  >
                    {account_detailed.user.first_name || "Issuer"}{" "}
                    {account_detailed.user.last_name || ""}
                  </Typography>
                  <>&nbsp;</>
                  {isSignatureEmpty[0] && (
                    <Typography variant="lead" color="red">
                      Please provide a signature.
                    </Typography>
                  )}
                </div>
                <div className="col-span-2 space-y-5">
                  <div className={signatureBoxclasses}>
                    <ReactSignatureCanvas
                      penColor="black"
                      canvasProps={{
                        width: 200,
                        height: 100,
                        className: "border-black border-2 rounded-s-xl",
                      }}
                      ref={signatureRefs[1]}
                      onEnd={() => handleSignatureChange(1)}
                    />
                    <div className={boxResetClasses}>
                      <GrPowerReset
                        size={"30px"}
                        onClick={() => handleReset(1)}
                        className="cursor-pointer"
                        color="white"
                      />
                    </div>
                  </div>
                  <Typography
                    className="text-center text-black m"
                    variant="h6"
                    component={"span"}
                  >
                    <Typography className="text-center text-black" variant="h6">
                      {receiver?.user?.first_name ?? "Receiver"}{" "}
                      {receiver?.user?.last_name ?? ""}
                    </Typography>
                  </Typography>
                  <>&nbsp;</>
                  {isSignatureEmpty[1] && (
                    <Typography variant="lead" color="red">
                      Please provide a signature.
                    </Typography>
                  )}
                </div>
              </div>
            </form>
          </DialogBody>
          <DialogFooter>
            <Confirmation
              onConfirm={handleSaveSignatures}
              trapMessage={trapMessage}
              isReadyToSave={isReadyToSave}
            />
            <Button
              variant="text"
              color="red"
              className="mr-3"
              onClick={handleMainClose}
            >
              <span>Cancel</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}

export default RequestModal;
