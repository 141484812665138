import { Link } from "react-router-dom";
import React from "react";
import {
  Typography,
  List,
  ListItem,
  ListItemPrefix,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import {
  HiPresentationChartBar,
  HiOutlineDocumentReport,
} from "react-icons/hi";
import { FiChevronDown } from "react-icons/fi";
import { MdOutlineWarehouse } from "react-icons/md";
import { LuPackagePlus, LuClipboardList, LuFactory } from "react-icons/lu";
import {
  FaArrowRightArrowLeft,
  FaStore,
  FaRegRectangleList,
  FaUserShield,
} from "react-icons/fa6";
import { TbTruckDelivery, TbTransferIn } from "react-icons/tb";
import { RiInboxArchiveFill } from "react-icons/ri";
import { FaCheckToSlot } from "react-icons/fa6";
import { VscNotebook } from "react-icons/vsc";
import { BsPatchQuestion } from "react-icons/bs";
import { useFetchClientLogoQuery } from "../../store";
function Sidebar() {
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [open, setOpen] = React.useState(0);
  const [selected, setSelected] = React.useState(1);

  const setSelectedItem = (value) => setSelected(value);
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };

  const logout = () => {
    localStorage.clear();
  };
  // fetch img/logo from local storage
  // const logo = JSON.parse(localStorage.getItem("account_detail"));
  const datas = useFetchClientLogoQuery();
  const logos = datas.data?.data[0].logo;
  const burgerMachineLogo = "../images/BurgerMachineLogo.jpg";
  // const burgerMachineLogo = process.env.REACT_APP_API_DOMAIN + "" + logos;
  let approval_tab;
  let accordion_1;
  let production_tab;
  let user_management_tab;
  let archive_tab;
  let logs_tab;
  let delivery_tab;
  let outlet_tab;
  let audit_tab;
  let kb_tab;
  if (account_detailed) {
    // Tabs for warehouse
    if (
      account_detailed.job_position === 1 ||
      account_detailed.job_position === 5 ||
      account_detailed.user_type === 1 ||
      account_detailed.job_position === 13 ||
      account_detailed.job_position === 6 ||
      account_detailed.job_position === 7 ||
      account_detailed.job_position === 8 ||
      account_detailed.job_position === 11 ||
      account_detailed.job_position === 12 ||
      account_detailed.job_position === 17 ||
      account_detailed.job_position === 19
    ) {
      accordion_1 = (
        <>
          <Accordion
            open={open === 1}
            icon={
              <FiChevronDown
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform ${
                  open === 1 ? "rotate-180" : ""
                }`}
              />
            }
          >
            <ListItem className="p-0 text-white">
              <AccordionHeader
                onClick={() => handleOpen(1)}
                className="border-b-0 p-3"
              >
                <ListItemPrefix>
                  <MdOutlineWarehouse className="h-5 w-5  text-white " />
                </ListItemPrefix>
                <Typography color="white" className="mr-auto font-normal">
                  Warehouse
                </Typography>
              </AccordionHeader>
            </ListItem>
            <AccordionBody
              className="py-1 flex justify-center"
              style={{ background: "#1AA55D", borderRadius: "8px" }}
            >
              <List className="p-0 text-white">
                {account_detailed &&
                  [1, 5, 6, 7, 8, 13, 14, 17].includes(
                    account_detailed.job_position
                  ) && (
                    <Link to="/base/warehouse_purchaseorder">
                      <ListItem className="flex">
                        <ListItemPrefix>
                          <LuPackagePlus className="h-5 w-5  text-white" />
                        </ListItemPrefix>
                        Purchase Order
                      </ListItem>
                    </Link>
                  )}
                {account_detailed &&
                  [1, 5, 13, 14].includes(account_detailed.job_position) && (
                    <Link to="/base/warehouse_raw_materials_delivery">
                      <ListItem>
                        <ListItemPrefix>
                          <HiOutlineDocumentReport className="h-5 w-5  text-white" />
                        </ListItemPrefix>
                        Receiving Report
                      </ListItem>
                    </Link>
                  )}
                {account_detailed &&
                  [1, 5, 6, 7, 8, 11, 12, 19].includes(
                    account_detailed.job_position
                  ) && (
                    <Link to="/base/warehouse_transfer">
                      <ListItem>
                        <ListItemPrefix>
                          <TbTransferIn className="h-5 w-5 text-white" />
                        </ListItemPrefix>
                        Transfer
                      </ListItem>
                    </Link>
                  )}
                {account_detailed &&
                  [1, 5, 6, 7, 8, 19].includes(
                    account_detailed.job_position
                  ) && (
                    <Link to="/base/warehouse_summary">
                      <ListItem>
                        <ListItemPrefix>
                          <LuClipboardList className="h-5 w-5  text-white" />
                        </ListItemPrefix>
                        Inventory
                      </ListItem>
                    </Link>
                  )}
              </List>
            </AccordionBody>
          </Accordion>
        </>
      );
    }
    if ([1, 10, 9, 11, 12, 19].includes(account_detailed.job_position)) {
      // Tab for Production
      production_tab = (
        <>
          <Accordion
            open={open === 2}
            icon={
              <FiChevronDown
                strokeWidth={2.5}
                className={`mx-auto h-4 w-4 transition-transform ${
                  open === 2 ? "rotate-180" : ""
                }`}
              />
            }
          >
            <List className="p-0 text-white">
              <Link to="/base/production">
                <ListItem>
                  <ListItemPrefix>
                    <LuFactory className="h-5 w-5  text-white" />
                  </ListItemPrefix>
                  Production
                </ListItem>
              </Link>
            </List>
          </Accordion>
        </>
      );
    }
    if (
      account_detailed.job_position === 1 ||
      account_detailed.user_type === 1 ||
      account_detailed.job_position === 13 ||
      account_detailed.job_position === 14
    ) {
      // Tab for approval
      approval_tab = (
        <>
          <List className="p-0 text-white">
            <Link to="/base/approval">
              <ListItem>
                <ListItemPrefix>
                  <FaCheckToSlot className="h-5 w-5  text-white" />
                </ListItemPrefix>
                Approval
              </ListItem>
            </Link>
          </List>
        </>
      );
    }

    if (
      account_detailed.job_position === 1 ||
      account_detailed.user_type === 1 ||
      account_detailed.job_position === 13
    ) {
      delivery_tab = (
        <>
          <List className="p-0 text-white">
            <Link to="/base/delivery">
              <ListItem>
                <ListItemPrefix>
                  <FaArrowRightArrowLeft className="h-5 w-5" />
                </ListItemPrefix>
                Delivery
              </ListItem>
            </Link>
          </List>
        </>
      );
    }
    if (
      account_detailed.job_position === 1 ||
      account_detailed.user_type === 1 ||
      account_detailed.job_position === 13 ||
      account_detailed.job_position === 20 ||
      account_detailed.job_position === 21 ||
      account_detailed.job_position === 24 ||
      account_detailed.job_position === 22
    ) {
      outlet_tab = (
        <>
          <List className="p-0 text-white">
            <Link to="/base/outlet">
              <ListItem>
                <ListItemPrefix>
                  <FaStore className="h-5 w-5" />
                </ListItemPrefix>
                Outlet
              </ListItem>
            </Link>
          </List>
        </>
      );
    }
    if (
      account_detailed.job_position === 1 ||
      account_detailed.user_type === 1 ||
      account_detailed.job_position === 13
    ) {
      user_management_tab = (
        <>
          <List className="p-0 text-white">
            <Link to="/base/user">
              <ListItem>
                <ListItemPrefix>
                  <FaUserShield className="h-5 w-5  text-white" />
                </ListItemPrefix>
                Users
              </ListItem>
            </Link>
          </List>
        </>
      );
    }
    if (
      account_detailed.job_position === 1 ||
      account_detailed.user_type === 1 ||
      account_detailed.job_position === 13
    ) {
      archive_tab = (
        <>
          <List className="p-0 text-white">
            <Link to="/base/archive">
              <ListItem>
                <ListItemPrefix>
                  <RiInboxArchiveFill className="h-5 w-5  text-white" />
                </ListItemPrefix>
                Archive
              </ListItem>
            </Link>
          </List>
        </>
      );
    }
    if (
      account_detailed.job_position === 1 ||
      account_detailed.user_type === 1 ||
      account_detailed.job_position === 13
    ) {
      audit_tab = (
        <>
          <List className="p-0 text-white">
            <Link to="/base/Audit">
              <ListItem>
                <ListItemPrefix>
                  <FaRegRectangleList className="h-5 w-5  text-white" />
                </ListItemPrefix>
                Audit
              </ListItem>
            </Link>
          </List>
        </>
      );
    }
    if (
      account_detailed.job_position === 1 ||
      account_detailed.user_type === 1 ||
      account_detailed.job_position === 13
    ) {
      logs_tab = (
        <>
          <List className="p-0 text-white">
            <Link to="/base/Logs">
              <ListItem>
                <ListItemPrefix>
                  <VscNotebook className="h-5 w-5  text-white" />
                </ListItemPrefix>
                Logs
              </ListItem>
            </Link>
          </List>
        </>
      );
    }
    if (
      account_detailed.job_position === 1 ||
      account_detailed.job_position === 5 ||
      account_detailed.user_type === 1 ||
      account_detailed.job_position === 13 ||
      account_detailed.job_position === 6 ||
      account_detailed.job_position === 7 ||
      account_detailed.job_position === 8 ||
      account_detailed.job_position === 11 ||
      account_detailed.job_position === 12 ||
      account_detailed.job_position === 17 ||
      account_detailed.job_position === 19
    ) {
      kb_tab = (
        <>
          <List className="p-0 text-white">
            <Link to="/base/kb-faqs">
              <ListItem>
                <ListItemPrefix>
                  <BsPatchQuestion className="h-5 w-5  text-white" />
                </ListItemPrefix>
                KB
              </ListItem>
            </Link>
          </List>
        </>
      );
    }

    // outlet view and driver view on side bar
    if (
      account_detailed.job_position === 15 ||
      account_detailed.job_position === 16 ||
      account_detailed.job_position === 2 ||
      account_detailed.job_position === 4
    ) {
      delivery_tab = (
        <>
          <List className="p-0 text-white">
            <Link to="/base/delivery">
              <ListItem>
                <ListItemPrefix>
                  <FaStore className="h-5 w-5" />
                </ListItemPrefix>
                {account_detailed.job_position <= 4 ? "Delivery" : "Outlet"}
              </ListItem>
            </Link>
          </List>
        </>
      );
    }
  }

  return (
    <div className="w-full max-w-[20rem] bg-transparent">
      <Link to="home">
        <img
          src={burgerMachineLogo}
          className="border-bottom-white border-b-8 aspect-auto w-full"
          alt="BurgerMachineLogo"
        />
      </Link>
      <hr></hr>
      <List className="text-white">
        {account_detailed?.job_position === 1 &&
          account_detailed?.job_position !== 13 &&
          account_detailed.job_position !== 14 &&
          account_detailed.job_position !== 9 && (
            <Link to="/base/dashboard">
              <ListItem className="text-white">
                <ListItemPrefix>
                  <HiPresentationChartBar className="h-5 w-5 text-white" />
                </ListItemPrefix>
                Dashboard
              </ListItem>
            </Link>
          )}
        {accordion_1}
        {production_tab}
        {delivery_tab}
        {outlet_tab}
        {approval_tab}
        {archive_tab}
        <hr className="my-2 border-white-50" />
        {user_management_tab}
        {audit_tab}
        {logs_tab}
        {kb_tab}
      </List>
    </div>
  );
}
export default Sidebar;
