import React, { useState } from "react";
import {
  Typography,
  Card,
  CardHeader,
  CardBody,
  Button,
  Input,
  CardFooter,
} from "@material-tailwind/react";
import { useDashProdReportQuery } from "../../../store";

const TABLE_HEAD = [
  {
    head: "Item",
    customeStyle: "!text-left",
  },
  {
    head: "Total Batch",
    customeStyle: "text-left",
  },
  {
    head: "Theoretical Yield",
    customeStyle: "text-left",
  },
  {
    head: "Actual Yield",
    customeStyle: "text-left",
  },
  {
    head: "Count Goods",
    customeStyle: "text-left",
  },
  {
    head: "Count Reject",
    customeStyle: "text-left",
  },
];

function ProdTable() {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchStr, setSearchStr] = useState("");

  const handleSearch = (e) => {
    setSearchStr(e.target.value);
  };

  const dash_prod_report = useDashProdReportQuery({
    page: currentPage,
    page_size: pageSize,
    search_str: searchStr,
  });


  const totalPage = Math.ceil(
    dash_prod_report.data?.count / pageSize)
  ;

  let content;
  if (dash_prod_report.isLoading) {
    return <div>Loading....</div>;
  } else if (dash_prod_report.error) {
    return <div>Refresh the page....</div>;
  } else {
    let data = dash_prod_report.data?.results
    console.log(data)
	  content = <>
        {data.map(
      (
        item,
        index
      ) => {
        const isLast = index === data.length - 1;
        const classes = isLast
          ? "!p-4"
          : "!p-4 border-b border-gray-300";
        return (

          <tr key={index}>
            <td className={classes}>
              <div className="flex items-center gap-4 text-left">
                <div>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="!font-semibold"
                  >
                    {item.item__name}
                  </Typography>
                </div>
              </div>
            </td>
            <td className={classes}>
              <Typography
                variant="small"
                className="!font-normal text-gray-600 text-left"
              >
                {item.total_batch}
              </Typography>
            </td>
            <td className={classes}>
              <Typography
                variant="small"
                color="green"
                className="!font-bold text-left"
              >
                N/A
              </Typography>
            </td>
            <td className={classes}>
              <Typography
                variant="small"
                className="!font-normal text-gray-600 text-left"
              >
                {item.total_actual_yield}
              </Typography>
            </td>
            <td className={classes}>
              <Typography
                  variant="small"
                  className="!font-normal text-gray-600 text-left"
              >
                  {item.total_good_qty}
              </Typography>
            </td>
            <td className={classes}>
              <Typography
                  variant="small"
                  className="!font-normal text-gray-600 text-left"
              >
                  {item.total_reject_qty == 'null' ? 0 : item.total_reject_qty}
              </Typography>
            </td>
          </tr>
        );
      }
    )}
    </>
  }
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  return (
    <section className="m-10">
      <Card className="h-full w-full">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none flex flex-wrap gap-4 justify-between mb-4"
        >
          <div>
            <Typography variant="h6" color="blue-gray">
              Yield Compliance
            </Typography>
          </div>
          <div className="flex items-center w-full shrink-0 gap-4 md:w-max">
            <div className="w-full md:w-72">
              <Input
                size="lg"
                label="Search"
                onChange={handleSearch}
              />
            </div>
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll !px-0 py-2">
          <table className="w-full min-w-max table-auto">
            <thead>
              <tr>
                {TABLE_HEAD.map(({ head, customeStyle }) => (
                  <th
                    key={head}
                    className={`border-b border-gray-300 !p-4 pb-8 ${customeStyle}`}
                  >
                    <Typography
                      color="blue-gray"
                      variant="small"
                      className="!font-bold"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
             {content}
            </tbody>
          </table>
        </CardBody>
        <CardFooter  className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography
            variant="small"
            color="blue-gray"
            className="font-normal"
          >
            Page{" "}
            {`${currentPage} of ${
              Math.ceil(totalPage ) || 1
            }`}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              size="sm"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleNextPage}
              disabled={currentPage === totalPage}
            >
              Next
            </Button>
          </div>
        </CardFooter>
      </Card>
    </section>
  );
}

export default ProdTable;
