import { useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";

function KBAccordionItem({
  faqs: { question, answer },
  onOpen,
  currOpen,
  num,
}) {
  const isOpen = num === currOpen;
  const handleToggle = () => {
    onOpen(isOpen ? null : num);
  };
  return (
    <div
      className={`${
        isOpen ? "border-t-4 border-[#117B34FF]" : "border-t-2"
      } px-2 py-6 hover:cursor-pointer`}
      onClick={handleToggle}
    >
      <div className="flex justify-between px-3 items-center mb-2">
        <span
          className={`${
            !isOpen
              ? "text-[20px] text-[#72767e] leading-8"
              : "text-[#117B34FF] font-semibold text-2xl"
          }`}
        >
          {question}
        </span>
        <div className="flex items-center justify-center rounded-full h-[30px] w-[30px] text-[18px] border-2 border-[#72767e] text-[#72767e]">
          {isOpen ? <IoIosArrowDown /> : <IoIosArrowForward />}
        </div>
      </div>
      <div className="px-3">
        {isOpen && <p className="pr-11 text-[#117B34FF]">{answer}</p>}
      </div>
    </div>
  );
}

export default KBAccordionItem;
