import React, { useState, useEffect } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useFetchDeliveryDetailsQuery } from "../../../store";
import { Button } from "@material-tailwind/react";

const DelSummaryPdf = ({ getDelId }) => {
  let result = [];
  const { data, isError, isLoading, isSuccess, refetch } =
    useFetchDeliveryDetailsQuery(getDelId);
  const [hasTriggeredRefetch, setHasTriggeredRefetch] = useState(false);
  const [isPDFLoading, setIsPDFLoading] = useState(false);

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError) {
    return <div>Error loading data</div>;
  } else if (isSuccess) {
    result = data.data;
    // console.log(account_detailed)
  }
  const generatePDF = () => {
    setIsPDFLoading(true);
    const currentDate = new Date();
    const deliveryDate = result.delivery.delivery_date.split("T")[0];
    const dateParts = deliveryDate.split("-");
    const formattedDeliveryDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      timeZone: "Asia/Hong_Kong",
    }).format(new Date(`${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`));

    const print_Date = currentDate.toLocaleDateString("en-US", {
      timeZone: "Asia/Tokyo",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

    const categoryMap = {
      1: "Product",
      2: "Beverages",
      3: "Buns",
      4: "Wrappers",
      5: "Sundries",
      6: "Cigarettes",
      7: "Office Supp.",
      8: "Tank",
    };

    let signatureCageCustodian = result.delivery.cage_custodian_signature;
    let signatureWarehouse = result.delivery.warehouse_custodian_signature;
    let signatureDriver = result.delivery.driver_signature;
    let signatureLpg = result.delivery.lpg_issuer_signature;
    let signaturePlantManager =
      result.delivery.transfer.signature_plant_manager;
    // let signatureReceiver = result.receiver_signature;
    // let signatureDriver = result.driver_signature;

    const pdfWidth = 215.9; // Width in millimeters (A4 size)
    const pdfHeight = 279.4; // Height in millimeters (custom length)

    // Create a new jsPDF instance with custom page size
    const pdfDoc = new jsPDF({
      orientation: "portrait", // or 'landscape'
      unit: "mm", // units of measurement
      format: [pdfWidth, pdfHeight], // specify the page size
    });

    // Add logo and title
    // pdfDoc.addImage("/images/burger_machine_logo.png", "PNG", 20, 10, 50, 0);
    pdfDoc.setFontSize(17);
    pdfDoc.setTextColor(0, 128, 0);
    pdfDoc.setFont("helvetica", "bold");
    pdfDoc.text("PREMIUM BURGER & FOOD PRODUCT INC.", 30, 25);
    pdfDoc.setFontSize(10);
    pdfDoc.setTextColor(0);
    pdfDoc.setFont("helvetica", "normal");
    pdfDoc.text(
      `1185 TOPAZ ST. RAMAR VILLAGE SAN AGUSTIN CITY OF SAN FERNANDO PAMPANGA`,
      29,
      30
    );
    pdfDoc.setFont("helvetica", "bold");
    pdfDoc.text(`DELIVERY SUMMARY RECEIPT`, 80, 38);
    pdfDoc.setFont("helvetica", "normal");
    // Add delivery information
    pdfDoc.text(`Delivery Date: ${formattedDeliveryDate}`, 20, 45);
    pdfDoc.text(`Delivery Number: ${result.delivery.delivery_number}`, 20, 50);
    pdfDoc.text(
      `Transfer Number: ${result.delivery.transfer.transfer_number}`,
      20,
      55
    );
    pdfDoc.text(`Cage Number: ${result.delivery.cage_number}`, 20, 60);
    pdfDoc.text(`Route: ${result.delivery.route}`, 125, 45);
    // pdfDoc.text(`Outlet: ${result.outlet.outlet}`, 120, 70);

    const tableStartY = 67;

    pdfDoc.autoTable({
      head: [
        [
          // { content: "Category", styles: { halign: 'center', valign: 'middle', fontSize: 8 } },
          {
            content: "Code",
            styles: { halign: "center", valign: "middle", fontSize: 8 },
          },
          {
            content: "Name",
            styles: { halign: "center", valign: "middle", fontSize: 8 },
          },
          // { content: "Total Ordered Qty", styles: { halign: 'center', valign: 'middle', fontSize: 8 } },
          {
            content: "Total Issued Quantity",
            styles: { halign: "center", valign: "middle", fontSize: 8 },
          },
          {
            content: "Remarks",
            styles: {
              halign: "center",
              valign: "middle",
              fontSize: 8,
              cellWidth: 40,
            },
          },
        ],
      ],
      body: result.delivery_items.map((item) => {
        const transferItem = result.transfer_items.find(
          (transferItem) => transferItem.product_id === item.product_id
        );
        const issuedQty = transferItem ? transferItem.issued_qty : 0;

        return [
          // { content: categoryMap[item.product__category], styles: { halign: 'center', valign: 'middle', fontSize: 6 } },
          {
            content: item.product__product_code,
            styles: { halign: "center", valign: "middle", fontSize: 6 },
          },
          {
            content: item.product__nickname,
            styles: { halign: "center", valign: "middle", fontSize: 6 },
          },
          {
            content: `${item.ordered_qty}`,
            styles: { halign: "center", valign: "middle", fontSize: 6 },
          },
          // { content: `${issuedQty} ${item.product__unit_measurement}`, styles: { halign: 'center', valign: 'middle', fontSize: 6 } },
        ];
      }),

      startY: tableStartY,
      theme: "grid",
      styles: {
        fillColor: [255, 255, 255],
        textColor: [0, 0, 0],
      },
    });

    const tableHeight = pdfDoc.previousAutoTable.finalY - tableStartY;
    pdfDoc.text(`Remarks:`, 20, tableStartY + tableHeight + 10);

    pdfDoc.text(
      `Paalala: Bawal magsulat ng "Cancelled Items" o magbago ng anumang impormasyong `,
      20,
      tableStartY + tableHeight + 20
    );
    pdfDoc.text(
      `nakatala sa papel na ito. Anumang pagbabago ay hindi kikilalanin o tatanggapin.`,
      25,
      tableStartY + tableHeight + 25
    );

    const startX = 10;
    const startY = tableStartY + tableHeight + 40;
    const lineHeight = 20;
    const columnWidth = 60;
    const horizontalSpacing = 65;

    const driverReceiverX = startX;
    const driverReceiverY = startY;
    const driverReceiverTextWidth = pdfDoc.getTextWidth("Driver");
    const centeredDriverReceiverX =
      driverReceiverX + (columnWidth - driverReceiverTextWidth) / 2;
    pdfDoc.text("Driver", centeredDriverReceiverX, driverReceiverY + 5);
    pdfDoc.text(`__________________`, driverReceiverX + 10, driverReceiverY);

    const receiverTextWidth = pdfDoc.getTextWidth("LPG Issuer");
    const receiverX = driverReceiverX + columnWidth + horizontalSpacing;
    const centeredReceiverX = receiverX + (columnWidth - receiverTextWidth) / 2;
    pdfDoc.text("LPG Issuer", centeredReceiverX, driverReceiverY + 5);
    pdfDoc.text(`__________________`, receiverX + 10, driverReceiverY);

    const custodianY = startY + lineHeight;
    const custodianTextWidth = pdfDoc.getTextWidth("Cage Custodian");
    const centeredCustodianX = 7 + (columnWidth - custodianTextWidth) / 2;
    pdfDoc.text("Cage Custodian", centeredCustodianX, custodianY + 5);
    pdfDoc.text(`__________________`, 7 + 10, custodianY);

    const imageWidth = 30;
    const imageHeight = 15;

    const plantManagerTextWidth = pdfDoc.getTextWidth("Plant Manager");
    const plantManagerNameTextWidth = pdfDoc.getTextWidth("Herman F. Gatela");
    const plantManagerX = startX + columnWidth + 5;
    const centeredPlantManagerX =
      plantManagerX + (columnWidth - plantManagerTextWidth) / 2;
    const centeredPMNameX =
      plantManagerX + (columnWidth - plantManagerNameTextWidth) / 2;
    pdfDoc.text("Plant Manager", centeredPlantManagerX, custodianY + 5);
    pdfDoc.text(`Herman F. Gatela`, centeredPMNameX, custodianY);
    pdfDoc.text(`__________________`, plantManagerX + 10, custodianY);

    const warehouseTextWidth = pdfDoc.getTextWidth("Warehouse Custodian");
    const warehouseX = plantManagerX + columnWidth + 5;
    const centeredWarehouseX =
      warehouseX + (columnWidth - warehouseTextWidth) / 2;
    pdfDoc.text("Warehouse Custodian", centeredWarehouseX, custodianY + 5);
    pdfDoc.text(`__________________`, warehouseX + 10, custodianY);

    //signatures
    pdfDoc.addImage(
      signatureCageCustodian,
      "PNG",
      centeredCustodianX,
      custodianY - 10,
      imageWidth,
      imageHeight
    );
    pdfDoc.addImage(
      signatureDriver,
      "PNG",
      centeredDriverReceiverX,
      driverReceiverY - 10,
      imageWidth,
      imageHeight
    );
    pdfDoc.addImage(
      signatureLpg,
      "PNG",
      centeredReceiverX,
      driverReceiverY - 10,
      imageWidth,
      imageHeight
    );
    pdfDoc.addImage(
      signaturePlantManager,
      "PNG",
      centeredPlantManagerX,
      custodianY - 10,
      imageWidth,
      imageHeight
    );
    pdfDoc.addImage(
      signatureWarehouse,
      "PNG",
      centeredWarehouseX,
      custodianY - 10,
      imageWidth,
      imageHeight
    );

    pdfDoc.save(
      `BM_${result.delivery.delivery_number}_Delivery_Summary_Receipt__${print_Date}.pdf`
    );
    setIsPDFLoading(false);
  };

  return (
    <div>
      <Button
        className="bg-orange-900 mr-5 mt-1"
        onClick={generatePDF}
        disabled={isPDFLoading}
      >
        {isPDFLoading ? " Loading..." : "Cage Summary"}
      </Button>
    </div>
  );
};

export default DelSummaryPdf;
