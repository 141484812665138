import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
  Typography,
} from "@material-tailwind/react";
import React, { useState } from "react";

function ApprovalTabComponents({ data }) {
  const [selectedTab, setSelectedTab] = useState(data[0].value);
  const handleChange = (newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Tabs id="custom-animation" value={selectedTab} onChange={handleChange}>
        <TabsHeader className="w-full mx-auto lg:w-[50%] lg:ml-4">
          {data.map(({ label, value }) => (
            <Tab key={value} value={value}>
              <Typography className=" text-sm md:text-base">{label}</Typography>
            </Tab>
          ))}
        </TabsHeader>
        <TabsBody
          animate={{
            initial: { y: 250 },
            mount: { y: 0 },
            unmount: { y: 250 },
          }}
        >
          {data.map(({ value, desc }) => (
            <TabPanel key={value} value={value}>
              {desc}
            </TabPanel>
          ))}
        </TabsBody>
      </Tabs>
    </>
  );
}
export default ApprovalTabComponents;
