import { createRef, useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Radio,
  Input,
} from "@material-tailwind/react";
import SignatureCanvas from "react-signature-canvas";
import { GrPowerReset } from "react-icons/gr";
import Moment from "react-moment";
import moment from "moment";
import { IoMdAdd } from "react-icons/io";
import { useAddVendorItemMutation } from "../../../../store/apis/VendorItemApi";
import VendorItemListOption from "./VendorItemListOption";
import VendorListNewItem from "./VendorListNewItem";
import { ToastContainer, toast } from "react-toastify";


function VendorListAddNewItem() {
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const currentDate = moment().format("YYYY-MM-DD");
  const [signature, setSignature] = useState([]);
  const [selectItem, setSelectItem] = useState("");
  const [selectItemId, setSelectItemId] = useState("");
  const [selectVendorId, setSelectVendorId] = useState("");
  const [selectVendorItem, setSelectVendorItem] = useState("");
  const [countSigFlag, setCountSigFlag] = useState(false);
  const [input, setInput] = useState({
    items: "",
    status: "",
    exist_item_id: "",
    convertion: "",
    weight: "",
    measurement:"",
    location:"",
  });
  // check if state is array or empty array
  const selectedVendorItem = Array.isArray(selectVendorItem)
    ? selectVendorItem
    : [];
  // for API POST
  const [addVendorItem, {isLoading}] = useAddVendorItemMutation();
  const signatureCanvas = [
    {
      id: "1",
      label: "canvas1",
      ref: createRef(),
      assignatories: "Purchasing Manager",
    },
  ];
  const measurement_list = [
    "kilos",
    "liters",
    "piece",
    "gallons",
    "tank",
    "pack",
    "rims",
    "bottles",
    "roll",
    "kilos"
  ]

  const location_list = [
    {
      "name": "Raw Materials",
      "value": 1
    },
    {
      "name": "Beverages",
      "value": 3
    },
    {
      "name": "Consumables",
      "value": 4
    },
    {
      "name": "Hardware",
      "value": 5
    },
    {
      "name": "Repacking",
      "value": 6
    },
    {
      "name": "Plastic",
      "value": 7
    },
    {
      "name": "Office Supplies",
      "value": 8
    }
  ]

  const data_respose = (res) => {
    if (res.data.success === true) {
      toast.success(res.data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(res.data.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  }
  async function handleSave(e) {
    e.preventDefault();
    console.log(selectItemId)
    const dataURLs = signatureCanvas
    .map((sig) => {
      return !sig.ref.current.isEmpty()
        ? sig.ref.current.getTrimmedCanvas().toDataURL()
        : null;
    })
    .filter((url) => url !== null);
    let data_url_split = dataURLs[0].split(',')
    const existingItemInput = {
      ...input,
      name: "",
      signature: data_url_split[1],
      vendor: selectVendorId,
      exist_item_id: selectItemId,
      client_id: account_detailed.client.id,
      accredited: true
    };
    const newItemInput = {
      ...input,
      signature: data_url_split[1],
      vendor: selectVendorId,
      client_id: account_detailed.client.id,
      accredited: false
    };
    // function to be executed based on the condition
    if (input.status === "accredited") {
      data_respose(
        await addVendorItem(
          existingItemInput
        )
      )
    } else {
      data_respose(
        await addVendorItem(
          newItemInput
        )
      )
    }

    handleOpen();
    // clear the state after the data was submited
    setInput({ name: "", status: "", files: "" });
    setSelectItem("");
    setSelectVendorId("");
    setSelectVendorItem("");
  }
  // clear the signature pad
  function handleReset() {
    setSignature("");
  }
  // setting the value of input field
  function handleChange(e) {
    setInput({ ...input, [e.target.name]: e.target.value });
  }
  // handle the state of selected item from child component through checkbox
  function handleChosenItem(value1, value2) {
    setSelectItemId(value1);
    setSelectItem(value2);
  }
  // handle the state of selected vendor from child component through checkbox
  function handleArrayVendor(value1, value2) {
    setSelectVendorId(value1);
    setSelectVendorItem(value2);
  }

  // function for disable submit
  const disable_submit_button = () => {
    if (countSigFlag == true){
      return false
    }
    if (isLoading){
      return true
    }
    return true
  }
  // Function to count signature
  const count_signature = () => {
    let count = 0;
    signatureCanvas
      .map((sig) => {
        return !sig.ref.current.isEmpty() ? (count += 1) : null;
      })
      .filter((url) => url !== null);

    if (count === 1) {
      setCountSigFlag(true);
    }
  };
  const clearSignature = (canvasRef) => {
    canvasRef.current.isEmpty() ? console.log("") : canvasRef.current.clear();
  };
  const boxResetClasses =
  "flex justify-center items-center h-[105px]  w-[60px] bg-[#0B6E4F] shadow-md rounded-r-xl";
  return (
    <>
      <div className="container">
        <Button
          onClick={handleOpen}
          className=" flex gap-3 bg-[#1AA55D]"
          size="sm"
        >
          <IoMdAdd strokeWidth={2} className="h-4 w-4" />
          Add New Item
        </Button>
        <Dialog open={open} handler={handleOpen} className="relative max-h-min">
          <form onSubmit={handleSave} autoComplete="off">
            <DialogHeader className="w-full bg-[#0B6E4F] rounded-sm flex justify-center">
              <Typography className="text-center text-white" variant="h5">
                Add New Item
              </Typography>
            </DialogHeader>
            <DialogBody className="container overflow-y-auto max-h-[60vh]">
              <ToastContainer />
              <div className="flex justify-end">
                <div className="w-42 gap-3 mt-1">
                  <Input
                    value={currentDate}
                    readOnly
                    label="Current Date"
                    name="currentDate"
                  />
                </div>
              </div>
              <div className="flex flex-col gap-4 justify-center px-3 mt-4">
                <div className="flex flex-col space-y-4 mx-2 my-5">
                  <Typography className="text-sm lg:text-xs font-gray-300 mb-[-15px]">
                    Select one category is required{" "}
                    <span className="text-red-900">*</span>
                  </Typography>
                  <div className="flex items-center gap-10">
                    <Typography>Category:</Typography>
                    <Radio
                      name="status"
                      label="Existing"
                      value="accredited"
                      onChange={handleChange}
                    />
                    <Radio
                      name="status"
                      label="New"
                      value="new"
                      onChange={handleChange}
                    />
                  </div>
                  {input.status === "accredited" && (
                    <Input
                      label="Item"
                      name="name"
                      readOnly
                      value={selectItem}
                      onChange={handleChange}
                    />
                  )}
                  {input.status === "new" && (
                    <div className=" space-y-4">
                      <Input
                        label="Item"
                        name="name"
                        defaultValue={input.name}
                        onChange={handleChange}
                      />
                      <select
                        label="Measurement"
                        name="measurement"
                        onChange={handleChange}
                        className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                      >
                        <option className="text-center" selected>--- Select Measurement ---</option>
                        {measurement_list.map((ml, i) => (
                          <option className="text-center" value={ml}>{ml}</option>
                        ))}
                      </select>
                      <select
                        label="Location"
                        name="location"
                        onChange={handleChange}
                        className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                      >
                        <option className="text-center" selected>--- Select Location ---</option>
                        {location_list.map((ml, i) => (
                          <option className="text-center" value={ml.value}>{ml.name}</option>
                        ))}
                      </select>
                      <Input
                        label="Convertion"
                        name="convertion"
                        defaultValue={input.convertion}
                        onChange={handleChange}
                      />
                      <Input
                        label="Weight in a box"
                        name="Weight"
                        defaultValue={input.weight}
                        onChange={handleChange}
                      />
                    </div>
                  )}
                  {selectedVendorItem.map((value) => (
                    <Input value={value} name="vendor" label="Vendor" />
                  ))}
                </div>
                <div className="flex justify-center flex-row items-center">
                  {signatureCanvas.map((sig) => (
                    <div key={sig.id} className="flex flex-col">
                      <div className="flex flex-row mt-8">
                        <SignatureCanvas
                          penColor="black"
                          canvasProps={{
                            width: 200,
                            height: 100,
                            className: "border-black border-2 rounded-l-xl",
                          }}
                          onEnd={() => count_signature()}
                          ref={sig.ref}
                        />
                        <div className={boxResetClasses}>
                          <GrPowerReset
                            size={"30px"}
                            onClick={() => clearSignature(sig.ref)}
                            className="cursor-pointer"
                            color="white"
                          />
                        </div>
                      </div>
                        <Typography className="text-center mt-3">
                          {sig.assignatories}
                        </Typography>
                    </div>
                  ))}
              </div>
            </div>
            <Moment format="YYYY-MM-DD" className="ml-2"></Moment>
            </DialogBody>
            <DialogFooter className="flex justify-between">
              <div className="flex space-x-2">
                {input.status === "accredited" ? (
                  <VendorItemListOption onSelect={handleChosenItem} />
                ) : (
                  ""
                )}
                <VendorListNewItem handleSelectVendor={handleArrayVendor} />
              </div>
              <div>
                <Button
                  variant="text"
                  color="red"
                  onClick={handleOpen}
                  className="mr-1"
                >
                  <span>Close</span>
                </Button>
                <Button
                  type="submit"
                  color="green"
                  disabled={disable_submit_button()}
                >
                  Submit
                </Button>
              </div>
            </DialogFooter>
          </form>
        </Dialog>
      </div>
    </>
  );
}

export default VendorListAddNewItem;
