import React, { useState } from "react";
import {
  Card, CardHeader, Input,
  Typography, Button, CardBody,
  CardFooter, Tabs, TabsHeader,
  Tab, Dialog, DialogHeader,
  DialogBody, DialogFooter,
} from "@material-tailwind/react";
import { FiSearch } from "react-icons/fi";
import { toast,  } from "react-toastify";
import {
  useTransferMaterialGoodRejectMutation,
  useFetctDelRejectProductListQuery,
  useSaveMaterialInputMutation
} from "../../store";
// import ItemHistoryModal from "../modal_form_components/Warehouse/WarehouseInventory/ItemHistory";
const TABLE_HEAD = [
  "Name",
  "UM",
  "Weight Box",
  "Pieces",
  "Kilos",
  "Last Modified",
];
export function CompleteTableMaterialRecovery() {
  let result = [];
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "desc",
  });
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(1);
  const [selectedMaterialCategory, setSelectedMaterialCategory] = useState(1);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  const account_detailed = JSON.parse(
    localStorage.getItem("account_detail")
  );
  const filterCategory = [];
  if (account_detailed.job_position === 7) {
    filterCategory.push(
      {
        label: "Foods Duty",
        value: 1,
      }
    );
    } else if (account_detailed.job_position === 8) {
      filterCategory.push(
        {
          label: "Beverages",
          value: 2,
        },
        {
          label: "Consumables",
          value: 3,
        }
      );

  }else{
    filterCategory.push(
      {
        label: "Foods Duty",
        value: 1,
      },
      {
        label: "Beverages",
        value: 2,
      },
      {
        label: "Consumables",
        value: 3,
      }
    )
  }

  let filterCategoryMaterial = [
    {
      label: "Reprocess",
      value: 1,
    },
    {
      label: "Disposal",
      value: 2,
    }
  ]

  const selectedTab = filterCategory.find(
    (item) => item.value === parseInt(selectedCategory)
  );

  const columnMapping = {
    "Order Number": "order_number",
    Outlet: "outlet",
    Route: "outlet__route",
    "Order Date": "order_date",
    Status: "status",
  };

  const [materialInput] = useSaveMaterialInputMutation();
  const [transMGoodReject] = useTransferMaterialGoodRejectMutation();

  const { data, error, isLoading, isSuccess, refetch } =
    useFetctDelRejectProductListQuery({
      page: currentPage,
      page_size: pageSize,
      m_category: selectedMaterialCategory,
      category: selectedCategory,
      search: searchTerm,
      order_by: sortConfig.key,
      order_dir: sortConfig.direction,
    });

  const handleSort = (column) => {
    let direction = "asc";
    if (
      sortConfig.key === columnMapping[column] &&
      sortConfig.direction === "asc"
    ) {
      direction = "desc";
    }
    setSortConfig({ key: columnMapping[column], direction });
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePageSizeChange = (e) => {
    const selectedSize = parseInt(e.target.value);
    console.log("Selected Page Size:", selectedSize);
    setPageSize(selectedSize);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleTabChange = (value) => {
    setCurrentPage(1);
    setSelectedCategory(value);
    refetch();
  };

  const handleTabMaterialChange = (value) => {
    setCurrentPage(1);
    setSelectedMaterialCategory(value);
    refetch();
  };

  const handleTransfer = () => {
    handleOpen();
  }

  const handleConfirm = async() => {
    let form = {
        user_id: user_info.id,
        client_id: account_detailed.client.id,
        data : data?.results
    };
  const sea = await transMGoodReject(form).unwrap();
  if (sea.success === true) {
    toast.success(sea.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    });
  } else {
    toast.error(sea.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    });
  }
  refetch()
  handleOpen();
};


const materialInputChange = async (value, type, id) => {
  let formData = new FormData();
  formData.append("id", id);
  formData.append("client_id", account_detailed.client.id);

  if (type === "rej_kilos_qty") {
    formData.append("value", value);
    formData.append("type", "rej_kilos_qty");
  }

  const mi = await materialInput(formData).unwrap();
  if (mi.success === true) {
    toast.success(mi.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    });
  } else {
    toast.error(mi.message, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
    });
  }
  refetch();
};

  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    result = data.results;
    let prod_reject_list = [];
    let count_null = 0;
    for (let i = 0; i < result.length; i++) {
      let eachMaterial = result[i];
      // if (eachProdSched.actual_batch === null || eachProdSched.actual_batch === '') {
      //   count_null += 1
      // }
      console.log(eachMaterial)
      prod_reject_list.push({
        id: eachMaterial.id,
        name: eachMaterial.product.name,
        unit_measurement: eachMaterial.product.unit_measurement,
        weight_box: eachMaterial.product.weight_box,
        weight_in_pack: eachMaterial.product.weight_in_pack,
        rejected_qty: eachMaterial.rejected_qty,
        rej_kilos_qty: eachMaterial.rej_kilos_qty,
        updated_at: eachMaterial.updated_at,
        soh_location: eachMaterial.soh_location,
      });
    }
    return (
      <>
        <Card className="h-full w-full mb-20 lg:mb-0">
          <CardHeader floated={false} shadow={false} className="rounded-none">
            <div className="flex md:flex-col space-y-5 w-[400px]">
              <Tabs value={selectedMaterialCategory} className="w-full hidden md:block">
                <TabsHeader>
                  {filterCategoryMaterial.map(({ label, value }) => (
                    <Tab
                      className="text-sm"
                      key={value}
                      value={value}
                      onClick={() => handleTabMaterialChange(value)}
                    >
                      <Typography className=" font-normal text-sm">
                        &nbsp;&nbsp;{label}&nbsp;&nbsp;
                      </Typography>
                    </Tab>
                  ))}
                </TabsHeader>
              </Tabs>
            </div>
            <div className="md:flex justify-between mt-5 md:mt-10">
              <div className="md:ml-5 mb-5">
                <Input
                  label="Search"
                  icon={<FiSearch className="h-5 w-5" />}
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="md:mr-10 flex justify-end mt-3 md:mt-0 items-center space-x-4">
                <Typography className=" font-medium text-sm">
                  Page size:
                </Typography>
                <select
                  className="block appearance-none bg-white border border-gray-300 rounded-md py-2 px-3 leading-tight focus:outline-none focus:border-indigo-500"
                  value={pageSize}
                  onChange={(e) => handlePageSizeChange(e)}
                >
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="75">75</option>
                </select>
              </div>
            </div>
            <div className="flex md:flex-col space-y-5">
              <Tabs value={selectedCategory} className="w-full hidden md:block">
                <TabsHeader>
                  {filterCategory.map(({ label, value }) => (
                    <Tab
                      className="text-sm"
                      key={value}
                      value={value}
                      onClick={() => handleTabChange(value)}
                    >
                      <Typography className=" font-normal text-sm">
                        &nbsp;&nbsp;{label}&nbsp;&nbsp;
                      </Typography>
                    </Tab>
                  ))}
                </TabsHeader>
              </Tabs>
              <div className="relative md:hidden mr-16">
                <select
                  value={selectedTab ? selectedTab.value : ""}
                  className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                  onChange={(e) => handleTabChange(e.target.value)}
                >
                  {filterCategory.map(({ value, label }) => (
                    <option key={value} value={value} className=" z-50">
                      {label}
                    </option>
                  ))}
                </select>
                <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                  Filter by
                </label>
              </div>

            </div>
          </CardHeader>
          <CardBody className="overflow-scroll px-0">
            <table className="w-full min-w-max table-auto text-left">
              <thead>
                <tr>
                  {TABLE_HEAD.map((head) => (
                    <th
                      key={head}
                      className="border-b border-green-600 bg-[#1AA55D] text-white p-4"
                      onClick={() => handleSort(head)}
                      style={{ cursor: "pointer" }}
                    >
                      <Typography
                        variant="small"
                        style={{
                          color: "white",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {prod_reject_list.sort(
                  (a, b) => b.id - a.id
                ).map((item, index) => {
                  const isLast = index === result.length - 1;
                  let item_weight_box = "";
                  const classes = isLast
                    ? "p-4"
                    : "p-4 border-b border-blue-gray-50";

                  const formattedDate = item.updated_at
                    ? new Intl.DateTimeFormat("en-US", {
                        year: "numeric",
                        month: "short",
                        day: "2-digit",
                      }).format(new Date(item.updated_at))
                    : "";

                  return (
                    <tr key={item.id}>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {item.name || "N/A"}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {item.unit_measurement || "N/A"}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {item.weight_box}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      <td className={classes}>
                        {
                          item.rejected_qty
                        }
                      </td>
                      <td>
                        <Input
                          type="number"
                          defaultValue={item.rej_kilos_qty}
                          autoFocus={false}
                          onBlur={(e) => {
                            materialInputChange(
                              e.target.value,
                              "rej_kilos_qty",
                              item.id,
                            );
                          }}
                        />
                      </td>
                      <td className={classes}>
                        <div className="flex items-center gap-3">
                          <div className="flex flex-col">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal"
                            >
                              {formattedDate}
                            </Typography>
                          </div>
                        </div>
                      </td>
                      {/* <td className={classes}>
                        <div className="flex items-center gap-3">
                          <div className="flex flex-col">
                            <ItemHistoryModal itemData={item.product} />
                          </div>
                        </div>
                      </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </CardBody>
          <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
            <Typography variant="small" color="blue-gray" className="font-normal">
              Page {`${currentPage} of ${Math.ceil(data?.count / pageSize) || 1}`}
            </Typography>
            <div className="flex gap-2">
              <Button
                variant="gradient"
                color="teal"
                size="sm"
                onClick={() => handleTransfer()}
              >
                Transfer
              </Button>
              <Button
                variant="outlined"
                size="sm"
                onClick={handlePreviousPage}
                disabled={!data?.previous}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                size="sm"
                onClick={handleNextPage}
                disabled={!data?.next}
              >
                {data?.next ? "Next" : "No More Pages"}
              </Button>
            </div>
          </CardFooter>
        </Card>
          <Dialog open={open} handler={handleOpen}>
          <DialogHeader>Transfer Product</DialogHeader>
          <DialogBody>Are you sure you want to proceed with this action</DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpen}
              className="mr-1"
            >
              <span>Cancel</span>
            </Button>
            <Button variant="gradient" color="green" onClick={handleConfirm}>
              <span>Confirm</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  }
}
