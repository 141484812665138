import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { FaSyncAlt } from "react-icons/fa";
import { FaTrash } from "react-icons/fa";

export function ResetQuantityConfirmation({ onConfirm, itemName, types }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);

  const handleConfirm = (event) => {
    // Execute the confirmation action
    onConfirm(event);
    // Close the dialog
    handleOpen();
  };

  return (
    <>
      <Button
        onClick={handleOpen}
        style={{
          marginLeft: "8px",
          backgroundColor: types === 2 ? "green" : "orange",
          padding: "10px",
          cursor: "pointer",
        }}
      >
        {types === 2 ? (
          <FaSyncAlt style={{ color: "white" }} />
        ) : (
          <FaTrash style={{ color: "white" }} />
        )}
      </Button>
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Confirm</DialogHeader>
        <DialogBody>
          {types === 2
            ? `Are you sure to revert ${itemName}'s quantity?`
            : `Are you sure to set ${itemName}'s quantity to 0?`}
        </DialogBody>
        <DialogFooter>
          <Button variant="text" color="red" onClick={handleOpen} className="mr-1">
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color={types === 2 ? "green" : "orange"} onClick={handleConfirm}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
