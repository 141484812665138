import { useState, useEffect } from "react";
import { Card, Typography } from "@material-tailwind/react";
import { useFetchDeliveryProductsQuery } from "../../../store";
import { useFetchDeliveryBeveragesQuery } from "../../../store";
import { useFetchDeliveryBunsQuery } from "../../../store";
import { useFetchDeliverySundriesQuery } from "../../../store";
import { useFetchDeliveryWrappersQuery } from "../../../store";
import { useFetchDeliveryTankQuery } from "../../../store";
import { useFetchDeliveryOSQuery } from "../../../store";
import { useFetchDeliveryCigarettesQuery } from "../../../store";
const TABLE_HEAD = [
  "Item Code",
  "Products",
  // "SL",
  // "SOH in Pieces",
  "Reject in Pieces",
  // "Order",
  "Conversion",
  "Unit Measure",
];
let product_result = []
let beverages_result = []
let buns_result = []
let wrappers_result = []
let os_result = []
let tank_result = []
let sundries_result = []
let cigarettes_result = []
const classes = "p-4 border-b border-blue-gray-50";

function capitalizeWords(str) {
  return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
  });
}

  // const [bunsActualQty, setBunsActualQty] = useState([]);
  // const [beveragesActualQty, setBeveragesActualQty] = useState([]);

function ProductTbl( {onOrderChange} ) {
  const { data, error, isLoading, isSuccess } = useFetchDeliveryProductsQuery()
  let [productActualQty, setProductActualQty] = useState([]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);

    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: product_result[index].id,
      prodQty: qty,
    })).filter(item => item.prodQty !== undefined && item.prodQty !== "" && item.prodQty !== 0);
    console.log(product_data)

    onOrderChange(product_data);
};


  if (isLoading) {

    return <div>Loading...</div>;
  }
  else if (error) {
    return <div>Error loading data</div>;
  }
  else if (data.length === 0) {
    return <div>No data found.</div>;
  }
  else if (isSuccess){
    product_result = data.data;


  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left ">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
           {product_result.map((product, index) => (
               <tr key={index}>
                  <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {product.product_code}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {product.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 {/* <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  1
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  1
                            </Typography>
                         </div>
                   </div>
                 </td> */}
                   <td>
                    <div className="flex items-center">
                      <div className="flex flex-col flex-grow">
                      <input
                              type="number"
                              value={productActualQty[index] || ""}
                              onChange={(e) => handleOrderChange(index, e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === 'Backspace') {
                                    handleOrderChange(index, "");
                                }
                              }}
                              className="w-20 border border-1 border-blue-gray-300 p-1"
                          />
                      </div>
                    </div>
                  </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {product.weight_box}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {product.unit_measurement}
                            </Typography>
                         </div>
                   </div>
                 </td>
               </tr>
             ))}
          </tbody>
      </table>
    </Card>
  );
}

}
function BeveragesTbl({onOrderChange}) {
  const { data, error, isLoading, isSuccess } = useFetchDeliveryBeveragesQuery()
  let [productActualQty, setProductActualQty] = useState([]);
  useEffect(() => {
    if (isSuccess && data) {
      setProductActualQty(new Array(data.data.length).fill(0));
    }
  }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);
    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: beverages_result[index].id,
      prodQty: qty,
    }));
    console.log(product_data)

    onOrderChange(product_data);
  };

  if (isLoading) {
    // console.log('Data:', data);

    return <div>Loading...</div>;
  }
  else if (error) {
    return <div>Error loading data</div>;
  }
  else if (data.length === 0) {
    return <div>No data found.</div>;
  }
  else if (isSuccess){
    beverages_result = data.data;
  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left ">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
           {beverages_result.map((beverages, index) => (
               <tr key={index}>
                  <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {beverages.product_code}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {beverages.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 {/* <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  1
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  1
                            </Typography>
                         </div>
                   </div>
                 </td> */}
                <td>
                    <div className="flex items-center">
                      <div className="flex flex-col flex-grow">
                        <input
                          type="number"
                          value={productActualQty[index] || ""}
                          onInput={(e) => handleOrderChange(index, e.target.value)}
                          className="w-20 border border-1 border-blue-gray-300 p-1"
                          // style={{ width: `${(productActualQty[index]?.toString().length || 2) * 10}px`, border: "1px solid #ccc" }} // Adjust width and add border style
                        />
                      </div>
                    </div>
                  </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {beverages.weight_box}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {beverages.unit_measurement}
                            </Typography>
                         </div>
                   </div>
                 </td>
               </tr>
             ))}
          </tbody>
      </table>
    </Card>
  );
}
}
function BunsTbl({onOrderChange}) {
  const { data, error, isLoading, isSuccess } = useFetchDeliveryBunsQuery()
  let [productActualQty, setProductActualQty] = useState([]);
  useEffect(() => {
    if (isSuccess && data) {
      setProductActualQty(new Array(data.data.length).fill(0));
    }
  }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);


    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: buns_result[index].id,
      prodQty: qty,
    }));
    console.log(product_data)

    onOrderChange(product_data);
  };
if (isLoading) {
  // console.log('Data:', data);
  return <div>Loading...</div>;
}
else if (error) {
  return <div>Error loading data</div>;
}
else if (data.length === 0) {
  return <div>No data found.</div>;
}
else if (isSuccess){
  buns_result = data.data;
return (
  <Card className="h-full w-full overflow-scroll">
    <table className="w-full min-w-max table-auto text-left ">
      <thead>
        <tr>
          {TABLE_HEAD.map((head) => (
            <th
              key={head}
              className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
            >
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal leading-none opacity-70"
              >
                {head}
              </Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
         {buns_result.map((buns, index) => (
             <tr key={index}>
                <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                {buns.product_code}
                          </Typography>
                       </div>
                 </div>
               </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                         {buns.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                          </Typography>
                       </div>
                 </div>
               </td>
               {/* <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                1
                          </Typography>
                       </div>
                 </div>
               </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                1
                          </Typography>
                       </div>
                 </div>
               </td> */}
             <td>
                    <div className="flex items-center">
                      <div className="flex flex-col flex-grow">
                        <input
                          type="number"
                          value={productActualQty[index] || ""}
                          onInput={(e) => handleOrderChange(index, e.target.value)}
                          className="w-20 border border-1 border-blue-gray-300 p-1"
                          // style={{ width: `${(productActualQty[index]?.toString().length || 2) * 10}px`, border: "1px solid #ccc" }} // Adjust width and add border style
                        />
                      </div>
                    </div>
                  </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                {buns.weight_box}
                          </Typography>
                       </div>
                 </div>
               </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                {buns.unit_measurement}
                          </Typography>
                       </div>
                 </div>
               </td>
             </tr>
           ))}
        </tbody>
    </table>
  </Card>
);
}
}
function WrappersTbl({onOrderChange}) {
  const { data, error, isLoading, isSuccess } = useFetchDeliveryWrappersQuery()
  let [productActualQty, setProductActualQty] = useState([]);
  useEffect(() => {
    if (isSuccess && data) {
      setProductActualQty(new Array(data.data.length).fill(0));
    }
  }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);


    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: wrappers_result[index].id,
      prodQty: qty,
    }));
    console.log(product_data)

    onOrderChange(product_data);
  };
if (isLoading) {
  // console.log('Data:', data);
  return <div>Loading...</div>;
}
else if (error) {
  return <div>Error loading data</div>;
}
else if (data.length === 0) {
  return <div>No data found.</div>;
}
else if (isSuccess){
  wrappers_result = data.data;
return (
  <Card className="h-full w-full overflow-scroll">
    <table className="w-full min-w-max table-auto text-left ">
      <thead>
        <tr>
          {TABLE_HEAD.map((head) => (
            <th
              key={head}
              className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
            >
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal leading-none opacity-70"
              >
                {head}
              </Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
         {wrappers_result.map((wrappers, index) => (
             <tr key={index}>
                <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                {wrappers.product_code}
                          </Typography>
                       </div>
                 </div>
               </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                         {wrappers.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                          </Typography>
                       </div>
                 </div>
               </td>
               {/* <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                1
                          </Typography
                       </div>
                 </div>
               </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                1
                          </Typography>
                       </div>
                 </div>
               </td> */}
            <td>
                    <div className="flex items-center">
                      <div className="flex flex-col flex-grow">
                        <input
                          type="number"
                          value={productActualQty[index] || ""}
                          onInput={(e) => handleOrderChange(index, e.target.value)}
                          className="w-20 border border-1 border-blue-gray-300 p-1"
                          // style={{ width: `${(productActualQty[index]?.toString().length || 2) * 10}px`, border: "1px solid #ccc" }} // Adjust width and add border style
                        />
                      </div>
                    </div>
                  </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                {wrappers.weight_box}
                          </Typography>
                       </div>
                 </div>
               </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                {wrappers.unit_measurement}
                          </Typography>
                       </div>
                 </div>
               </td>
             </tr>
           ))}
        </tbody>
    </table>
  </Card>
);
}
}
function SundriesTbl({onOrderChange}) {
  const { data, error, isLoading, isSuccess } = useFetchDeliverySundriesQuery()
  let [productActualQty, setProductActualQty] = useState([]);
  useEffect(() => {
    if (isSuccess && data) {
      setProductActualQty(new Array(data.data.length).fill(0));
    }
  }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);


    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: sundries_result[index].id,
      prodQty: qty,
    }));
    console.log(product_data)

    onOrderChange(product_data);
  };
if (isLoading) {
  // console.log('Data:', data);
  return <div>Loading...</div>;
}
else if (error) {
  return <div>Error loading data</div>;
}
else if (data.length === 0) {
  return <div>No data found.</div>;
}
else if (isSuccess){
  sundries_result = data.data;
return (
  <Card className="h-full w-full overflow-scroll">
    <table className="w-full min-w-max table-auto text-left ">
      <thead>
        <tr>
          {TABLE_HEAD.map((head) => (
            <th
              key={head}
              className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
            >
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal leading-none opacity-70"
              >
                {head}
              </Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
         {sundries_result.map((sundries, index) => (
             <tr key={index}>
                <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                {sundries.product_code}
                          </Typography>
                       </div>
                 </div>
               </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                         {sundries.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                          </Typography>
                       </div>
                 </div>
               </td>
               {/* <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                1
                          </Typography>
                       </div>
                 </div>
               </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                1
                          </Typography>
                       </div>
                 </div>
               </td> */}
              <td>
                    <div className="flex items-center">
                      <div className="flex flex-col flex-grow">
                        <input
                          type="number"
                          value={productActualQty[index] || ""}
                          onInput={(e) => handleOrderChange(index, e.target.value)}
                          className="w-20 border border-1 border-blue-gray-300 p-1"
                          // style={{ width: `${(productActualQty[index]?.toString().length || 2) * 10}px`, border: "1px solid #ccc" }} // Adjust width and add border style
                        />
                      </div>
                    </div>
                  </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                {sundries.weight_box}
                          </Typography>
                       </div>
                 </div>
               </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                {sundries.unit_measurement}
                          </Typography>
                       </div>
                 </div>
               </td>
             </tr>
           ))}
        </tbody>
    </table>
  </Card>
);
}
}
function CigaretteTbl({onOrderChange}) {
  const { data, error, isLoading, isSuccess } = useFetchDeliveryCigarettesQuery()
  let [productActualQty, setProductActualQty] = useState([]);
  useEffect(() => {
    if (isSuccess && data) {
      setProductActualQty(new Array(data.data.length).fill(0));
    }
  }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);


    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: cigarettes_result[index].id,
      prodQty: qty,
    }));
    console.log(product_data)

    onOrderChange(product_data);
  };
  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  }
  else if (error) {
    return <div>Error loading data</div>;
  }
  else if (data.length === 0) {
    return <div>No data found.</div>;
  }
  else if (isSuccess){
    cigarettes_result = data.data;
  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left ">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
           {cigarettes_result.map((cigarettes, index) => (
               <tr key={index}>
                  <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {cigarettes.product_code}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {cigarettes.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 {/* <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  1
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  1
                            </Typography>
                         </div>
                   </div>
                 </td> */}
                 <td>
                    <div className="flex items-center">
                      <div className="flex flex-col flex-grow">
                        <input
                          type="number"
                          value={productActualQty[index] || ""}
                          onInput={(e) => handleOrderChange(index, e.target.value)}
                          className="w-20 border border-1 border-blue-gray-300 p-1"
                          // style={{ width: `${(productActualQty[index]?.toString().length || 2) * 10}px`, border: "1px solid #ccc" }} // Adjust width and add border style
                        />
                      </div>
                    </div>
                  </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {cigarettes.weight_box}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {cigarettes.unit_measurement}
                            </Typography>
                         </div>
                   </div>
                 </td>
               </tr>
             ))}
          </tbody>
      </table>
    </Card>
  );
}
}

function OSTbl({onOrderChange}) {
  const { data, error, isLoading, isSuccess } = useFetchDeliveryOSQuery()
  let [productActualQty, setProductActualQty] = useState([]);
  useEffect(() => {
    if (isSuccess && data) {
      setProductActualQty(new Array(data.data.length).fill(0));
    }
  }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);


    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: os_result[index].id,
      prodQty: qty,
    }));
    console.log(product_data)

    onOrderChange(product_data);
  };
  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  }
  else if (error) {
    return <div>Error loading data</div>;
  }
  else if (data.length === 0) {
    return <div>No data found.</div>;
  }
  else if (isSuccess){
    os_result = data.data;
  return (
    <Card className="h-full w-full overflow-scroll">
      <table className="w-full min-w-max table-auto text-left ">
        <thead>
          <tr>
            {TABLE_HEAD.map((head) => (
              <th
                key={head}
                className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
              >
                <Typography
                  variant="small"
                  color="blue-gray"
                  className="font-normal leading-none opacity-70"
                >
                  {head}
                </Typography>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
           {os_result.map((os, index) => (
               <tr key={index}>
                  <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {os.product_code}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                           {os.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 {/* <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  1
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  1
                            </Typography>
                         </div>
                   </div>
                 </td> */}
                 <td>
                    <div className="flex items-center">
                      <div className="flex flex-col flex-grow">
                        <input
                          type="number"
                          value={productActualQty[index] || ""}
                          onInput={(e) => handleOrderChange(index, e.target.value)}
                          className="w-20 border border-1 border-blue-gray-300 p-1"
                          // style={{ width: `${(productActualQty[index]?.toString().length || 2) * 10}px`, border: "1px solid #ccc" }} // Adjust width and add border style
                        />
                      </div>
                    </div>
                  </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {os.weight_box}
                            </Typography>
                         </div>
                   </div>
                 </td>
                 <td className={classes}>
                      <div className="flex items-center gap-3">
                         <div className="flex flex-col">
                           <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                  {os.unit_measurement}
                            </Typography>
                         </div>
                   </div>
                 </td>
               </tr>
             ))}
          </tbody>
      </table>
    </Card>
  );
}
}
function TankTbl({onOrderChange}) {
  const { data, error, isLoading, isSuccess } = useFetchDeliveryTankQuery()
  let [productActualQty, setProductActualQty] = useState([]);

  useEffect(() => {
    if (isSuccess && data) {
      setProductActualQty(new Array(data.data.length).fill(0));
    }
  }, [isSuccess, data]);

  let handleOrderChange = (index, value) => {
    value = value < 0 ? 0 : (value > 9999 ? 9999 : value);
    let updatedActualQty = [...productActualQty];
    updatedActualQty[index] = value;
    setProductActualQty(updatedActualQty);

    let product_data = updatedActualQty.map((qty, index) => ({
      prodId: tank_result[index].id,
      prodQty: qty,
    }));
    console.log(product_data)

    onOrderChange(product_data);
  };

if (isLoading) {
  // console.log('Data:', data);
  return <div>Loading...</div>;
}
else if (error) {
  return <div>Error loading data</div>;
}
else if (data.length === 0) {
  return <div>No data found.</div>;
}
else if (isSuccess){
  tank_result = data.data;
return (
  <Card className="h-full w-full overflow-scroll">
    <table className="w-full min-w-max table-auto text-left ">
      <thead>
        <tr>
          {TABLE_HEAD.map((head) => (
            <th
              key={head}
              className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
            >
              <Typography
                variant="small"
                color="blue-gray"
                className="font-normal leading-none opacity-70"
              >
                {head}
              </Typography>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
         {tank_result.map((tank, index) => (
             <tr key={index}>
                <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                {tank.product_code}
                          </Typography>
                       </div>
                 </div>
               </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                         {tank.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ')}
                          </Typography>
                       </div>
                 </div>
               </td>
               {/* <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                1
                          </Typography>
                       </div>
                 </div>
               </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                1
                          </Typography>
                       </div>
                 </div>
               </td> */}
               <td>
                    <div className="flex items-center">
                      <div className="flex flex-col flex-grow">
                        <input
                          type="number"
                          value={productActualQty[index] || ""}
                          onInput={(e) => handleOrderChange(index, e.target.value)}
                          className="w-20 border border-1 border-blue-gray-300 p-1"
                          // style={{ width: `${(productActualQty[index]?.toString().length || 2) * 10}px`, border: "1px solid #ccc" }} // Adjust width and add border style
                        />
                      </div>
                    </div>
                  </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                {tank.weight_box}
                          </Typography>
                       </div>
                 </div>
               </td>
               <td className={classes}>
                    <div className="flex items-center gap-3">
                       <div className="flex flex-col">
                         <Typography variant="small" color="blue-gray" className="font-normal" component={'span'}>
                                {tank.unit_measurement}
                          </Typography>
                       </div>
                 </div>
               </td>
             </tr>
           ))}
        </tbody>
    </table>
  </Card>
);
}
}

export {
  ProductTbl,
  BeveragesTbl,
  BunsTbl,
  WrappersTbl,
  SundriesTbl,
  CigaretteTbl,
  OSTbl,
  TankTbl,
};
