import React, { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import { FaSync } from "react-icons/fa";
import {
  Card,
  CardHeader,
  Input,
  Typography,
  Button,
  CardBody,
  CardFooter,
  Tabs,
  TabsHeader,
  Tab,
  Select,
  Option,
} from "@material-tailwind/react";
import { FiSearch } from "react-icons/fi";
import { useFetchAuditReportListPageQueryQuery } from "../../store";
import AddNewAuditModal from "../modal_form_components/Warehouse/WarehouseInventory/AddNewAuditReport";
import AuditReportModal from "../modal_form_components/Warehouse/WarehouseInventory/AuditReportModal";
import AuditReportCompltedModal from "../modal_form_components/Warehouse/WarehouseInventory/AuditReportCompletedModal";
const TABLE_HEAD = ["Audit #", "Auditor", "Date", "Status", "Action"];
export function CompleteTableView() {
  let result = [];
  const [sortConfig, setSortConfig] = useState({
    key: "id",
    direction: "desc",
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(1);

  const { data, error, isLoading, isSuccess, refetch } =
    useFetchAuditReportListPageQueryQuery({
      page: currentPage,
      page_size: pageSize,
      search: searchTerm,
    });

  const [isRefreshing, setIsRefreshing] = useState(false);

  const handleClickRefresh = () => {
    setIsRefreshing(true);

    // Trigger the refetch function
    refetch();

    // Disable the button after 2 seconds
    setTimeout(() => {
      setIsRefreshing(false);
    }, 2000);
  };
  useEffect(() => {
    if (isSuccess) {
      result = data.results;
      console.log();
    }
  }, [isSuccess]);

  // const handleSort = (column) => {
  //   let direction = "asc";
  //   if (
  //     sortConfig.key === columnMapping[column] &&
  //     sortConfig.direction === "asc"
  //   ) {
  //     direction = "desc";
  //   }
  //   setSortConfig({ key: columnMapping[column], direction });
  // };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePageSizeChange = (e) => {
    const selectedSize = parseInt(e.target.value);
    console.log("Selected Page Size:", selectedSize);
    setPageSize(selectedSize);
    setCurrentPage(1);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setCurrentPage(1);
  };

  const handleTabChange = (value) => {
    setCurrentPage(1);
    setSelectedStatus(value);
    refetch();
  };
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

  // if (isLoading) {
  //   // console.log('Data:', data);
  //   return <div>Loading...</div>;
  // } else if (error) {
  //   return <div>Error loading data</div>;
  // } else if (data.length === 0) {
  //   return <div>No data found.</div>;
  // } else if (isSuccess) {
  //   result = data.results;
  console.log();
  return (
    <Card className="h-full w-full mb-20 lg:mb-0">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <AddNewAuditModal refetchMainTable={refetch} />
        <div className="md:flex justify-between mt-5 md:mt-10">
          <div className="md:ml-5">
            <Input
              label="Search"
              icon={<FiSearch className="h-5 w-5" />}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <div className="md:mr-10 flex justify-end mt-3 md:mt-0 items-center space-x-4">
            <Typography className=" font-medium text-sm">Page size:</Typography>
            <select
              className="block appearance-none bg-white border border-gray-300 rounded-md py-2 px-3 leading-tight focus:outline-none focus:border-indigo-500"
              value={pageSize}
              onChange={(e) => handlePageSizeChange(e)}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>

        {/* <div className="flex flex-col items-center justify-between gap-4 md:flex-row">
            <Tabs value={selectedStatus} className="w-full md:w-[70%]">
              <TabsHeader>
                {filterCategory.map(({ label, value }) => (
                  <Tab
                    className="text-sm"
                    key={value}
                    value={value}
                    onClick={() => handleTabChange(value)}
                  >
                    &nbsp;&nbsp;{label}&nbsp;&nbsp;
                  </Tab>
                ))}
              </TabsHeader>
            </Tabs>
          </div> */}
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-b border-green-600 bg-[#1AA55D] text-white p-4"
                  // onClick={() => handleSort(head)}
                  style={{ cursor: "pointer" }}
                >
                  <Typography
                    variant="small"
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      textTransform: "uppercase",
                    }}
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          {isSuccess && data.results.length > 0 && (
            <tbody>
              {data.results.map((audit, index) => {
                const isLast = index === data.results.length - 1;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";

                const formattedDate = audit.create_at
                  ? new Intl.DateTimeFormat("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "2-digit",
                    }).format(new Date(audit.create_at))
                  : "";

                return (
                  <tr key={audit.id}>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {audit.audit_number || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {audit.auditor_name || "N/A"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {formattedDate}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal"
                          >
                            {audit.status == 1
                              ? "In-Progress"
                              : audit.status == 5
                              ? "Completed"
                              : "Pending"}
                          </Typography>
                        </div>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          {audit.status === 1 && (
                            <AuditReportModal
                              auditData={audit}
                              refetchMainTable={refetch}
                            />
                          )}
                          {audit.status === 5 && (
                            <AuditReportCompltedModal
                              auditData={audit}
                              refetchMainTable={refetch}
                            />
                          )}
                        </div>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          )}
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page {`${currentPage} of ${Math.ceil(data?.count / pageSize) || 1}`}
        </Typography>
        <div className="flex gap-2">
          <Button
            variant="outlined"
            size="sm"
            onClick={handlePreviousPage}
            disabled={!data?.previous}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            size="sm"
            onClick={handleClickRefresh}
            disabled={isRefreshing}
          >
            Refresh
          </Button>

          <Button
            variant="outlined"
            size="sm"
            onClick={handleNextPage}
            disabled={!data?.next}
          >
            {data?.next ? "Next" : "No More Pages"}
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
