import React, { useState } from "react";
import {
  Button,
  Typography,
  Card,
  CardHeader,
  CardBody,
  Input,
  CardFooter,
} from "@material-tailwind/react";
import { useDashPendingPoQuery } from "../../../store";
import moment from "moment";

const TABLE_HEAD = [
  {
    head: "Vendor Name",
    customeStyle: "text-left",
  },
  {
    head: "PO #",
    customeStyle: "text-left",
  },
  {
    head: "Item Name",
    customeStyle: "text-left",
  },
  {
    head: "Total Ordered Qty",
    customeStyle: "text-left",
  },
  {
    head: "Delivery Date",
    customeStyle: "text-left",
  },
];

function POTable() {
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);


  const handleSearch = (e) => {
    setSearchStr(e.target.value);
  };
  // preview of pagination
  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };
  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const dash_pending_po = useDashPendingPoQuery({
    page: currentPage,
    page_size: pageSize,
    search_str: searchStr,
  });

  const totalPage = Math.ceil(
    dash_pending_po.data?.count / pageSize)
  ;

  let content;
  if (dash_pending_po.isLoading) {
    return <div>Loading....</div>;
  } else if (dash_pending_po.error) {
    return <div>Refresh the page....</div>;
  } else {
    let data = dash_pending_po.data?.results
		content = <>
        {data.map(
      (
        item,
        index
      ) => {
        const isLast = index === data.length - 1;
        const classes = isLast
          ? "!p-4"
          : "!p-4 border-b border-gray-300";
        return (

          <tr key={index}>
            <td className={classes}>
              <div className="flex items-center gap-4 text-left">
                <div>
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="!font-semibold"
                  >
                    {item.vendor_name}
                  </Typography>
                </div>
              </div>
            </td>
            <td className={classes}>
              <Typography
                variant="small"
                className="!font-normal text-gray-600 text-left"
              >
                {item.po_number}
              </Typography>
            </td>
            <td className={classes}>
              <Typography
                variant="small"
                color="green"
                className="!font-bold text-left"
              >
                {item.item}
              </Typography>
            </td>
            <td className={classes}>
              <Typography
                variant="small"
                className="!font-normal text-gray-600 text-left"
              >
                {item.required_qty}
              </Typography>
            </td>
            <td className={classes}>
              <Typography
                variant="small"
                className="!font-normal text-gray-600 text-left"
              >
                {moment(new Date(item.committed_date)).format("YYYY-MM-DD")}
              </Typography>
            </td>
            {/* <td className={classes}>
            </td> */}
          </tr>
        );
      }
    )}
    </>
  }



  return (
    <section className="m-10">
      <Card className="h-full w-full">
        <CardHeader
          floated={false}
          shadow={false}
          className="rounded-none flex flex-wrap gap-4 justify-between mb-4"
        >
          <div>
            <Typography variant="h5" color="blue-gray">
              Pending PO
            </Typography>
          </div>
          <div className="flex items-center w-full shrink-0 gap-4 md:w-max">
            <div className="w-full md:w-72">
              <Input
                size="lg"
                label="Search"
                onChange={handleSearch}
              />
            </div>
            {/* <Button variant="outlined" className="flex items-center gap-2">
              24h
            </Button> */}
          </div>
        </CardHeader>
        <CardBody className="overflow-scroll !px-0 py-2">
          <table className="w-full min-w-max table-auto">
            <thead>
              <tr>
                {TABLE_HEAD.map(({ head, customeStyle }) => (
                  <th
                    key={head}
                    className={`border-b border-gray-300 !p-4 pb-8 ${customeStyle}`}
                  >
                    <Typography
                      color="blue-gray"
                      variant="small"
                      className="!font-bold"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
             {content}
            </tbody>
          </table>
        </CardBody>
        <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography
              variant="small"
              color="blue-gray"
              className="font-normal"
            >
              Page{" "}
              {`${currentPage} of ${
                Math.ceil(totalPage ) || 1
              }`}
            </Typography>
            <div className="flex gap-2">
              <Button
                variant="outlined"
                size="sm"
                onClick={handlePreviousPage}
                disabled={currentPage === 1}
              >
                Previous
              </Button>
              <Button
                variant="outlined"
                size="sm"
                onClick={handleNextPage}
                disabled={currentPage === totalPage}
              >
                Next
              </Button>
            </div>
        </CardFooter>
      </Card>
    </section>
  );
}

export default POTable;
