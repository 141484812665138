import TabComponent from "../page_components/TabComponent";
import SelectField from "../page_components/SelectField";
import React, { useState } from "react";
import {
  Typography,
  Input,
  Select,
  Option,
  IconButton,
  Card,
  CardBody,
  CardFooter,
  Button,
} from "@material-tailwind/react";
import { FaPlus } from "react-icons/fa";

const trclasses = "p-4 border-b border-blue-gray-50";

const catogories = ["Raw Materials", "Office Supplies", "Hardware"];
const sections = {
  Production: ["Beverages", "Food", "Consumables", "Raw Materials"],
  Warehouse: [""],
  Sales: [""],
  Administration: [""],
  IT: [""],
};

function RequestForm() {
  const [optionSection, setOptionsSection] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [rows, setRows] = useState([]);
  const addRow = () => {
    const newRow = {
      id: rows.length + 1,
      item: "",
      unitmeasure: "",
      stocklevel: "",
      stockonhand: "",
      requiredqty: "",
    };
    setRows([...rows, newRow]);
  };

  const [tableRows, setTableRows] = useState([
    // Initial row content
    {
      id: 1,
      col1: (
        <td className={trclasses}>
          <Input variant="standard" label="Items" />
        </td>
      ),
      col2: (
        <td className={trclasses}>
          <Input variant="standard" label="Unit Measure" />
        </td>
      ),
      col3: (
        <td className={trclasses}>
          <Input variant="standard" label="Stock Level" />
        </td>
      ),
      col4: (
        <td className={trclasses}>
          <Input variant="standard" label="Stock on Hand" />
        </td>
      ),
      col5: (
        <td className={trclasses}>
          <Input variant="standard" label="Required Quantity" />
        </td>
      ),
    },
  ]);

  const handleChange = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Access the collection of submitted data in the 'rows' state
    console.log(rows);
  };

  const data = [
    {
      label: "Purchase Request Form",
      value: "prform",
      desc: (
        <>
          <Card className="h-full w-full">
            <CardBody className="overflow-auto px-0">
              <div className="grid grid-cols-2 gap-16 m-4">
                <div>
                  <div class="relative h-10 w-full">
                    <select
                      label="Department"
                      onChange={(evt) =>
                        setSelectedDepartment(evt.target.value)
                      }
                      className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
                    >
                      <option value="Warehouse">Warehouse</option>
                      <option value="Production">Production</option>
                      <option value="Sales">Sales</option>
                      <option value="Administration">Administration</option>
                      <option value="IT">IT</option>
                    </select>
                    <label class="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                      Department
                    </label>
                  </div>
                </div>
                <div>
                  <Input variant="outlined" label="PR Number" />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-16 m-4">
                <div>
                  {selectedDepartment !== "Production" && (
                    <SelectField
                      selectlabel="Section"
                      options={[]}
                    ></SelectField>
                  )}
                  {selectedDepartment == "Production" && (
                    <SelectField
                      selectlabel="Section"
                      options={sections["Production"]}
                    ></SelectField>
                  )}
                </div>
                <div>
                  <Input variant="outlined" label="Date" />
                </div>
              </div>
              <div className="grid grid-cols-2 gap-16 m-4">
                <div>
                  <SelectField
                    options={catogories}
                    selectlabel="Category"
                  ></SelectField>
                </div>
              </div>
              <div className="grid grid-cols-1 gap-16 m-4">
                <div class="overflow-x-auto">
                  <table className="table-auto min-w-full w-full min-w-max table-auto text-left">
                    <thead>
                      <tr>
                        <th
                          key="item"
                          className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                          >
                            Items
                          </Typography>
                        </th>
                        <th
                          key="unitmeasure"
                          className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                          >
                            Unit Measure
                          </Typography>
                        </th>
                        <th
                          key="stocklevel"
                          className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                          >
                            Stock Level
                          </Typography>
                        </th>
                        <th
                          key="stockonhand"
                          className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                          >
                            Stock on Hand
                          </Typography>
                        </th>
                        <th
                          key="requiredqty"
                          className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                          >
                            Required Quantity
                          </Typography>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {rows.map((row, index) => (
                        <tr key={index}>
                          <td className={trclasses}>
                            <Input
                              variant="standard"
                              value={row.item}
                              label="Items"
                              onChange={(e) =>
                                handleChange(index, "item", e.target.value)
                              }
                            />
                          </td>
                          <td className={trclasses}>
                            <Input
                              variant="standard"
                              value={row.unitmeasure}
                              label="Unit Measure"
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  "unitmeasure",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td className={trclasses}>
                            <Input
                              variant="standard"
                              value={row.stocklevel}
                              label="Stock Level"
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  "stocklevel",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td className={trclasses}>
                            <Input
                              variant="standard"
                              value={row.stockonhand}
                              label="Stock on Hand"
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  "stockonhand",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                          <td className={trclasses}>
                            <Input
                              variant="standard"
                              value={row.requiredqty}
                              label="Required Quantity"
                              onChange={(e) =>
                                handleChange(
                                  index,
                                  "requiredqty",
                                  e.target.value
                                )
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </CardBody>
            <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
              <Button size="sm" color="blue" onClick={addRow}>
                <FaPlus className="inline"></FaPlus> Add Row
              </Button>
              <Button variant="outlined" size="sm" onClick={handleSubmit}>
                Submit
              </Button>
            </CardFooter>
          </Card>
        </>
      ),
    },
    {
      label: "PR List",
      value: "prlist",
    },

    {
      label: "Approval",
      value: "approval",
    },

    {
      label: "Vendor Database",
      value: "vendordatabase",
    },
  ];

  return (
    <>
      <div className="container mx-auto px-4">
        <Typography className="text-[#137944]" variant="h1">
          BURGER MACHINE
        </Typography>
        <TabComponent data={data} />
      </div>
    </>
  );
}
export default RequestForm;
