import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
  Select,
  Option,
} from "@material-tailwind/react";
import { useFetchFCListQuery } from "../../../store";
import { useFetchRouteListQuery } from "../../../store";
import { useAddNewOutletMutation } from "../../../store";
import { Confirmation } from "../Error_trap_components/ErrorTrapComponent";
import { ToastContainer, toast } from "react-toastify";
const trapMessage =
"Are you sure you want to submit the Add Outlet form? ";
function AddOutletModal({ refetchMainTable }) {
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const token = localStorage.getItem("mytoken");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [outletName, setOutletName] = useState("");
  const [dealerNmae, setDelearName] = useState("");
  const [address, setAdress] = useState("");
  const [selectedFC, setSelectedFC] = useState("");
  const [selectedRoute, setSelectedRoute] = useState("");
  let [isReadyToSave, setIsReadyToSave] = useState(false);

  useEffect(() => {
    // Check if any of the arrays contain data and selectedOutlet is not null
    const anyArrayHasData =

      selectedFC !== "" &&
      selectedRoute !== "" &&
      address !== "" &&
      outletName != "";

    // Update isReadyToSave based on the condition
    setIsReadyToSave(anyArrayHasData);
  }, [
    selectedFC,
    selectedRoute,
    address,
    outletName,
  ]);

  const {
    data: fcData,
    error: fcError,
    isLoading: fcLoading,
    isSuccess: fcSuccess,
  } = useFetchFCListQuery();
  const {
    data: routeData,
    error: routeError,
    isLoading: loadingRoute,
    isSuccess: routeSucess,
  } = useFetchRouteListQuery({
    page: 1,
    page_size: 50,
    schedule: 1,
  });

  const handleFCChange = (value) => {
    const fcId = parseInt(value, 10);
    setSelectedFC(fcId);
  };

  const handleRouteChange = (value) => {
    const rID = parseInt(value, 10);
    setSelectedRoute(rID);
  };
  function handleOutletChange(event) {
    const newValue = event.target.value;
    setOutletName(newValue);
  }
  function handleDealerChange(event) {
    const newValue = event.target.value;
    setDelearName(newValue);
  }
  function handleAddressChange(event) {
    const newValue = event.target.value;
    setAdress(newValue);
  }


  const [saveOutlets] = useAddNewOutletMutation();
  const handleSave = async (event) => {
    event.preventDefault();
    // Gather data from each table component

    // const formattedDate = deliveryDate.format("YYYY-MM-DD");
    // Repeat for other table components

    // Combine all data as needed
    let allData = {
      fc: selectedFC,
      route: selectedRoute,
      name: outletName,
      address: address,
      user: account_detailed.user.id,

      // deliveryDate: formattedDate,

      // Add other data as needed
    };
    console.log(allData);
    try {
      const sea = await saveOutlets(allData).unwrap();
      if (sea.success === true) {
        toast.success(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        setSelectedFC("");
        setSelectedRoute("");
        setAdress("");
        setOutletName("");
        handleClose();
        setIsReadyToSave(false)
        refetchMainTable();
      } else {
        toast.error(sea.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
        });
        // formRef.current?.reset();
      }
    } catch (error) {
      // Handle the error here
      // You can display a generic error message to the user if needed
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]">
        Add Outlet
      </Button>

      <Dialog
        size="lg"
        open={open}
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          <Typography className="text-green-900" variant="h3">
            Add Outlet Form
          </Typography>
        </DialogHeader>
        <DialogBody>
          <form>
            <div className="grid grid-cols-2 gap-5 mb-10">
              <div className="space-y-5">
                <Input
                  label="Outlet Name"
                  name="outletName"
                  onChange={handleOutletChange}
                  // Add value and onChange handler
                />
                   {routeSucess && (
                  <Select
                  label="Route"
                  onChange={handleRouteChange}
                  value={selectedRoute ? `${selectedRoute}` : null}
                  variant="outlined"
                  >
                  {routeData.results.map((route) => (
                      <Option key={route.id} value={`${route.id}`}>
                      {route.name}
                      </Option>
                  ))}
                  </Select>
              )}

                {/* <Input
                  label="Dealer Name"
                  name="dealerName"
                  onChange={handleDealerChange}
                  // Add value and onChange handler
                /> */}
              </div>
              <div className="space-y-5">
              <Input
                  label="Address"
                  name="address"
                  onChange={handleAddressChange}
                  // Add value and onChange handler
                />
                    {fcSuccess && (
                    <Select
                    label="FC"
                    onChange={handleFCChange}
                    value={selectedFC ? `${selectedFC}` : null}
                    variant="outlined"
                    >
                    {fcData.data.map((fc) => (
                        <Option key={fc.id} value={`${fc.id}`}>
                        {fc.name}
                        </Option>
                    ))}
                    </Select>
                )}

              </div>
            </div>
            <Confirmation
              onConfirm={handleSave}
              trapMessage={trapMessage}
              isReadyToSave={isReadyToSave}
            />
          </form>
        </DialogBody>
        <ToastContainer />
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            className="mr-3"
            onClick={handleClose}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default AddOutletModal;
