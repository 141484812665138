import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    Typography,
    Input,
    Select,
    Option,
    Button,
} from "@material-tailwind/react";
import Chart from "react-apexcharts";
import { format } from "date-fns";
import { useFetchDeliveryTotalSalesQuery } from "../../../store";
import { useFetchFCListQuery } from "../../../store"
import { useFetchRouteListQuery } from "../../../store"

export default function DelGrapLine() {
    const inputClasses = "flex flex-row items-center";
    let th_class = "border-b border-green-600 bg-[#1AA55D] text-white p-4";
    const trclasses = "p-4 border-b border-blue-gray-50";
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");
    const [selectedFC, setSelectedFC] = useState("");
    const [selectedRoute, setSelectedRoute] = useState("");
    const [salesData, setSalesData] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    useEffect(() => {
        // Set default date range if no dates are provided
        // if (startDate === "" || endDate === "" ){
            const currentDate = new Date();
            const defaultStartDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const defaultEndDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
            setStartDate(defaultStartDate.toISOString().split('T')[0]);
            setEndDate(defaultEndDate.toISOString().split('T')[0]);
    }, []);

    const {
        data,
        isError,
        isLoading,
        isSuccess,
        refetch,
    } = useFetchDeliveryTotalSalesQuery({
        fc: selectedFC,
        route: selectedRoute,
        startDate: startDate,
        endDate: endDate,
    });

    const {
        data: dataTable,
        isError: errTable,
        isLoading: loadingTable,
        isSuccess: successTable,
        refetch: refetchTable,
    } = useFetchDeliveryTotalSalesQuery({
        fc: selectedFC,
        route: selectedRoute,
        startDate: startDate,
        endDate: endDate,
        page: currentPage,
        page_size: pageSize,
    });
    const {
        data: fcData,
        error: fcError,
        isLoading: fcLoading,
        isSuccess: fcSuccess,
      } = useFetchFCListQuery();
      const {
        data: routeData,
        error: routeError,
        isLoading: loadingRoute,
        isSuccess: routeSucess,
      } = useFetchRouteListQuery({
        page: 1,
        page_size: 50,
      });

      const [isRefreshing, setIsRefreshing] = useState(false);

      const handleClickRefresh = () => {
        setIsRefreshing(true);

        // Trigger the refetch function
        refetch();
        refetchTable();

        // Disable the button after 2 seconds
        setTimeout(() => {
          setIsRefreshing(false);
        }, 2000);
      };

      const handlePreviousPage = () => {
        setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
      };

      const handleNextPage = () => {
        setCurrentPage((prevPage) => prevPage + 1);
      };

    function formatNumber(num) {
        if (num >= 1000000) {
          return Math.floor(num / 1000000) + "M";
        } else if (num >= 1000) {
          return Math.floor(num / 1000) + "k";
        }
        return num;
      }


    useEffect(() => {
        if (isSuccess && data) {
            // Process the fetched data to match the format expected by the chart library
            const formattedData = {};
            for (let i = 1; i <= 6; i++) {
                formattedData[i.toString()] = [];
            }
            data.results.forEach(entry => {
                const cageNumber = entry.cage_number.toString(); // Convert to string to use as key
                formattedData[cageNumber].push({
                    x: new Date(entry.delivery_date_only), // Assuming delivery_date_only is a date string
                    y: Math.floor(entry.summary_cost,)
                });
            });
            setSalesData(formattedData);
        }
    }, [isSuccess, data]);

    const handleStartDateChange = (event) => {
        const selectedStartDate = event.target.value;
        setStartDate(selectedStartDate);
        setEndDate("");

        // Ensure end date is not before start date
        if (endDate && selectedStartDate > endDate) {
          setEndDate(selectedStartDate);
        }
      };

      const handleEndDateChange = (event) => {
        const selectedEndDate = event.target.value;
        setEndDate(selectedEndDate);
      };

      const handleFCChange = (value) => {
        const fcId = parseInt(value, 10);
        setSelectedFC(fcId);
      };

      const handleRouteChange = (value) => {
        const rID = parseInt(value, 10);
        setSelectedRoute(rID);
      };

    const chartSeries = Object.keys(salesData).map(cageNumber => ({
        name: `Cage ${cageNumber}`,
        data: salesData[cageNumber],
    }));

    const chartConfig = {
        type: "line",
        height: 350,
        width: '100%',
        series: chartSeries,
        options: {
            chart: {
                toolbar: {
                    show: false,
                },
            },
            title: {
                show: "",
            },
            dataLabels: {
                enabled: false,
            },
            colors: ["#48BB78", "#FF5733", "#1E90FF", "#FFC300", "#8A2BE2", "#FF1493"], // Set colors for each cage
            stroke: {
                lineCap: "round",
                curve: "smooth",
            },
            markers: {
                size: 0,
            },
            xaxis: {
                type: "datetime",
                labels: {
                    style: {
                        colors: "#48BB78",
                        fontSize: "12px",
                        fontFamily: "inherit",
                        fontWeight: 400,
                    },
                },
            },
            yaxis: {
                labels: {
                    style: {
                        colors: "#48BB78",
                        fontSize: "12px",
                        fontFamily: "inherit",
                        fontWeight: 400,
                    },
                },
            },
            grid: {
                show: true,
                borderColor: "#48BB78",
                strokeDashArray: 5,
                xaxis: {
                    lines: {
                        show: true,
                    },
                },
                padding: {
                    top: 5,
                    right: 20,
                },
            },
            fill: {
                opacity: 0.8,
            },
            tooltip: {
                theme: "dark",
            },
        },
    };

    return (
        <>
        <div style={{ width: '100%', display: 'flex' }}>
            <Card className="w-full ml-2">
                <CardHeader
                    floated={false}
                    shadow={false}
                    color="transparent"
                    className="flex flex-col gap-2 rounded-none md:flex-row md:items-center"
                >
                    {/* <div className="w-max rounded-lg bg-green-900 p-5 text-white">
                    </div> */}

                    <div>
                    <Typography variant="h6" color="blue-gray">
                        Delivery Cage Sales Graph
                    </Typography>
                    <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto mt-2">

              <div className="col-span-2 space-y-5">
              <div className={inputClasses}>
              <Input
                label="Start Date"
                type="date"
                id="start-date"
                value={startDate}
                onChange={handleStartDateChange}
                />
                </div>
                <div className={inputClasses}>
              <Input
                label="End Date"
                type="date"
                id="end-date"
                value={endDate}
                onChange={handleEndDateChange}
                min={startDate} // Set minimum date for end date to the selected start date
                disabled={!startDate}
                />
                </div>

              <div className="col-span-2 space-y-5">

                <div className="flex w-72 flex-col gap-6 mt-5 relative z-50">
                    {routeSucess && (
                        <Select
                        label="Route"
                        onChange={handleRouteChange}
                        value={selectedRoute ? `${selectedRoute}` : null}
                        variant="outlined"
                        lockScroll={true}
                        >
                        {routeData.results.map((route) => (
                            <Option key={route.id} value={`${route.id}`}>
                            {route.name}
                            </Option>
                        ))}
                        </Select>
                    )}
                    </div>
                    {/* <div className="flex w-72 flex-col gap-6 mt-2 relative z-50">
                {fcSuccess && (
                    <Select
                    label="FC"
                    onChange={handleFCChange}
                    value={selectedFC ? `${selectedFC}` : null}
                    variant="outlined"
                    lockScroll={true}
                    >
                    {fcData.data.map((fc) => (
                        <Option key={fc.id} value={`${fc.id}`}>
                        {fc.name}
                        </Option>
                    ))}
                    </Select>
                )}
              </div> */}
              </div>
        </div>
        </div>
        </div>

                </CardHeader>
                <CardBody className="px-2 pb-0">
                    {/* {data && isSuccess &&( */}
                    <Chart {...chartConfig} />
                </CardBody>
            </Card>
        </div>

        <div className="px-4 pt-3 pb-4 flex-1">
{/* <strong className=" text-blue-gray-700 font-bold text-xl">
  Detailed Delivery Cage Sales
</strong> */}
<Card className="h-full w-full overflow-scroll p-2">
  <table className="w-full min-w-max table-auto text-left ">
    <thead>
      <tr>
        <th className={th_class}>DTN</th>
        <th className={th_class}>Cage #</th>
        <th className={th_class}>Date</th>
        <th className={th_class}>Sales Value</th>
      </tr>
    </thead>
    <tbody>
  {successTable && dataTable &&(
    dataTable.results.map((del) => (
      <tr key={del.id} className={trclasses}>
        <td className={trclasses}>
          <Typography>
            {del.delivery_number}
          </Typography>
        </td>
        <td className={trclasses}>
          <Typography>
            {del.cage_number}
          </Typography>
        </td>
        <td className={trclasses}>
          <Typography>
            {format(new Date(del.delivery_date_only), "dd MMM yyyy")}
          </Typography>
        </td>
        <td className={trclasses}>
          <Typography>
            ₱{del.summary_cost.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            })}
          </Typography>
        </td>
        <td className={trclasses}>
          <Typography> .</Typography>
        </td>
      </tr>
    ))
  )}
</tbody>

  </table>
</Card>
<CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
          <Typography variant="small" color="blue-gray" className="font-normal">
            Page {`${currentPage} of ${Math.ceil(dataTable?.count / pageSize) || 1}`}
          </Typography>
          <div className="flex gap-2">
            <Button
              variant="outlined"
              size="sm"
              onClick={handlePreviousPage}
              disabled={!dataTable?.previous}
            >
              Previous
            </Button>
            <Button
              variant="outlined"
              size="sm"
              onClick={handleClickRefresh}
              disabled={isRefreshing}
            >
              Refresh
            </Button>

            <Button
              variant="outlined"
              size="sm"
              onClick={handleNextPage}
              disabled={!dataTable?.next}
            >
              {data?.next ? "Next" : "No More Pages"}
            </Button>
          </div>
        </CardFooter>
</div>
</>
    );
}
