import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
const apiDomain = process.env.REACT_APP_API_DOMAIN;
const token = localStorage.getItem("mytoken");

const vendorApi = createApi({
  reducerPath: "vendor",
  baseQuery: fetchBaseQuery({
    baseUrl: apiDomain,
  }),
  endpoints(builder) {
    return {
      fetchVendor: builder.query({
        query: () => {
          return {
            url: "/api/warehouse/vendor/vendor_item_list/",
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      fetchVendorList: builder.query({
        query: ({ pageSize, page, search}) => {
          return {
            url: `/api/warehouse/vendor/vendor_list_page/?&page=${page}&page_size=${pageSize}&search_str=${search}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      filterVendorItemList: builder.query({
        query: ({ item, pageSize, page, orderBy = "id", orderDir = "asc" }) => {
          return {
            url: `/api/warehouse/vendor/filter_vendor_item_page/?item=${item}&page=${page}&page_size=${pageSize}&order_by=${orderBy}&order_dir=${orderDir}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      addVendor: builder.mutation({
        query: (updatedInput) => {
          return {
            url: "/api/warehouse/vendor/add_vendor/",
            headers: {
              Authorization: "token " + token,
              "Content-Type": "application/json",
            },
            method: "POST",
            body: updatedInput,
          };
        },
      }),
      updateReceivingReport: builder.mutation({
        query: (form) => {
          return {
            url: "/api/warehouse/request/update_receiving_report/",
            headers: {
              Authorization: "token " + token,
              "Content-Type": "application/json",
            },
            method: "POST",
            body: form,
          };
        },
      }),
      detVendorItemList : builder.query({
        query: ({ search_str, vendor_id, pageSize, page, orderBy = "id", orderDir = "asc" }) => {
          return {
            url: `/api/warehouse/vendor/${vendor_id}/det_vendor_item_list/?ipage=${page}&page_size=${pageSize}&order_by=${orderBy}&order_dir=${orderDir}&search=${search_str}`,
            headers: { Authorization: "token " + token },
            method: "GET",
          };
        },
      }),
      updateVendorItem: builder.mutation({
        query: (form) => {
          return {
            url: "/api/warehouse/vendor/update_vendor_item/",
            headers: {
              Authorization: "token " + token,
            },
            method: "POST",
            body: form,
          };
        },
      }),
    };
  },
});
export const {
  useFetchVendorQuery,
  useAddVendorMutation,
  useFetchVendorListQuery,
  useFilterVendorItemListQuery,
  useUpdateReceivingReportMutation,
  useDetVendorItemListQuery,
  useUpdateVendorItemMutation,
} = vendorApi;
export { vendorApi };
