import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Input,
  Typography,
} from "@material-tailwind/react";
import { useState } from "react";

import { HashLoader } from "react-spinners";
import {
  useFetchRequestVendorItemQuery,
  useRequestItemVendorApproveMutation,
  useUpdateItemRequestMutation,
} from "../../../../store";
import DatePicker from "react-datepicker";
import { ToastContainer, toast } from "react-toastify";
import VendorFieldPRForm from "../../../table_components/Warehouse/PurchaseOrder/VendorFieldPRForm";
import { AdjustedQuantityApprovalForm } from "./AdjustedQuantityApprovalForm";

const PurchaseRequestItemVendorView = ({ po_id, refetchRIList }) => {
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [inputFlag, setInputFlag] = useState(true)

  // function for modal opening
  const openViewRequestModal = () => {
    setOpen(!open);
  };
  const handleOpen = () => setOpen(!open);
  const handleOpenError = () => setOpenError(!openError);

  // useState for date format
  const [itemDate, setItemDate] = useState(new Date());
  const [requestDate, setRequestDate] = useState(new Date());
  const [togglePicker, setTogglePicker] = useState(false);

  const [dropDown, setDropDown] = useState({
    category: "",
    department: "",
  });

  const vendorTableHeader = [
    "Selected Vendor",
    "Required Quantity",
    "Previous Price",
    "Previous Price pe kilos",
    "Quoted Price",
    "Payment Terms (in days)",
    "Commited Date",
    "File",
  ];
  const categoryList = [
    {
      id: "1",
      name: "Raw Materials",
    },
    {
      id: "2",
      name: "Office Supplies",
    },
    {
      id: "3",
      name: "Hardware",
    },
  ];
  //function for selecting the category and department dropdown
  function handleChangeDropdown(e) {
    setDropDown({ ...dropDown, [e.target.name]: e.target.value });
  }

  const [selectItemId, setSelectItemId] = useState([]);
  const [selectItemName, setSelectItemName] = useState([]);

  // function for selecting item from child component through checkbox
  const handleSelect = (values1, values2) => {
    setSelectItemId(values1);
    setSelectItemName(values2);
  };

  // function for check the input value
  const handleInputFlag = (value) => {
    if (value === true){
      setInputFlag(false)
    }
  };
  // for toggling the date picker
  const handleToggle = () => setTogglePicker(!togglePicker);

  const selectedItemsArray = Array.isArray(selectItemName)
    ? selectItemName
    : [];

  const insideFormTblHeadView = [
    "Items",
    "Unit Measure",
    "Total Required Quantity",
    "Delivery Date",
    "Vendor",
    "Action",
    "Remarks",
  ];
  const trclasses = "p-4 border-b border-blue-gray-50";

  const fetch_request_item = useFetchRequestVendorItemQuery(po_id);

  // Save Request Item remark
  const [updateItemRequest] = useUpdateItemRequestMutation();
  const handleSaveRemark = async (e, id) => {
    let ir_form = {
      id: id,
      suggested_remark: e,
    };
    const uir = await updateItemRequest(ir_form).unwrap();
    if (uir.success === true) {
      toast.success(uir.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(uir.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetchRIList();
    fetch_request_item.refetch();
  };

  // Approve Request Item vendor

  const [requestItemVendorApprove] = useRequestItemVendorApproveMutation();
  const approveRIV = async () => {
    let riva_form = {
      request_id: po_id,
    };

    const riva = await requestItemVendorApprove(riva_form).unwrap();
    if (riva.success === true) {
      toast.success(riva.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    } else {
      toast.error(riva.message, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
      });
    }
    refetchRIList();
    fetch_request_item.refetch();
    handleOpenError();
  };

  let content;
  let request_item_flag = false;
  //fetching of data from api
  if (fetch_request_item.isLoading) {
    content = <HashLoader />;
  } else if (fetch_request_item.error) {
    content = <div>Error Loading...</div>;
  } else {
    let result = fetch_request_item.data.data;
    request_item_flag = result.items
      .map((ri) => ri.status === 2)
      .includes(true);
    content = (
      <>
        <div className="grid grid-flow-row col-span-2 md:grid-cols-2 items-center gap-2 mx-5 mb-10">
          <div className="relative h-10 w-full">
            {/* <Typography>PO Number: {result.po_number}</Typography> */}
            <Input
              label="PO Number:"
              readOnly defaultValue={result.po_number}>
            </Input>
          </div>
          <div className="relative h-10">
            <DatePicker
              className="bordered border p-1 border-blue-gray-300 rounded-lg w-[140%] md:[100%]"
              selected={new Date(result.date)}
              value={requestDate}
              onChange={setRequestDate}
              dateFormat="yyyy/MM/dd"
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              onInputClick={handleToggle}
              onClickOutside={handleToggle}
              open={togglePicker}
              dropdownMode="select"
              placeholderText="Select"
              disabled
            />
          </div>
          <div className="relative h-10 w-full">
            <select
              label="Department"
              disabled
              className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
            >
              <option
                value="Warehouse"
                checked={result.department === "Warehouse" ? "checked" : ""}
              >
                Warehouse
              </option>
              <option
                value="Production"
                checked={result.department === "Production" ? "checked" : ""}
              >
                Production
              </option>
              <option
                value="Sales"
                checked={result.department === "Sales" ? "checked" : ""}
              >
                Sales
              </option>
              <option
                value="Administration"
                checked={
                  result.department === "Administration" ? "checked" : ""
                }
              >
                Administration
              </option>
              <option
                checked={result.department === "IT" ? "checked" : ""}
                value="IT"
              >
                IT
              </option>
            </select>
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              Department
            </label>
          </div>
          <div className="relative h-10 w-full">
            <select
              label="Category"
              name="category"
              disabled
              onChange={handleChangeDropdown}
              className="peer h-full w-full rounded-[7px] border border-blue-gray-200 border-t-transparent bg-transparent px-3 py-2.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border placeholder-shown:border-blue-gray-200 placeholder-shown:border-t-blue-gray-200 empty:!bg-gray-900 focus:border-2 focus:border-gray-900 focus:border-t-transparent focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50"
            >
              {categoryList.map((cl, i) => (
                <option key={i} value={cl.id}>
                  {cl.name}
                </option>
              ))}
            </select>
            <label className="before:content[' '] after:content[' '] pointer-events-none absolute left-0 -top-1.5 flex h-full w-full select-none text-[11px] font-normal leading-tight text-blue-gray-400 transition-all before:pointer-events-none before:mt-[6.5px] before:mr-1 before:box-border before:block before:h-1.5 before:w-2.5 before:rounded-tl-md before:border-t before:border-l before:border-blue-gray-200 before:transition-all after:pointer-events-none after:mt-[6.5px] after:ml-1 after:box-border after:block after:h-1.5 after:w-2.5 after:flex-grow after:rounded-tr-md after:border-t after:border-r after:border-blue-gray-200 after:transition-all peer-placeholder-shown:text-sm peer-placeholder-shown:leading-[3.75] peer-placeholder-shown:text-blue-gray-500 peer-placeholder-shown:before:border-transparent peer-placeholder-shown:after:border-transparent peer-focus:text-[11px] peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:before:border-t-2 peer-focus:before:border-l-2 peer-focus:before:border-gray-900 peer-focus:after:border-t-2 peer-focus:after:border-r-2 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:before:border-transparent peer-disabled:after:border-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
              Category
            </label>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-16 m-4">
          <div className="overflow-x-auto">
            <table className="table-auto w-full min-w-max text-left">
              <thead>
                <tr>
                  <th className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"></th>
                  {insideFormTblHeadView.map((head, i) => (
                    <th
                      key={("requiredqty", i)}
                      className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                    >
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal leading-none opacity-70"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {result.items.map((ri, index) => {
                  return (
                    <tr key={index}>
                      <td className={trclasses}></td>
                      <td className={trclasses}>{ri.item.name}</td>
                      <td className={trclasses}>
                        <Typography className=" text-center">
                          {ri.stan_um_measure}
                        </Typography>
                      </td>
                      <td className={trclasses}>
                        <Typography className=" text-center">
                          {ri.des_required_qty}
                        </Typography>
                      </td>
                      <td className={trclasses}>
                        <DatePicker
                          className="bordered border p-1 border-blue-gray-300 rounded-lg"
                          selected={
                            ri.delivery_date === ""
                              ? itemDate
                              : new Date(ri.delivery_date)
                          }
                          dateFormat="yyyy/MM/dd"
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="Select"
                          disabled
                        />
                      </td>
                      <td className={trclasses}>
                        <VendorFieldPRForm
                          vendorTableHeader={vendorTableHeader}
                          po_id={result.id}
                          item_id={ri.item.id}
                          suggested_vendor={ri.suggested_vendor}
                          refetchRequestItemList={fetch_request_item.refetch}
                          required_qty={ri.required_qty}
                          des_required_qty={ri.des_required_qty}
                          stock_on_hand={ri.stock_on_hand}
                          stock_level={ri.stock_level}
                          inputFlag={handleInputFlag}
                        />
                      </td>
                      <td className={trclasses}>
                        <AdjustedQuantityApprovalForm
                          request_item_id={ri.id}
                          item_name={ri.item.name}
                          ri_status={ri.status}
                          po_number={result.po_number}
                          unit_measurement={ri.item.unit_measurement}
                          data_refetch={fetch_request_item.refetch}
                        />
                      </td>
                      <td className={trclasses}>
                        <textarea
                          placeholder="Enter your remarks here..."
                          name=""
                          id=""
                          cols="20"
                          rows="5"
                          className="border-2 border-blue-gray-500 text-black p-2"
                          autoFocus={false}
                          onBlur={(e) => {
                            handleSaveRemark(e.target.value, ri.id);
                          }}
                        >
                          {ri.suggested_remark}
                        </textarea>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <ToastContainer />
          </div>
        </div>
      </>
    );
  }

  const handle_submit_disable = () => {
    if (inputFlag === true || request_item_flag === true){
      return true
    }
    return false
  }

  return (
    <>
      <Button
        className="flex items-center space-x-1"
        color="teal"
        size="sm"
        onClick={handleOpen}
      >
        <span>View Details</span>
      </Button>
      <Dialog
        open={open}
        size="xxl"
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="bg-[#0B6E4F] rounded-sm flex justify-center">
          <Typography className="text-white" variant="h5">
            Purchase Request Form
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto mt-10">{content}</DialogBody>
        <DialogFooter className="mt-14">
          <Button
            variant="text"
            color="red"
            className="mr-1"
            onClick={openViewRequestModal}
          >
            <span>Cancel</span>
          </Button>
          <Button
            className="bg-green-900"
            size="sm"
            onClick={handleOpenError}
            disabled={handle_submit_disable()}
          >
            Submit
          </Button>
        </DialogFooter>
      </Dialog>
      {/* Error trap modal */}
      <Dialog open={openError} handler={handleOpenError}>
        <DialogHeader>Approve PO Order</DialogHeader>
        <DialogBody>Are you sure you'd like to proceed?</DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpenError}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="green" onClick={approveRIV}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
};

export default PurchaseRequestItemVendorView;
