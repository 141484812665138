import React, { useState } from "react";
import SignatureCanvas from "react-signature-canvas";
import { GrPowerReset } from "react-icons/gr";
import { Typography } from "@material-tailwind/react";
const boxResetClasses =
  "flex justify-center items-center h-[105px]  w-[60px] bg-[#0B6E4F] shadow-md rounded-r-xl";

function ApprovalSignatureCanvas({
  assignatories,
  fetchSignature,
  saveSignature,
  approve_type,
  account_detailed
}) {
  let signatureCanvas = [];
  let requestor_sig_view;
  let plant_signature_view;
  let trea_signature_view;
  let gm_signature_view;

  let requestor_sig_filter = fetchSignature.filter((elm) => elm.requestor_sig != null)
  if (requestor_sig_filter.length > 0){
    requestor_sig_view = <>
        <div className="flex flex-col justify-center items-center mt-10 space-y-3">
          <div className="border border-blue-gray-300 rounded-lg py-1 px-2">
            <img src={requestor_sig_filter[0].requestor_sig} alt="" />
          </div>
          <Typography>{requestor_sig_filter[0].full_name}</Typography>
          <Typography>Custodian</Typography>
        </div>
    </>
  }

  let plant_sig_filter = fetchSignature.filter((elm) => elm.pm_sig != null)
  if (plant_sig_filter.length > 0){
    plant_signature_view = <>
      <div className="flex flex-col justify-center items-center mt-10 space-y-3">
        <div className="border border-blue-gray-300 rounded-lg py-1 px-2">
          <img src={plant_sig_filter[0].pm_sig} alt="" />
        </div>
        <Typography>{plant_sig_filter[0].full_name}</Typography>
        <Typography>Plant Manager</Typography>
      </div>
    </>
  }else{
    if (account_detailed.job_position === 1){
      signatureCanvas.push(
        {
          id: "1",
          label: "canvas1",
          ref: React.createRef(),
          assignatories: "Plant Manager",
        }
      )
    }
  }

  if (approve_type == 2){
    let treasury_sig_filter = fetchSignature.filter((elm) => elm.treasury_sig != null)
    if (treasury_sig_filter.length > 0){
      trea_signature_view = <>
        <div className="flex flex-col justify-center items-center mt-10 space-y-3">
          <div className="border border-blue-gray-300 rounded-lg py-1 px-2">
            <img src={treasury_sig_filter[0].treasury_sig} alt="" />
          </div>
          <Typography>{treasury_sig_filter[0].full_name}</Typography>
          <Typography>Treasury Manager</Typography>
        </div>
      </>
    }else{
      if (account_detailed.job_position === 14){
        signatureCanvas.push(  {
          id: "3",
          label: "canvas1",
          ref: React.createRef(),
          assignatories: "Treasury Manager",
        })
      }
    }
  }

  if (assignatories.toString() === "Hardware") {
    let gm_signature_filter = fetchSignature.filter((elm) => elm.gm_sig != null)
    console.log(gm_signature_filter);
    if (gm_signature_filter.length > 0){
      gm_signature_view = <>
        <div className="flex flex-col justify-center items-center mt-10 space-y-3">
          <div className="border border-blue-gray-300 rounded-lg py-1 px-2">
            <img src={gm_signature_filter[0].gm_sig} alt="" />
          </div>
          <Typography>{gm_signature_filter[0].full_name}</Typography>
          <Typography>General Manager</Typography>
        </div>
      </>
    }else{
      if (account_detailed.job_position === 13){
        signatureCanvas.push(
          {
            id: "2",
            label: "canvas2",
            ref: React.createRef(),
            assignatories: "General Manager",
          }
        )
      }
    }
  }

  const [signature, setSignature] = useState(signatureCanvas);

  const handleSave = () => {
    let result = [];
    signature.map((sig) => {
      if(!sig.ref.current.isEmpty()){
        let sigi = sig.ref.current.getTrimmedCanvas().toDataURL()
        let sigi_split = sigi.split(',')
        result.push({
          "assignatories": sig.assignatories,
          "dataURLs" : sigi_split[1]
        })
      }
        return result
      });

    // Do something with dataURLs array, for example, log it
    saveSignature(result);
  };

  const clearSignature = (canvasRef) => {
    canvasRef.current.isEmpty() ? console.log("") : canvasRef.current.clear();
  };

  return (
    <>
      <div className="flex flex-row justify-center items-center space-x-3">
        {requestor_sig_view}
        {plant_signature_view}
        {gm_signature_view}
        {trea_signature_view}
        {signature.map((sig) => (
          <div key={sig.id} className="flex flex-col">
            <div className="flex flex-row mt-8">
              <SignatureCanvas
                penColor="black"
                canvasProps={{
                  width: 200,
                  height: 100,
                  className: "border-black border-2 rounded-l-xl",
                }}
                ref={sig.ref}
                onEnd={handleSave}
              />
              <div className={boxResetClasses}>
                <GrPowerReset
                  size={"30px"}
                  onClick={() => clearSignature(sig.ref)}
                  className="cursor-pointer"
                  color="white"
                />
              </div>
            </div>
            <Typography className="text-center mt-3">
              {sig.assignatories}
            </Typography>
          </div>
        ))}
      </div>
    </>
  );
}

export default ApprovalSignatureCanvas;
