import { useState } from "react";
import { Card, Typography } from "@material-tailwind/react";
import { LuPackagePlus, LuClipboardList, LuFactory } from "react-icons/lu";
import { Link, Outlet } from "react-router-dom";
import Sidebar from "../page_components/Sidebar";
import TopLogo from "./../../img/BurgerMachineLogo.jpg";
import { MdOutlineWarehouse } from "react-icons/md";
import { TbTruckDelivery, TbTransferIn } from "react-icons/tb";
import { FaArrowRightArrowLeft, FaCheckToSlot, FaStore } from "react-icons/fa6";
import { RiInboxArchiveFill } from "react-icons/ri";
import { VscNotebook } from "react-icons/vsc";
import { HiPresentationChartBar } from "react-icons/hi";
import SmallScreenNav from "../page_components/SmallScreenNav";
import Img from "./../../img/welcomeImg.png";
import { BsPatchQuestion } from "react-icons/bs";
function Base() {
  const [open, setOpen] = useState(false);
  const [cardClass, setCardClass] = useState(
    "hidden absolute bottom-16 w-52 bg-[#137944]"
  );
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

  // toggle the sub navigation for warehouse
  const handleOpen = () => {
    setOpen(!open);
    open
      ? setCardClass("block absolute bottom-16 w-52 bg-[#137944]")
      : setCardClass("hidden absolute bottom-16 w-52 bg-[#137944]");
  };
  return (
    <>
      <div className="relative">
        <div className="grid grid-cols-5 gap-1">
          <div className="fixed top-0  z-20 w-full">
            <div className=" bg-[#137944] hidden lg:hidden md:block">
              <img
                src={TopLogo}
                className="border-bottom-white border-b-8 h-20"
                alt="toplogo"
              />
            </div>
            <div className="md:hidden z-10">
              <SmallScreenNav />
            </div>
          </div>
          <div className="hidden fixed left-0 lg:block lg:col-span-1 bg-[#137944] h-full z-30">
            <Sidebar />
          </div>
          <div className="col-span-5 ml-0 lg:ml-80 mt-28 lg:mb-5 lg:mt-5 z-0">
            <Outlet />
          </div>
          <div className="hidden md:block w-full fixed bottom-0 z-10">
            <div
              id="bottom-navigation"
              className="lg:hidden block z-10 bg-white shadow"
            >
              <div
                id="tabs"
                className="flex justify-between items-center bg-[#137944] p-4 "
              >
                <div className="flex flex-col justify-center items-center w-full focus:text-teal-500 hover:text-teal-500">
                  {account_detailed &&
                    [1, 13, 23, 14].includes(account_detailed.job_position) && (
                      <Link to="/base/dashboard">
                        <div className=" flex flex-col items-center">
                          <HiPresentationChartBar className="h-7 w-7  text-white " />
                          <span className="tab tab-home block text-[13px] text-white">
                            Dashboard
                          </span>
                        </div>
                      </Link>
                    )}
                </div>
                <div className="relative  flex flex-col justify-center items-center w-full">
                  {account_detailed &&
                    [1, 13, 23, 5, 6, 7, 8, 5, 9, 10, 11, 19].includes(
                      account_detailed.job_position
                    ) && (
                      <div
                        className="flex flex-col justify-center items-center"
                        onClick={handleOpen}
                      >
                        <MdOutlineWarehouse className="h-7 w-7 text-white " />
                        <span className="tab tab-home block text-[13px] text-white">
                          Warehouse
                        </span>
                      </div>
                    )}
                  <Card className={cardClass}>
                    <div className="p-6">
                      {account_detailed &&
                        [1, 13, 23, 14, 5, 17].includes(
                          account_detailed.job_position
                        ) && (
                          <Link to="/base/warehouse_purchaseorder">
                            <div
                              className="flex space-x-6 items-center"
                              onClick={handleOpen}
                            >
                              <LuPackagePlus className="h-5 w-5  text-white" />
                              <span className="tab tab-home block text-[14px] text-white">
                                Purchase Order
                              </span>
                            </div>
                          </Link>
                        )}
                      {account_detailed &&
                        [1, 13, 23, 14, 2, 3].includes(
                          account_detailed.job_position
                        ) && (
                          <Link to="/base/warehouse_raw_materials_delivery">
                            <div
                              className="flex space-x-6 items-center mt-4"
                              onClick={handleOpen}
                            >
                              <TbTruckDelivery className="h-5 w-5  text-white" />
                              <span className="tab tab-home block text-[14px] text-white">
                                Receiving Report
                              </span>
                            </div>
                          </Link>
                        )}
                      {account_detailed &&
                        [1, 13, 23, 5, 6, 7, 8, 5, 9, 10, 11, 19].includes(
                          account_detailed.job_position
                        ) && (
                          <Link to="/base/warehouse_transfer">
                            <div
                              className="flex space-x-6 items-center mt-4"
                              onClick={handleOpen}
                            >
                              <TbTransferIn className="h-5 w-5  text-white" />
                              <span className="tab tab-home block text-[14px] text-white">
                                Transfer
                              </span>
                            </div>
                          </Link>
                        )}
                      {account_detailed &&
                        [1, 13, 23, 5, 6, 7, 8, 9, 19].includes(
                          account_detailed.job_position
                        ) && (
                          <Link to="/base/warehouse_summary">
                            <div
                              className="flex space-x-6 items-center mt-4"
                              onClick={handleOpen}
                            >
                              <LuClipboardList className="h-5 w-5  text-white" />
                              <span className="tab tab-home block text-[14px] text-white">
                                Inventory
                              </span>
                            </div>
                          </Link>
                        )}
                    </div>
                  </Card>
                </div>
                {account_detailed &&
                  [1, 13, 10, 11, 12, 9, 23, 19].includes(
                    account_detailed.job_position
                  ) && (
                    <div className="flex flex-col justify-center items-center w-full focus:text-teal-500 hover:text-teal-500">
                      <Link to="/base/production">
                        <div className=" flex flex-col items-center">
                          <LuFactory className="h-7 w-7  text-white " />
                          <span className="tab tab-home block text-[13px] text-white">
                            Production
                          </span>
                        </div>
                      </Link>
                    </div>
                  )}
                {account_detailed &&
                  [1, 13, 20, 4, 3, 2, 15, 16].includes(
                    account_detailed.job_position
                  ) && (
                    <div className="flex flex-col justify-center items-center w-full focus:text-teal-500 hover:text-teal-500">
                      <Link to="/base/delivery">
                        <div className=" flex flex-col items-center">
                          <FaArrowRightArrowLeft className="h-6 w-6  text-white " />
                          <span className="tab tab-home block text-[13px] text-white">
                            Delivery
                          </span>
                        </div>
                      </Link>
                    </div>
                  )}
                {account_detailed &&
                  [1, 13, 20, 21, 3, 2, 24, 22].includes(
                    account_detailed.job_position
                  ) && (
                    <div className="flex flex-col justify-center items-center w-full focus:text-teal-500 hover:text-teal-500">
                      <Link to="/base/outlet">
                        <div className=" flex flex-col items-center">
                          <FaStore className="h-6 w-6  text-white " />
                          <span className="tab tab-home block text-[13px] text-white">
                            Outlet
                          </span>
                        </div>
                      </Link>
                    </div>
                  )}
                {account_detailed &&
                  [1, 13, 14].includes(account_detailed.job_position) && (
                    <div className="flex flex-col justify-center items-center w-full focus:text-teal-500 hover:text-teal-500">
                      <Link to="/base/approval">
                        <div className=" flex flex-col items-center">
                          <FaCheckToSlot className="h-6 w-6  text-white " />
                          <span className="tab tab-home block text-[13px] text-white">
                            Approval
                          </span>
                        </div>
                      </Link>
                    </div>
                  )}
                {account_detailed &&
                  [1, 13].includes(account_detailed.job_position) && (
                    <div className="flex flex-col justify-center items-center w-full focus:text-teal-500 hover:text-teal-500">
                      <Link to="/base/archive">
                        <div className=" flex flex-col items-center">
                          <RiInboxArchiveFill className="h-6 w-6  text-white " />
                          <span className="tab tab-home block text-[13px] text-white">
                            Archive
                          </span>
                        </div>
                      </Link>
                    </div>
                  )}
                {account_detailed &&
                  [1, 13].includes(account_detailed.job_position) && (
                    <div className="flex flex-col justify-center items-center w-full focus:text-teal-500 hover:text-teal-500">
                      <Link to="/base/logs">
                        <div className=" flex flex-col items-center">
                          <VscNotebook className="h-6 w-6  text-white " />
                          <span className="tab tab-home block text-[13px] text-white">
                            Logs
                          </span>
                        </div>
                      </Link>
                    </div>
                  )}
                <div className="flex flex-col justify-center items-center w-full focus:text-teal-500 hover:text-teal-500">
                  {account_detailed &&
                    [1, 13, 23, 14].includes(account_detailed.job_position) && (
                      <Link to="/base/kb-faqs">
                        <div className=" flex flex-col items-center">
                          <BsPatchQuestion className="h-8 w-8  text-white " />
                          <span className="tab tab-home block text-[13px] text-white">
                            KB
                          </span>
                        </div>
                      </Link>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Base;
