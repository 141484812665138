import React, { useState } from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Button } from "@material-tailwind/react";
import { useFetchOrderSummaryPDFDetailsQuery } from "../../../store/apis/DeliveryApi";

const OrderCompleteSumPDF = ({ getOrderId }) => {
  let result = []; // Added missing declaration for result variable
  const [isPDFLoading, setIsPDFLoading] = useState(false);
  const { data, isError, isLoading, isSuccess } =
    useFetchOrderSummaryPDFDetailsQuery(getOrderId); // Changed getDelId to getOrderId
  const apiDomain = process.env.REACT_APP_API_DOMAIN;
  if (isLoading) {
    return <div>Loading...</div>;
  } else if (isError) {
    return <div>Error loading data</div>;
  } else if (isSuccess) {
    result = data.data;
    console.log(result);
  }

  const generatePDF = async () => {
    setIsPDFLoading(true);

    try {
      const deliveryDate = result.delivery_date.split("T")[0];
      const dateParts = deliveryDate.split("-");
      const formattedDeliveryDate = new Intl.DateTimeFormat("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        timeZone: "Asia/Hong_Kong",
      }).format(new Date(`${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`));

      const categoryMap = {
        1: "Product",
        2: "Beverages",
        3: "Buns",
        4: "Wrappers",
        5: "Sundries",
        6: "Cigarettes",
        7: "Office Supp.",
        8: "Tank",
      };

      let signatureCageCustodian =
        result.delivery_tracking_number.cage_custodian_signature;
      let signatureWarehouse =
        result.delivery_tracking_number.warehouse_custodian_signature;
      // let signatureDriver = result.delivery_tracking_number.driver_signature;
      // let signatureReceiver = result.delivery_tracking_number.custodian_signature;
      let signaturePlantManager =
        result.delivery_tracking_number.transfer.signature_plant_manager;
      let signatureReceiver = result.receiver_signature;
      let signatureDriver = result.driver_signature;

      const pdfDoc = new jsPDF();

      // Add logo and title
      // pdfDoc.addImage("/images/burger_machine_logo.png", "PNG", 20, 10, 50, 0);
      pdfDoc.setFontSize(20);
      pdfDoc.setTextColor(0, 128, 0);
      pdfDoc.setFont("helvetica", "bold");
      pdfDoc.text("PREMIUM BURGER & FOOD PRODUCT INC.", 30, 25);
      pdfDoc.setFontSize(12);
      pdfDoc.setTextColor(0);
      pdfDoc.setFont("helvetica", "normal");
      pdfDoc.text(
        `1185 TOPAZ ST. RAMAR VILLAGE SAN AGUSTIN CITY OF SAN FERNANDO PAMPANGA`,
        29,
        30
      );
      pdfDoc.setFont("helvetica", "bold");
      pdfDoc.text(`OUTLET ORDER RECEIPT`, 75, 37);
      pdfDoc.setFont("helvetica", "normal");
      // Add delivery information
      pdfDoc.text(`Delivery Date: ${formattedDeliveryDate}`, 20, 45);
      pdfDoc.text(
        `Delivery Number: ${result.delivery_tracking_number.delivery_number}`,
        20,
        50
      );
      pdfDoc.text(`Order Number: ${result.order_number}`, 20, 55);
      pdfDoc.text(`Address: ${result.outlet.address}`, 20, 60);
      pdfDoc.text(`Route: ${result.outlet.route.name}`, 125, 45);
      pdfDoc.text(`Outlet: ${result.outlet.outlet}`, 125, 50);

      // Add table header
      const tableStartY = 68;
      pdfDoc.autoTable({
        head: [
          [
            {
              content: "Code",
              styles: { halign: "center", valign: "middle", fontSize: 7 },
            },
            {
              content: "Name",
              styles: { halign: "center", valign: "middle", fontSize: 7 },
            },
            {
              content: "UM",
              styles: { halign: "center", valign: "middle", fontSize: 7 },
            },
            {
              content: "Ordered Quantity",
              styles: { halign: "center", valign: "middle", fontSize: 7 },
            },
            // { content: "Adjusted Qty", styles: { halign: 'center', valign: 'middle', fontSize: 7 } },
            {
              content: "Delivered Quantity",
              styles: { halign: "center", valign: "middle", fontSize: 7 },
            },
            {
              content: "Unit Price",
              styles: { halign: "center", valign: "middle", fontSize: 7 },
            },
            {
              content: "Cost",
              styles: { halign: "center", valign: "middle", fontSize: 7 },
            },
            {
              content: "Reject",
              styles: { halign: "center", valign: "middle", fontSize: 7 },
            },
            {
              content: "Missing",
              styles: { halign: "center", valign: "middle", fontSize: 7 },
            },
          ],
        ],
        body: result.items.map((item) => [
          {
            content: item.prod_code,
            styles: { halign: "center", valign: "middle", fontSize: 7 },
          },
          {
            content: item.prod_nickname,
            styles: { halign: "center", valign: "middle", fontSize: 7 },
          },
          {
            content: `${item.weight_box}/${item.product_unit_measurement}`,
            styles: { halign: "center", valign: "middle", fontSize: 7 },
          },
          // { content: `${item.stated_qty}/${item.product_unit_measurement}`, styles: { halign: 'center', valign: 'middle', fontSize: 7 } },
          {
            content: `${item.ordered_qty}/${item.product_unit_measurement}`,
            styles: { halign: "center", valign: "middle", fontSize: 7 },
          },
          {
            content: `${item.received_qty}/${item.product_unit_measurement}`,
            styles: { halign: "center", valign: "middle", fontSize: 7 },
          },
          {
            content: `PHP ${item.price}`,
            styles: { halign: "center", valign: "middle", fontSize: 7 },
          },
          {
            content: `PHP ${item.product_cost}`,
            styles: { halign: "center", valign: "middle", fontSize: 7 },
          },
          {
            content: item.reject_qty !== null ? item.reject_qty : "",
            styles: { halign: "center", valign: "middle", fontSize: 7 },
          },
          {
            content: item.missing_qty !== null ? item.missing_qty : "",
            styles: { halign: "center", valign: "middle", fontSize: 7 },
          },
        ]),
        startY: tableStartY,
        theme: "grid",
        styles: {
          fillColor: [255, 255, 255],
          textColor: [0, 0, 0],
        },
      });

      // Add remarks
      const tableHeight = pdfDoc.previousAutoTable.finalY - tableStartY;
      pdfDoc.text(
        `Total Order Cost: PHP ${result.total_cost}`,
        20,
        tableStartY + tableHeight + 10
      );

      const startX = 10;
      const startY = tableStartY + tableHeight + 40;
      const lineHeight = 20;
      const columnWidth = 60;
      const horizontalSpacing = 65;

      const driverReceiverX = startX;
      const driverReceiverY = startY;
      const driverNameWidth = pdfDoc.getTextWidth(
        `${result.delivery_tracking_number.driver.first_name} ${result.delivery_tracking_number.driver.last_name}`
      );
      const driverNameCenter =
        driverReceiverX + (columnWidth - driverNameWidth) / 2;
      pdfDoc.text(
        `${result.delivery_tracking_number.driver.first_name} ${result.delivery_tracking_number.driver.last_name}`,
        driverNameCenter,
        driverReceiverY
      );
      const driverReceiverTextWidth = pdfDoc.getTextWidth("Driver");
      const centeredDriverReceiverX =
        driverReceiverX + (columnWidth - driverReceiverTextWidth) / 2;
      pdfDoc.text("Driver", centeredDriverReceiverX, driverReceiverY + 5);
      pdfDoc.text(`__________________`, driverReceiverX + 10, driverReceiverY);

      const receiverTextWidth = pdfDoc.getTextWidth("Outlet Receiver");
      const receiverX = driverReceiverX + columnWidth + horizontalSpacing;
      const receiverWidth = pdfDoc.getTextWidth(`${result.receiver_name}`);
      const receiverCenter = receiverX + (columnWidth - receiverWidth) / 2;
      pdfDoc.text(`${result.receiver_name}`, receiverCenter, driverReceiverY);
      const centeredReceiverX =
        receiverX + (columnWidth - receiverTextWidth) / 2;
      pdfDoc.text("Outlet Receiver", centeredReceiverX, driverReceiverY + 5);
      pdfDoc.text(`__________________`, receiverX + 10, driverReceiverY);

      const custodianY = startY + lineHeight;
      const custodianTextWidth = pdfDoc.getTextWidth("Cage Custodian");
      const centeredCustodianX = 7 + (columnWidth - custodianTextWidth) / 2;
      pdfDoc.text("Cage Custodian", centeredCustodianX, custodianY + 5);
      pdfDoc.text(`__________________`, 7 + 10, custodianY);

      const imageWidth = 30;
      const imageHeight = 15;

      const plantManagerTextWidth = pdfDoc.getTextWidth("Plant Manager");
      const plantManagerNameTextWidth = pdfDoc.getTextWidth("Herman F. Gatela");
      const plantManagerX = startX + columnWidth + 5;
      const centeredPlantManagerX =
        plantManagerX + (columnWidth - plantManagerTextWidth) / 2;
      const centeredPMNameX =
        plantManagerX + (columnWidth - plantManagerNameTextWidth) / 2;
      pdfDoc.text("Plant Manager", centeredPlantManagerX, custodianY + 5);
      pdfDoc.text(`Herman F. Gatela`, centeredPMNameX, custodianY);
      pdfDoc.text(`__________________`, plantManagerX + 10, custodianY);

      const warehouseTextWidth = pdfDoc.getTextWidth("Warehouse Custodian");
      const warehouseX = plantManagerX + columnWidth + 5;
      const centeredWarehouseX =
        warehouseX + (columnWidth - warehouseTextWidth) / 2;
      pdfDoc.text("Warehouse Custodian", centeredWarehouseX, custodianY + 5);
      pdfDoc.text(`__________________`, warehouseX + 10, custodianY);

      //signatures
      // pdfDoc.addImage(signatureCageCustodian, 'PNG', centeredCustodianX, custodianY -10, imageWidth, imageHeight);
      // pdfDoc.addImage(signatureDriver, 'PNG', centeredDriverReceiverX, driverReceiverY -10, imageWidth, imageHeight);
      // pdfDoc.addImage(signatureReceiver, 'PNG', centeredReceiverX,driverReceiverY  -10, imageWidth, imageHeight);
      // pdfDoc.addImage(signaturePlantManager, 'PNG', centeredPlantManagerX,custodianY  -10, imageWidth, imageHeight);
      // pdfDoc.addImage(signatureWarehouse, 'PNG', centeredWarehouseX,custodianY  -10, imageWidth, imageHeight);

      const currentDate = new Date();
      const date_now = currentDate.toLocaleDateString("en-US", {
        timeZone: "Asia/Tokyo",
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
      });

      pdfDoc.save(
        `BM_${result.outlet.outlet}_Complete_Receipt_${result.order_number}_${date_now}.pdf`
      );
    } catch (error) {
      console.error("Error fetching or processing images:", error);
    } finally {
      setIsPDFLoading(false);
    }
  };

  return (
    <div>
      <Button
        className="bg-orange-900 mr-5"
        onClick={generatePDF}
        disabled={isPDFLoading}
      >
        {isPDFLoading ? " Loading..." : "Extract PDF"}
      </Button>
    </div>
  );
};

export default OrderCompleteSumPDF;
