import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Checkbox,
} from "@material-tailwind/react";
import { FaUserCheck } from "react-icons/fa";
import { IconButton } from "@material-tailwind/react";
import { IoArrowBackOutline, IoArrowForwardOutline } from "react-icons/io5";
import HashLoader from "react-spinners/HashLoader";
import { useState } from "react";
import { useEmployeeListQuery } from "../../../store";

function SelectEmployeeLIst({selectEmployee, employee_list_id }) {
  const [open, setOpen] = useState(false);
  const tableHead = ["", "Name", "Position"];
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));
  const [checkEmployeeId, setCheckEmployeeId] = useState([]);
  const [checkEmployeeName, setCheckEmployeeName] = useState([]);
  const [selectActive, setSelectActive] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const employeList = useEmployeeListQuery({
    client_id: account_detailed.client.id,
    page: selectActive,
    pageSize: pageSize,
    jobposition: "MOLDERREPACKER",
  });

  const totalPage = Math.ceil(employeList.data?.count / pageSize);
  // variable for data of api
  let result = [];

  // Update the state based on whether the checkbox is checked or unchecked
  const handleChange = (e) => {
    const { checked, value } = e.target;
    const newCheckboxvalue = value.split(",");
    console.log(checked);
    if (checked) {
      setCheckEmployeeId([...checkEmployeeId, parseInt(newCheckboxvalue[0])]);
      setCheckEmployeeName([
        ...checkEmployeeName,
        parseInt(newCheckboxvalue[1]),
      ]);
    } else {
      const filteredEmployee = checkEmployeeId.filter(
        (employee) => employee !== parseInt(newCheckboxvalue[0])
      );
      const filteredEmployeeName = checkEmployeeName.filter(
        (empl_name) => empl_name !== newCheckboxvalue[1]
      );
      setCheckEmployeeId(filteredEmployee);
      setCheckEmployeeName(filteredEmployeeName);
    }
  };
  // preview of pagination
  const prev = () => {
    if (selectActive === 1) return;
    setSelectActive(selectActive - 1);
  };
  // next of pagination
  const next = () => {
    if (selectActive === totalPage) return;
    setSelectActive(selectActive + 1);
  };

  // handle selection of checkbox
  const handleSelect = () => {
    selectEmployee(checkEmployeeId, checkEmployeeName);
    setOpen(!open);
  };

  // handle the page size of table
  const changePagesize = (e) => {
    setPageSize(e.target.value);
    setSelectActive(1);
  };
  // open/closing of dialog
  const handleOpen = () => setOpen(!open);
  // for data fetching
  if (employeList.isLoading) {
    return <HashLoader color="#36d7b7" />;
  } else if (employeList.data) {
    result = employeList.data.results;
  } else {
    return <div>Error Loading..</div>;
  }
  return (
    <div>
      <Button
        variant="outlined"
        color="teal"
        size="sm"
        onClick={handleOpen}
        className="mr-2"
      >
        <div className="flex items-center">
          <FaUserCheck className=" mr-1" />
          Select molder and repackers
        </div>
      </Button>
      <Dialog
        open={open}
        size="xl"
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="text-green-900">
          List of Employees
        </DialogHeader>
        <div className=" mr-10 flex justify-end space-x-4">
          <Typography>Page size:</Typography>
          <select
            defaultValue={pageSize}
            onChange={changePagesize}
            className="border-2 border-blue-gray-500"
          >
            <option value="10">10</option>
            <option value="25">25</option>
            <option value="50">50</option>
          </select>
        </div>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <table className="w-full min-w-max table-auto text-left">
            <thead>
              <tr>
                {tableHead.map((head, index) => (
                  <th
                    key={index}
                    className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                  >
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal leading-none opacity-70"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {result.map((item, index) => {
                const isLast = index === employeList.data.results.length - 1;
                let full_name = item.first_name + " " + item.last_name;
                let checkboxValue = item.id + "," + full_name;
                const classes = isLast
                  ? "p-4"
                  : "p-4 border-b border-blue-gray-50";
                return (
                  <tr key={item.id}>
                    <td className={classes}>
                      <Checkbox
                        type="checkbox"
                        color="teal"
                        value={checkboxValue}
                        id="select_vendor"
                        defaultChecked={employee_list_id.includes(item.id)}
                        onChange={handleChange}
                      />
                    </td>
                    <td className={classes}>
                      {item.first_name} {item.last_name}
                    </td>
                    <td className={classes}>{item.job_title}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </DialogBody>
        <DialogFooter className="flex justify-between">
          <div className="flex justify-center items-center gap-8 ml-[41%]">
            <IconButton
              size="sm"
              variant="outlined"
              onClick={prev}
              disabled={selectActive === 1}
            >
              <IoArrowBackOutline strokeWidth={2} className="h-4 w-4" />
            </IconButton>
            <Typography color="gray" className="font-normal">
              <strong className="text-gray-900">{selectActive}</strong> of
              <strong className="text-gray-900 ml-1">{totalPage}</strong>
            </Typography>
            <IconButton
              size="sm"
              variant="outlined"
              onClick={next}
              disabled={selectActive === totalPage}
            >
              <IoArrowForwardOutline strokeWidth={2} className="h-4 w-4" />
            </IconButton>
          </div>
          <div>
            <Button
              color="red"
              variant="text"
              onClick={handleOpen}
              className="mr-2"
            >
              <span>Close</span>
            </Button>
            <Button onClick={handleSelect} className="bg-green-900">
              <span>Select</span>
            </Button>
          </div>
        </DialogFooter>
      </Dialog>
    </div>
  );
}

export default SelectEmployeeLIst;
