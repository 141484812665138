import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Typography,
  Button,
  CardBody,
  CardFooter,
} from "@material-tailwind/react";
import { useFetchTransferProductQuery } from "../../store";
import { HashLoader } from "react-spinners";
import ProductionTransfer from "../modal_form_components/Warehouse/WarehouseTransfer/ProductionTransfer";
import ProductionItemTranfer from "../modal_form_components/Warehouse/WarehouseTransfer/ProductionItemTranfer";
import moment from "moment";

const TABLE_HEAD = [
  "Transfer Tracking Number",
  "Issuance From",
  "Requested By",
  "Transfer Request Date",
  "Actual Transfer Date",
  "Action",
  "Remarks",
];

function TransferProduction() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const user_info = JSON.parse(localStorage.getItem("user_info"));
  const { data, isLoading, error, refetch } = useFetchTransferProductQuery({
    page: page,
    page_size: pageSize,
    user_id: user_info.id
  });

  const totalPage = Math.ceil(data?.count / pageSize);

  const handlePreviousPage = () => {
    setPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setPage((prevPage) => prevPage + 1);
  };

  const handlePageSizeChange = (e) => {
    const selectedSize = parseInt(e.target.value);
    console.log("Selected Page Size:", selectedSize);
    setPageSize(selectedSize);
    setPage(1);
  };

  // const handleSearchChange = (event) => {
  //   setSearchTerm(event.target.value);
  //   setPage(1);
  // };

  // refetching the data from database every 5 sec
  const interval = 5000;
  useEffect(() => {
    refetch();
  }, [interval]);
  const remarks = [
    {
      id: 2,
      label: "Issue",
      color: "text-yellow-800 font-semibold",
    },
    {
      id: 1,
      label: "Receive",
      color: "text-green-800 font-semibold",
    },
  ];
  const prodLine = [
    {
      id: 1,
      name: "Bakery",
    },
    {
      id: 2,
      name: "Patty",
    },
    {
      id: 3,
      name: "Sandwich line",
    },
    {
      id: 4,
      name: "Catsup",
    },
    {
      id: 5,
      name: "Cake and Pastries",
    },
    {
      id: 6,
      name: "Premixes",
    },
    {
      id: 7,
      name: "Bread Crumbs Buns",
    },
    {
      id: 8,
      name: "Bread Crumbs",
    },
  ];
  let content = [];

  //handle change page sir of table
  const changePagesize = (e) => {
    setPageSize(e.target.value);
  };
  // conditioning for data fetching for api
  if (isLoading) {
    return <HashLoader />;
  } else if (error) {
    return <div>Refresh the page...</div>;
  } else {
    content = data?.results;
  }
  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="mb-8 flex items-center justify-between gap-8"></div>
        <div className="flex justify-end mr-10 space-x-2">
          <Typography>Page size:</Typography>
            <select
              className=" border border-black"
              label="Page Size"
              value={`${pageSize}`}
              onChange={(e) => handlePageSizeChange(e)}
            >
              <option value={`${10}`}>10</option>
              <option value={`${25}`}>25</option>
              <option value={`${50}`}>50</option>
            </select>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <table className="mt-4 w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head) => (
                <th
                  key={head}
                  className="border-y border-blue-gray-100 bg-blue-gray-50/50 p-4"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal leading-none opacity-70"
                  >
                    {head}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {content.map((tp, i) => {
              const isLast = tp === data.results.length - 1;
              const issuance_from = prodLine.find(
                (item) => item.id === tp.product_line
              )
              const remarksValue = remarks.find(
                (item) => item.id === tp.status
              )
              const classes = isLast
                ? "p-4"
                : "p-4 border-b border-blue-gray-50";
              return (
                <tr key={i}>
                  <td className={classes}>{tp.transfer_number}</td>
                  <td className={classes}>{issuance_from.name }</td>
                  <td className={classes}>{tp.requested_by}</td>
                  <td className={classes}>{tp.transfer_request_date}</td>
                  <td className={classes}>
                    {moment(new Date()).format("YYYY-MM-DD")}
                  </td>
                  <td className={classes}>
                    {/*  Status RECEIVE = 1 ISSUE = 2 */}
                    {tp.status === 2 ? (
                      <ProductionItemTranfer
                        transferId={tp.id}
                        refetch_trans={refetch}
                        trans_number={tp.transfer_number}
                      />
                    ) : (
                      <ProductionTransfer
                        status={tp.status}
                        transferId={tp.id}
                        refetch_trans={refetch}
                        trans_number={tp.transfer_number}
                      />
                    )}
                  </td>
                  <td className={classes}>
                    <Typography className={remarksValue.color}>
                      {remarksValue.label}
                    </Typography>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">
        <Typography variant="small" color="blue-gray" className="font-normal">
          Page {page} of {totalPage}
        </Typography>
        <div className="flex gap-2">
          <Button
            variant="outlined"
            size="sm"
            onClick={handlePreviousPage}
          >
            Previous
          </Button>
          <Button
            variant="outlined"
            size="sm"
            onClick={handleNextPage}
            disabled={page === totalPage}
          >
            Next
          </Button>
        </div>
      </CardFooter>
    </Card>
  );
}
export default TransferProduction;
