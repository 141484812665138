import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Card,
} from "@material-tailwind/react";
import { CiReceipt } from "react-icons/ci";
import { useRawMatDetailedPageQuery } from "../../../../store";
import { HashLoader } from "react-spinners";
import "react-datepicker/dist/react-datepicker.css";
import GeneratePODraft from "../../../form_components/pdf/GeneratePODraft";

function ReceivingExtractPO({ vrr_id }) {
  const trclasses = "p-4 border-b border-blue-gray-50";
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);

  const fetch_raw_mats_item = useRawMatDetailedPageQuery(vrr_id);

  const TABLE_HEAD = [
    "Items",
    "Unit Measure",
    "Ordered Quantity",
    "Quoted Price",
    "Subtotal",
    "Payment in terms (in days)",
  ];

  let content;
  let result;
  let vrrr_id;
  //fetching of data from api
  if (fetch_raw_mats_item.isLoading) {
    content = <HashLoader />;
  } else if (fetch_raw_mats_item.error) {
    content = <div>Error Loading...</div>;
  } else {
    result = fetch_raw_mats_item.data.data;
    vrrr_id = result.id;
    content = (
      <>
        <div className="flex flex-col gap-4  mt-7 w-full">
          <div className="flex flex-row">
            <div className="flex flex-col space-y-3 w-[50%]">
              <Typography>PO Number: {result.po_number}</Typography>
              <Typography>Vendor Name: {result.vendor.name}</Typography>
              <Typography>Contact Person: {result.vendor.poc}</Typography>
            </div>
            <div className="ml-5 w-[55%] space-y-3">
              <Typography>Contact Number: {result.vendor.contact}</Typography>
              <Typography>Address: {result.vendor.address}</Typography>
            </div>
          </div>
          <div className="mt-8">
            <Card className="h-full w-full overflow-scroll">
              <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TABLE_HEAD.map((head) => (
                      <th
                        key={head}
                        className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {result.vendor_items.map((vl, i) => {
                    let subtotal =
                      parseInt(vl.request_item.required_qty) *
                      parseFloat(vl.quoted_price);
                    if (subtotal > 0){
                      subtotal = subtotal.toFixed(2)
                    }
                    return (
                      <>
                        <tr key={i} className={trclasses}>
                          <td className={trclasses}>
                            {vl.request_item.item.name}
                          </td>
                          <td className={trclasses}>
                            {vl.request_item.item.unit_measurement}
                          </td>
                          <td className={trclasses}>
                            {vl.request_item.required_qty}
                          </td>
                          <td className={trclasses}>{`₱ ${vl.quoted_price}`}</td>
                          <td className={trclasses}>{`₱ ${subtotal}`}</td>
                          <td className={trclasses}>{vl.vendor.terms}</td>
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </Card>
          </div>
        </div>
        <div className="w-42 flex justify-between items-center my-7">
            <div className="w-68">
              <Typography variant="h5">
                Total: ₱{result.sub_total_amount}
              </Typography>
            </div>
          </div>
      </>
    );
  }

  return (
    <>
      <div className="container">
        <Button
          onClick={handleOpen}
          className=" flex gap-0 md:gap-0 lg:gap-2 bg-yellow-700"
          size="sm"
        >
          <CiReceipt strokeWidth={2} className="h-4 w-4" />
          Extract PO
        </Button>
        <Dialog
          open={open}
          handler={handleOpen}
          className="relative max-h-min"
          size="xxl"
        >
          <DialogHeader className="w-full bg-[#0B6E4F] rounded-sm flex justify-center">
            <Typography className="text-center text-white" variant="h5">
              Purchase Order
            </Typography>
          </DialogHeader>
          <DialogBody className="overflow-y-auto">
            {content}
          </DialogBody>
          <DialogFooter>
            <Button
              variant="text"
              color="red"
              onClick={handleOpen}
              className="mr-2"
            >
              <span>Close</span>
            </Button>
            <GeneratePODraft vrr_id={vrrr_id} />
          </DialogFooter>
        </Dialog>
      </div>
    </>
  );
}

export default ReceivingExtractPO;
