import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";

export function ApproveDeliveryConfirmation({ onConfirm, isSufficient, isSignatureEmpty }) {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => setOpen(!open);
  const handleConfirm = (event) => {
    // Execute the confirmation action
    onConfirm(event);
    // Close the dialog
    handleOpen();
  };

  return (
    <>
      <Button
      onClick={handleOpen}
      className="bg-green-900"
      disabled={!isSufficient || isSignatureEmpty == ""}>
        Submit
      </Button>
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>Confirm Approval</DialogHeader>
        <DialogBody>Are you sure to approve this delivery to transfer?</DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-1"
          >
            <span>Cancel</span>
          </Button>
          <Button variant="gradient" color="green" onClick={handleConfirm}>
            <span>Confirm</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}
