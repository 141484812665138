import React from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";
import { usePremixesRecipeComputationQuery } from "../../../store";


function PremixesIngredientsCom({id, batch}) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);

  let pre_rep_com = usePremixesRecipeComputationQuery({
    id: id,
    batch: batch
  })
  const TableHead = [
   "Name",
   "Required Quantity"
  ];
  let content;
  if (pre_rep_com.isLoading) {
    return <div>Loading....</div>;
  } else if (pre_rep_com.error) {
    return <div>Refresh the page....</div>;
  } else {
    let data = pre_rep_com.data.data;
    content = <>
        {data.map((pre_m, i) => {
          const isLast = pre_m.length - 1;
          const classes = isLast
            ? "p-4 text-[#252525] text-sm"
            : "p-4 border-b border-blue-gray-50 text-[#252525] text-sm";
          return (
            <tr key={i}>
              <td className={classes}>{
                pre_m.ingredient
              }</td>
              <td className={classes}>{
                pre_m.total
              }</td>
            </tr>
          );
        })}
    </>
  }

  return (
    <>
      <div className="container">
        <Button
          onClick={handleOpen}
          className=" flex gap-3"
          color="teal"
          size="sm"
        >
          View
        </Button>
        <Dialog open={open} handler={handleOpen} className="relative max-h-min">
          <form autoComplete="off">
            <DialogHeader className="w-full bg-[#0B6E4F] rounded-sm flex justify-center">
              <Typography className="text-center text-white" variant="h5">
                Ingredients
              </Typography>
            </DialogHeader>
            <DialogBody className="container overflow-y-auto max-h-[60vh]">
            <table className="w-full min-w-max table-auto text-left">
                <thead>
                  <tr>
                    {TableHead.map((head) => (
                      <th
                        key={head}
                        className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                      >
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal leading-none opacity-70"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {content}
                </tbody>
              </table>
            </DialogBody>
            <DialogFooter className="flex justify-between">
              <div>
                <Button
                  variant="text"
                  color="red"
                  onClick={handleOpen}
                  className="mr-1"
                >
                  <span>Close</span>
                </Button>
              </div>
            </DialogFooter>
          </form>
        </Dialog>
      </div>
    </>
  );
}

export default PremixesIngredientsCom;
