import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
} from "@material-tailwind/react";
import AdjDelRecpTbl from "../../table_components/Delivery/AdjDelRecTbl";
import { GrPowerReset } from "react-icons/gr";
import ReactSignatureCanvas from "react-signature-canvas";
import { useFetchGasListQuery } from "../../../store";
import { ToastContainer, toast } from "react-toastify";
import { Confirmation } from "../Error_trap_components/ErrorTrapComponent";
import { useUpdateSerialNumberMutation } from "../../../store";

function GasSerialNumberModal({ getOrderNumber, outletData, orderNumber }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const inputClasses = "flex flex-row items-center";
  const signatureClasses = "grid grid-flow-row grid-cols-1";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";
  const tableHead = ["ID", "SERIAL #", "NEW SERIAL #"];
  const trapMessage = "Are you sure to update these tank serial number?";

  const [gasSerialNumber, setGasSerialNumber] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(500);
  const [isReadyToSave, setIsReadyToSave] = useState(true);
  const [isMainReady, setIsMainReady] = useState(false);

  const { data, error, isLoading, isSuccess, refetch } = useFetchGasListQuery({
    page: currentPage,
    page_size: pageSize,
    order_number: getOrderNumber,
  });

  function handleSerialChange(index, value) {
    setGasSerialNumber((prev) => {
      const updatedSerialNumbers = [...prev];
      updatedSerialNumbers[index] = value;
      return updatedSerialNumbers;
    });
  }

  useEffect(() => {
    if (isSuccess) {
      const anyNull = data.results.some((gas) => gas.serial_number === null);
      setIsMainReady(!anyNull);
    }
  }, [isSuccess, data]);

  const [saveSerialNumber] = useUpdateSerialNumberMutation();

  const handleSave = async (event) => {
    event.preventDefault();

    let allData = {
      tankData: gasSerialNumber.map((sNum, index) => ({
        tankId: data.results[index].id,
        serialNumber: sNum,
      })),
    };

    try {
      const response = await saveSerialNumber(allData).unwrap();
      if (response.success) {
        toast.success(response.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
        refetch();
      } else {
        toast.error(response.message, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
        });
      }
    } catch (error) {
      const errorMessage =
        error?.data?.message || "An error occurred. Please try again later.";
      toast.error(errorMessage, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (isSuccess && data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess && data.results.length > 0) {
    return (
      <>
        <Button onClick={handleOpen} className="bg-[#1AA55D]">
          LPG Serial Number
        </Button>
        <Dialog
          size="lg"
          open={open}
          handler={handleOpen}
          className="relative max-h-min"
        >
          <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
            <Typography className="text-green-900" variant="h3">
              Tank Serial Number
            </Typography>
          </DialogHeader>
          <DialogBody className="overflow-y-auto max-h-[60vh]">
            <form>
              <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto">
                <div className="col-span-2 space-y-5">
                  <div className={inputClasses}>
                    <Input
                      label="Order Number"
                      name="order_number"
                      value={orderNumber}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Outlet"
                      name="outlet"
                      value={outletData.outlet}
                      readOnly
                    />
                  </div>
                  <div className={inputClasses}>
                    <Input
                      label="Address"
                      name="address"
                      value={outletData.address}
                      readOnly
                    />
                  </div>
                </div>
              </div>
              <div className="m-5">
                <table className="w-full min-w-max table-auto text-left">
                  <thead>
                    <tr>
                      {tableHead.map((head, index) => (
                        <th
                          key={index}
                          className="border-b border-blue-gray-100 bg-blue-gray-50 p-4"
                        >
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal leading-none opacity-70"
                          >
                            {head}
                          </Typography>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {data.results.map((gas, index) => {
                      const isLast = index === data.results.length - 1;
                      const classes = isLast
                        ? "p-4"
                        : "p-4 border-b border-blue-gray-50";
                      return (
                        <tr key={gas.id}>
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <div className="flex flex-col">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {gas.id || "N/A"}
                                </Typography>
                              </div>
                            </div>
                          </td>
                          <td className={classes}>
                            <div className="flex items-center gap-3">
                              <div className="flex flex-col">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-normal"
                                >
                                  {gas.serial_number || "No Serial Number"}
                                </Typography>
                              </div>
                            </div>
                          </td>
                          <td>
                            <div className="flex items-center">
                              <div className="flex items-center">
                                <div className="flex flex-col flex-grow">
                                  <input
                                    type="text"
                                    value={gasSerialNumber[index] || ""}
                                    onInput={(e) =>
                                      handleSerialChange(index, e.target.value)
                                    }
                                    className="flex-grow border border-1 border-blue-gray-300 p-1"
                                  />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </form>
          </DialogBody>
          <ToastContainer />
          <DialogFooter>
            <Confirmation
              onConfirm={handleSave}
              trapMessage={trapMessage}
              isReadyToSave={isReadyToSave}
            />
            <Button
              variant="text"
              color="red"
              className="mr-3"
              onClick={handleOpen}
            >
              <span>Close</span>
            </Button>
          </DialogFooter>
        </Dialog>
      </>
    );
  } else {
    return (
      <>
        <Button className="bg-orange-500" disabled={true}>
          No Order LPG
        </Button>
      </>
    );
  }
}

export default GasSerialNumberModal;
