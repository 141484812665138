import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
} from "@material-tailwind/react";
import AdjDelRecpTbl from "../../table_components/Delivery/AdjDelRecTbl";
import { GrPowerReset } from "react-icons/gr";
import ReactSignatureCanvas from "react-signature-canvas";
import { useFetchDeliveryItemsAdjustQuery } from "../../../store";

function AdjDelRcptModal({ getOrderNumber, outletData, orderNumber }) {
  let del_items_result = [];
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(!open);
  const inputClasses = "flex flex-row items-center";
  const signatureClasses = "grid grid-flow-row grid-cols-1";
  const signatureBoxclasses = "flex mb-1 mt-5";
  const boxResetClasses =
    "flex justify-center items-center h-[103px] w-[60px] bg-[#0B6E4F] shadow-md rounded-e-xl";

  // Assume you have the data fetched from your API
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(500);
  const { data, error, isLoading, isSuccess, refetch } =
    useFetchDeliveryItemsAdjustQuery({
      page: currentPage,
      page_size: pageSize,
      order_number: getOrderNumber,
    });

  if (isLoading) {
    // console.log('Data:', data);
    return <div>Loading...</div>;
  } else if (error) {
    return <div>Error loading data</div>;
  } else if (data.length === 0) {
    return <div>No data found.</div>;
  } else if (isSuccess) {
    del_items_result = data.results;
    // console.log(outletData)
  }

  const categoryMappings = {
    1: "Products",
    2: "Beverages",
    3: "Buns",
    4: "Wrappers",
    5: "Sundries",
    6: "Cigarettes",
    7: "Operating Supplies",
    8: "Tank",
    // Add more mappings as needed
  };
  // Organize data by categories
  const categories = {};
  del_items_result.forEach((item) => {
    const categoryId = item.product.category; // Assuming category ID is available in the data
    const categoryName = categoryMappings[categoryId];
    if (!categories[categoryName]) {
      categories[categoryName] = [];
    }
    categories[categoryName].push(item);
  });

  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]">
        View
      </Button>
      <Dialog
        size="lg"
        open={open}
        handler={handleOpen}
        className="relative max-h-min"
      >
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          <Typography className="text-green-900" variant="h3">
            Outlet Order Summary
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <form>
            <div className="grid grid-cols-4 gap-5 grid-auto-rows-auto">
              <div className="col-span-2 space-y-5">
                <div className={inputClasses}>
                  <Input
                    label="Order Number"
                    name="order_number"
                    value={orderNumber}
                    readOnly
                  />
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Outlet"
                    name="outlet"
                    value={outletData.outlet}
                    readOnly
                  />
                </div>
                <div className={inputClasses}>
                  <Input
                    label="Address"
                    name="address"
                    value={outletData.address}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className="m-5">
              {/* Render tables for each category present in the data */}
              {Object.entries(categories).map(([category, items]) => (
                <div key={category}>
                  <Typography className="text-green-900 mb-5" variant="h3">
                    {category}
                    <AdjDelRecpTbl items={items} />
                  </Typography>
                </div>
              ))}
            </div>
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            className="mr-3"
            onClick={handleOpen}
          >
            <span>Close</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default AdjDelRcptModal;
