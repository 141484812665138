import React, { useState } from "react";
import { RxHamburgerMenu } from "react-icons/rx";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  Card,
  Typography,
} from "@material-tailwind/react";
import { RxCross1 } from "react-icons/rx";
import TopLogo from "./../../img/BurgerMachineLogo.jpg";
import "./../../index.css";
import {
  HiOutlineDocumentReport,
  HiPresentationChartBar,
} from "react-icons/hi";
import { LuClipboardList, LuFactory, LuPackagePlus } from "react-icons/lu";
import { FaArrowRightArrowLeft, FaCheckToSlot, FaStore } from "react-icons/fa6";
import { RiInboxArchiveFill } from "react-icons/ri";
import { MdOutlineWarehouse } from "react-icons/md";
import { TbTransferIn } from "react-icons/tb";
import { Link } from "react-router-dom";
import { VscNotebook } from "react-icons/vsc";

const SmallScreenNav = () => {
  const [menu, setMenu] = useState(false);
  const [open, setOpen] = useState(0);
  const [toggleNav, setToggleNav] = useState(false);

  // Handle toggle the navbar and submenu visibility
  const handleToggle = () => {
    setMenu(!menu);
    setToggleNav(!toggleNav);
  };

  // Icon component for submenu toggle
  const Icon = ({ id, open }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } text-white h-5 w-5 mr-2 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );

  const subNav = [
    {
      id: 1,
      path: "/base/warehouse_purchaseorder",
      name: "Purchase Order",
      icon: <LuPackagePlus className="h-5 w-5  text-white" />,
    },
    {
      id: 2,
      path: "/base/warehouse_raw_materials_delivery",
      name: "Receiving Report",
      icon: <HiOutlineDocumentReport className="h-5 w-5  text-white" />,
    },
    {
      id: 3,
      path: "/base/warehouse_transfer",
      name: "Transfer",
      icon: <TbTransferIn className="h-5 w-5  text-white" />,
    },
    {
      id: 4,
      path: "/base/warehouse_summary",
      name: "Inventory",
      icon: <LuClipboardList className="h-5 w-5  text-white" />,
    },
  ];
  const account_detailed = JSON.parse(localStorage.getItem("account_detail"));

  // Handle opening of sub nav
  const handleOpen = (value) => setOpen(open === value ? 0 : value);

  let dashboard;
  let warehouse;
  let production;
  let delivery;
  let approval;
  let archive;
  let log;
  let outlet;

  // conditioning to hide the tabs to their respective access
  if ([1, 13].includes(account_detailed?.job_position)) {
    dashboard = (
      <li onClick={handleToggle}>
        <Link to="/base/dashboard">
          <div className="flex items-center space-x-3">
            <HiPresentationChartBar className="h-6 w-6 text-white" />
            <Typography className="text-white text-sm tracking-wide font-normal ">
              Dashboard
            </Typography>
          </div>
        </Link>
      </li>
    );
  }
  if ([1, 5, 6, 7, 8, 13, 14].includes(account_detailed?.job_position)) {
    warehouse = (
      <li>
        <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
          <AccordionHeader
            onClick={() => handleOpen(1)}
            className="border-none py-0"
          >
            <div className="flex items-center space-x-3">
              <MdOutlineWarehouse className="h-6 w-6 text-white" />
              <Typography className="text-white text-sm tracking-wide font-normal">
                Warehouse
              </Typography>
            </div>
          </AccordionHeader>
          <AccordionBody className=" pl-5 mx-auto rounded-xl mt-2 bg-green-900">
            {subNav.map((item) => (
              <Link to={item.path} key={item.id}>
                <div
                  className="w-[80vw] flex items-center space-x-3 p-3"
                  onClick={handleToggle}
                >
                  {item.icon}
                  <Typography className="text-white text-sm tracking-wide font-normal">
                    {item.name}
                  </Typography>
                </div>
              </Link>
            ))}
          </AccordionBody>
        </Accordion>
      </li>
    );
  }
  if ([1, 10, 9, 11, 12].includes(account_detailed?.job_position)) {
    production = (
      <li onClick={handleToggle}>
        <Link to="/base/production">
          <div className="flex items-center space-x-3">
            <LuFactory className="h-6 w-6 text-white" />
            <Typography className="text-white text-sm tracking-wide font-normal">
              Production
            </Typography>
          </div>
        </Link>
      </li>
    );
  }
  if ([1, 13, 14].includes(account_detailed?.job_position)) {
    delivery = (
      <li onClick={handleToggle}>
        <Link to="/base/delivery">
          <div className="flex items-center space-x-3">
            <FaArrowRightArrowLeft className="h-6 w-6 text-white" />
            <Typography className="text-white text-sm tracking-wide font-normal">
              Delivery
            </Typography>
          </div>
        </Link>
      </li>
    );
  }
  if ([1, 13, 14, 20, 21, 23, 24, 22].includes(account_detailed?.job_position)) {
    outlet = (
      <li onClick={handleToggle}>
        <Link to="/base/outlet">
          <div className="flex items-center space-x-3">
            <FaStore className="h-6 w-6 text-white" />
            <Typography className="text-white text-sm tracking-wide font-normal">
              Outlet
            </Typography>
          </div>
        </Link>
      </li>
    );
  }
  if ([1, 13, 14].includes(account_detailed?.job_position)) {
    approval = (
      <li onClick={handleToggle}>
        <Link to="/base/approval">
          <div className="flex items-center space-x-3">
            <FaCheckToSlot className="h-6 w-6 text-white" />
            <Typography className="text-white text-sm tracking-wide font-normal">
              Approval
            </Typography>
          </div>
        </Link>
      </li>
    );
  }
  if ([1, 13, 14].includes(account_detailed?.job_position)) {
    archive = (
      <li onClick={handleToggle}>
        <Link to="/base/archive">
          <div className="flex items-center space-x-3">
            <RiInboxArchiveFill className="h-6 w-6 text-white" />
            <Typography className="text-white text-sm tracking-wide font-normal">
              Archive
            </Typography>
          </div>
        </Link>
      </li>
    );
  }
  if ([1, 13, 14].includes(account_detailed?.job_position)) {
    log = (
      <li onClick={handleToggle}>
        <Link to="/base/logs">
          <div className="flex items-center space-x-3">
            <VscNotebook className="h-6 w-6 text-white" />
            <Typography className="text-white text-sm tracking-wide font-normal">
              Logs
            </Typography>
          </div>
        </Link>
      </li>
    );
  }

  if ([15, 16].includes(account_detailed?.job_position)) {
    outlet = (
      <li onClick={handleToggle}>
        <Link to="/base/delivery">
          <div className="flex items-center space-x-3">
            <FaStore className="h-6 w-6 text-white" />
            <Typography className="text-white text-sm tracking-wide font-normal">
              Outlet
            </Typography>
          </div>
        </Link>
      </li>
    );
  }


  return (
    <>
      <div>
        <div className="flex justify-between items-center bg-[#137944]">
          <img
            src={TopLogo}
            className="md:border-bottom-white md:border-b-8 md:h-20 h-20 p-2 md:p-0"
            alt="toplogo"
          />
          <Typography className="mr-10 text-white" onClick={handleToggle}>
            <div className={`menu-icon ${menu ? "menu-open" : ""}`}>
              {!menu ? (
                <RxHamburgerMenu className="text-center h-10 w-8" />
              ) : (
                <RxCross1 className="text-center h-10 w-8 transition-transform" />
              )}
            </div>
          </Typography>
        </div>
        <hr className="border border-white" />
        <div className={toggleNav ? "fade-in" : "hidden"}>
          <div className={`fade-in ${toggleNav ? "block" : "hidden"}`}>
            <Card className="rounded-none bg-[#137944]">
              <ul className="mb-8 mt-3 mx-auto p-4 space-y-8">
                {dashboard}
                {warehouse}
                {production}
                {delivery}
                {approval}
                {archive}
                {log}
                {outlet}
              </ul>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmallScreenNav;
