import { useState } from "react";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
  Input,
} from "@material-tailwind/react";
import ExtractPOtbl from "../../../table_components/Warehouse/PurchaseOrder/ExtractPOtbl";
import axios from "axios";

function PoListViewButton() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(!open);
  const formInput = {
    vendorname: "",
    contactperson: "",
    contactnumber: "",
    address: "",
    deliverydate: "",
  };
  const [input, setInput] = useState(formInput);

  function handleChange(e) {
    setInput({ ...input, [e.target.name]: e.target.value });
    //
  }

  function handleSubmit(e) {
    e.preventDefault();
    console.log(input);
    setInput({
      vendorname: "",
      contactperson: "",
      contactnumber: "",
      address: "",
      deliverydate: "",
    });
    //
  }

  return (
    <>
      <Button onClick={handleOpen} className="bg-[#1AA55D]" size="sm">
        Extract PO
      </Button>
      <Dialog open={open} handler={handleOpen} className="relative max-h-min">
        <DialogHeader className="w-full space-x-10 rounded-sm flex justify-center">
          <img
            src="https://oodc.com.ph/wp-content/uploads/2023/05/viber_image_2023-05-02_14-20-42-700.png"
            className="aspect-auto w-48"
            alt="logo"
          />
          <Typography className="text-green-900 font-bold text-2xl lg:text-3xl leading-normal">
            PURCHASE ORDER
          </Typography>
        </DialogHeader>
        <DialogBody className="overflow-y-auto max-h-[60vh]">
          <form>
            <div className="flex flex-col gap-4  mt-5 w-full">
              <div className="flex flex-row">
                <div className="flex flex-col space-y-3 w-[50%]">
                  <Input
                    value={input.vendorname}
                    onChange={handleChange}
                    label="Vendor Name"
                    name="vendorname"
                  />
                  <Input
                    value={input.contactperson}
                    onChange={handleChange}
                    label="Contact Person"
                    name="contactperson"
                  />
                  <Input
                    value={input.contactnumber}
                    onChange={handleChange}
                    label="Contact Number"
                    name="contactnumber"
                  />
                </div>
                <div className="ml-5 w-[55%] space-y-3">
                  <Input
                    value={input.address}
                    onChange={handleChange}
                    label="Address"
                    name="address"
                  />
                  <Input
                    value={input.deliverydate}
                    onChange={handleChange}
                    label="Delivery Date"
                    name="deliverydate"
                  />
                </div>
              </div>
            </div>
            <div className="m-5">
              <ExtractPOtbl />
            </div>
          </form>
        </DialogBody>
        <DialogFooter>
          <Button
            variant="text"
            color="red"
            onClick={handleOpen}
            className="mr-3"
          >
            <span>Close</span>
          </Button>
          <Button className="bg-green-900" onClick={handleSubmit}>
            <span>Extract PDF</span>
          </Button>
        </DialogFooter>
      </Dialog>
    </>
  );
}

export default PoListViewButton;
